<template>
  <div class="slot">
    <div class="slotAndFishContainer">
      <div class="tabShow">
        <!-- <div class="search-bar">
          <input v-model="searchObj.searchKey" class="input" type="seach" placeholder="Tìm kiếm" v-on:input="searchFun">
          <span class="iconfont icon-icon_search"></span>
        </div> -->
        <div class="headItem slot">
          <div class="head">
            <div class="list-wp slotList list-effect">
              <div class="list-item" v-for="(item, index) in tabObj.data" @click="tabItemClick(index)">
                <img :src="$img(`slot/${item.name}_icon_2.png`, 'mobile')" alt="">
                <template v-if="index !== 0">
                  <div class="brand-icon">
                    <img :src="$img(`slot/${item.name}_icon_3.png`, 'mobile')" alt="">
                  </div>
                  <span class="brand-name">{{ $tools.getFactoryName(item.name) }}</span>
                </template>
                <img class="btn" src="@/assets/mobile/slot/slot_flag.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CommonItemPop :data="tabObj.data[curSelectIndex].list" v-if="showPop" />
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.css";
import CommonItemPop from "@/views/mobile/components/CommonItemPop.vue"
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const { version } = useGetters("global", ["version"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
let showPop = ref(false);
bus.on(bus.event.showPop, (bool) => {
  showPop.value = false;
})
let curSelectIndex = ref(0);
let tabObj = reactive({
  data: []
});
tabObj.data.push(reactive({ name: 'apiHot', list: getSlotRecommendData() }));//自定义一个热推荐对象
for (let key in lobbyInfo.value.StaticPicture.Slot) {
  if (key == 'apiLuckyWin') {
    continue
  }
  let info = lobbyInfo.value.StaticPicture.Slot[key];
  if (info.GameList.length > 0) {
    tabObj.data.push(
      {
        name: key,
        list: info.GameList
      }
    );
  }
}
function getSlotRecommendData() {
  let result = [];
  let data = lobbyInfo.value.StaticPicture.Slot;
  for (let key in data) {
    if (key == 'apiLuckyWin') {
      continue
    }
    let gameData = data[key].GameList;
    for (let key1 in gameData) {
      let gameInfo = gameData[key1];
      if (gameInfo.Hot > 0) {
        result.push(gameInfo);
      }
    }
  }
  // log.info('getSlotRecommendData---', result);
  return result;
}
function tabItemClick(index) {
  curSelectIndex.value = index;
  showPop.value = true;
}
</script>

<style lang="scss" scoped>
.slot {
  position: relative;
  width: 100%;

  .slotAndFishContainer {
    position: relative;
    background: #35416d;

    .tabShow {
      padding-top: 1.066667vw;

      .search-bar {
        width: 250px;
        margin: 0 auto;
        position: relative;

        .input {
          position: relative;
          width: 100%;
          background: #293356;
          color: #fff;
          box-shadow: none;
          border-radius: 2.933333vw;
          border: none;
          min-width: 200px;
          height: 30px;
          font-size: 16px;
          padding: 0 30px;
          box-sizing: border-box;
        }

        .iconfont {
          position: absolute;
          color: #90a2dc;
          top: 50%;
          transform: translateY(-50%);
          margin-left: -30px;
          font-size: 22px;
        }
      }

      .headItem {
        background: #35416d;
        padding: 1.6vw 0 4.266667vw;

        .head {
          width: 100%;

          .slotList {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 0 4vw 4vw;

            .list-item {
              width: 43.733333vw;
              height: 24.533333vw;
              position: relative;
              background-image: url("@/assets/mobile/slot/sub_bg.png");
              background-size: 100% 100%;
              border-radius: 1.066667vw;
              overflow: hidden;
              margin: 1.866667vw 0;

              img {
                width: 100%;
                height: 100%;
              }

              .brand-icon {
                width: 17.6vw;
                height: 9.6vw;
                position: absolute;
                top: 1.066667vw;
                left: 2.666667vw;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                  width: auto;
                  height: auto;
                  max-width: 90%;
                  max-height: 90%;
                }
              }

              .brand-name {
                font-size: 3.2vw;
                color: #fff;
                position: absolute;
                top: 11.733333vw;
                left: 0;
                display: inline-block;
                width: 22.933333vw;
                text-align: center;
              }

              .btn {
                width: 19.2vw;
                height: 5.333333vw;
                background: #293666;
                border-radius: 1.6vw 0 1.6vw 0;
                font-size: 3.2vw;
                color: #dbe6ff;
                position: absolute;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }

          .list-effect {
            -webkit-animation: list-effect-ani .5s ease-in-out forwards;
            animation: list-effect-ani .5s ease-in-out forwards;
          }

          @keyframes list-effect-ani {
            0% {
              opacity: 0;
              -webkit-transform: translateY(5.333333vw);
              transform: translateY(5.333333vw);
            }

            100% {
              opacity: 1;
              -webkit-transform: translateY(0);
              transform: translateY(0);
            }
          }
        }
      }
    }
  }
}
</style>