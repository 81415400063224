<template>
  <div class="login">
    <div class="mask"></div>
    <div class="wrapper">
      <div class="header">
        <span>Đăng nhập</span>
        <button class="close-btn" @click="close()">
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </button>
      </div>
      <div class="body">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-position="top">
          <el-form-item class="el-form-item--feedback" prop="account">
            <div class="form-layout">
              <el-input v-model="ruleForm.account" type="text" autocomplete="on" placeholder="Tài khoản" name="account" />
              <img class="left-icon" src="@/assets/pc/register/account.png" alt="">
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div class="form-layout">
              <el-input v-model="ruleForm.password" type="text" autocomplete="off" placeholder="6-15 ký tự chữ và số"
                name="password" />
              <img class="left-icon" src="@/assets/pc/register/password.png" alt="">
            </div>
          </el-form-item>
          <p class="links">
            <el-checkbox label="Nhớ mật khẩu ">
            </el-checkbox>
          </p>
        </el-form>
      </div>
      <div class="footer">
        <el-button class="loginBtn" @click="login()">Đăng nhập</el-button>
        <el-button class="registerBtn" @click="openRegister()">Đăng ký </el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import tools from "@/utils/tools";
import { nameRule, passRule, phoneRule, realNameRule } from '@/utils/vaildate.js'
import { useGetters, useActions } from '@/store/hooks/storeState/index'
import { bus } from "@/components/core/bus";
import { getCurrentInstance } from 'vue'
import log from "@/utils/logger";
const $tools = getCurrentInstance().appContext.config.globalProperties.$tools
const { uuid } = useGetters("global", ["globalState", "uuid"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const ruleFormRef = ref()
const ruleForm = reactive({
  account: '',
  password: '',
  checkPass: "",
  phone: '',
  realName: '',
  code: '',
  codeId: '',
  codeImg: '',
  agreement: false,
})

const checkPassRule = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Xác nhận mật khẩu'))
  } else if (value !== ruleForm.password) {
    callback(new Error("Nhập 2 lần mật khẩu không khớp"))
  } else {
    callback()
  }
}
const rules = reactive({
  account: [{ validator: nameRule, trigger: 'blur' }],
  password: [{ validator: passRule, trigger: 'blur' }],
})
const close = () => {
  bus.emit(bus.event.loginState, false);
}

const openRegister = () => {
  close();
  bus.emit(bus.event.registerState, true);
}

const login = () => {
  let params = {
    account: ruleForm.account,
    password: ruleForm.password,
    platform: tools.platform(),
    uuid: uuid.value,
    uuid_web: uuid.value,
    code: '',
    codeId: '',
  };
  doLogin({
    params: params,
    url: http.userLogin,
  }).then(
    (resolve) => {
      ElMessage({
        message: 'Đăng nhập thành công',
        type: 'success'
      });
      close();
    },
    (reject) => {
      ElMessage({
        message: reject.ErrMsg,
        type: 'error'
      });
    }
  );
}
</script>

<style lang="scss" scoped>
.login {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 10001;

  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
    z-index: -1;

  }

  .wrapper {
    position: relative;
    margin: 0 auto 50px;
    margin-top: 15vh;
    width: 440px;
    background: #292e3b;
    border-radius: 24px;
    box-shadow: none;

    .header {
      background: url('@/assets/pc/register/bg.png') 0 0 no-repeat !important;
      background-size: cover !important;
      border-radius: 24px 24px 0 0;
      box-shadow: none;
      height: 80px;
      line-height: 60px;
      text-align: center;

      span {
        font-size: 16px;
        line-height: 24px;
        color: white;
        font-weight: bold;
      }

      .close-btn {
        width: 32px;
        height: 32px;
        font-size: 24px;
        position: absolute;
        top: 14px;
        right: 20px;
        padding: 0;
        background: 0 0;
        border: none;
        outline: none;
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .close-btn:hover {
        zoom: 1.02;
      }
    }

    .body {
      background: #292e3b !important;
      padding: 20px 35px;
      color: #fff;
      font-size: 14px;
      word-break: break-all;

      .el-form-item {
        margin-bottom: 5px !important;

        :deep(.el-form-item__content) {
          margin-bottom: 20px;
          line-height: 40px;
          position: relative;
          font-size: 14px;
        }

        .form-layout {
          width: 100%;

          .left-icon {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 22px;
            height: 24px;
          }

          .el-input {
            background-color: #10131a !important;
            border-radius: 6px !important;
            color: #c0c4cc !important;
            height: 48px;
            padding-left: 40px !important;

            :deep(.el-input__wrapper) {
              width: 100%;
              background-color: #10131a !important;
              box-shadow: none !important;
              padding: 0;
              border-radius: none;

              .el-input__inner {
                width: 100%;
                height: 100%;
                padding: 0;
                border: none;
                background: 0 0;
                color: inherit;
                box-sizing: border-box;
              }

              .el-input__suffix {
                position: absolute;
                height: 100%;
                right: 5px;
                top: 0;
                text-align: center;
                color: #c0c4cc;
                transition: all .3s;
                pointer-events: none;
              }
            }
          }
        }
      }

      .links {
        height: 30px;
        float: left;
        margin-bottom: 10px;
        color: #90a2dc;

        .el-checkbox {
          color: #8491a5 !important;
          margin-right: 0;
          font-size: 14px;
          position: relative;
          cursor: pointer;
          display: inline-block;
          white-space: nowrap;
          user-select: none;

          .el-checkbox__input {
            white-space: nowrap;
            cursor: pointer;
            outline: none;
            display: inline-block;
            line-height: 1;
            position: relative;
            vertical-align: middle;
          }

          :deep(.el-checkbox__inner) {
            border-color: #b3b3b3 !important;
            background-color: #292e3b;
          }

          :deep(.el-checkbox__label) {
            color: #c0c4cc !important;
            display: inline-block;
            padding-left: 10px;
            line-height: 19px;
            font-size: 14px;
          }
        }
      }
    }

    .footer {
      border-radius: 0 0 24px 24px;
      padding: 0 35px 15px;
      background: #292e3b !important;
      text-align: center;
      cursor: pointer;

      .el-button {
        display: block;
        line-height: 40px;
        margin: 0 auto 20px;
        padding: 0;
        width: 100% !important;
      }

      .loginBtn {
        background: linear-gradient(#292e3b, #292e3b) padding-box, linear-gradient(100deg, #94b3ff, #5b7cfe) border-box !important;
        border: 2px solid transparent !important;
        border-radius: 24px !important;
        color: #fff !important;
        height: 48px !important;
      }

      .registerBtn {
        background: linear-gradient(#292e3b, #292e3b) padding-box, #9cb0f0 border-box !important;
        border: 2px solid transparent !important;
        border-radius: 24px !important;
        color: #fff !important;
        height: 48px !important;
      }

      .to-login {
        color: #9cb0f0;
        margin: 0 auto 5px;
        display: inline-block;
        text-decoration: underline;
        font-size: 14px;
      }
    }
  }
}
</style>
