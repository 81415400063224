<template>
  <div v-for="(item, index) in playData">
    <div
      style="
        width: 100%;
        line-height: 8.533333vw;
        height: 8.533333vw;
        background: #35416d;
        font-size: 3.733333vw;
        color: #fff;
        text-align: center;
        font-weight: 400;
        margin-top: 2.666667vw;
      "
    >
      {{ item.Name }}
    </div>

    <div
      v-if="item.PlayCode == 'SUM'"
      style="padding: 1.333333vw 2.133333vw 0"
      class="flexs_sb"
    >
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Tài', item.SubPlays[0].Odds / 1000, 'SUM_DXDS')"
        :style="
          containTS('Tài')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Tài
        </div>
        {{ item.SubPlays[0].Odds / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Xỉu', item.SubPlays[0].Odds / 1000, 'SUM_DXDS')"
        :style="
          containTS('Xỉu')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Xỉu
        </div>
        {{ item.SubPlays[0].Odds / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Lẻ', item.SubPlays[0].Odds / 1000, 'SUM_DXDS')"
        :style="
          containTS('Lẻ')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Lẻ
        </div>
        {{ item.SubPlays[0].Odds / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Chẵn', item.SubPlays[0].Odds / 1000, 'SUM_DXDS')"
        :style="
          containTS('Chẵn')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Chẵn
        </div>
        {{ item.SubPlays[0].Odds / 1000 }}
      </div>
    </div>
    <div
      v-if="item.PlayCode == 'DXDS'"
      style="padding: 1.333333vw 2.133333vw 0"
      class="flexs_sb"
    >
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Tài Lẻ', item.SubPlays[0].Odds / 1000, 'DXDS_T')"
        :style="
          containTS('Tài Lẻ')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Tài Lẻ
        </div>
        {{ item.SubPlays[0].Odds / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Xỉu Lẻ', item.SubPlays[0].Odds / 1000, 'DXDS_T')"
        :style="
          containTS('Xỉu Lẻ')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Xỉu Lẻ
        </div>
        {{ item.SubPlays[0].Odds / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Tài Chẵn', item.SubPlays[0].Odds / 1000, 'DXDS_T')"
        :style="
          containTS('Tài Chẵn')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Tài Chẵn
        </div>
        {{ item.SubPlays[0].Odds / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Xỉu Chẵn', item.SubPlays[0].Odds / 1000, 'DXDS_T')"
        :style="
          containTS('Xỉu Chẵn')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Xỉu Chẵn
        </div>
        {{ item.SubPlays[0].Odds / 1000 }}
      </div>
    </div>

    <div
      v-if="item.PlayCode == 'ELE5'"
      style="padding: 1.333333vw 2.133333vw 0"
      class="flexs_sb"
    >
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Kim', getJMSHT('J', item.SubPlays) / 1000, 'ELE5_JT')"
        :style="
          containTS('Kim')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Kim
        </div>
        {{ getJMSHT("J", item.SubPlays) / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Mộc', getJMSHT('M', item.SubPlays) / 1000, 'ELE5_MH')"
        :style="
          containTS('Mộc')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Mộc
        </div>
        {{ getJMSHT("M", item.SubPlays) / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Thủy', getJMSHT('S', item.SubPlays) / 1000, 'ELE5_S')"
        :style="
          containTS('Thủy')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Thủy
        </div>
        {{ getJMSHT("S", item.SubPlays) / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Hỏa', getJMSHT('H', item.SubPlays) / 1000, 'ELE5_MH')"
        :style="
          containTS('Hỏa')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Hỏa
        </div>
        {{ getJMSHT("H", item.SubPlays) / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="tsClick(item.Name, 'Thổ', getJMSHT('T', item.SubPlays) / 1000, 'ELE5_JT')"
        :style="
          containTS('Thổ')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Thổ
        </div>
        {{ getJMSHT("T", item.SubPlays) / 1000 }}
      </div>
    </div>
    <div
      v-if="item.PlayCode == 'UPDOWN'"
      style="padding: 1.333333vw 2.133333vw 0"
      class="flexs_sb"
    >
      <div
        class="colum playNolmal canClick"
        @click="
          tsClick(item.Name, 'Trên', getSZX('S', item.SubPlays) / 1000, 'UPDOWN_UP_DOWN')
        "
        :style="
          containTS('Trên')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Trên
        </div>
        {{ getSZX("S", item.SubPlays) / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="
          tsClick(item.Name, 'Hoà', getSZX('Z', item.SubPlays) / 1000, 'UPDOWN_DRAW')
        "
        :style="
          containTS('Hoà')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Hoà
        </div>
        {{ getSZX("Z", item.SubPlays) / 1000 }}
      </div>
      <div
        class="colum playNolmal canClick"
        @click="
          tsClick(item.Name, 'Dưới', getSZX('X', item.SubPlays) / 1000, 'UPDOWN_UP_DOWN')
        "
        :style="
          containTS('Dưới')
            ? 'background-image: linear-gradient(135deg,#7146ff,#4a69ff);'
            : ''
        "
      >
        <div
          style="
            margin-top: 0px;
            font-size: 3.733333vw;
            font-weight: 600;
            margin-bottom: 4px;
          "
        >
          Dưới
        </div>
        {{ getSZX("X", item.SubPlays) / 1000 }}
      </div>
    </div>
  </div>

  <div class="flexs_r_c" style="height: 8.533333vw">
    <div
      v-for="(item, index) in titleArr"
      class="canClick"
      :key="index"
      @click="selectIndex = index"
      style="
        width: 25%;
        height: 8.533333vw;
        line-height: 8.533333vw;
        text-align: center;
        font-size: 12.5px;
        color: #aac5f2;
      "
      :class="selectIndex == index ? 'select' : 'nolmal'"
    >
      {{ item }}
    </div>
  </div>

  <div
    v-if="selectIndex == 0"
    style="margin-top: 14px; position: relative; padding-bottom: 20px"
  >
    <div class="flexs_sb" style="padding: 0 50px">
      <div class="flexs_r_c">
        <div class="redTag tag">Tài</div>
        <progress-bar
          v-if="bigSmallData.length"
          style="margin-left: 5px"
          :target="getPersent(0)"
          :colorTop="'#f94168'"
          :percentageColor="'white'"
          :colorBg="'transparent'"
          :percentageSize="7"
          :textSize="7"
          :sizeOut="25"
          :sizeIn="25"
          :total="100"
          :encumbrances="0"
        />
      </div>

      <div class="flexs_r_c">
        <div class="blueTag tag">Xỉu</div>
        <progress-bar
          v-if="bigSmallData.length"
          style="margin-left: 5px"
          :target="getPersent(1)"
          :colorTop="'#26a1ff'"
          :percentageColor="'white'"
          :colorBg="'transparent'"
          :percentageSize="7"
          :textSize="7"
          :sizeOut="25"
          :sizeIn="25"
          :total="100"
          :encumbrances="0"
        />
      </div>
    </div>
    <div
      style="margin-top: 10px; justify-content: center; align-items: center"
      class="flexs_r"
    >
      <div v-for="(item, index) in 16" :key="item">
        <div v-for="item1 in bigSmallData[index]" :key="item1">
          <div
            class="corner redTag"
            v-if="item1 == 'big'"
            style="margin-top: 2px; margin-left: 2px"
          >
            T
          </div>
          <div class="corner blueTag" v-else style="margin-top: 2px; margin-left: 2px">
            X
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="selectIndex == 1"
    style="margin-top: 14px; position: relative; padding-bottom: 20px"
  >
    <div class="flexs_sb" style="padding: 0 50px">
      <div class="flexs_r_c">
        <div class="redTag tag" style="">Lẻ</div>
        <progress-bar
          v-if="twoOneData.length"
          style="margin-left: 5px"
          :target="getPersent(2)"
          :colorTop="'#f94168'"
          :percentageColor="'white'"
          :colorBg="'transparent'"
          :percentageSize="7"
          :textSize="7"
          :sizeOut="25"
          :sizeIn="25"
          :total="100"
          :encumbrances="0"
        />
      </div>
      <div class="flexs_r_c">
        <div class="blueTag tag" style="">Chẵn</div>
        <progress-bar
          v-if="twoOneData.length"
          style="margin-left: 5"
          :target="getPersent(3)"
          :colorTop="'#26a1ff'"
          :percentageColor="'white'"
          :colorBg="'transparent'"
          :percentageSize="7"
          :textSize="7"
          :sizeOut="25"
          :sizeIn="25"
          :total="100"
          :encumbrances="0"
        />
      </div>
    </div>
    <div
      style="margin-top: 10px; justify-content: center; align-items: center"
      class="flexs_r"
    >
      <div v-for="(item, index) in 16" :key="item">
        <div v-for="item1 in twoOneData[index]" :key="item1">
          <div
            class="corner redTag"
            v-if="item1 == 'single'"
            style="margin-top: 2px; margin-left: 2px"
          >
            L
          </div>
          <div class="corner blueTag" v-else style="margin-top: 2px; margin-left: 2px">
            C
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="selectIndex == 2"
    style="margin-top: 14px; position: relative; padding-bottom: 20px"
  >
    <div class="flexs_r_c" style="justify-content: center; align-items: center">
      <div class="redTag tag" style="">Trên</div>
      <progress-bar
        v-if="upDownData.length"
        style="margin-left: 5px"
        :target="getPersent(4)"
        :colorTop="'#f94168'"
        :percentageColor="'white'"
        :colorBg="'transparent'"
        :percentageSize="12"
        :textSize="12"
        :sizeOut="25"
        :sizeIn="25"
        :total="100"
        :encumbrances="0"
      />

      <div class="grayTag tag" style="margin-left: 20px">Hoà</div>
      <progress-bar
        v-if="upDownData.length"
        style="margin-left: 5px"
        :target="getPersent(5)"
        :colorTop="'#909090'"
        :percentageColor="'white'"
        :colorBg="'transparent'"
        :percentageSize="12"
        :textSize="12"
        :sizeOut="25"
        :sizeIn="25"
        :total="100"
        :encumbrances="0"
      />

      <div class="blueTag tag" style="margin-left: 20px">Dưới</div>
      <progress-bar
        v-if="upDownData.length"
        style="margin-left: 5px"
        :target="getPersent(6)"
        :colorTop="'#26a1ff'"
        :percentageColor="'white'"
        :colorBg="'transparent'"
        :percentageSize="12"
        :textSize="12"
        :sizeOut="25"
        :sizeIn="25"
        :total="100"
        :encumbrances="0"
      />
    </div>
    <div
      style="margin-top: 10px; justify-content: center; align-items: center"
      class="flexs_r"
      v-if="upDownData.length"
    >
      <div v-for="(item, index) in 16" :key="item">
        <div v-for="item1 in upDownData[index]" :key="item1">
          <div
            class="corner redTag"
            v-if="item1 == 'top'"
            style="margin-top: 2px; margin-left: 2px"
          >
            T
          </div>
          <div
            class="corner grayTag"
            v-else-if="item1 == 'center'"
            style="margin-top: 2px; margin-left: 2px"
          >
            H
          </div>
          <div class="corner blueTag" v-else style="margin-top: 2px; margin-left: 2px">
            D
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="selectIndex == 3"
    style="margin-top: 14px; position: relative; padding-bottom: 20px"
  >
    <div class="flexs_r_c" style="justify-content: center; align-items: center">
      <div class="jinTag tag" style="">Kim</div>
      <progress-bar
        v-if="fiveData.length"
        style="margin-left: 5px"
        :target="getPersent(7)"
        :colorTop="'#ffbb00'"
        :percentageColor="'white'"
        :colorBg="'transparent'"
        :percentageSize="12"
        :textSize="12"
        :sizeOut="25"
        :sizeIn="25"
        :total="100"
        :encumbrances="0"
      />

      <div class="muTag tag" style="margin-left: 20px">Mộc</div>
      <progress-bar
        v-if="fiveData.length"
        style="margin-left: 5px"
        :target="getPersent(8)"
        :colorTop="'#6dd400'"
        :percentageColor="'white'"
        :colorBg="'transparent'"
        :percentageSize="12"
        :textSize="12"
        :sizeOut="25"
        :sizeIn="25"
        :total="100"
        :encumbrances="0"
      />

      <div class="blueTag tag" style="margin-left: 20px">Thủy</div>
      <progress-bar
        v-if="fiveData.length"
        style="margin-left: 5px"
        :target="getPersent(9)"
        :colorTop="'#26a1ff'"
        :percentageColor="'white'"
        :colorBg="'transparent'"
        :percentageSize="12"
        :textSize="12"
        :sizeOut="25"
        :sizeIn="25"
        :total="100"
        :encumbrances="0"
      />
    </div>
    <div
      class="flexs_r_c"
      style="margin-top: 10px; justify-content: center; align-items: center"
    >
      <div class="redTag tag" style="">Hỏa</div>
      <progress-bar
        v-if="fiveData.length"
        style="margin-left: 5px"
        :target="getPersent(10)"
        :colorTop="'#f94168'"
        :percentageColor="'white'"
        :colorBg="'transparent'"
        :percentageSize="12"
        :textSize="12"
        :sizeOut="25"
        :sizeIn="25"
        :total="100"
        :encumbrances="0"
      />

      <div class="tuTag tag" style="margin-left: 20px">Thổ</div>
      <progress-bar
        v-if="fiveData.length"
        style="margin-left: 5px"
        :target="getPersent(11)"
        :colorTop="'#ca8c10'"
        :percentageColor="'white'"
        :colorBg="'transparent'"
        :percentageSize="12"
        :textSize="12"
        :sizeOut="25"
        :sizeIn="25"
        :total="100"
        :encumbrances="0"
      />
    </div>
    <div
      style="margin-top: 10px; justify-content: center; align-items: center"
      class="flexs_r"
      v-if="fiveData.length"
    >
      <div v-for="(item, index) in 16" :key="item">
        <div v-for="item1 in fiveData[index]" :key="item1">
          <div
            class="corner jinTag"
            v-if="item1 == 'jin'"
            style="margin-top: 2px; margin-left: 2px"
          >
            K
          </div>
          <div
            class="corner muTag"
            v-else-if="item1 == 'mu'"
            style="margin-top: 2px; margin-left: 2px"
          >
            M
          </div>
          <div
            class="corner blueTag"
            v-else-if="item1 == 'shui'"
            style="margin-top: 2px; margin-left: 2px"
          >
            T
          </div>
          <div
            class="corner redTag"
            v-else-if="item1 == 'huo'"
            style="margin-top: 2px; margin-left: 2px"
          >
            H
          </div>
          <div class="corner tuTag" v-else style="margin-top: 2px; margin-left: 2px">
            T
          </div>
        </div>
      </div>
    </div>
  </div>

  <vipInfo v-if="showVip" :info="selectInfo" @close="showVip = false"></vipInfo>
</template>

<script>
import { bus, busName } from "@/components/core/bus";

import ProgressBar from "./ProgressBar.vue";
import vipInfo from "./vipInfo.vue";

export default {
  components: {
    ProgressBar,
    vipInfo,
  },
  data() {
    return {
      titleArr: ["Tài Xỉu", "Chẵn lẻ", "Trên hòa dưới", "Ngũ hành"],
      selectIndex: 0,
      bigSmallData: [],
      twoOneData: [],
      upDownData: [],
      fiveData: [],
      // recordData:[],
      showVip: false,
      selectTS: [],
    };
  },

  props: {
    data: {},
    playData: {},
  },
  setup() {},
  computed: {},
  mounted() {
    var that = this;
    // bus.off('freshVData')
    // bus.on('freshVData', (val) => {
    // 	that.recordData = val
    // 	that.countBigSmall(val)
    // 	that.countDoubleSingle(val)
    // 	that.countUpDown(val)
    // 	that.countFiveData(val)
    // });
    bus.on("allClean", (val) => {
      that.selectTS = [];
    });
    that.countBigSmall(this.recordData);
    that.countDoubleSingle(this.recordData);
    that.countUpDown(this.recordData);
    that.countFiveData(this.recordData);
  },
  methods: {
    showInfo(text) {
      this.selectInfo = text;
      this.showVip = true;
    },
    containTS(str) {
      //var contain = false
      for (var item of this.selectTS) {
        if (str == item) {
          return true;
        }
      }
      return false;
    },
    tsClick(str, code, odd, code1) {
      var contain = false;
      var newStr = code;
      for (var i = 0; i < this.selectTS.length; i++) {
        if (this.selectTS[i] == newStr) {
          contain = true;
          this.selectTS.splice(i, 1);

          break;
        }
      }
      if (!contain) {
        this.selectTS.push(newStr);
      }

      var obj = {
        num: code,
        Odds: odd * 1000,
        betType: "VIP",
        SubPlayCode: code1,
        Name: str,

        UnitBetAmount: 1000,
      };
      bus.emit("BET", obj);
    },

    getSZX(type, data) {
      if (type == "S" || type == "X") {
        for (var obj of data) {
          if (obj.SubPlayCode == "UPDOWN_UP_DOWN") {
            return obj.Odds;
          }
        }
      } else {
        for (var obj of data) {
          if (obj.SubPlayCode == "UPDOWN_DRAW") {
            return obj.Odds;
          }
        }
      }
    },
    getJMSHT(type, data) {
      if (type == "J" || type == "T") {
        for (var obj of data) {
          if (obj.SubPlayCode == "ELE5_JT") {
            return obj.Odds;
          }
        }
      } else if (type == "M" || type == "H") {
        for (var obj of data) {
          if (obj.SubPlayCode == "ELE5_MH") {
            return obj.Odds;
          }
        }
      } else {
        for (var obj of data) {
          if (obj.SubPlayCode == "ELE5_S") {
            return obj.Odds;
          }
        }
      }
    },

    getPersent(type) {
      //0大 1小 2单 3双
      var arr;
      if (type == 0 || type == 1) {
        arr = this.bigSmallData;
      } else if (type == 2 || type == 3) {
        arr = this.twoOneData;
      } else if (type == 4 || type == 5 || type == 6) {
        arr = this.upDownData;
      } else {
        arr = this.fiveData;
      }

      var count = 0;
      var total = 0;

      for (var i = 0; i < (arr.length >= 16 ? 16 : arr.length); i++) {
        var tmpArr = arr[i];

        for (var j = 0; j < tmpArr.length; j++) {
          var str = tmpArr[j];
          total++;

          if (type == 0) {
            if (str == "big") {
              count++;
            }
          } else if (type == 1) {
            if (str == "small") {
              count++;
            }
          } else if (type == 2) {
            if (str == "single") {
              count++;
            }
          } else if (type == 3) {
            if (str == "double") {
              count++;
            }
          } else if (type == 4) {
            if (str == "top") {
              count++;
            }
          } else if (type == 5) {
            if (str == "center") {
              count++;
            }
          } else if (type == 6) {
            if (str == "bottom") {
              count++;
            }
          } else if (type == 7) {
            if (str == "jin") {
              count++;
            }
          } else if (type == 8) {
            if (str == "mu") {
              count++;
            }
          } else if (type == 9) {
            if (str == "shui") {
              count++;
            }
          } else if (type == 10) {
            if (str == "huo") {
              count++;
            }
          } else if (type == 11) {
            if (str == "tu") {
              count++;
            }
          }
        }
      }

      return (count / total) * 100;
    },

    getCurrentType(type, data) {
      if (!data) {
        return "";
      }
      var total = 0;
      var arr = data.OpenCode.L0;
      var topCount = 0;
      var bottomCount = 0;

      for (var num of arr) {
        total += parseInt(num);
        if (num > 0 && num < 41) {
          topCount++;
        } else if (num >= 41) {
          bottomCount++;
        }
      }
      if (type == 0) {
        if (total >= 811) {
          return "big";
        } else {
          return "small";
        }
      } else if (type == 1) {
        if (total % 2 == 0) {
          return "double";
        } else {
          return "single";
        }
      } else if (type == 2) {
        if (topCount > 10) {
          return "top";
        } else if (bottomCount > 10) {
          return "bottom";
        } else {
          return "center";
        }
      } else if (type == 3) {
        if (total >= 210 && total <= 695) {
          return "jin";
        } else if (total >= 696 && total <= 763) {
          return "mu";
        } else if (total >= 764 && total <= 855) {
          return "shui";
        } else if (total >= 856 && total <= 923) {
          return "huo";
        } else if (total >= 924 && total <= 1410) {
          return "tu";
        }
      } else {
        return total;
      }
    },

    countDoubleSingle(val) {
      var data = this.data;
      if (val) {
        data = val;
      }
      var index = 0;
      var indexArr = [];
      var tmpArr = [];

      var lastStr;
      for (var i = 0; i < data.length; i++) {
        var total = 0;
        var arr = data[i].OpenCode.L0;
        for (var num of arr) {
          total += parseInt(num);
        }

        var str;
        if (total % 2 == 0) {
          str = "double";
        } else {
          str = "single";
        }
        if (lastStr == str) {
          if (indexArr.length < 6) {
            indexArr.push(str);
            tmpArr[index] = indexArr;
          } else {
            indexArr = [];
            index++;
            indexArr.push(str);
          }
        } else {
          indexArr = [];
          if (lastStr) {
            index++;
          }
          indexArr.push(str);
        }
        tmpArr[index] = indexArr;

        lastStr = str;
      }
      if (tmpArr.length > 16) {
        tmpArr = tmpArr.slice(0, 16);
      }
      var arrs = [];
      for (var i = tmpArr.length - 1; i >= 0; i--) {
        arrs.push(tmpArr[i]);
      }

      this.twoOneData = arrs;
    },
    countFiveData(val) {
      var data = this.data;
      if (val) {
        data = val;
      }
      var index = 0;
      var indexArr = [];
      var tmpArr = [];

      var lastStr;
      for (var i = 0; i < data.length; i++) {
        var total = 0;
        var arr = data[i].OpenCode.L0;
        for (var num of arr) {
          total += parseInt(num);
        }

        var str;
        if (total >= 210 && total <= 695) {
          str = "jin";
        } else if (total >= 696 && total <= 763) {
          str = "mu";
        } else if (total >= 764 && total <= 855) {
          str = "shui";
        } else if (total >= 856 && total <= 923) {
          str = "huo";
        } else if (total >= 924 && total <= 1410) {
          str = "tu";
        }
        if (lastStr == str) {
          if (indexArr.length < 6) {
            indexArr.push(str);
            tmpArr[index] = indexArr;
          } else {
            indexArr = [];
            index++;
            indexArr.push(str);
          }
        } else {
          indexArr = [];
          if (lastStr) {
            index++;
          }
          indexArr.push(str);
        }
        tmpArr[index] = indexArr;

        lastStr = str;
      }

      // if(tmpArr.length>16){
      // 	tmpArr = tmpArr.slice(0,16)
      // }
      // var arrs = []
      // for(var i=tmpArr.length-1;i>=0;i--){
      // 	arrs.push(tmpArr[i])
      // }
      //  console.log('8888888',arrs)

      // this.fiveData = arrs
      this.fiveData = tmpArr;
    },

    countUpDown(val) {
      var data = this.data;
      if (val) {
        data = val;
      }
      var index = 0;
      var indexArr = [];
      var lastStr;
      var tmpArr = [];
      for (var i = 0; i < data.length; i++) {
        var total = 0;
        var arr = data[i].OpenCode.L0;

        var topCount = 0;
        var bottomCount = 0;

        for (var num of arr) {
          if (num > 0 && num < 41) {
            topCount++;
          } else if (num >= 41) {
            bottomCount++;
          }
        }

        var str;
        if (topCount > 10) {
          str = "top";
        } else if (bottomCount > 10) {
          str = "bottom";
        } else str = "center";
        if (lastStr == str) {
          if (indexArr.length < 6) {
            indexArr.push(str);
            tmpArr[index] = indexArr;
          } else {
            indexArr = [];
            index++;
            indexArr.push(str);
          }
        } else {
          indexArr = [];
          if (lastStr) {
            index++;
          }
          indexArr.push(str);
        }
        //this.bigSmallData[index] = indexArr
        tmpArr[index] = indexArr;
        lastStr = str;
      }
      //this.upDownData = tmpArr

      if (tmpArr.length > 16) {
        tmpArr = tmpArr.slice(0, 16);
      }
      var arrs = [];
      for (var i = tmpArr.length - 1; i >= 0; i--) {
        arrs.push(tmpArr[i]);
      }

      this.upDownData = arrs;
    },
    countBigSmall(val) {
      //>881大  1-40超过10个上 41-80>10个 下  和 1-40等于10
      var data = this.data;
      if (val) {
        data = val;
      }
      var index = 0;
      var indexArr = [];
      var lastStr;
      var tmpArr = [];
      for (var i = 0; i < data.length; i++) {
        var total = 0;
        var arr = data[i].OpenCode.L0;
        for (var num of arr) {
          total += parseInt(num);
        }

        var str;
        if (total >= 811) {
          str = "big";
        } else {
          str = "small";
        }

        if (lastStr == str) {
          if (indexArr.length < 6) {
            indexArr.push(str);
            tmpArr[index] = indexArr;
          } else {
            indexArr = [];
            index++;
            indexArr.push(str);
            tmpArr[index] = indexArr;
          }
        } else {
          indexArr = [];
          if (lastStr) {
            index++;
          }
          indexArr.push(str);
          tmpArr[index] = indexArr;
        }
        //this.bigSmallData[index] = indexArr
        lastStr = str;
      }
      //this.bigSmallData = tmpArr

      if (tmpArr.length > 16) {
        tmpArr = tmpArr.slice(0, 16);
      }
      var arrs = [];
      for (var i = tmpArr.length - 1; i >= 0; i--) {
        arrs.push(tmpArr[i]);
      }

      this.bigSmallData = arrs;
    },
  },
};
</script>

<style scoped>
.select {
  background-color: #90a2dc;
}

.nolmal {
  background-color: rgb(27, 35, 61);
}

.tag {
  width: 35px;
  height: 20px;
  color: white;
  font-size: 8px;
  text-align: center;
  line-height: 20px;
}

.redTag {
  background-color: #f94168;
}

.blueTag {
  background-color: #26a1ff;
}

.grayTag {
  background-color: #909090;
}

.jinTag {
  background-color: #ffbb00;
}

.muTag {
  background-color: #6dd400;
}

.tuTag {
  background-color: #ca8c10;
}

.corner {
  width: 17px;
  height: 17px;
  border-radius: 8.5px;
  color: white;
  font-size: 10.5px;
  text-align: center;
  line-height: 17px;
}

.playSelect {
  background-image: linear-gradient(135deg, #7146ff, #4a69ff);
}

.playNolmal {
  background-color: #35416d;
}

.fourbtn {
  width: 22vw;
  height: 48px;
  text-align: center;
  border-radius: 3px;
  color: white;
  font-size: 10px;
}

.threeBtn {
  width: 29vw;
  height: 48px;
  text-align: center;
  color: white;
  font-size: 10px;
  border-radius: 3px;
}

.fiveBtn {
  width: 17.4vw;
  height: 48px;
  text-align: center;
  color: white;
  font-size: 10px;
  border-radius: 3px;
}

.colum {
  flex: 1;
  height: 13.066667vw;
  margin-top: 3.2vw;
  margin: 1.6vw;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #35416d;
  border-radius: 1.066667vw;
}
</style>
