import Fingerprint from "fingerprintjs2";
import log from "@/utils/logger";
import { http, topic } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import {mqant} from "@/components/protocol/mqantlib";
import { bus, busName } from "@/components/core/bus";


export const pay = {
  namespaced: true,
  state: {
    payInfo: {
      "code": "",
      "doudouMax": 500000000,
      "doudouMaxCnt": 5,
      "doudouMin": 200000,
      myDouDouBT: [
      ],
      payList: [
        {
          "FeePerThousand": 0,
          "IsClose": 0,
          "Max": 300000000,
          "Merchant": "Official",
          "MethodId": "6151f6bb29bb35071894d8bb",
          "MethodType": "bank",
          "Mini": 50000,
          "Name": "银行转账",
          "Priority": 1,
          "Remark": "银行卡转账",
          "UpdateAt": "2022-07-05T13:19:47.239Z"
        }
      ],
      "phoneChargeConf": [
        {
          "Amount": 10000,
          "FeePerThousand": 260,
          "Name": "VIETTEL",
          "UpdateAt": "0001-01-01T00:00:00Z"
        }
      ],
      "receiveBankList": [
        
      ],
      "vgPayBankList": [
        {
          "_id": "bankQr",
          "bankList": [
            {
              "Identifier": "ACB",
              "MethodType": "bankQr",
              "Name": "ACB",
              "_id": "6151f6bc29bb35071894d8ea"
            }
          ]
        },
        {
          "_id": "direct",
          "bankList": []
        }
      ]
    },
    douDouBtList:[]
  },
  mutations: {
    setPayInfo(state, payInfo) {
      // 这里的 `state` 对象是模块的局部状态
      state.payInfo = payInfo
    },
    setDouDouBtList(state, data){
      state.douDouBtList = data
    },
  },
  actions: {
    requestDoudouBtList({ commit, dispatch, rootGetters, getters }) {
      return new Promise(async (resolve, reject) => {
        mqant.request(topic.payDoudouBtList, {}, function(res){
          log.info(res)
          if (res.Code == 0){
            commit("setDouDouBtList", res.Data.douDouBtList)
            resolve(res.Data.douDouBtList)
          }else{
            reject(res.ErrMsg)
          }
        })
      })
    },
    requestPayInfo({ commit, dispatch, rootGetters, getters }) {
      return new Promise(async (resolve, reject) => {
        mqant.request(topic.payInfo, {}, function (res, topic, msg) {
          log.info(res)
          // log.info(msg.payloadString)
          if (res.Code == 0){
            commit("setPayInfo", res.Data)
			bus.emit('requestPayInfo')
			
          }
          resolve()
        })
      })
    },
  },
  getters: {
    payInfo(state) {
      return state.payInfo
    },
    showPayList(state) {
      const list = {
        Official_bank: {
          id: "charge_bank",
          oid: "",
          bg: "recharge_icon_bank.png",
          title: "BANK",
        },
        VgPay_MomoPay: {
          id: "charge_momo",
          methodType: "MomoPay",
          bg: "recharge_icon_momo.png",
          title: "MOMO",
        },
        VgPay_bankQr: {
          id: "charge_qr",
          methodType: "bankQr",
          bg: "recharge_icon_qr.png",
          title: "QR",
        },
        VgPay_direct: {
          id: "charge_link",
          methodType: "direct",
          bg: "recharge_icon_link.png",
          title: "LINK",
        },
        giftCode_giftCode: {
          id: "charge_gift",
          methodType: "giftCode",
          bg: "recharge_icon_gift.png",
          title: "CODE",
        },
      }
      let res = [];
      for (let i = 0; i < state.payInfo.payList.length; ++i) {
        let item = state.payInfo.payList[i];
        let key = item.Merchant + "_" + item.MethodType;
        let payItem = list[key];
        if (typeof payItem != "undefined"){
          res.push(payItem)
        }
      }
      return res
    },
    receiveBankList(state){
      let res = []
      for(let k in state.payInfo.receiveBankList){
        let item = state.payInfo.receiveBankList[k]
        res.push(item.BankName)
      }
      return res
    },
    douDouBtList(state){
      return state.douDouBtList
    },
  }
}
