<template>
    <div class="avatar">
        <div class="van-overlay">
            <div class="content">
                <div class="avatar_title">
                    <span class="header">Chọn avatar</span>
                    <img :src="$img('avatar/title_bg.png')" alt="">
                </div>
                <div class="wrapper">
                    <div class="items" v-for="(item, index) in list">
                        <div class="titles">
                            <span class="title">{{ item.title }}</span>
                            <span class="line"></span>
                        </div>
                        <div class="list">
                            <div class="avatar_img" v-for="(number, index1) in item.list" @click="itemClick(number)">
                                <span :class="number == curSelectNum ? 'active' : ''">
                                    <img :src="require('@/assets/common/avatar/system_' + number + '.png')" alt=""
                                        class="avatar">
                                    <img :src="$img('avatar/gouxuan.png')" alt="" class="choose">
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="avatar_fotter">
                    <div class="btn delete" @click="close()">Huỷ</div>
                    <div class="btn confim" @click="submit()">Xác nhận</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ref,
    onMounted,
    onBeforeUnmount,
    reactive,
    nextTick,
    watch,
} from "vue";

import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { Toast } from "vant";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
    "onLogged",
    "doLogin",
]);
let avatarMaxNum = 42;
let allList = [];
for (let i = 1; i <= avatarMaxNum; i++) {
    allList.push(i);
}
let curSelectNum = ref(0);
let list = reactive([
    { title: 'Mặc định', list: getList(0, 6) },
    { title: 'Phong cách chân thực', list: getList(6, 6 + 12) },
    { title: 'Phong cách game thủ', list: getList(18, 18 + 12) },
    { title: 'Phong cách hoạt hoạ', list: getList(30, 30 + 12) },
])
function itemClick(index) {
    curSelectNum.value = index;
}
function submit() {
    if (!isLogged.value) {
        router.push({ name: "LoginRegist" });
        close();
        return;
    }
    if (curSelectNum.value == 0) return;
    let obj = {
        Avatar: "system_" + curSelectNum.value
    }
    mqant.request(topic.setAvatar, obj, function (data, topicName, msg) {
        if (data.Code == 0) {
            Toast.success(data.ErrMsg);
            userInfo.value.Avatar = 'system_' + curSelectNum.value;
            close();
        } else {
            Toast.fail(data.ErrMsg);
        }

    });
}
function close() {
    bus.emit(bus.event.avatarState, false);
}
function getList(index, number) {
    if (number > avatarMaxNum) {
        number = avatarMaxNum;
    }
    let list = allList.slice(index, number);
    return list
}
</script>

<style lang="scss" scoped>
.avatar {
    position: relative;

    .van-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, .5);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3003;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .content {
            background: #fafafa;
            border-radius: 3.2vw;
            width: 88%;
            height: 70%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            position: relative;
            min-height: min-content;

            .avatar_title {
                width: 100%;
                position: relative;
                height: 15.466667vw;

                .header {
                    position: absolute;
                    left: 50%;
                    top: 40%;
                    -webkit-transform: translate(-50%, -40%);
                    transform: translate(-50%, -40%);
                    font-size: 4.266667vw;
                    color: #fff;
                    text-align: center;
                    line-height: 4.8vw;
                    font-weight: 500;
                }

                img {
                    width: 100%;
                }
            }

            .wrapper {
                overflow: scroll;
                flex: 1;

                .items {
                    width: 100%;

                    .titles {
                        line-height: 4.266667vw;
                        padding: 2.666667vw;
                        display: flex;
                        align-items: center;

                        .title {
                            font-size: 4.266667vw;
                            color: #333;
                            margin-right: 1.333333vw;
                        }

                        .line {
                            flex: 1;
                            height: 2.133333vw;
                            background-image: url("@/assets/mobile/avatar/line.png");
                            background-repeat: no-repeat;
                            background-size: auto 100%;
                        }
                    }

                    .list {
                        display: flex;
                        padding: 0 2.666667vw;
                        flex-wrap: wrap;
                        overflow: hidden;

                        .avatar_img {
                            width: 16%;
                            text-align: center;
                            color: #fff;
                            margin: 0 .33333% 2.133333vw;
                            box-sizing: border-box;

                            span {
                                width: 13.333333vw;
                                height: 13.333333vw;
                                border-radius: 50%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                box-sizing: border-box;

                                .avatar {
                                    width: 11.733333vw;
                                    height: 11.733333vw;
                                    border: 0.533333vw solid transparent;
                                }

                                .choose {
                                    position: absolute;
                                    width: 3.733333vw;
                                    bottom: -1.6vw;
                                    display: none;
                                }
                            }

                            .active {
                                border: 0.533333vw solid #4869fd;

                                .choose {
                                    display: block;
                                }
                            }
                        }
                    }
                }
            }

            .avatar_fotter {
                background: #fff;
                box-shadow: 0 -0.533333vw 1.066667vw 0 rgba(0, 0, 0, .04);
                border-radius: 0 0 3.2vw 3.2vw;
                height: 16.533333vw;
                min-height: 16.533333vw;
                padding: 3.2vw;
                display: flex;
                bottom: 0;
                width: 100%;
                justify-content: space-between;

                .btn {
                    border-radius: 6.666667vw;
                    font-size: 4.8vw;
                    flex: 1;
                    letter-spacing: 0;
                    text-align: center;
                    line-height: 4.8vw;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-weight: 500;
                }

                .delete {
                    color: #7a88cc;
                    border: 1px solid #7a88cc;
                    margin-right: 5.333333vw;
                }

                .confim {
                    background-image: linear-gradient(270deg, #4869fd, #6c45e2);
                    border-radius: 6.666667vw;
                    color: #fff;
                }
            }
        }
    }
}
</style>