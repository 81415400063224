<template>
  <div class="root">
    <div class="not-activity" v-if="!isShow">
      <span>Không có hoạt động gì cả !!!</span>
    </div>
    <!-- <ul class="nav" v-if="isShow">
      <li v-for=" (item, index) in destList" :key="item" @click="topIndex = index"
        :class="topIndex == index ? 'selected' : ''">
        {{ item.name.toUpperCase() }}
      </li>
    </ul> -->
    <div class="flexs_r_c" style="flex-wrap:wrap;width:1200px;margin-top:-15px;">
      <div v-for="(item, index) in topList" class="canClick topList" @click="topIndex = index"
        :class="topIndex == index ? 'select' : 'nol'">

        {{ item.ActivityClass }}

      </div>
    </div>
    <div class="main" v-if="isShow">
      <div v-for="item in getDataList()" :key="item" @click="toInfo(item)">
        <img :src="item.TitleUrl" alt="">
      </div>
    </div>
  </div>
  <ActivityInfo v-if="showActInfo" :data='paramsInfo' :list="destList"></ActivityInfo>
</template>

<script setup>
import { bus, busName } from '@/components/core/bus'
import { reactive, ref } from 'vue'
import ActivityInfo from '@/views/pc/components/activity/ActivityInfo'
import { useGetters, useActions, useMutations } from '@/store/hooks/storeState/index'
let { lobbyInfo } = useGetters('global', ["lobbyInfo"]);
let isShow = ref(false);
let topIndex = ref(0);
let destList = reactive([])
let topList = reactive([])


var arr = []
var list = lobbyInfo.value.ActivityConf.Rebate.concat(
  lobbyInfo.value.ActivityConf.Bonus
);

for (var obj of list) {
  var hasContain = false;
  for (var newObj of arr) {
    if (newObj.ActivityClass == obj.ActivityClass) {
      hasContain = true;
    }
  }
  if (!hasContain) {
    arr.push(obj);
  }
}
arr = arr.sort((a, b) => a.Sort - b.Sort);
topList = arr
destList = list
isShow.value = destList && destList.length > 0;



function getDataList() {
  var className = topList[topIndex.value].ActivityClass
  var arr = []
  for (var obj of destList) {
    if (obj.ActivityClass == className) {
      arr.push(obj)
    }
  }
  arr = arr.sort((a, b) => a.Sort - b.Sort);
  return arr
}

let paramsInfo = ref(null);
let showActInfo = ref(false);
const toInfo = (item) => {
  paramsInfo.value = item//JSON.stringify(item);
  showActInfo.value = true;
}
bus.on('closeActivityInfo', () => {
  showActInfo.value = false;
})
</script>

<style lang="scss" scoped>
.topList {
  width: 136.875px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  border-radius: 8px;
  font-size: 14px;
  margin-left: 14px;
  margin-top: 15px;
}

.topList:first-child {
  margin-left: 0;
}

.topList:nth-child(9) {
  margin-left: 0;
}

.select {
  background-color: #4a69ff;
  color: white;

}

.nol {
  background-color: #292e3b;
  color: #8491a5;

}


.root {
  position: relative;
  width: 1200px;
  min-height: calc(100vh - 720px);
  margin: 30px auto;

  .not-activity {
    position: relative;
    height: calc(100vh - 720px);

    >span {
      position: absolute;
      font-size: 30px;
      color: gray;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 20px;
    padding-left: 0;

    .selected {
      color: #fff;
      background: linear-gradient(270deg, #f83600, #fe8c00);
    }

    li {
      display: inline-block;
      min-width: 140px;
      height: 30px;
      margin-right: 10px;
      font-size: 14px;
      font-weight: bold;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      border-radius: 15px;
      background-image: linear-gradient(180deg, #7146ff, #4a69ff);
      transition: all 0.2s;
      padding-right: 5px;
      padding-left: 5px;
    }
  }

  .main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 30px auto;

    >div {
      width: 1200px;
      height: 200px;
      margin-bottom: 10px;

      img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
        cursor: pointer;
      }
    }
  }
}
</style>