<template>
  <div style="overflow:hidden;">
    <topHeader title="Lịch sử" :showRight="false" :showBack="true"></topHeader>

    <div class="typeContainer">
      <ul class="gameType">
        <li @click="clickTop(index)" :class="selectIndex == index ? 'active' : ''" v-for="(item, index) in dataList">
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="bet-record-selector-wp">
      <div class="day-selector-wp" @click="showChoose(0)">
        {{ timeArr[defaultTimeIndex] }}
        <span class="iconfont icon-icon_drop_down_solid"></span>
      </div>
      <div class="lottery-selector-wp" @click="showChoose(1)">
        {{ getCurrent()[defaultTypeIndex].split("/")[1] }}
        <span class="iconfont icon-icon_drop_down_solid"></span>
      </div>
    </div>

    <div class="no-data" v-if="!recordList.length">{{ 'Không có dữ liệu' }}</div>

    <div class="list-wp" style="height:calc(100vh - 48.53333vw - 13.333333vw);overflow-y: scroll">
      <div class="list-item" v-for="(item, index) in recordList">
        <div class="summary-wp">
          <div class="info-wp">
            <div class="lottery-name">
              {{ item.factory_name + "/" + item.game_type }}
            </div>
            <div class="time">{{ startDate }}-{{ endDate }}</div>
          </div>
          <div>
            <div class="bet-amount-wp">
              Đặt cược {{ $tools.formatNum2k(item.valid_bet_amount) }}
            </div>
            <div class="win-loss">
              <span :class="item.income > 0 ? 'win' : 'loss'">Thắng thua {{ $tools.formatNum2k(item.income) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="totalData showBottom" style="bottom:13.333333vw;">
      <div class="total">
        <span>Tổng tiền cược：{{ $tools.formatNum2k(getTotol()[0]) }}</span>
      </div>
      <div class="loseOrWin">
        <span>Tổng thắng thua：{{ $tools.formatNum2k(getTotol()[1]) }}</span>
      </div>
    </div>

    <div class="van-overlay" v-show="showChooseWrap" @click="showChooseWrap = false">
      <div class="overlay-wrapper lottery-type-list" @click.stop="wrapClick()">
        <div class="title">
          {{ showType == 0 ? "Chọn thời gian" : "Kiểu cược" }}
          <span class="overlay-xbtn iconfont icon-icon_close_white" @click="showChooseWrap = false"></span>
        </div>
        <div class="panel">
          <div v-for="(item, index) in showType == 0 ? timeArr : getCurrent()" @click="shooseEvent(index)" :key="item"
            role="radio" tabindex="0" aria-checked="true" class="panel-item van-radio">
            <div v-if="(showType == 0 && defaultTimeIndex == index) ||
              (showType == 1 && defaultTypeIndex == index)
              " class="van-radio__icon van-radio__icon--round van-radio__icon--checked">
              <span class="radio-style iconfont icon-icon_radio_button_sel1"></span>
            </div>
            <div v-else class="van-radio__icon van-radio__icon--round">
              <span class="radio-style iconfont icon-icon_radio_button_nor"></span>
            </div>
            <span class="van-radio__label">
              {{ showType == 1 ? item.split("/")[1] : item }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";
import { bus, busName } from "@/components/core/bus";
import moment from "moment";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";

export default {
  name: "",
  data() {
    return {
      dataList: [],
      selectIndex: 0,
      startDate: "",
      endDate: "",
      isRoot: true,
      defaultTimeIndex: 2,
      defaultTypeIndex: 0,
      showType: 0,
      showChooseWrap: false,
      timeArr: ["Hôm nay", "Hôm qua", "7 ngày gần đây", "30 ngày gần đây"],
      recordList: [],
    };
  },
  props: {},
  components: { topHeader },

  computed: {
    ...mapGetters("global", ["lobbyInfo"]),
    ...mapGetters("tcp", ["isLogged"]),
  },
  created() {

    if (this.$route.params.isRoot == 'false') {
      this.isRoot = false
    }
    var obj = {
      name: this.$t("全部"),
      data: [],
    };
    if (this.lobbyInfo) {
      this.dataList = [obj].concat(this.getAllFactoryList());
    }
    var that = this;
    bus.on(busName.lobbyInfo, (val) => {
      that.dataList = [obj].concat(that.getAllFactoryList());
    });

    if (this.isLogged) {
      this.getData();
    }
    bus.on("onConnected", () => {
      that.getData();
    });
  },
  methods: {
    shooseEvent(index) {
      if (this.showType == 0) {
        this.defaultTimeIndex = index;
      } else {
        this.defaultTypeIndex = index;
      }
      this.showChooseWrap = false;
      this.getData();
    },
    getTotol() {
      var num1 = 0
      var num2 = 0
      if (this.recordList) {
        for (var obj of this.recordList) {
          num1 += obj.valid_bet_amount
          num2 += obj.income
        }
      }

      return [num1, num2]
    },
    getCurrent() {
      return ["/Toàn bộ"].concat(this.dataList[this.selectIndex].data);
    },
    wrapClick() { },
    showChoose(type) {
      this.showType = type;
      this.showChooseWrap = true;
    },
    clickTop(index) {
      this.selectIndex = index;
      this.defaultTimeIndex = 2;
      this.defaultTypeIndex = 0;
      this.getData();
    },
    getData() {
      this.tableData = [];
      var that = this;

      var date = moment();

      if (this.defaultTimeIndex == 0) {
        this.startDate = moment(moment().valueOf()).format("YYYY-MM-DD");
      } else if (this.defaultTimeIndex == 1) {
        this.startDate = date.subtract(1, "days").format("YYYY-MM-DD");
      } else if (this.defaultTimeIndex == 2) {
        this.startDate = date.subtract(7, "days").format("YYYY-MM-DD");
      } else {
        this.startDate = date.subtract(30, "days").format("YYYY-MM-DD");
      }
      this.endDate = moment(moment().valueOf()).format("YYYY-MM-DD");

      var FactoryNames = [];
      if (this.defaultTypeIndex == 0) {
        FactoryNames = this.dataList[this.selectIndex].data;
      } else {
        FactoryNames = [
          this.dataList[this.selectIndex].data[this.defaultTypeIndex - 1],
        ];
      }

      var obj = {
        FactoryName: FactoryNames,
        startDate: this.startDate + " 00:00:00",
        endDate: this.endDate + " 23:59:59",
      };

      console.log("rrr", obj);

      mqant.request(topic.betRecord, obj, function (data, topicName, msg) {
        console.log("dsda", data);
        that.recordList = data.Data.BetRecord;
        // for (var i = 0; i < data.Data.BetRecord.length; i++) {
        // 	var obj = data.Data.BetRecord[i]
        // 	obj.index = i + 1
        // 	obj.startDate = that.startDate + ' 00:00:00'
        // 	obj.endDate = that.endDate + ' 23:59:59'
        // 	that.tableData.push(obj)
        // }
        // that.totalCount = data.Data.BetRecord.length
      });
    },
    getAllFactoryList() {
      //获取所有厂商类型
      let srcList = this.lobbyInfo.FactoryList;
      srcList = srcList.slice(1, srcList.length - 1)
      let destList = [];
      let tempList = [];
      for (let key in srcList) {
        let data = srcList[key].Factory;

        let info = srcList[key].vi.split('/');
        //apiBg/LiveCasino,取Bg则从第3位取到/
        let factoryName = info[0]//info.slice(3, info.indexOf("/"));

        if (tempList.indexOf(factoryName) === -1) {
          destList.push({
            name: factoryName,
            data: [data],
          });
          tempList.push(factoryName);
        } else {
          for (let j = 0; j < destList.length; j++) {
            if (destList[j].name == factoryName) {
              destList[j].data.push(data);
              break;
            }
          }
        }
      }
      return destList;
    },
  },
};
</script>

<style lang="scss" scoped>
.typeContainer {
  margin-top: 50px;
  width: 100%;
  height: 11.733333vw;
  overflow-x: auto;
  overflow-y: hidden;
  background-color: #1b233d;
  padding: 0 0.533333vw;

  .gameType {
    width: auto;
    padding-right: 5.333333vw;
    height: 11.733333vw;
    padding-top: 2.133333vw;
    white-space: nowrap;

    li {
      display: inline-block;
      padding: 1.6vw 2.133333vw;
      height: 4.533333vw;
      line-height: 4.533333vw;
      margin: 0 1.6vw;
      border-radius: 1.066667vw;
      font-weight: 400;
      color: #90a2dc;
      font-size: 3.2vw;
      background: #35416d;
    }

    li.active {
      background-image: linear-gradient(90deg, #7146ff, #4a69ff);
      color: #fff;
    }
  }
}

.typeContainer::-webkit-scrollbar {
  display: none;
}

.typeContainer {
  /* 隐藏滚动条 */
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.bet-record-selector-wp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4.266667vw;
  height: 11.733333vw;
  position: relative;

  .day-selector-wp {
    font-size: 3.2vw;
    color: #fff;
    font-weight: 400;
  }

  .lottery-selector-wp {
    font-size: 3.2vw;
    color: #fff;
    font-weight: 400;
  }
}

.bet-record-selector-wp:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(180deg, transparent 40%, #90a2dc);
}

.van-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);

  .overlay-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 72.266667vw;
    background: #1a223b;
    border-radius: 2.133333vw;
    overflow: hidden;

    .panel {
      height: 74.666667vw;
      overflow: scroll;

      .panel-item {
        font-size: 3.733333vw;
        font-weight: 400;
        box-shadow: inset 0 0.5px 0 0 rgba(144, 162, 220, 0.5);
        height: 10.666667vw;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        line-height: 10.666667vw;
        display: flex;
      }

      .van-radio {
        justify-content: space-around;
        flex-direction: row-reverse;

        .van-radio__label {
          width: 40vw;
          color: #fff;
          text-align: center;
          margin-left: 0;
          line-height: 5.333333vw;
        }

        .van-radio__icon {
          color: #e9cfa4;
          -webkit-box-flex: 0;
          -webkit-flex: none;
          flex: none;
          height: 1em;
          font-size: 5.333333vw;
          line-height: 1em;
          cursor: pointer;
        }
      }
    }

    .title {
      width: 100%;
      border-top-left-radius: 2.133333vw;
      border-top-right-radius: 2.133333vw;
      background: #1a223b;
      color: #fff;
      text-align: center;
      font-size: 4.266667vw;
      height: 10.666667vw;
      line-height: 10.666667vw;
      font-weight: 500;

      .overlay-xbtn {
        position: absolute;
        right: 3.733333vw;
        font-size: 3.04vw;
      }
    }
  }
}

.list-item {
  display: flex;
  padding: 2.133333vw 4.266667vw;
  height: 21.333333vw;
  position: relative;

  .lottery-name,
  .bet-amount-wp {
    color: #fff;
    font-size: 3.733333vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    height: 8vw;
    line-height: 8vw;
  }

  .time {
    color: hsla(0, 0%, 100%, 0.5019607843137255);
    font-size: 3.733333vw;
    height: 8vw;
    line-height: 8vw;
  }

  .win-loss {
    font-size: 3.2vw;
    font-weight: 400;
    height: 8vw;
    line-height: 8vw;

    .loss {
      color: #21e06b;
    }

    .win {
      color: #f93e3e;
    }
  }

  .bet-amount-wp {
    font-size: 3.2vw;
    text-align: right;
    font-weight: 500;
    height: 8vw;
    line-height: 8vw;
    color: #fff;
  }

  .summary-wp {
    flex: 1;
    display: flex;
    justify-content: space-between;

    .info-wp {
      max-width: 48vw;
      overflow: hidden;
    }
  }
}

.list-item:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(180deg, transparent 40%, #90a2dc);
}

.totalData {
  position: fixed;
  margin-bottom: env(safe-area-inset-bottom);
  border-bottom: 1px solid hsla(0, 0%, 100%, .08);
  width: 100%;
  height: 12.8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 3.466667vw;
  color: #e9cfa4;
  padding: 4.266667vw;
  font-weight: 400;
  background: #1a233d;
}

.no-data {
  padding-top: 6.4vw;
  height: 5.333333vw;
  line-height: 5.333333vw;
  opacity: .5;
  color: #fff;
  text-align: center;
  font-size: 3.733333vw;
  font-weight: 500;
}
</style>
