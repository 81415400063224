<template>
  <div class="bank">
    <div class="card">
      <p class="channelTitle">Số tiền nạp</p>
      <div class="less-pay">
        <span>Chuyển khoản ngân hàng</span>
        <span>Giới hạn nạp tối thiểu <span style="color:red;">&nbsp;{{ $tools.formatNum2k(obj.Mini) }}~{{
          $tools.formatNum2k(obj.Max) }} VND(K)</span></span>
      </div>
    </div>
    <div class="card">
      <div class="select-bank" @click="showPicker = true">
        <span class="span-1">Chọn ngân hàng</span>
        <span class="span-2">{{ obj.curBankItem.BankName }}</span>
        <img :src="$img('userCenter/icon_jiantou.png')" alt="">
      </div>
      <div class="bank-info">
        <div class="bank-info-item">
          <span>{{ obj.curBankItem.AccountName }}</span>
          <img :src="$img('userCenter/fuzhi_icon.png')" @click="copyUrl(obj.curBankItem.AccountName)" alt="">
        </div>
        <div class="bank-info-item">
          <span>{{ obj.curBankItem.CardNumber }}</span>
          <img :src="$img('userCenter/fuzhi_icon.png')" @click="copyUrl(obj.curBankItem.CardNumber)" alt="">
        </div>
        <div class="bank-info-item">
          <span>{{ obj.remark }}</span>
          <img :src="$img('userCenter/fuzhi_icon.png')" @click="copyUrl(obj.remark)" alt="">
        </div>
        <div class="bank-tips"> Lưu Ý: Vui lòng ghi nội dung chuyển khoản như yêu cầu </div>
      </div>
    </div>
    <div class="card">
      <div class="input-class">
        <van-field v-if="ChargeNum > 0" v-model="obj.money" type="number" readonly placeholder="Vui lòng nhập số tiền" :border="false" />

        <van-field v-else v-model="obj.money" type="number" placeholder="Vui lòng nhập số tiền" :border="false" />

        <div class="input-money">= {{ Number(obj.money) * 1000 }} VND</div>

        <!-- <van-field v-model="value" label="银行账号" placeholder="请输入您的银行账号" :border="false" /> -->
      </div>
    </div>
    <div class="card">
      <span class="tips-label">vui lòng chuyển khoản trước khi làm lệnh nạp</span>
    </div>
    <div class="card">
      <div class="pay-btn" @click="onSubmit">
        <span> Nạp ngay </span>
      </div>
    </div>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker :columns="obj.bankNameList" @cancel="showPicker = false" @confirm="onConfirm" confirm-button-text="confirm" cancel-button-text="cancel" />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch, defineProps } from "vue";
import { Toast } from "vant";
import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const loading = ref(true);
const props = defineProps({
  data: {}
})
let showPicker = ref(false);
let obj = reactive({
  bankList: [],
  bankNameList: [],
  curBankItem: {},
  remark: '',
  money: '',
  Mini: 0,
  Max: 0,
  MethodId: ''
})
let ChargeNum = ref(0);

function getPayInfo(refreshCode = false) {
  mqant.request(topic.payInfo, {}, function (data, topicName, msg) {
    if (refreshCode) {
      //刷新一下code
      obj.remark = data.Data.code;
      return;
    }
  });
}

onMounted(() => {


  if (Object.keys(props.data).length <= 0) return;

  if (localStorage.getItem('ChargeNum')) {
    ChargeNum.value = localStorage.getItem('ChargeNum')
    obj.money = localStorage.getItem('ChargeNum')
  }

  obj.bankList = props.data.receiveBankList;
  obj.bankNameList = []
  for (let i = 0; i < props.data.receiveBankList.length; i++) {
    let info = props.data.receiveBankList[i];
    obj.bankNameList.push(info.BankName);
  }
  obj.curBankItem = props.data.receiveBankList[0];
  obj.remark = props.data.code;
  for (let key in props.data.payList) {
    let info = props.data.payList[key];
    if (info.MethodType == "bank" && info.Merchant == 'Official') {
      obj.Mini = info.Mini;
      obj.Max = info.Max;
      obj.MethodId = info.MethodId;
    }
  }
})
function copyUrl(text) {
  tools.copyText(text).then(
    (succ) => {
      Toast.success("Copy thành công");
    },
    (err) => {
      Toast.fail("Can not copy");
    }
  );
}
function onConfirm(value) {
  for (let key in obj.bankList) {
    let info = obj.bankList[key];
    if (info.BankName == value) {
      obj.curBankItem = info;
      showPicker.value = false;
      break;
    }
  }
}
function onSubmit() {
  if (!obj.money) {
    Toast.fail('Vui lòng nhập số tiền');
    return;
  }
  if (Number(obj.money) * 1000 < obj.Mini || Number(obj.money) * 1000 > obj.Max) {
    Toast.fail('Số tiền nhập khẩu sẽ là' + obj.Mini + '-' + obj.Max);
    return;
  }
  let objSubmit = {
    saveType: "",
    amount: Number(obj.money) * 1000,
    methodId: obj.MethodId,
    accountName: 'default',
    code: obj.remark,
    receiveId: obj.curBankItem.Oid,
  }

  if (ChargeNum.value > 0) {
    objSubmit.aType = 'TreasureBowl'
  }
  // log.info('objSubmit', objSubmit)
  mqant.request(topic.recharge, objSubmit, function (data, topicName, msg) {
    console.log('cc', data)
    if (data.Code == 0) {
      obj.money = '';
      getPayInfo(true);
      Toast.success(data.ErrMsg)
    } else {
      Toast.fail(data.ErrMsg)
    }
  })
}
</script>

<style lang="scss" scoped>
.bank {
  .card {
    padding: 4.266667vw 0 0;
    display: table;
    width: 100%;

    .channelTitle {
      height: 4.8vw;
      font-family: PingFangSC-Regular;
      font-size: 3.466667vw;
      color: #fff;
      font-weight: 400;
      margin-bottom: 2.666667vw;
    }

    :deep(.van-cell) {
      background-color: #1e2646;
      border-radius: 8px;
      font-size: 12px;
      --van-field-label-color: #424f81;
      --van-field-input-text-color: #fff;
    }
  }

  .less-pay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 21px;
    font-size: 12px;
    width: 100%;
    height: 82px;
    padding: 21px 12px;
    background-color: #2f395f;
    border-radius: 8px;

    > span {
      color: #fff;
    }

    span + span {
      color: #8a9fdc;
    }
  }

  .select-bank {
    width: 100%;
    height: 49px;
    padding: 0 16px;
    background-color: #1e2646;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .span-1 {
      font-size: 12px;
      color: #424f81;
    }

    .span-2 {
      font-size: 12px;
      color: #ffffff;
    }

    img {
      height: 11px;
    }
  }

  .bank-info {
    margin-top: 12px;
    background-color: #2f395f;
    border-radius: 5px;
    height: 167px;
    padding: 13px 17px;

    .bank-info-item {
      height: 30px;
      border-bottom: solid 2px #434f78;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11px;

      span {
        font-size: 13px;
        color: #8a9fdc;
      }

      img {
        width: 17px;
        height: 17px;
      }
    }

    .bank-tips {
      margin-top: 12px;
      font-size: 10px;
      color: #ee0a24;
    }
  }

  .input-class {
    .input-money {
      margin: 9px;
      font-size: 10px;
      color: #e9cfa4;
    }
  }

  .tips-label {
    font-size: 12px;
    color: #ee0a24;
  }

  .pay-btn {
    position: relative;
    height: 49px;
    background-image: linear-gradient(90deg, #6e4aff 17%, #5064ff 100%),
      linear-gradient(#0e1525, #0e1525);
    background-blend-mode: normal, normal;
    border-radius: 25px;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 14px;
    }
  }
}
</style>
