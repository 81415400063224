<template>
  <div>
    <div style="padding: 0 10px; position: relative">
      <div
        class="flexs_sb"
        style=" margin-left: 0%; margin-top: 0px; justify-content: center;border-bottom:.5px solid rgba(144,162,220,.5);padding-bottom:10px;"
      >
        <div
          class="funcBtn"
          @click="funcIndex = index"
          :style="index > 0 ? 'margin-left:3.5px;' : ''"
          :class="funcIndex == index ? 'funcSe' : 'funCNo'"
          v-for="(item, index) in funcArr"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div style=" margin-top: 5px; position: relative">
        <div
          v-if="funcArr[funcIndex] == 'Ngẫu nhiên'"
          class="flexs_sb"
          style="justify-content: center; align-items: center"
        >
          <div
            v-for="(item, index) in numArr2"
            v-if="isR"
            class="rightBtn canClick"
            :class="selectItem == item ? 'rightSelect' : 'rightNolmal'"
            @click="itemClick(item)"
          >
            {{ item }}
          </div>
          <div
            v-for="(item, index) in numArr1"
            v-else
            class="rightBtn canClick"
            :class="selectItem == item ? 'rightSelect' : 'rightNolmal'"
            @click="itemClick(item)"
          >
            {{ item }}
          </div>
        </div>

        <div
          v-if="funcArr[funcIndex] == 'Chọn số'"
          class="flexs_sb"
          style="justify-content: center; align-items: center"
        >
          <div
            v-for="(item, index) in dxdsArr"
            class="rightBtn canClick"
            :class="selectItem == item ? 'rightSelect' : 'rightNolmal'"
            @click="itemClick(item)"
          >
            {{ item }}
          </div>
        </div>

        <div
          v-if="funcArr[funcIndex] == 'Ít xuất hiện'"
          class="flexs_sb"
          style="justify-content: center; align-items: center"
        >
          <div
            v-for="(item, index) in topArr"
            class="rightBtn canClick"
            :class="selectItem == item ? 'rightSelect' : 'rightNolmal'"
            @click="itemClick(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>
      <div style="background-color: #1e2646; border-radius: 5px; margin-top: 8px">
        <textarea
          name=""
          v-model="text"
          cols="30"
          rows="10"
          style="
            margin-left: 5%;
            width: 90%;
            height: 110px;
            font-size: 13px;
            background-color: #1e2646;
            border-color: none;
            outline-color: transparent;
            color: white;
            margin-top: 12px;
          "
          placeholder="Cách nhập: giữa mỗi số cược ngăn cách bởi dấu phẩy, ví dụ 01, 11, 12 , 3D, 4D: 111, 222 ; 1111.1112"
        >
        </textarea>
      </div>
      <div class="flexs_sb" style="margin-top: 12px">
        <div
          class="canClick btn"
          @click="reset()"
          style="width: 30%; height: 35px; line-height: 35px; border:1px solid  rgb(74, 95, 169);border-radius:2.133333vw;"
        >
          Xóa
        </div>

        <div
          class="canClick btn"
          @click="doBet()"
          style="width: 65%; height: 35px; line-height: 35px; background-image: linear-gradient(90deg,#7146ff,#4a69ff);border-radius:2.133333vw;"
          :style="text.length?'':'opacity: .5;'"
        >
        Xác nhận
        </div>
      </div>

      <!-- <div style="width: 408px;height: 304px;background-color: #1b2c5e;padding: 0 19px;position: relative;">
				<div class="titleBtn">Chọn ngẫu nhiên</div>
				<div class="flexs_r_c" style="margin-top: 10px;font-size: 14px;">
					<div v-for="(item,index) in numArr2" v-if="isR" :class="selectItem==item?'select':'nolmal'" @click="itemClick(item)" class="btn canClick" :style="index>0?'margin-left:6px':''">
						{{item}}
					</div>
					<div v-for="(item,index) in numArr1" v-else :class="selectItem==item?'select':'nolmal'" @click="itemClick(item)" class="btn canClick" :style="index>0?'margin-left:6px':''">
						{{item}}
					</div>
				</div>	
				
				<div v-if="!isZH">
					<div class="titleBtn">Chọn số</div>
					<div class="flexs_r_c" style="margin-top: 10px;font-size: 14px;">
						<div v-for="(item,index) in dxdsArr" :class="selectItem==item?'select':'nolmal'" @click="itemClick(item)" class="btn canClick" :style="index>0?'margin-left:6px':''">
							{{item}}
						</div>
					</div>	
					<div class="titleBtn">Trường long</div>
					<div class="flexs_r_c" style="margin-top: 10px;font-size: 14px;">
						<div v-for="(item,index) in topArr" :class="selectItem==item?'select':'nolmal'" @click="itemClick(item)" class="btn canClick" :style="index>0?'margin-left:6px':''">
							{{item}}
						</div>
					</div>	
				</div>
				
				<div class="btn canClick nolmal" @click="reset()" style="width: 371px;height: 42px;line-height: 42px;position: absolute;bottom:15px ;">Chọn lại</div>
				
			</div> -->
      <!-- <div style="width: 423px;height: 304px;background-color: #1b2c5e;padding: 0 19px;margin-left: 15px;">
				<textarea name="" v-model="text"  cols="30" rows="10" style="width: 90%;height:205px ;font-size: 15px;background-color:#1b2c5e ;border-color:none;margin-left: 5%;margin-top: 30px;outline-color: transparent;color: white;" placeholder="Cách nhập: giữa mỗi số cược ngăn cách bởi dấu phẩy, ví dụ 01, 11, 12 , 3D, 4D: 111, 222 ; 1111.1112">
					
				</textarea>
				<div class="flexs_sb">
					<div class="canClick btn" @click="doBet()" style="width: 190px;height: 42px;line-height: 42px;background-color: #1f4a97;">Đặt cược</div>
					<div class="canClick btn" @click="reset()" style="width: 190px;height: 42px;line-height: 42px;background-color: #4a5fa9;">Chọn lại</div>
					
				</div>
			</div> -->
    </div>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";

export default {
  data() {
    return {
      numArr1: ["1số", "2số", "3số", "5số", "10số"],
      numArr2: ["10số", "20số", "30số", "40số", "50số"],
      dxdsArr: ["Kép bằng", "Chẵn", "Lẻ", "Tài", "Xỉu"],
      topArr: ["Top 1", "Top 2", "Top 5", "Top 10", "Top 20"],
      text: "",
      rightIndex1: -1,
      rightIndex2: -1,
      rightIndex3: -1,
      selectItem: null,
      numLength: "",
      isZH: false,
      isR: false,
      funcArr: ["Ngẫu nhiên", "Chọn số", "Ít xuất hiện"],
      funcIndex: 0,
      selfSub: null,
    };
  },
  props: {
    type: {
      //1.1键全选 2.全部
      type: Number,
      default: 1,
    },

    recordData: {},
    selectSub: {},
  },
  mounted() {
    var that = this;

    this.selfSub = this.selectSub;

    this.configSub();

    bus.on("allClean", (val) => {
      that.text = "";
      that.selectItem = null;
    });

    bus.on("playChange", (val) => {
      that.text = "";
      that.selectItem = null;

      that.selfSub = val;
      that.configSub();
    });
  },
  methods: {
    configSub() {
      if (
        this.selfSub.SubPlayCode.indexOf("ZH") >= 0 ||
        this.selfSub.SubPlayCode.indexOf("BCZH") >= 0
      ) {
        this.numArr1 = [this.numArr1[0]];
        this.funcArr = [this.funcArr[0]];

        this.isZH = true;
      }
      if (
        this.selfSub.SubPlayCode.indexOf("2DTW") >= 0 ||
        this.selfSub.SubPlayCode.indexOf("3DTW") >= 0 ||
        this.selfSub.SubPlayCode.indexOf("4D") >= 0
      ) {
        this.numArr1 = this.numArr2;
        this.isR = true;
      }
      //this.getTopData()
      if (
        this.selfSub.SubPlayCode == "BZ_C3" ||
        this.selfSub.SubPlayCode == "BZ_C4" ||
        this.selfSub.SubPlayCode.indexOf("3DTW") >= 0 ||
        this.selfSub.SubPlayCode.indexOf("4D") >= 0
      ) {
        this.dxdsArr = [this.dxdsArr[0]];
      }
      if (
        this.selfSub.SubPlayCode == "BZ_C3" ||
        this.selfSub.SubPlayCode.indexOf("3DTW") >= 0
      ) {
        this.numLength = 3;
      } else if (
        this.selfSub.SubPlayCode == "BZ_C4" ||
        this.selfSub.SubPlayCode.indexOf("4D") >= 0
      ) {
        this.numLength = 4;
      } else {
        this.numLength = 2;
      }
    },
    doBet() {
      if(!this.text.length){
        return
      }
      this.text += "";
      var obj = {
        numArr: this.text.split(","),

        betType: "random",
        SubPlayCode: this.selfSub.SubPlayCode,
      };

      bus.emit("BET", obj);
      this.text = "";
      this.selectItem = null;
    },
    reset() {
      this.text = "";
      this.selectItem = null;
    },
    itemClick(str) {
      if (this.selectItem == str) {
        this.selectItem = null;
        this.text = "";
        return;
      }
      this.selectItem = str;
      if (str == "1số") {
        if (this.isZH) {
          var forNum = this.selfSub.UnitBetCodeCount;
          var arr = [];
          for (var i = 0; i < 100; i++) {
            var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
            if (this.numLength == 3 && num >= 10 && num < 100) {
              num = "0" + num;
            }
            if (this.numLength == 4) {
              if (num >= 10 && num < 100) {
                num = "00" + num;
              } else if (num >= 100 && num < 1000) {
                num = "0" + num;
              }
            }
            num = num < 10 ? "0" + num : num;

            if (arr.indexOf(num) < 0) {
              arr.push(num);
              if (arr.length == forNum) {
                break;
              }
            }
          }
          this.text = arr.join(",");
        } else {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          this.text = num < 10 ? "0" + num : num;
        }
      } else if (str == "2số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 2) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "3số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 3) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "5số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 5) {
              break;
            }
          }
        }

        this.text = arr.join(",");
      } else if (str == "10số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 10) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "20số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 20) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "30số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 30) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "40số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 40) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "50số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 50) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "Kép bằng") {
        if (this.numLength == 2) {
          this.text = "00,11,22,33,44,55,66,77,88,99";
        } else if (this.numLength == 3) {
          this.text = "000,111,222,333,444,555,666,777,888,999";
        } else {
          this.text = "0000,1111,2222,3333,4444,5555,6666,7777,8888,9999";
        }
      } else if (str == "Chẵn") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = i;
          if (num % 2 == 0) {
            num = num < 10 ? "0" + num : num;
            arr.push(num);
          }
        }
        this.text = arr.join(",");
      } else if (str == "Lẻ") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = i;
          if (num % 2 == 1) {
            num = num < 10 ? "0" + num : num;
            arr.push(num);
          }
        }
        this.text = arr.join(",");
      } else if (str == "Tài") {
        var arr = [];
        for (var i = 50; i < 100; i++) {
          var num = i;

          num = num < 10 ? "0" + num : num;
          arr.push(num);
        }
        this.text = arr.join(",");
      } else if (str == "Xỉu") {
        var arr = [];
        for (var i = 0; i < 50; i++) {
          var num = i;

          num = num < 10 ? "0" + num : num;
          arr.push(num);
        }
        this.text = arr.join(",");
      } else if (str == "Top 1") {
        this.text = this.getTopData(1);
      } else if (str == "Top 2") {
        this.text = this.getTopData(2);
      } else if (str == "Top 5") {
        this.text = this.getTopData(3);
      } else if (str == "Top 10") {
        this.text = this.getTopData(4);
      } else if (str == "Top 20") {
        this.text = this.getTopData(5);
      }
    },
    done(len, max) {
      var arr = [];
      var result = "";
      var count = 0;
      while (count < len) {
        var n = Math.floor(Math.random() * (max + 1));
        if (arr.join().indexOf(n) == -1) {
          arr.push(n);
          count++;
        }
      }
      for (let index = 0; index < arr.length; index++) {
        result = result + arr[index];
      }
      return parseInt(result);
    },
    getTopData(type) {
      var data = {};

      for (var recordObj of this.recordData) {
        var obj = recordObj.OpenCode;
        var forNum = 10;
        if (this.selfSub.SubPlayCode.indexOf("FUNNY") >= 0 || this.isR) {
          forNum = 1;
        }

        for (var i = 0; i < forNum; i++) {
          if (obj["L" + i]) {
            var arr = obj["L" + i];
            if (
              this.selfSub.SubPlayCode == "2DTW_TOU" ||
              this.selfSub.SubPlayCode == "2DTW_WEI" ||
              this.selfSub.SubPlayCode == "3DTW_TJ" ||
              this.selfSub.SubPlayCode.indexOf("4D") >= 0
            ) {
              arr = obj.L0;
            }

            if (
              this.selfSub.SubPlayCode == "2DTW_TOU_L1" ||
              this.selfSub.SubPlayCode == "2DTW_WEI_L1" ||
              this.selfSub.SubPlayCode == "3DTW_TJ_L1"
            ) {
              arr = obj.L1;
            }
            if (this.selfSub.SubPlayCode == "2DTW_L7") {
              arr = obj.L7;
            }

            for (var num of arr) {
              var str;
              if (
                this.selfSub.SubPlayCode == "BZ_C2_HEAD" ||
                this.selfSub.SubPlayCode.indexOf("2DTW_TOU") >= 0 ||
                this.selfSub.SubPlayCode == "2DTW_L7"
              ) {
                str = num.substr(0, 2);
              } else if (
                this.selfSub.SubPlayCode == "BZ_C2" ||
                this.selfSub.SubPlayCode == "BZ_C2_1K" ||
                this.selfSub.SubPlayCode.indexOf("FUNNY") >= 0 ||
                this.selfSub.SubPlayCode.indexOf("2DTW_WEI") >= 0
              ) {
                str = num.substr(num.length - 2, 2);
              } else if (
                this.selfSub.SubPlayCode == "BZ_C3" ||
                this.selfSub.SubPlayCode.indexOf("3DTW") >= 0
              ) {
                if (num.length >= 3) {
                  str = num.substr(num.length - 3, 3);
                }
              } else if (
                this.selfSub.SubPlayCode == "BZ_C4" ||
                this.selfSub.SubPlayCode.indexOf("4D") >= 0
              ) {
                if (num.length >= 4) {
                  str = num.substr(num.length - 4, 4);
                }
              }

              if (data[str]) {
                data[str]++;
              } else {
                data[str] = 1;
              }
            }
          }
        }
      }
      let newArr = Object.entries(data).sort((a, b) => b[1] - a[1]); //Object.fromEntries(Object.entries(data).sort((a, b) => a[1]  - b[1]));

      if (type == 1) {
        return newArr[0][0];
      } else if (type == 2) {
        var tmpArr = [];
        for (var i = 0; i < 2; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);
        return tmpArr.join(",");
      } else if (type == 3) {
        var tmpArr = [];
        for (var i = 0; i < 5; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr.join(",");
      } else if (type == 4) {
        var tmpArr = [];
        for (var i = 0; i < 10; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr.join(",");
      } else if (type == 5) {
        var tmpArr = [];
        for (var i = 0; i < 20; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr.join(",");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  width: 69px;
  height: 40px;
  border-radius: 3px;
  position: relative;
  line-height: 40px;
  text-align: center;
  color: white;
  font-size:3.733333vw;
}
.select {
  background-color: #173e83;
  color: white;
}
.nolmal {
  background-color: #4a5fa9;
  color: white;
}
.titleBtn {
  background-color: #35416d;
  width: 130px;
  height: 33px;
  line-height: 33px;
  padding-left: 11px;
  color: white;
  font-size: 14.5px;
  border-radius: 5px;
  margin-top: 9px;
}
textarea {
  border: solid 0px;

  outline: none;
}
.funcBtn {
  width: 31%;
  height: 36px;
  text-align: center;

  line-height: 36px;
  border-radius: 5px;
  font-size: 12px;
}
.funcSe {
  color: #ffd477;
  border: 1px solid #ffd477;
}
.funCNo {
  color: #aac5f2;
  border: 1px solid #aac5f2;
}
.rightBtn {
  width: 18%;
  margin-left: 2%;

  height: 8.533333vw;
  border-radius: 3px;
  line-height: 8.533333vw;
  text-align: center;
  color: white;
  font-size: 3.466667vw;;
}

.rightSelect {
  background-color: #90a2dc;
}

.rightNolmal {
  background-color: #35416d;
}
</style>
