
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/assets/css/pc.css'
import defaneDrag from "@/directives/drag";

let install = function (app) {
  app.use(ElementPlus)
  app.use(defaneDrag);
}
export {
  install
}