<template>
  <div class="cardList">
    <!-- <topHeader
      :title="showDetail ? 'Hòm thư' : 'Chi tiết tin nhắn'"
      :showRight="false"
      :showBack="true"
    ></topHeader> -->

    <div class="van-nav-bar van-nav-bar--fixed">
      <div class="van-nav-bar__content">
        <div class="van-nav-bar__left" @click="back()">
          <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"><!----></i>
        </div>
        <div class="van-nav-bar__title van-ellipsis">
          <span class="topBarC">{{
            showDetail ? "Chi tiết tin nhắn" : "Hộp thư"
          }}</span>
        </div>
      </div>
    </div>

    <div v-if="showDetail" class="msgDetails">
      <div class="msgDetails" style="transform: translateX(0px) translateY(0px) translateZ(1px)">
        <div class="msgDetailsTitle" style="pointer-events: auto">
          [{{ messageData[detailIndex].ContentTitle }}]
        </div>
        <div class="msgDetailsDataTime" style="pointer-events: auto">
          {{
            $tools.formatDate(messageData[detailIndex].SendTime, "yyyy-MM-dd hh:mm:ss")
          }}

        </div>
        <div class="msgDetailsCountDiv">
          <p>
            {{ messageData[detailIndex].Content }}
          </p>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="msgList" v-for="(item, index) in messageData" @click="cellClick(index)">
        <div class="listLeft">
          <div>
            <div class="listTop">
              <span class="listClassName">
                [{{ item.ContentTitle }}]
                <i class="redDot" style="display: none"></i></span><span class="listTime">{{
                  $tools.formatDate(item.SendTime, "yyyy-MM-dd")
                }}</span>
            </div>
            <div class="listBottom">{{ item.Content }}</div>
          </div>
        </div>
        <div class="listRight">
          <i class="iconArrow van-icon van-icon-arrow van-cell__right-icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";

export default {
  data() {
    return {
      messageData: [],
      showDetail: false,
      detailIndex: -1,
    };
  },
  components: { topHeader },

  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged"]),
  },

  created() {
    if (this.isLogged) {
      this.getMessageList();
    }
    var that = this;
    bus.on("onConnected", () => {
      that.getMessageList();
    });
  },
  methods: {
    back() {
      if (this.showDetail) {
        this.showDetail = false
      } else {
        window.history.back();

      }
    },
    cellClick(index) {
      this.detailIndex = index;
      this.showDetail = true;
    },
    getMessageList() {
      var that = this;
      mqant.request(topic.mailList, {}, function (data, topicName, msg) {
        that.messageData = [];
        for (var i = 0; i < data.Data.mail.length; i++) {
          var obj = data.Data.mail[i];
          obj.check = false;
          obj.show = false;
          that.messageData.push(obj);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.cardList {
  padding: 8vw 1.333333vw;
  padding-top: 50px;
}

.msgList {
  width: 91.466667vw;
  margin: 0 auto;
  padding: 3.2vw 0;
  height: 19.2vw;
  box-shadow: inset 0 -0.5px 0 0 rgba(144, 162, 220, 0.5);

  .listBottom {
    width: 68vw;
    height: 6.4vw;
    overflow: hidden;
    line-height: 6.4vw;
    font-family: PingFangSC-Regular;
    font-size: 3.733333vw;
    color: #f9f9f9;
  }

  .listLeft {
    width: 74.666667vw;
    float: left;
    height: 100%;

    .listTop {
      height: 6.4vw;
      line-height: 6.4vw;
      display: flex;
      justify-content: space-between;

      .listTime {
        width: 21.333333vw;
        height: 4.533333vw;
        font-family: PingFangSC-Regular;
        font-size: 3.2vw;
        color: #f9f9f9;
        text-align: right;
        font-weight: 400;
      }

      .listClassName {
        width: 53.333333vw;
        height: 100%;
        font-size: 4vw;
        color: #f9f9f9;
        font-weight: 800;
        overflow: hidden;
      }
    }
  }

  .listRight {
    color: #f9f9f9;
    height: 12.8vw;
    line-height: 12.8vw;
    text-align: right;
    font-weight: bolder;

    .iconArrow {
      color: #f9f9f9;
      margin-left: 1.066667vw;
      height: 6.4vw;
      font-size: 4.266667vw;
      line-height: 6.4vw;
    }
  }
}

.msgDetails {
  padding: 0 10px 0;
  font-family: PingFangSC-Medium;
  font-size: 3.733333vw;
  color: #f9f9f9;

  .msgDetailsTitle {
    font-weight: 700;
    padding: 3.2vw 0;
    font-size: 4vw;
  }

  .msgDetailsDataTime {
    font-size: 3.2vw;
  }

  .msgDetailsCountDiv {
    padding: 3.2vw 0;
    font-weight: 400;
    line-height: 4.266667vw;
    font-size: 3.2vw;
  }
}

.van-nav-bar {
  box-shadow: 0 0.5px 0 0 hsla(0, 0%, 100%, 0.15);
  line-height: 5.866667vw;
  text-align: center;
  background-color: #293356;
}

.van-nav-bar__content {
  position: relative;

  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 13.333333vw;
}

.van-nav-bar--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.van-nav-bar__left {
  left: 0;
}

.van-nav-bar__left,
.van-nav-bar__right {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 4.266667vw;
  font-size: 3.733333vw;
  cursor: pointer;
}

.van-nav-bar .van-icon {
  color: #fff;
}

.van-nav-bar__arrow {
  margin-right: 1.066667vw;
  font-size: 4.266667vw;
}

.van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #fff;
  font-weight: 500;
  font-size: 4.266667vw;
}

.topBarC {
  width: 19.2vw;
  height: 6.666667vw;
  font-family: PingFangSC-Regular;
  font-size: 4.266667vw;
  color: #fff;
  text-align: center;
  font-weight: 400;
}
</style>
