<template>
  <div class="pop">
    <el-dialog v-model="props.show" draggable :destroy-on-close="true" @close="close">
      <template #header>
        <span class="el-dialog__title">{{ props.title }}</span>
      </template>
      <div class="content">
        <ul>
          <li v-for="item in props.data" @click="itemClick(item)">
            <div class="img">
              <div class="el-image">
                <img :src="getImg(item)" class="el-image__inner">
              </div>
            </div>
            <div class="name"> {{ item.GameName }} </div>
            <div class="cover"><span>Bắt đầu chơi </span></div>
          </li>
        </ul>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { bus } from "@/components/core/bus";
import { ref, defineProps } from "vue";
import i18n from "@/language/i18n";
import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { onMounted } from "vue";
import log from "@/utils/logger";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { isLogged } = useGetters("tcp", ["isLogged"]);
let curSelectIndex = ref(0);
const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: ''
  },
  data: {
    type: [],
    default: [],
  }
})
const close = () => {
  bus.emit(bus.event.showPop, false);
}
function language() {
  return i18n.global.locale;
}
function getImg(item) {
  let str = lobbyInfo.value.HotDomain + '/gameIcon2' + '/' + item.FactoryName + '/' + language() + '/' + item.GameCode + '.png';
  return str;
}
const itemClick = (item) => {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    return;
  }
  let url = getApiLoginUrl(item.FactoryName, item.GameCode);
  $act.openPage(url);
}
</script>

<style lang="scss" scoped>
.pop {
  :deep(.el-dialog) {
    border-radius: 12px;
    width: auto;
    max-width: 860px;
    margin-top: 25vh !important;
    background: rgba(41, 46, 59, .9);

    header {
      position: relative;
      text-align: left;
      box-shadow: inset 0 -1px 0 0 #363d4e;
      color: #fff;
      padding: 0 20px;
      width: 100%;
      height: 56px;
      line-height: 56px;
      font-size: 16px;
      box-sizing: border-box;

      .el-dialog__title {
        line-height: 24px;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
      }

      .el-dialog__headerbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: #fff;
        border-radius: 5px;
        text-align: center;
        font-size: 20px;

        position: absolute;
        padding: 0;
        background: 0 0;
        border: none;
        outline: none;
        cursor: pointer;

        .el-dialog__close {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff !important;
          font-size: 20px !important;
        }
      }
    }

    .el-dialog__body {
      color: #90a2dc;
      font-size: 14px;
      word-break: break-all;
      text-align: initial;
      padding: 0 16px 10px;

      .content {
        max-height: 460px;
        overflow-y: auto;
        height: auto;
        min-height: 0;
        border-radius: 20px;
        position: relative;
        transition: all .3s;
        max-width: 1400px;

        ul {
          display: flex;
          flex-flow: wrap;
          justify-content: start;
          padding-bottom: 10px;

          li {
            box-shadow: 0 0 3px 0 rgba(0, 0, 0, .31);
            background: #363d4e;
            width: 254px;
            height: 84px;
            margin: 10px;
            border-radius: 16px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            transition: all 0.3s;

            .img {
              width: 64px;
              height: 64px;
              background: 0 0;
              margin: 10px;

              .el-image {
                width: 64px;
                height: 64px;
                border-radius: 10px;
                box-shadow: none;
                background: 0 0;
                position: relative;
                display: inline-block;
                overflow: hidden;

                img {
                  background: 0 0;
                  width: 100%;
                  height: 100%;
                  vertical-align: top;
                }
              }
            }

            .name {
              color: #fff;
              padding: 0;
              font-size: 16px;
              font-weight: 700;
            }

            .cover {
              padding: 0;
              display: none;

              span {}
            }
          }

          li:hover {
            transform: scale(1.05);
          }
        }
      }
    }
  }
}
</style>
