<template>
    <div class="chess">
        <div class="container">
            <div class="banner">
                <img :src="$img('chess/chess_banner.png')" alt="">
            </div>
        </div>
        <div class="main">
            <div class="providerList">
                <ul>
                    <li class="third-game-live fiveFelx" v-for="(item, index) in listData"
                        :class="item.Factory.Status !== 1 ? 'disabled' : ''" @click="itemClick(item)">
                        <div class="gameItem">
                            <div class="imgs">
                                <img :src="$img(`chess/${item.Factory.FactoryName}_icon_2.png`)" alt=""
                                    class="intersperse_1">
                                <img :src="$img(`chess/${item.Factory.FactoryName}_icon_3.png`)" alt=""
                                    class="intersperse_2">
                                <img :src="$img(`chess/${item.Factory.FactoryName}_icon_1.png`)" alt="" class="croupier">
                            </div>
                            <div class="rt">
                                <p class="name">{{ $tools.getFactoryName(item.Factory.FactoryName) }}</p>
                                <span class="playRebate">Những điều bất ngờ thú
                                    vị</span>
                                <div class="playButton"> Vào trò chơi </div>
                            </div>
                            <div class="ani-container">
                                <SvgaPlayer :id="'btn_ani_chess_white_' + index" :svgaUrl="btn_ani_chess_white_svga"
                                    :svgaWidth="688" :svgaHeight="200">
                                </SvgaPlayer>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <CommonItemPop :title="popObj.title" :data="popObj.data" v-if="showPop" />
</template>
  
  
<script setup>
import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance, watchEffect } from 'vue'
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import btn_ani_chess_white_svga from "@/assets/pc/svga/btn_ani_chess_white.svga";
import { ref } from "vue";
import CommonItemPop from "@/views/pc/components/CommonItemPop.vue"
import { reactive } from "vue";
import { useRoute } from "vue-router";
import { watch } from "vue";
import log from "@/utils/logger";
const curRouter = useRoute();
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { isLogged } = useGetters("tcp", ["isLogged"]);
const $act = getCurrentInstance().appContext.config.globalProperties.$act
let showPop = ref(false);
let popObj = reactive({
    show: true,
    data: []
})
let listData = getFactorDataBytype('Card');
function getFactorDataBytype(type) {
    let data = lobbyInfo.value.StaticPicture[type];
    let rusult = [];
    if (!data) return;
    for (let key in data) {
        let info = data[key];
        if (info.Factory.Status == 1) {
            rusult.push(info);
        }
    }
    rusult.sort((a, b) => {
        return b.Factory.Sort - a.Factory.Sort
    })
    return rusult;
}
const itemClick = (item) => {
    if (item.Factory.Status !== 1) {
        return;
    }
    popObj.title = item.Factory.FactoryShowName;
    popObj.data = item.GameList;
    showPop.value = true;
}
bus.on(bus.event.showPop, (bool) => {
    showPop.value = false;
})
function openSelectFacName() {
    let curPathFacName = curRouter.query.facName || '';
    for (let key in lobbyInfo.value.StaticPicture.Card) {
        if (curPathFacName == key) {
            popObj.title = lobbyInfo.value.StaticPicture.Card[key].Factory.FactoryShowName;
            popObj.data = lobbyInfo.value.StaticPicture.Card[key].GameList;
            showPop.value = true;
            break;
        }
    }
}
watch(() => {
    curRouter.query.facName, openSelectFacName();
})
</script>
  
<style lang="scss" scoped>
.chess {
    position: relative;
    margin: 0 auto;
    background: #292e3b;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;

    .container {
        width: 1400px;
        height: auto;
        margin: 0 auto;
        position: relative;

        .banner {
            width: 1400px;
            margin: 35px 0 10px;
            padding-bottom: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .main {
        padding-top: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;

        .providerList {
            padding: 0;
            width: 1400px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            z-index: 9;

            >ul {
                width: 100%;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                max-width: 100%;
                padding: 0 0 30px;

                .third-game-live {
                    width: 688px !important;
                    height: 200px !important;
                    background-image: url('@/assets/pc/chess/chess_item_bg.png');
                    background-size: 100% 100%;
                    margin: 26px 0 0 !important;
                    padding: 0 !important;
                    overflow: hidden;
                    cursor: pointer;
                    position: relative;

                    .gameItem {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;
                        height: 100%;

                        .imgs {
                            width: 400px;
                            height: 100%;
                            position: relative;
                            pointer-events: none;
                            top: 0;
                            left: 0;

                            img {
                                position: absolute;
                                transition: all .2s;
                                -webkit-transform-origin: 50% 0;
                                transform-origin: 50% 0;
                                pointer-events: none;
                            }

                            .intersperse_1 {
                                width: 120px;
                                left: 20px;
                                bottom: 10px;
                            }

                            .intersperse_2 {
                                width: 120px;
                                right: 10px;
                                bottom: 10px;
                            }

                            .croupier {
                                width: 170px;
                                left: 120px;
                            }
                        }

                        .rt {
                            width: 260px;
                            height: 100%;
                            text-align: left;
                            padding-top: 40px;
                            pointer-events: none;

                            .name {
                                font-size: 28px;
                                color: #fff;
                                text-align: left;
                                font-weight: 600;
                            }

                            .playRebate {
                                font-size: 18px;
                                color: hsla(0, 0%, 100%, .5);
                                line-height: 25px;
                                font-weight: 400;
                                margin: 12px 0;
                                display: inline-block;
                            }

                            span {
                                position: relative;
                                z-index: 99;
                            }

                            .playButton {
                                margin-top: 6px;
                                width: 130px;
                                height: 36px;
                                background: #fff;
                                box-shadow: inset 0 1px 4px 0 hsla(0, 0%, 100%, .4);
                                box-shadow: inset 0 -2px 4px 0 rgba(0, 0, 0, .3);
                                border-radius: 18.08px;
                                font-size: 14px;
                                color: #24262b;
                                font-weight: 600;
                                text-align: center;
                                line-height: 36px;
                                transition: all .2s;
                            }
                        }

                        .ani-container {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            bottom: 0;
                            display: none;
                        }
                    }
                }

                .third-game-live:hover .croupier {
                    transform: scale(1.1);
                }

                .third-game-live:hover .rt .playButton {
                    transform: scale(1.1);
                }

                .third-game-live:hover .ani-container {
                    display: block;
                }

                .fiveFelx {
                    width: 688px;
                    height: 200px;
                    margin: 0 0 24px;
                }

                .disabled {
                    cursor: not-allowed;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                }
            }
        }
    }
}
</style>