<template>
  <div>
    <div style="padding: 0 10px; position: relative; padding-bottom: 20px">
      <div class="flexs_sb" style="margin-top: 10px">
        <div
          class="canClick"
          @click="showChoose = true"
          style="
            width: 90px;
            height: 36px;
            background-color: #90a2dc;
            line-height: 36px;
            color: white;
            font-size: 4.266667vw;
            position: relative;
            border-radius: 5px;
            padding-left: 10px;
          "
        >
          {{ getRange() }}
          <div
            style="
              position: absolute;
              background-color: #ff0000;
              height: 16px;
              line-height: 16px;
              font-size: 9.5px;
              color: white;
              width: 20px;
              right: -8px;
              top: -8px;
              border-radius: 5px;
              text-align: center;
            "
          >
            {{ selectItems.length }}
          </div>
          <img
            src="@/assets/mobile/lottery/blackDown.webp"
            alt=""
            style="position: absolute; right: 4px; width: 10px; bottom: 14px"
          />
        </div>
        <div class="flexs_sb">
          <div
            class="funcBtn"
            @click="funcIndex = index"
            :style="index > 0 ? 'margin-left:3.5px;' : ''"
            :class="funcIndex == index ? 'funcSe' : 'funCNo'"
            v-for="(item, index) in funcArr"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <div style=" margin-top: 10px; position: relative">
        <div
          v-if="funcArr[funcIndex] == 'Ngẫu nhiên'"
          class="flexs_sb"
          style=""
        >
          <div
            v-for="(item, index) in numArr2"
            v-if="isR"
            class="rightBtn canClick"
            :class="selectRight == item ? 'rightSelect' : 'rightNolmal'"
            @click="itemClick(item)"
          >
            {{ item }}
          </div>
          <div
            v-for="(item, index) in numArr1"
            v-else
            class="rightBtn canClick"
            :class="selectRight == item ? 'rightSelect' : 'rightNolmal'"
            @click="itemClick(item)"
          >
            {{ item }}
          </div>
        </div>

        <div
          v-if="funcArr[funcIndex] == 'Chọn số'"
          class="flexs_sb"
          style=""
        >
          <div
            v-for="(item, index) in dxdsArr"
            class="rightBtn canClick"
            :class="selectRight == item ? 'rightSelect' : 'rightNolmal'"
            @click="itemClick(item)"
          >
            {{ item }}
          </div>
        </div>

        <div
          v-if="funcArr[funcIndex] == 'Ít xuất hiện'"
          class="flexs_sb"
        >
          <div
            v-for="(item, index) in topArr"
            class="rightBtn canClick"
            :class="selectRight == item ? 'rightSelect' : 'rightNolmal'"
            @click="itemClick(item)"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <div style="display: flex; flex-direction: row; flex-wrap: wrap">
        <div
          class="btn canClick"
          v-for="(item, index) in numArr"
          v-if="numCount == 2"
          @click="numClick(item)"
          :class="containNum(item) ? 'select' : 'nolmal'"
          style="margin-top: 10px;width: calc(20vw - 13.6px);"
          :style="index % 5 != 0 ? 'margin-left: 12px;' : ''"
        >
          {{ item }}
          <div
            class="tag"
            :class="{
              redTag: recordOriginData[item] >= 10,
              huiTag: recordOriginData[item] <= 5,
              blueTag: recordOriginData[item] > 5 && recordOriginData[item] < 10,
            }"
          >
            {{ recordOriginData[item] }}
          </div>
        </div>
        <div
          class="btn canClick"
          v-for="(item, index) in getCurrentArr()"
          v-else
          @click="numClick(item)"
          :class="containNum(item) ? 'select' : 'nolmal'"
          style="margin-top: 10px;width: calc(20vw - 13.6px);"
          :style="index % 5 != 0 ? 'margin-left: 12px;' : ''"
        >
          {{ item }}

          <div
            class="tag"
            :class="{
              redTag: recordOriginData[item] >= 10,
              huiTag: recordOriginData[item] <= 5,
              blueTag: recordOriginData[item] > 5 && recordOriginData[item] < 10,
            }"
          >
            {{ recordOriginData[item] }}
          </div>
        </div>
      </div>
    </div>
    <chooseArea
      v-if="showChoose && numCount == 3"
      @close="showChoose = false"
      :numData="selectItems"
      @sure="changeIndex"
      :defauleIndex="numIndex"
    ></chooseArea>
  </div>
</template>

<script>
import chooseArea from "./chooseArea.vue";
import { bus, busName } from "@/components/core/bus";

export default {
  components: {
    chooseArea,
  },
  data() {
    return {
      numArr1: ["1số", "2số", "3số", "5số", "10số"],
      numArr2: ["10số", "20số", "30số", "40số", "50số"],
      dxdsArr: ["Kép bằng", "Chẵn", "Lẻ", "Tài", "Xỉu"],
      topArr: ["Top 1", "Top 2", "Top 5", "Top 10", "Top 20"],
      showChoose: false,
      rightIndex1: -1,
      rightIndex2: -1,
      rightIndex3: -1,
      selectItems: [],
      selectRight: null,
      recordCount: [],
      recordOriginData: [],
      numCount: 2,
      numArr: [],
      numIndex: 0,
      isZH: false,
      isR: false,
      funcArr: ["Ngẫu nhiên", "Chọn số", "Ít xuất hiện"],
      funcIndex: 0,
      selfSub: "",
    };
  },
  props: {
    type: {
      //1.1键全选 2.全部
      type: Number,
      default: 1,
    },
    recordData: {},
    selectSub: {},
  },
  created() {
    this.selfSub = this.selectSub;
    this.configSub();
    var that = this;
    bus.on("selectSub", (val) => {
      that.selectItems = [];
      this.selectRight = null;
    });

    bus.on("playChange", (val) => {
      that.selectItems = [];
      this.selectRight = null;

      that.selfSub = val;
      that.configSub();
    });

    bus.on("allClean", (val) => {
      that.selectItems = [];
      this.selectRight = null;
    });
  },
  methods: {
    configSub() {
      if (this.selfSub.SubPlayCode.indexOf("ZH") >= 0) {
        this.numArr1 = [this.numArr1[0]];
        this.funcArr = [this.funcArr[0]];

        this.isZH = true;
      }
      if (
        this.selfSub.SubPlayCode.indexOf("2DTW") >= 0 ||
        this.selfSub.SubPlayCode.indexOf("3DTW") >= 0
      ) {
        this.isR = true;
      }
      this.getTopData();

      if (
        this.selfSub.SubPlayCode == "BZ_C3" ||
        this.selfSub.SubPlayCode.indexOf("3DTW") >= 0
      ) {
        this.numCount = 3;
        this.dxdsArr = [this.dxdsArr[0]];

        for (var i = 0; i < 1000; i++) {
          var num = i;
          if (num < 10) {
            num = "00" + num;
          } else if (num >= 10 && num < 100) {
            num = "0" + num;
          }
          this.numArr.push(num);
        }
      } else {
        for (var i = 0; i < 100; i++) {
          var num = i;
          if (num < 10) {
            num = num < 10 ? "0" + num : num;
          }
          this.numArr.push(num);
        }
      }
    },
    clean() {
      this.selectItems = [];
      this.selectRight = null;
      var obj = {
        numArr: this.selectItems,

        betType: "fast",
        SubPlayCode: this.selfSub.SubPlayCode,
      };

      bus.emit("BET", obj);
    },
    changeIndex(val) {
      this.numIndex = val;
      this.showChoose = false;
    },
    getRange() {
      if (this.numCount == 3) {
        var begin = this.numIndex * 100;
        if (begin == 0) {
          begin = "000";
        }
        var end = this.numIndex * 100 + 99;
        if (begin == 0) {
          end = "099";
        }
        return begin + "-" + end;
      } else {
        return "00-99";
      }
    },
    getCurrentArr() {
      return this.numArr.slice(this.numIndex * 100, 100 + this.numIndex * 100);
    },
    itemClick(str) {
      if (this.selectRight == str) {
        this.selectRight = null;
        this.selectItems = [];
        var obj = {
          numArr: this.selectItems,

          betType: "fast",
          SubPlayCode: this.selfSub.SubPlayCode,
        };

        bus.emit("BET", obj);
        return;
      }

      this.selectRight = str;
      if (str == "1số") {
        var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

        if (this.numCount == 3) {
          if (num < 10) {
            num = "00" + num;
          } else if (num > 10 && num < 100) {
            num = "0" + num;
          }
          this.selectItems = [num];
        } else {
          if (this.isZH) {
            var forNum = this.selfSub.UnitBetCodeCount;
            var arr = [];
            for (var i = 0; i < 100; i++) {
              var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

              num = num < 10 ? "0" + num : num;

              if (arr.indexOf(num) < 0) {
                arr.push(num);
                if (arr.length == forNum) {
                  break;
                }
              }
            }
            this.selectItems = arr;
          } else {
            this.selectItems = [num < 10 ? "0" + num : num];
          }
        }

        //this.text = num<10?'0'+num:num
      } else if (str == "2số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)
          if (this.numCount == 3) {
            if (num < 10) {
              num = "00" + num;
            } else if (num > 10 && num < 100) {
              num = "0" + num;
            }
          } else {
            num = num < 10 ? "0" + num : num;
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 2) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "3số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)
          if (this.numCount == 3) {
            if (num < 10) {
              num = "00" + num;
            } else if (num > 10 && num < 100) {
              num = "0" + num;
            }
          } else {
            num = num < 10 ? "0" + num : num;
          }

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 3) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "5số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)
          if (this.numCount == 3) {
            if (num < 10) {
              num = "00" + num;
            } else if (num > 10 && num < 100) {
              num = "0" + num;
            }
          } else {
            num = num < 10 ? "0" + num : num;
          }

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 5) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "10số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)
          if (this.numCount == 3) {
            if (num < 10) {
              num = "00" + num;
            } else if (num > 10 && num < 100) {
              num = "0" + num;
            }
          } else {
            num = num < 10 ? "0" + num : num;
          }

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 10) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "20số") {
        var arr = [];
        for (var i = 0; i < 10000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

          num = num < 10 ? "0" + num : num;

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 20) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "30số") {
        var arr = [];
        for (var i = 0; i < 10000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

          num = num < 10 ? "0" + num : num;

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 30) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "40số") {
        var arr = [];
        for (var i = 0; i < 10000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

          num = num < 10 ? "0" + num : num;

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 40) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "50số") {
        var arr = [];
        for (var i = 0; i < 10000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

          num = num < 10 ? "0" + num : num;

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 50) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "Kép bằng") {
        this.selectItems = [0, 11, 22, 33, 44, 55, 66, 77, 88, 99];
        if (this.numCount == 3) {
          this.selectItems = [0, 111, 222, 333, 444, 555, 666, 777, 888, 999];
        }
      } else if (str == "Chẵn") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = i;
          if (num % 2 == 0) {
            num = num < 10 ? "0" + num : num;
            arr.push(num);
          }
        }
        this.selectItems = arr;
      } else if (str == "Lẻ") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = i;
          if (num % 2 == 1) {
            num = num < 10 ? "0" + num : num;
            arr.push(num);
          }
        }
        this.selectItems = arr;
      } else if (str == "Tài") {
        var arr = [];
        for (var i = 50; i < 100; i++) {
          var num = i;

          num = num < 10 ? "0" + num : num;
          arr.push(num);
        }
        this.selectItems = arr;
      } else if (str == "Xỉu") {
        var arr = [];
        for (var i = 0; i < 50; i++) {
          var num = i;

          num = num < 10 ? "0" + num : num;
          arr.push(num);
        }
        this.selectItems = arr;
      } else if (str == "Top 1") {
        this.selectItems = this.getTopData(1);
      } else if (str == "Top 2") {
        this.selectItems = this.getTopData(2);
      } else if (str == "Top 5") {
        this.selectItems = this.getTopData(3);
      } else if (str == "Top 10") {
        this.selectItems = this.getTopData(4);
      } else if (str == "Top 20") {
        this.selectItems = this.getTopData(5);
      }

      var obj = {
        numArr: this.selectItems,

        betType: "fast",
        SubPlayCode: this.selfSub.SubPlayCode,
      };

      bus.emit("BET", obj);
    },
    numClick(num) {
      var contain = false;
      for (var i = 0; i < this.selectItems.length; i++) {
        if (this.selectItems[i] == num) {
          contain = true;
          this.selectItems.splice(i, 1);

          break;
        }
      }

      if (!contain) {
        if (this.selectItems.length >= this.selfSub.MaxBetNumber) {
          return;
        }
        this.selectItems.push(num);
      }
      var obj = {
        numArr: this.selectItems,

        betType: "fast",
        SubPlayCode: this.selfSub.SubPlayCode,
      };

      bus.emit("BET", obj);
      this.$emit("numChange", this.selectItems);
    },
    containNum(num) {
      //num = num < 10 ? '0' + num : num

      //var contain = false
      for (var nums of this.selectItems) {
        if (num == nums) {
          return true;
        }
      }
      return false;
    },
    reset() {
      this.text = "";
      this.selectItem = null;
      var obj = {
        numArr: this.selectItems,

        betType: "fast",
        SubPlayCode: this.selfSub.SubPlayCode,
      };

      bus.emit("BET", obj);
    },

    done(len, max) {
      var arr = [];
      var result = "";
      var count = 0;
      while (count < len) {
        var n = Math.floor(Math.random() * (max + 1));
        if (arr.join().indexOf(n) == -1) {
          arr.push(n);
          count++;
        }
      }
      for (let index = 0; index < arr.length; index++) {
        result = result + arr[index];
      }
      return parseInt(result);
    },
    getTopData(type) {
      var data = {};

      for (var recordObj of this.recordData) {
        var obj = recordObj.OpenCode;
        var forNum = 10;
        if (this.isR) {
          forNum = 1;
        }
        if (!obj) {
          continue;
        }
        for (var i = 0; i < forNum; i++) {
          if (obj["L" + i]) {
            var arr = obj["L" + i];
            if (
              this.selfSub.SubPlayCode == "2DTW_TOU" ||
              this.selfSub.SubPlayCode == "2DTW_WEI" ||
              this.selfSub.SubPlayCode == "3DTW_TJ"
            ) {
              arr = obj.L0;
            }

            if (
              this.selfSub.SubPlayCode == "2DTW_TOU_L1" ||
              this.selfSub.SubPlayCode == "2DTW_WEI_L1" ||
              this.selfSub.SubPlayCode == "3DTW_TJ_L1"
            ) {
              arr = obj.L1;
            }
            if (this.selfSub.SubPlayCode == "2DTW_L7") {
              arr = obj.L7;
            }

            for (var num of arr) {
              var str;
              if (
                this.selfSub.SubPlayCode == "BZ_C2_HEAD" ||
                this.selfSub.SubPlayCode.indexOf("2DTW_TOU") >= 0 ||
                this.selfSub.SubPlayCode == "2DTW_L7"
              ) {
                str = num.substr(0, 2);
              } else if (
                this.selfSub.SubPlayCode == "BZ_C2" ||
                this.selfSub.SubPlayCode == "BZ_C2_1K" ||
                this.isZH ||
                this.selfSub.SubPlayCode.indexOf("2DTW_WEI") >= 0
              ) {
                str = num.substr(num.length - 2, 2);
              } else if (
                this.selfSub.SubPlayCode == "BZ_C3" ||
                this.selfSub.SubPlayCode.indexOf("3DTW") >= 0
              ) {
                if (num.length >= 3) {
                  str = num.substr(num.length - 3, 3);
                }
              } else if (this.selfSub.SubPlayCode == "BZ_C4") {
                if (num.length >= 4) {
                  str = num.substr(num.length - 4, 4);
                }
              }

              if (data[str]) {
                data[str]++;
              } else {
                data[str] = 1;
              }
            }
          }
        }
      }
      this.recordOriginData = data;

      let newArr = Object.entries(data).sort((a, b) => b[1] - a[1]); //Object.fromEntries(Object.entries(data).sort((a, b) => a[1]  - b[1]));
      this.recordCount = newArr;
      if (type == 1) {
        return [newArr[0][0]];
      } else if (type == 2) {
        var tmpArr = [];
        for (var i = 0; i < 2; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);
        return tmpArr;
      } else if (type == 3) {
        var tmpArr = [];
        for (var i = 0; i < 5; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr;
      } else if (type == 4) {
        var tmpArr = [];
        for (var i = 0; i < 10; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr;
      } else if (type == 5) {
        var tmpArr = [];
        for (var i = 0; i < 20; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
 
  height: 35px;
  border-radius: 5px;
  position: relative;
  line-height: 35px;
  text-align: center;
  font-size: 18px;
}

.select {
  background-image: linear-gradient(135deg,#7146ff,#4a69ff);
  color: white;
}

.nolmal {
  background-color: white;
  color: black;
}

.tag {
  width: 22px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  font-size: 14px;
  color: white;
  position: absolute;
  right: 0;
  top: 0;
}

.redTag {
  background-color: #ff0000;
}

.huiTag {
  background-color: #6b6c6f;
}

.blueTag {
  background-color: #4a5fa9;
}

.rightBtn {
  width: 17.066667vw;
  height: 8.533333vw;
  line-height: 8.533333vw;
  border-radius: 1.066667vw;
  font-size: 3.466667vw;
  color: #fff;
  text-align: center;
  font-weight: 400;
}

.rightSelect {
  background-color: #90a2dc;
}

.rightNolmal {
  background-color: #35416d;
}

.funcBtn {
  width: 69px;
  height: 28px;
  text-align: center;

  line-height: 28px;
  border-radius: 2.5px;
  font-size: 12px;
}
.funcSe {
  color: #ffd477;
  border: 1px solid #ffd477;
}
.funCNo {
  color: #aac5f2;
  border: 1px solid #aac5f2;
}
</style>
