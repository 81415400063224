
import { mapGetters, mapActions, mapMutations } from "vuex";
import { bus, busName } from "@/components/core/bus";

export default {
    methods: {
        waitFinish() {
            return new Promise((resolve, reject) => {
                if (this.logging) {
                    bus.on(busName.onLogged, function (isLogged) {
                        resolve(isLogged)
                    })
                } else {
                    resolve(this.isLogged)
                }
            })

        }
    },
    computed: {
        ...mapGetters("tcp", ["isLogged", "logging"]),
    },
    data() {
        return {
            minDate: new Date(this.$tools.timestampMillisec() - 180 * 86400 * 1000),
            maxDate: new Date(),
            startDate: new Date().Format("yyyy-MM-dd"),
            // startDate: "2022-06-01",
            endDate: new Date().Format("yyyy-MM-dd"),
            calenderShow: false,
            calenderType: "",
        }
    },
    mounted() {
        this.waitFinish().then(isLogged => {
            // console.log("mounted111")
            this.$log.info("auth wait finish:" + isLogged)
            if (!isLogged) {
                if (this.$tools.isMobile())
                    this.$router.push({ name: "login" })
            }
        })
    },
}