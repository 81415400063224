<template>
  <div class="sport">
    <div class="container">
      <div class="banner">
        <img :src="$img('sport/sports_banner.png')" alt="">
      </div>
    </div>
    <div class="main">
      <div class="providerList">
        <ul>
          <li class="third-game-sport fourFelx sports" v-for="(item, index) in lobbyInfo.StaticPicture.LiveSports"
            @click="openFactory(item.Factory.FactoryName)">
            <div class="gameItem">
              <div class="ani-container">
                <SvgaPlayer :id="'btn_ani_ty_black_' + index" :svgaUrl="btn_ani_ty_black_svga" :svgaWidth="450"
                  :svgaHeight="254">
                </SvgaPlayer>
              </div>
              <div class="content">
                <div class="row-l">
                  <div class="platLogo">
                    <img :src="$img(`sport/${item.Factory.FactoryName}_icon_2.png`)" alt="">
                  </div>
                  <p class="playCode">{{ item.Factory.FactoryShowName }}</p>
                  <div class="playRebateWp">
                    <span class="playRebate">Phí hoàn tối đa 1.7%</span>
                  </div>
                  <div class="playButton">Vào trò chơi</div>
                </div>
                <div class="game-img-athlete">
                  <img :src="$img(`sport/${item.Factory.FactoryName}_icon_1.png`)" alt="">
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script setup>
import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import btn_ani_ty_black_svga from "@/assets/pc/svga/btn_ani_ty_black.svga";
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { isLogged } = useGetters("tcp", ["isLogged"]);
const $act = getCurrentInstance().appContext.config.globalProperties.$act
let listData = getFactorDataBytype('LiveSports');
function getFactorDataBytype(type) {
  let data = lobbyInfo.value.StaticPicture[type];
  let rusult = [];
  if (!data) return;
  for (let key in data) {
    let info = data[key];
    if (info.Factory.Status == 1) {
      rusult.push(info);
    }
  }
  rusult.sort((a, b) => {
    return b.Factory.Sort - a.Factory.Sort
  })
  return rusult;
}
const openFactory = (factoryName) => {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    return;
  }
  if (factoryName) {
    let url = getApiLoginUrl(factoryName, '');
    $act.openPage(url);
  }
}
</script>

<style lang="scss" scoped>
.sport {
  background: #292e3b;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;

  .container {
    width: 1400px;
    height: auto;
    margin: 0 auto;
    position: relative;

    .banner {
      width: 1400px;
      margin: 35px 0 10px;
      padding-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .main {
    padding-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    .providerList {
      padding: 0;
      width: 1400px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      z-index: 9;

      >ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 100%;
        padding: 0 0 30px;

        .sports {
          height: 254px;
        }

        .fourFelx {
          width: 450px;
          margin-right: 16px;
        }

        .third-game-sport {
          margin-bottom: 24px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .2);
          border-radius: 24px;
          cursor: pointer;
          position: relative;
          transition: transform .3s, -webkit-transform .3s;

          .gameItem {
            position: relative;
            width: 450px;
            height: 254px;
            background: url("@/assets/pc/recommend/bg_sports_item.png") no-repeat 50%/100%;
            display: flex;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, .2);
            border-radius: 24px;
            pointer-events: all;

            .content {
              display: flex;
              pointer-events: none;

              .row-l {
                width: 230px;
                padding: 26px 0 0 28px;

                .platLogo {
                  height: 40px;
                  margin-bottom: 10px;

                  img {
                    height: 100%;
                    position: relative;
                    left: -18px;
                  }
                }

                .playCode {
                  font-size: 28px;
                  color: #fff;
                  line-height: 40px;
                  font-weight: 600;
                  white-space: nowrap;
                }

                .playRebateWp {
                  line-height: 25px;
                  margin-top: 6px;
                  font-size: 16px;
                  color: hsla(0, 0%, 100%, .5);

                  .playRebate {
                    color: hsla(0, 0%, 100%, .5);
                  }
                }

                .playButton {
                  transition: -webkit-transform .3s;
                  transition: transform .3s;
                  transition: transform .3s, -webkit-transform .3s;
                  width: 130px;
                  height: 36px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 24px;
                  font-size: 14px;
                  background-image: linear-gradient(105deg, #7146ff, #4a69ff);
                  box-shadow: inset 0 1px 4px 0 hsla(0, 0%, 100%, .4);
                  border-radius: 18.08px;
                  color: #fff;
                }
              }

              .game-img-athlete {
                width: 230px;
                padding: 26px 0 0 28px;

                img {
                  width: 100%;
                  height: 100%;
                  transition: transform .3s, -webkit-transform .3s;
                  width: 100%;
                }
              }
            }

            .ani-container {
              position: absolute;
              width: 100%;
              height: 100%;
              z-index: 0;
              display: none;
            }
          }

          .gameItem:hover .playButton,
          .gameItem:hover .game-img-athlete img {
            transform: scale(1.1);
          }

          .gameItem:hover .ani-container {
            display: block;
          }
        }
      }
    }
  }
}
</style>