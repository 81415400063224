let rootView = require('@/views/pc/RootView.vue').default
let home = require('@/views/pc/components/Home.vue').default
let liveCasino = require('@/views/pc/components/LiveCasino.vue').default
let slot = require('@/views/pc/components/Slot.vue').default
let fish = require('@/views/pc/components/Fish.vue').default
let chess = require('@/views/pc/components/Chess.vue').default
let sport = require('@/views/pc/components/Sport.vue').default
let lottery = require('@/views/pc/components/Lottery.vue').default
let luckyWin = require('@/views/pc/components/LuckyWin.vue').default
let cockFight = require('@/views/pc/components/CockFight.vue').default
let vip = require('@/views/pc/components/Vip.vue').default
let turntable = require('@/views/pc/components/turntable.vue').default
let dowload = require('@/views/pc/components/downLoad.vue').default
let casinoIframe = require('@/views/pc/components/casinoIframe.vue').default

let activity = require('@/views/pc/components/Activity.vue').default
let agency = require('@/views/pc/components/Agency.vue').default
let help = require('@/views/pc/components/Help.vue').default
let userCenter = require('@/views/pc/components/UserCenter.vue').default
let recharge = require('@/views/pc/components/userCenter/Recharge.vue').default
let drawMoney = require('@/views/pc/components/userCenter/DrawMoney.vue').default
let personal = require('@/views/pc/components/userCenter/Personal.vue').default
let record = require('@/views/pc/components/userCenter/Record.vue').default
let billRecord = require('@/views/pc/components/userCenter/BillRecord.vue').default
let betHistory = require('@/views/pc/components/userCenter/BetHistory.vue').default
let message = require('@/views/pc/components/userCenter/Message.vue').default
let lotteryResult = require('@/views/pc/components/userCenter/LotteryResult.vue').default
let discountDraw = require('@/views/pc/components/userCenter/DiscountDraw.vue').default
let userVip = require('@/views/pc/components/userCenter/userVip.vue').default



let userCenterChildren = [
  {
    path: 'recharge',
    component: recharge,
    name: 'recharge'
  },
  {
    path: 'drawMoney',
    component: drawMoney,
    name: 'drawMoney'
  },
  {
    path: 'personal',
    component: personal,
    name: 'personal'
  },
  {
    path: 'record',
    component: record,
    name: 'record'
  },
  {
    path: 'billRecord',
    component: billRecord,
    name: 'billRecord'
  },
  {
    path: 'betHistory',
    component: betHistory,
    name: 'betHistory'
  },
  {
    path: 'message',
    component: message,
    name: 'message'
  },
  {
    path: 'lotteryResult',
    component: lotteryResult,
    name: 'lotteryResult'
  },
  {
    path: 'discountDraw',
    component: discountDraw,
    name: 'discountDraw'
  },
  {
    path: 'userVip',
    component: userVip,
    name: 'userVip'
  }
  
  // {
  //   path: 'changePW',
  //   component: changePW,
  //   name: 'changePW'
  // },
  // {
  //   path: 'secure',
  //   component: secure,
  //   name: 'secure'
  // },
  // {
  //   path: 'userMessage',
  //   component: userMessage,
  //   name: 'userMessage'
  // },
  // {
  //   path: 'GameRecord',
  //   component: GameRecord,
  //   name: 'GameRecord'
  // },
  // {
  //   path: 'BillRecord',
  //   component: BillRecord,
  //   name: 'BillRecord'
  // },
]

let rootChilren = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/liveCasino',
    name: 'liveCasino',
    component: liveCasino
  },
  {
    path: '/slot',
    name: 'slot',
    component: slot
  },
  {
    path: '/fish',
    name: 'fish',
    component: fish
  },
  {
    path: '/chess',
    name: 'chess',
    component: chess
  },
  {
    path: '/sport',
    name: 'sport',
    component: sport
  },
  {
    path: '/lottery',
    name: 'lottery',
    component: lottery
  },
  {
    path: '/Zwin',
    name: 'Zwin',
    component: luckyWin
  },
  {
    path: '/cockFight',
    name: 'cockFight',
    component: cockFight
  },
  {
    path: '/vip',
    name: 'vip',
    component: vip
  },
  {
    path: '/activity',
    name: 'activity',
    component: activity
  },
  {
    path: '/agency',
    name: 'agency',
    component: agency
  },
  {
    path: '/help',
    name: 'help',
    component: help
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: userCenter,
    children: userCenterChildren
  },
  {
    path: '/turntable',
    name: 'turntable',
    component: turntable
  },
  {
    path: '/dowload',
    name: 'dowload',
    component: dowload
  },
]


let routes = [
  {
    path: '/',
    name: 'rootView',
    component: rootView,
    children: rootChilren
  },
  {
    path: '/navigator',
    name: 'casino',
    component: casinoIframe
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]
export {
  routes,
} 