<template>
  <div class="personal">
    <div v-if="loadSucess" class="information">
      <div class="cardWrap">
        <div class="cardInfo cardBtnHover">
          <div class="cardTitle">
            <span class="title">Thông tin cá nhân</span>
          </div>
          <div class="cardBody">
            <p class=""><span></span></p>
            <form class="el-form">
              <div class="el-form-item el-form-item--mini">
                <label class="el-form-item__label" style="width: 170px">Tài khoản</label>
                <div class="el-form-item__content">
                  <p class="lh40 usernames">{{ userInfo.Account }}</p>
                </div>
              </div>

              <div class="el-form-item el-form-item--mini">
                <label class="el-form-item__label" style="width: 170px">Sinh nhật</label>
                <div class="el-form-item__content">

                  <p class="lh40" v-if="countDate(userAll.userData.Birthday).indexOf('1970-01-01') >= 0"></p>

                  <p class="lh40" v-else>{{ countDate(userAll.userData.Birthday).split(' ')[0] }}</p>


                  <el-date-picker style="width:175px;height:40px;margin-left:25px;" class="birthdayPicker"
                    v-if="countDate(userAll.userData.Birthday).indexOf('1970-01-01') >= 0" :clearable='false'
                    :editable='false' v-model="birthday" type="date" :placeholder="$t('设置生日')" @change="dateChange">
                  </el-date-picker>


                </div>
              </div>

              <div class="el-form-item el-form-item--mini">
                <label class="el-form-item__label" style="width: 170px">Họ tên</label>
                <div class="el-form-item__content">
                  <p class="lh40">{{ userAll.userData.realName }}</p>
                  <button type="button" class="el-button changeBtn ml24 el-button--primary el-button--mini"
                    @click="toUrl(lobbyInfo.CustomerInfo.customerLiveChat)">
                    <span>Liên hệ CSKH để thay đổi</span>
                  </button>
                </div>
              </div>


            </form>
          </div>
        </div>
        <div class="cardInfo cardBtnHover">
          <div class="cardTitle">
            <span class="title">Bảo mật tài khoản</span>
          </div>
          <div class="cardBody">
            <p class=""><span></span></p>
            <form class="el-form">
              <div class="el-form-item el-form-item--mini">
                <label class="el-form-item__label" style="width: 170px">Số điện thoại</label>
                <div class="el-form-item__content">
                  <p class="lh40">{{ userAll.userData.Phone }}</p>
                  <button disabled="disabled" type="button"
                    class="el-button verified ml24 w124 el-button--text el-button--mini is-disabled">
                    <span>Đã xác nhận</span>
                  </button>
                </div>
              </div>
              <div class="el-form-item el-form-item--mini">
                <label class="el-form-item__label" style="width: 170px">Mật khẩu đăng nhập</label>
                <div class="el-form-item__content">
                  <!-- <p class="lh40">{{ userAll.userData.Password }}</p> -->
                  <input type="password" class="lh40" disabled v-model="userAll.userData.Password"
                    style="background-color: transparent;">


                  <button type="button" @click="changePwDialog.show = true"
                    class="el-button ml24 w124 el-button--primary el-button--mini">
                    <span>Thay đổi</span>
                  </button>
                </div>
              </div>
              <div class="el-form-item el-form-item--mini">
                <label class="el-form-item__label" style="width: 170px">Mật khẩu rút tiền</label>
                <div class="el-form-item__content">
                  <p v-if="!userAll.userData.DoudouPwd.length" class="lh40">
                    Quý khách chưa cài đặt mật khẩu rút tiền
                  </p>
                  <!-- <p v-else class="lh40">{{ userAll.userData.DoudouPwd }}</p> -->
                  <input v-else type="password" class="lh40" disabled v-model="userAll.userData.DoudouPwd"
                    style="background-color: transparent;">

                  <button type="button" class="el-button ml24 w124 el-button--primary el-button--mini"
                    @click="drawMoneyDialog.show = true">
                    <span>Cài đặt</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="bankCard">
        <div class="cardInfo cardBtnHover">
          <div class="cardTitle">
            <div>
              <span class="account-name">số tài khoản</span>
              <span class="accountsNumText">có thể điền：{{
                userAll.userData.myDouDouBT.length
              }}/3</span>
            </div>
          </div>
          <div class="cardBody">
            <p class=""><span></span></p>
            <div class="cardList">
              <div v-if="userAll.userData.myDouDouBT.length < 3" class="cardItem addBankCard" @click="openBindCard()">
                <i size="16" class="iconfont icon-icon_add_bankcard"></i>
                <span>Thêm vào</span>
              </div>
              <div class="cardItem" :class="addBankDialog.selectIndex == index ? 'cardList-active' : ''
                " v-for="(item, index) in userAll.userData.myDouDouBT" @click="addBankDialog.selectIndex = index">
                <div class="cardItems">
                  <div class="cardItemInfo">
                    <div class="cardInfoLeft">
                      <span class="cardName">{{ item.BtName }}</span>
                    </div>
                  </div>
                  <div class="cardNum">
                    <div class="cardLists">
                      <!-- <span class="cardSpan">***</span>
                      <span class="cardSpan">****</span>
                      <span class="cardSpan">{{
                        item.CardNum.substr(-4)
                      }}</span> -->
                      <span class="cardSpan">{{
                        item.CardNum
                      }}</span>
                    </div>
                    <div v-if="addBankDialog.selectIndex == index" class="defaultBank">
                      <button type="button" class="el-button defaultBankBtn el-button--primary el-button--small">
                        <span>Mặc định</span>
                      </button>
                    </div>
                  </div>
                  <i class="iconfont icon-duigou"></i>
                </div>
                <div class="bg_bank_card"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="dialog">
        <el-dialog v-model="changePwDialog.show" title="ĐỔI MẬT KHẨU" :show-close="false">
          <span class="closeBtn" @click="changePwDialog.show = false">
            <i class="iconfont icon-icon_close_dark"></i>
          </span>
          <div class="dialogBody transactionPwdBody">
            <el-form ref="changePasswordRef" :model="changePwDialog" :rules="rules" label-position="top">
              <el-form-item label="Mật khẩu cũ" prop="oldPassword">
                <el-input v-model="changePwDialog.oldPassword" placeholder="Mật khẩu cũ" type="password" />
              </el-form-item>
              <el-form-item label="Mật khẩu mới" prop="newPassword">
                <el-input v-model="changePwDialog.newPassword" placeholder="Mật khẩu mới" type="password" />
              </el-form-item>
              <el-form-item label="Xác nhận mật khẩu mới" prop="passwordCheckPass">
                <el-input v-model="changePwDialog.passwordCheckPass" placeholder="Xác nhận mật khẩu mới"
                  type="password" />
              </el-form-item>
            </el-form>
            <el-button class="el-button el-button--primary el-button--small" @click="changePW">
              {{ $t("提交") }}</el-button>
          </div>
        </el-dialog>

        <el-dialog v-model="drawMoneyDialog.show" title="Thay đổi mật khẩu giao dịch" :show-close="false">
          <span class="closeBtn" @click="drawMoneyDialog.show = false">
            <i class="iconfont icon-icon_close_dark"></i>
          </span>
          <div class="dialogBody transactionPwdBody">
            <el-form ref="drawMoneyRef" :model="drawMoneyDialog" :rules="rules" label-position="top">
              <el-form-item v-if="userAll.userData.DoudouPwd.length > 0" label="Mật khẩu cũ" prop="oldDwPassword">
                <el-input v-model="drawMoneyDialog.oldDwPassword" placeholder="Mật khẩu cũ" type="password" />
              </el-form-item>
              <el-form-item label="Mật khẩu giao dịch mới" prop="password">
                <el-input v-model="drawMoneyDialog.password" placeholder="Mật khẩu rút tiền" type="password" />
              </el-form-item>
              <el-form-item label="Xác nhận lại mật khẩu giao dịch" prop="checkPass">
                <el-input v-model="drawMoneyDialog.checkPass" placeholder="Mật khẩu rút tiền" type="password" />
              </el-form-item>
            </el-form>
            <el-button class="el-button el-button--primary el-button--small" @click="drawMoneySumit">
              {{ $t("提交") }}</el-button>
          </div>
        </el-dialog>

        <el-dialog v-model="addBankDialog.show" title="Thêm thông tin ngân hàng" :show-close="false">
          <span class="closeBtn" @click="addBankDialog.show = false">
            <i class="iconfont icon-icon_close_dark"></i>
          </span>
          <div class="dialogBody transactionPwdBody">
            <el-form ref="addBankRef" :model="addBankDialog" :rules="rules" label-position="top">
              <el-form-item label="Chọn tên ngân hàng">
                <el-select v-model="addBankDialog.bankName">
                  <el-option v-for="item in addBankDialog.list" :key="item" :value="item.BtName">{{ item.BtName
                  }}</el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Vui lòng nhập stk ngân hàng" prop="cardNum">
                <el-input v-model="addBankDialog.cardNum" placeholder="vui lòng nhập số tài khoản ngân hàng" />
              </el-form-item>
              <el-form-item label="Xin điền họ tên" prop="realName">
                <el-input v-model="userAll.userData.realName" :disabled="addBankDialog.isDisabled"
                  style="cursor: not-allowed" placeholder="Xin điền họ tên" />
              </el-form-item>

              <el-form-item label="Vui lòng Mật khẩu rút tiền" prop="DoudouPwd">
                <el-input type="text" v-model="addBankDialog.DoudouPwd" style="cursor: not-allowed"
                  placeholder="Vui lòng Mật khẩu rút tiền" />
              </el-form-item>
            </el-form>
            <el-button class="el-button el-button--primary el-button--small" @click="addBankSumit">
              {{ $t("提交") }}</el-button>
          </div>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import sf_games_btn_02_svga from "@/assets/pc/svga/sf_games_btn_02.svga";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import {
  nameRule,
  passRule,
  phoneRule,
  realNameRule,
} from "@/utils/vaildate.js";
import { topic } from "@/components/protocol/api";
import { http } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
let drawMoneyRef = ref(null);
let addBankRef = ref(null);
let changePasswordRef = ref(null);
let birthday = ref(null);

let drawMoneyDialog = reactive({
  show: false,
  oldDwPassword: "",
  password: "",
  checkPass: "",
});

let changePwDialog = reactive({
  show: false,
  oldPassword: "",
  newPassword: "",
  passwordCheckPass: "",
});

let addBankDialog = reactive({
  show: false,
  list: [],
  selectIndex: 0,
  bankName: "",
  cardNum: "",
  realName: "",
  isDisabled: false,
  DoudouPwd: "",
});

let loadSucess = ref(false);
let userAll = reactive({
  userData: {
    DoudouPwd: []
  },
});
if (isLogged.value) {
  getUserInfo();
} else {
  bus.on('onConnected', () => {
    getUserInfo()
  })
}

function countYear(birthday) {
  var dateBegin = new Date(birthday);
  var dateEnd = new Date();

  var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
  var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
  if (dayDiff / 365.0 < 18) {
    return false
  } else {
    return true
  }
}
function onConfirm() {




  var parmes = {
    date: birthday.value
  }
  mqant.request(topic.setBirthday, parmes, function (res) {


    if (res.Code == 0) {
      getUserInfo()


    } else {
      ElMessage({
        message: reject.ErrMsg,
        type: "error",
      });


    }
  });
}
function dateFormat(dateData) {
  var date = new Date(dateData)
  var y = date.getFullYear()
  var m = date.getMonth() + 1
  m = m < 10 ? ('0' + m) : m
  var d = date.getDate()
  d = d < 10 ? ('0' + d) : d
  const time = y + '-' + m + '-' + d
  return time
}

function dateChange(val) {
  if (!countYear(dateFormat(val))) {


    ElMessage({
      message: 'Ngày sinh phải trên 18 tuổi',
      type: "error",
    });
    return
  }
  birthday.value = dateFormat(val)

  onConfirm()
}

function getUserInfo() {
  mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
    if (data.Code == 0) {
      log.info('getUserInfo', data.Data)
      userAll.userData = data.Data;
      loadSucess.value = true;
      addBankDialog.isDisabled = !data.Data.realName ? false : true
      getBankList();
    } else {
      ElMessage.error(data.ErrMsg);
    }
  });
}
function getBankList() {
  mqant.request(topic.bankList, {}, function (data, topicName, msg) {
    addBankDialog.list = data.Data.douDouBtList;
    addBankDialog.bankName = data.Data.douDouBtList[0].BtName;
    // log.info('getBankList', data.Data)
  });
}

function toUrl(url) {
  window.open(url);
}
function openBindCard() {
  if (!userAll.userData.DoudouPwd.length) {
    drawMoneyDialog.show = true;
    return;
  }
  addBankDialog.show = true;
}
const checkPassRule = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Vui lòng Mật khẩu rút tiền"));
  } else if (value !== drawMoneyDialog.password) {
    callback(new Error("Nhập 2 lần mật khẩu không khớp"));
  } else {
    callback();
  }
};

const oldDwPassRule = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Nhập mật khẩu cũ"));
  } else if (value !== userAll.userData.DoudouPwd) {
    callback(new Error("Mật khẩu ban đầu không đúng"));
  } else {
    callback();
  }
};

const oldPassRule = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Nhập mật khẩu cũ"));
  } else if (value !== userAll.userData.Password) {
    callback(new Error("Mật khẩu ban đầu không đúng"));
  } else {
    callback();
  }
};

const passwordRule = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Nhập mật khẩu cũ"));
  } else if (value !== changePwDialog.newPassword) {
    callback(new Error("Hai mật khẩu không khớp"));
  } else {
    callback();
  }
};

const douDouRule = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Xác nhận mật khẩu"));
  } else if (value !== userAll.userData.DoudouPwd) {
    callback(new Error("Mật khẩu sai"));
  } else {
    callback();
  }
};

const drawMoneySumit = () => {
  drawMoneyRef.value.validate((valid) => {
    if (valid) {
      let obj = {
        DoudouPwd: drawMoneyDialog.checkPass,
      };
      mqant.request(
        topic.updateDoudouPwd,
        obj,
        function (data, topicName, msg) {
          if (data.Code == 0) {
            ElMessage({
              message: "Thiết lập thành công",
              type: "success",
            });
            drawMoneyDialog.show = false;
            drawMoneyDialog.password = '';
            drawMoneyDialog.oldDwPassword = '';
            drawMoneyDialog.checkPass = '';
            getUserInfo();
          } else {
            ElMessage({
              message: data.ErrMsg,
              type: "erro",
            });
          }
        }
      );
    } else {
      return false;
    }
  });
};

const changePW = () => {
  changePasswordRef.value.validate((valid) => {
    if (valid) {
      let params = {
        OldPwd: changePwDialog.oldPassword,
        NewPwd: changePwDialog.newPassword,
        ConfirmPwd: changePwDialog.passwordCheckPass,
      };
      var that = this;
      mqant.request(topic.changePW, params, function (data, topicName, msg) {
        if (data.Code == 0) {
          ElMessage({
            message: "Mật khẩu thay đổi thành công",
            type: "success",
          });
          changePwDialog.show = false;
          changePwDialog.oldPassword = "";
          changePwDialog.newPassword = "";
          changePwDialog.passwordCheckPass = "";
          getUserInfo();
        } else {
          ElMessage({
            message: data.ErrMsg,
            type: "error",
          });
        }
      });
    } else {
      return false;
    }
  });
};
const addBankSumit = () => {
  addBankRef.value.validate((valid) => {
    if (valid) {
      let obj = {
        btName: addBankDialog.bankName,
        cardNum: addBankDialog.cardNum,
        accountName: userAll.userData.realName.toString(),
      };
      mqant.request(topic.bindBank, obj, function (data, topicName, msg) {
        if (data.Code == 0) {
          ElMessage({
            message: "Thiết lập thành công",
            type: "success",
          });
          addBankDialog.show = false;
          getUserInfo();
        } else {
          ElMessage({
            message: data.ErrMsg,
            type: "error",
          });
        }
      });
    } else {
      return false;
    }
  });
};

const rules = reactive({
  // account: [{ validator: nameRule, trigger: 'blur' }],
  password: [{ validator: passRule, trigger: "blur" }],
  cardNum: [{ validator: passRule, trigger: "blur" }],
  checkPass: [{ validator: checkPassRule, trigger: "blur" }],
  DoudouPwd: [{ validator: douDouRule, trigger: "blur" }],
  oldDwPassword: [{ validator: oldDwPassRule, trigger: "blur" }],
  oldPassword: [{ validator: oldPassRule, trigger: "blur" }],
  newPassword: [{ validator: passRule, trigger: "blur" }],
  passwordCheckPass: [{ validator: passwordRule, trigger: "blur" }],
});

function countDate(val) {
  if (!val) {
    return ''
  }
  return val.split('T')[0] + ' ' + val.split('T')[1].substr(0, 8)
}
</script>

<style lang="scss" scoped>
.personal {
  .el-button--primary {
    box-sizing: border-box;
    background: #4a69ff !important;
  }

  .el-button--primary:hover {
    background: #007aff !important;
    color: #fff !important;
  }

  .el-button--mini {
    padding: 7px 5px;
    min-width: 94px;
    height: 40px;
    margin-left: 24px;
    width: unset;
  }

  .information {
    padding: 0 20px;

    .cardInfo {
      margin-bottom: 0;
      overflow: hidden;
      width: 100%;
      border-radius: 8px;

      .cardTitle {
        display: flex;
        padding: 24px 0 21px 24px;
        height: 73px;
        line-height: 28px;
        background-color: #292e3b;
        font-weight: 500;
        color: #fff;
        align-items: baseline !important;
        justify-content: flex-start !important;

        .title {
          color: #fff;
          font-size: 20px;
          font-weight: 500;
        }
      }

      .cardBody {
        background-color: #232733 !important;
        color: #fff;
        border-radius: 8px;
        margin: 0 4px !important;
        padding: 16px;
        font-size: 12px;

        .is-disabled {
          border: 1px solid #9cb0f0 !important;
          color: #9cb0f0 !important;
        }
      }
    }

    .cardWrap {
      margin-bottom: 0;
      overflow: hidden;
      width: 100%;
      border-radius: 8px;

      :deep(.el-form) {
        .el-form-item__label {
          line-height: 40px;
          font-size: 14px;
          height: 40px;
          padding-right: 32px;
          color: #8491a5 !important;
        }

        .lh40 {
          height: 40px;
          line-height: 40px;
          display: inline-block;
          min-width: 336px;
          color: #fff;
        }
      }
    }

    .bankCard {
      .account-name {
        font-size: 20px;
        font-weight: 500;
      }

      .accountsNumText {
        color: #fff;
        font-size: 12px;
      }

      .cardList {
        display: flex;
        flex-wrap: wrap;
        border-radius: 8px;
        padding: 0;

        .cardList-active {
          border: 1px solid #4a69ff;
        }

        .addBankCard {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;
          cursor: pointer;
          background: #292e3b;
          box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);

          .icon-icon_add_bankcard {
            color: #fff;
          }

          span {
            color: #fff;
            margin-left: 5px;
            font-size: 12px;
          }
        }

        .cardItem {
          width: 218px;
          height: 132px;
          margin: 0 35px 24px 0;
          padding: 20px;
          position: relative;
          box-sizing: border-box;
          border-radius: 8px;

          .cardItems {
            position: absolute;
            z-index: 3;
            left: 0;
            right: 0;
            top: 0;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 20px;
            height: 100%;

            .cardItemInfo {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .cardInfoLeft {
                display: flex;
                justify-content: center;
                align-items: center;

                .cardName {
                  color: #fff;
                  font-size: 16px;
                  margin-left: 2px;
                }
              }
            }

            .cardNum {
              display: flex;
              align-items: center;
              justify-content: space-between;
              color: #8491a5;

              .cardLists {
                padding: 0;
                flex: 2;

                .cardSpan {
                  margin-right: 6px;
                  letter-spacing: 3px;
                }
              }

              .defaultBank {
                flex: 1;

                .defaultBankBtn {
                  padding: 0;
                  width: 60px;
                  border-radius: 2px;
                  font-size: 12px;
                  font-weight: 400;
                  box-sizing: border-box;
                  height: 30px;
                }
              }
            }
          }

          .bg_bank_card {
            position: absolute;
            z-index: 2;
            left: 0;
            top: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            padding: 16px;
            background-image: url("@/assets/pc/user/add_bank_bg.png");
            background-position: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
          }
        }
      }
    }

    .dialog {
      .closeBtn {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }

      :deep(.el-dialog) {
        width: 360px;
        background-color: #fbfbfb;
        color: #1b233d;
        border-radius: 8px;

        .el-dialog__header {
          padding: 15px 24px;
          height: 60px;
          border-bottom: 1px solid #d4d4d4;
          box-sizing: border-box;
          color: #1b233d;
          font-size: 18px;
        }

        .el-dialog__body {
          padding: 30px 24px;

          .el-select {
            width: 100%;
          }

          .dialogBody {
            button {
              font-size: 14px;
              text-align: center;
              width: 312px;
              height: 40px;
              border-radius: 4px;
            }

            // .el-form-item {
            //     margin-bottom: 16px;

            //     .el-form-item__label {
            //         font-size: 14px;
            //         color: #1b233d;
            //         line-height: 40px;
            //         height: 40px;
            //         padding-right: 32px;
            //     }
            // }
          }
        }

        // .el-input__inner {
        //     background: #fbfbfb;
        //     width: 312px;
        //     height: 40px;
        //     border: 1px solid #1b233d;
        //     border-radius: 4px;
        //     color: #000;
        //     font-size: 14px;
        // }
      }

      // :deep(.el-input) {
      //     width: 312px;

      //     .el-input__wrapper {
      //         padding: 0;
      //         width: 312px;
      //     }

      //     .el-input__inner {
      //         background: #fbfbfb;
      //         width: 312px;
      //         height: 40px;
      //         border: 1px solid #1b233d;
      //         border-radius: 4px;
      //         color: #000;
      //         font-size: 14px;
      //     }
      // }

      // :deep(.el-form) {
      //     margin-bottom: 30px;
      // }
    }
  }
}
</style>
<style>
.el-picker-panel__icon-btn {
  color: white !important;
}
</style>