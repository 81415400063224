<template>
  <div id="mobile-root">
    <div class="router">
      <router-view />
    </div>
    <Avatar v-if="avatarState" />
    <!-- 此处若是用v-if会导致重连时showFooter状态切换引起的Footer中HistoryRecord报错 -->
    <Footer v-show="showFooter" />
    <Notice @close="showNotice = false" v-if="notice.length && showNotice && isLogged"></Notice>
    <rewardOpen></rewardOpen>
    <!-- <div v-if="isIos">
      <div v-if="showDownload"
        style="z-index:999;width:100vw;text-align: center;position: fixed;bottom:8vh;left:0;height:323px;border-top-left-radius: 5px;border-top-right-radius: 5px;background-color: white;">
        <div style="position: relative;width: 100%;">
          <img src="@/assets/mobile/common/neirong_img.png" alt="" style="width:296px;margin: 0px auto;">
          <img @click='showDownload = false' src="@/assets/mobile/common/downloadClose.png" alt=""
            style="width:12px;position: absolute;right:8px;top:8px">

        </div>

      </div>
      <img v-else @click='showDownload = true' src="@/assets/mobile/common/xiazai_tishi.png" alt=""
        style="width:121px;position: fixed;bottom:10vh;z-index:999;left:calc(50vw - 60.5px);">

    </div> -->
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";

import { mqant_lottery } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import axios from "axios"; // 引入axios
import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus, busName } from "@/components/core/bus";
import { getApiLoginUrl } from "@/components/protocol/api";
import Footer from "@/views/mobile/components/Footer.vue"
import Notice from "@/views/mobile/components/Notice.vue"
import Avatar from "@/views/mobile/components/Avatar.vue";
import rewardOpen from "@/views/mobile/components/lottery/rewardOpen.vue";

import tools from '@/utils/tools'
import { useRoute } from "vue-router";
import log from "@/utils/logger";
import { Dialog } from "vant";
import action from '@/components/core/action'

const { isLogged, notice } = useGetters("tcp", ["isLogged", "notice"]);
const { isConnect } = useGetters("tcp", ["isConnect"]);
const { version } = useGetters("global", ["version"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);

let showNotice = ref(true);
let isIos = ref(false)
let showDownload = ref(true)

const { init } = useActions("global", ["init"]);


const { autoLogin } = useActions("user", ["autoLogin"]);
const { setLotteries, setPlays, setTimestamp, setLogged } = useMutations("tcp_lottery", [
  "setLotteries",
  "setPlays",
  "setTimestamp",
  "setLogged",
]);

const route = useRoute();


let showFooter = ref(true);
bus.off(bus.event.showFooter)
bus.on(bus.event.showFooter, (bool) => {
  showFooter.value = bool;
})
let avatarState = ref(false);
bus.on(bus.event.avatarState, (bool) => {
  avatarState.value = bool;
})
if (tools.platform() == 'h5_ios') {
  if (!window.navigator.standalone) {
    isIos.value = true
  }
}

bus.on(bus.event.noticeState, (index) => {
  showNotice.value = true
})

bus.off("lotteryConnect");
bus.on("lotteryConnect", () => {
  getLottryConfig();
});


bus.off("setLotteries");
bus.on("setLotteries", (val) => {
  setLotteries(val);
});



getVersion()
bus.on("getVersion", (val) => {
  getVersion()

});





function getVersion() {
  var showDia = false
  var versions
  var platform = tools.platform()
  var url
  if (version.value.indexOf('_') >= 0) {

    var currentVersion = version.value.split('_')[1]
    if (platform == 'ios') {
      if (parseInt(lobbyInfo.value.VersionGet.ios.VersionCode) > parseInt(currentVersion)) {
        url = lobbyInfo.value.VersionGet.ios.UrlPath
        showDia = true
        versions = lobbyInfo.value.VersionGet.ios.VersionCode
      }
    } else if (platform == 'android') {
      if (parseInt(lobbyInfo.value.VersionGet.android.VersionCode) > parseInt(currentVersion)) {
        url = lobbyInfo.value.VersionGet.android.UrlPath

        showDia = true
        versions = lobbyInfo.value.VersionGet.android.VersionCode

      }
    }

    if (showDia) {
      Dialog.confirm({
        title: 'LỜI NHẮC',
        showCancelButton: false,
        message: 'Cập nhật phiên bản mới' + versions,
        confirmButtonText: 'Cập nhật'
      })
        .then(() => {
          action.openBrowser(url, '')


        })
        .catch(() => {

        });
    }
  }



}



const getLottryConfig = () => {
  var that = this;
  mqant_lottery.request(topic.guestGetInfo, {}, function (data, topicName, msg) {
    log.info("getLottery-----", data);
    setLotteries(data.Data.Lotteries);
    setPlays(data.Data.Plays);
    bus.emit("getLottery", data.Data.Lotteries);
    var lotterys = data.Data.Lotteries;

    mqant_lottery.request(topic.syncTime, {}, function (data, topicName, msg) {

      setTimestamp(data.Data.Timestamp);

      var CountDownTime = data.Data.CountDownTime;

      for (var key in lotterys) {
        var arr = lotterys[key];
        for (var i = 0; i < arr.length; i++) {
          var obj = arr[i];

          if (CountDownTime[obj.LotteryCode]) {
            obj.Countdown = CountDownTime[obj.LotteryCode];
          }

          arr[i] = obj;
        }

        lotterys[key] = arr;
      }
      setLotteries(lotterys);

      commit("setLotteries", lotterys);
    });
  });
};
</script>

<style lang="scss">
#mobile-root {
  position: relative;
  width: 100%;
  height: 100%;

  .router {
    position: relative;
    width: 100%;
    height: 100%;
    // 顶部安全区
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }
}
</style>
