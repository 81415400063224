<template>
  <div class="notice">
    <el-dialog v-model="props.show" draggable :destroy-on-close="true" @close="close">
      <template #header>
        <span class="el-dialog__title">Thông báo mới nhất</span>
      </template>
      <div class="announcement">
        <div class="tab-wp">
          <div v-for="(item, index) in  lobbyInfo.LobbyNotice" class="tab"
            :class="curSelectIndex == index ? 'tab-active' : ''" @click="curSelectIndex = index">
            {{ item.Title }}
          </div>
        </div>
        <div class="announceContent">
          <div class="content">
            {{ lobbyInfo.LobbyNotice[curSelectIndex].Content }}
          </div>
          <div class="create-time">
            Ngày:
            {{ $tools.formatDate(lobbyInfo.LobbyNotice[curSelectIndex].CreateAt, "yyyy-MM-dd") }}
            {{ $tools.formatDate(lobbyInfo.LobbyNotice[curSelectIndex].CreateAt, "hh:mm") }}
          </div>
          <img :src="lobbyInfo.LobbyNotice[curSelectIndex].Url" alt="">
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { bus } from "@/components/core/bus";
import { ref, defineProps } from "vue";
import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let curSelectIndex = ref(0);
const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  }
})
const close = () => {
  bus.emit(bus.event.noticeState, false);
}
</script>

<style lang="scss" scoped>
.notice {
  :deep(.el-dialog) {
    width: 1000px;
    background: #292e3b;

    header {
      position: relative;
      text-align: left;
      box-shadow: inset 0 -1px 0 0 #363d4e;
      color: #fff;
      padding: 0 20px;
      width: 100%;
      height: 56px;
      line-height: 56px;
      font-size: 16px;
      box-sizing: border-box;

      .el-dialog__title {
        line-height: 24px;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
      }

      .el-dialog__headerbtn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        width: 32px;
        height: 32px;
        line-height: 32px;
        color: #fff;
        border-radius: 5px;
        text-align: center;
        font-size: 20px;

        position: absolute;
        padding: 0;
        background: 0 0;
        border: none;
        outline: none;
        cursor: pointer;

        .el-dialog__close {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #fff !important;
          font-size: 20px !important;
        }
      }
    }

    .el-dialog__body {
      background: #292e3b;
      color: #8491a5;
      padding: 0 !important;
      border-radius: 0 0 8px 8px;
      font-size: 14px;
      word-break: break-all;

      .announcement {
        background-color: #292e3b;
        display: flex;
        height: 500px;

        .tab-wp {
          border-right: 1px solid #363d4e;
          width: 240px;
          height: 100%;

          .tab-active {
            background-color: #1e222c !important;
            color: #fff !important;
          }

          .tab-active::before {
            background: #edc592 !important;
            height: 32px !important;
            margin-top: 6px;
            content: "";
            position: absolute;
            top: 7px;
            left: 0;
            width: 4px;
            border-radius: 2px;
          }

          .tab {
            color: #8491a5;
            border-color: #363d4e;

            position: relative;
            width: 100%;
            line-height: 55px;
            border-bottom: 1px solid #363d4e;
            text-align: center;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-weight: bold;
            cursor: pointer;
            height: 55px !important;
            display: block !important;
          }
        }

        .announceContent {
          width: 760px;
          height: 100%;
          padding: 10px 10px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .content {
            font-size: 14px;
            color: #ffffff;
          }

          .create-time {
            margin-top: 20px;
            font-size: 14px;
            color: #8491a5;
            white-space: nowrap;
          }

          img {
            flex: 1;
            margin: 20px 0 20px 0;
          }
        }
      }
    }
  }
}
</style>
