<template>
  <!-- 	<div  @click="cancel()" class="wrap" style="width: 100%;height: 100%;font-weight: bold;position: fixed;z-index: 999;background-color: rgba(0, 0, 0, .4);top: 0;left: 0;display: flex;flex-direction: column-reverse;">
 -->
  <div @click.stop="" style="
      background-color: #1b233d;

      position: fixed;
      bottom: 13.333333vw;
      left: 0;
      width: 100vw;
    ">
    <div class="title">
      <i class="icon iconfont icon-icon_totalPrize"></i>
      Tổng tiền thưởng: {{ formatNum(getAllWin()) }}
    </div>
    <div class="secondTitle">
      <div class="defMoney">
        <span>
          Mặc định <i>{{ formatNum(defaultNum) }} </i></span><i class="van-icon van-icon-edit" @click="showBetNum = true"
          style="font-size: 18px"><!----></i>
      </div>
      <div class="balance">
        <span>
          Số dư <i>{{ formatNum(wallet.VndBalance) }}</i></span>
      </div>
    </div>

    <div class="quickSelect">
      <div v-for="(item, index) in columArr" @touchstart="light(index)" @touchend="noLight(index)"
        @click="setDefault(item, index)" :key="item" :class="actIndex == index ? 'active' : ''" class="moneyItem"
        :style="index % 5 == 4 ? 'margin-right:0;' : ''">
        <div class="txtAniBox" v-if="anIndex == index">
          <i v-for="(item1, index1) in item.num + ''" :key="item1.title"
            :style="'animation-delay:' + index1 * 0.2 + 's;' + 'animation-name:' + 'toInput' + index"
            style="animation-iteration-count: 1;">{{ (item.num + "").substr(index1, 1) }}</i>
        </div>
        {{ item.title }}
      </div>
    </div>

    <div class="inputGroup" v-if="chooseArr.length">
      <div>
        <span>
          Số đơn cược <i>{{ getNumFromCode(chooseArr[0]) }}</i></span>
      </div>
      <div>
        <span>
          Cược đơn <i>1</i><i>X</i></span><input v-model="chooseArr[0].UnitBetAmount" type="tel" @input="
            chooseArr[0].UnitBetAmount = checkNum(chooseArr[0].UnitBetAmount)
            " />
      </div>
    </div>

    <div class="flexs_sb" style="margin-top: 3.2vw;padding: 0 4.266667vw;">
      <div class="cancelBtn" @click="allClean()">
        Hủy
      </div>

      <div class="sure" @click="doBet()" style="
        
        ">
        <div style="margin-top: 7px">Đặt Cược</div>
        <div>{{ formatNum(getAllBet()) }}</div>
      </div>
    </div>
    <setBetNum v-if="showBetNum" @close="showBetNum = false"></setBetNum>
    <!-- <betDetail v-if="showBetInfo" :info='selectInfo'></betDetail> -->
  </div>
  <!-- </div> -->
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import { http, topic } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";
import log from "@/utils/logger";
import { mqant_lottery } from "@/components/protocol/mqantlib";


import setBetNum from './setBetNum.vue'



// import betDetail from './betDetail.vue'

export default {
  data() {
    return {
      titleArr: ["Bảng cược", "Chưa thanh toán", "Đã thanh toán"],
      selectIndex: 0,
      selectLottry: null,
      selectColum: null,
      bigDanwei: this.formatNum(1000),
      selfSub: null,
      chooseArr: [],
      selectInfo: false,
      showBetInfo: false,
      canClick: true,
      showChoose: false,
      columArr: [
        {
          title: "+1K",
          num: 1000,
        },
        {
          title: "+5K",
          num: 5000,
        },
        {
          title: "+10K",
          num: 10000,
        },
        {
          title: "+50K",
          num: 50000,
        },
        {
          title: "+100K",
          num: 100000,
        },
        {
          title: "+500K",
          num: 500000,
        },
        {
          title: "+1M",
          num: 1000000,
        },
        {
          title: "+5M",
          num: 5000000,
        },
        {
          title: "+10M",
          num: 10000000,
        },
        {
          title: "+50M",
          num: 50000000,
        },
      ],
      columIndex: null,
      ykjData: [],
      wkjData: [],
      dialogTableVisible: false,
      defaultNum: localStorage.getItem("defaultNum")
        ? localStorage.getItem("defaultNum")
        : 1000,
      actIndex: -1,
      anIndex: -1,
      showBetNum: false,
    };
  },
  components: {
    setBetNum
  },
  computed: {
    ...mapGetters("tcp", ["isLogged", "wallet"]),
  },
  props: {
    originLottry: {},
    currentQi: {},
    selectSub: {},
    originColum: {},
  },
  created() {
    this.selfSub = this.selectSub;
    this.selectLottry = this.originLottry;
    var that = this;
    bus.on("changeLo", (val) => {
      that.selectLottry = val;
    });

    bus.on("defaultNum", (val) => {
      that.defaultNum = val
      that.chooseArr[0].UnitBetAmount = val
    })
    bus.on("playChange", (val) => {
      that.selfSub = val;
      that.chooseArr = [];
    });
    bus.on("selectColum", (val) => {
      that.selectColum = val;
      that.selfSub = that.selectColum.SubPlays[0];
    });
    bus.on("selectSub", (val) => {
      that.selfSub = val;
      for (var i = 0; i < that.chooseArr.length; i++) {
        var hasNum = 0;
        if (that.chooseArr[i].numArr) {
          for (var arr of that.chooseArr[i].numArr) {
            if (arr.length) {
              hasNum++;
            }
          }
          if (
            that.chooseArr[i].CodeLength > hasNum &&
            that.chooseArr[i].betType == "group"
          ) {
            that.chooseArr.splice(i, 1);
          }
        }
      }
    });

    bus.off("BET");
    bus.on("BET", (val) => {
      that.selfSub = that.selectSub;
      if (that.originColum) {
        that.selectColum = that.originColum;
      }
      val.UnitBetAmount = that.formatNum(that.selfSub.UnitBetAmount);
      val.UnitBetAmount = that.formatNum(that.defaultNum);

      if (val.betType == "group") {
        if (
          that.chooseArr.length &&
          that.chooseArr[that.chooseArr.length - 1]
        ) {
          var obj = that.chooseArr[that.chooseArr.length - 1];

          if (obj.SubPlayCode == val.SubPlayCode) {
            if (obj.PlayCode == "1D") {
              if (val.num.length == 0) {
                that.chooseArr.splice(that.chooseArr.length - 1, 1);
              } else {
                obj.numArr = val.num;
              }
            } else {
              obj.numArr[obj.CodeLength - val.type] = val.num;

              var hasNum = 0;
              for (var arr of obj.numArr) {
                for (var num of arr) {
                  hasNum++;
                }
              }
              if (hasNum == 0) {
                that.chooseArr.splice(that.chooseArr.length - 1, 1);
              } else {
                that.chooseArr[that.chooseArr.length - 1] = obj;
              }
            }
          } else {
            var weiArr = [];
            for (var i = 0; i < that.selfSub.CodeLength; i++) {
              var arr = [];

              weiArr.push(arr);
            }
            weiArr[that.selfSub.CodeLength - val.type] = val.num;

            val.OpenCodeCount = that.selfSub.OpenCodeCount;
            val.SubName = that.selfSub.SubName;
            val.CodeLength = that.selfSub.CodeLength;
            val.Name = that.selectColum.Name;
            val.Odds = that.selfSub.Odds;
            val.UnitBetCodeCount = that.selfSub.UnitBetCodeCount;
            val.numArr = weiArr;
            val.PlayCode = that.selectColum.PlayCode;

            that.chooseArr.push(val);
          }
        } else {
          val.OpenCodeCount = that.selfSub.OpenCodeCount;
          val.SubName = that.selfSub.SubName;
          val.CodeLength = that.selfSub.CodeLength;
          val.Name = that.selectColum.Name;
          val.PlayCode = that.selectColum.PlayCode;
          val.Odds = that.selfSub.Odds;
          val.UnitBetCodeCount = that.selfSub.UnitBetCodeCount;
          if (that.selectColum.PlayCode == "1D") {
            val.numArr = val.num;
          } else {
            var weiArr = [];
            for (var i = 0; i < that.selfSub.CodeLength; i++) {
              var arr = [];

              weiArr.push(arr);
            }
            weiArr[that.selfSub.CodeLength - val.type] = val.num;
            val.numArr = weiArr;
          }
          that.chooseArr.push(val);
        }
      } else if (val.betType == "random") {
        val.OpenCodeCount = that.selfSub.OpenCodeCount;
        val.SubName = that.selfSub.SubName;
        val.CodeLength = that.selfSub.CodeLength;
        val.Name = that.selectColum.Name;
        val.Odds = that.selfSub.Odds;
        val.UnitBetCodeCount = that.selfSub.UnitBetCodeCount;
        val.PlayCode = that.selectColum.PlayCode;

        that.chooseArr.push(val);
      } else if (val.betType == "fast") {
        if (
          that.chooseArr.length &&
          that.chooseArr[that.chooseArr.length - 1]
        ) {
          var obj = that.chooseArr[that.chooseArr.length - 1];

          if (obj.SubPlayCode == val.SubPlayCode) {
            obj.numArr = val.numArr;

            if (obj.numArr.length == 0) {
              that.chooseArr.splice(that.chooseArr.length - 1, 1);
            } else {
              that.chooseArr[that.chooseArr.length - 1] = obj;
            }
          } else {
            val.OpenCodeCount = that.selfSub.OpenCodeCount;
            val.SubName = that.selfSub.SubName;
            val.CodeLength = that.selfSub.CodeLength;
            val.Name = that.selectColum.Name;
            val.Odds = that.selfSub.Odds;
            val.UnitBetCodeCount = that.selfSub.UnitBetCodeCount;
            val.PlayCode = that.selectColum.PlayCode;

            that.chooseArr.push(val);
          }
        } else {
          val.OpenCodeCount = that.selfSub.OpenCodeCount;
          val.SubName = that.selfSub.SubName;
          val.CodeLength = that.selfSub.CodeLength;
          val.Name = that.selectColum.Name;
          val.UnitBetCodeCount = that.selfSub.UnitBetCodeCount;
          val.PlayCode = that.selectColum.PlayCode;

          val.Odds = that.selfSub.Odds;
          that.chooseArr.push(val);
        }
      } else if (val.betType == "Tap") {
        if (val.SubPlayCode.indexOf("TS") >= 0) {
          var contain = false;
          var containIndex = -1;
          for (var i = 0; i < that.chooseArr.length; i++) {
            if (
              that.chooseArr[i].num == val.num &&
              that.chooseArr[i].SubPlayCode == val.SubPlayCode
            ) {
              contail = true;
              containIndex = i;
              break;
            }
          }
          if (contail) {
            that.chooseArr.splice(containIndex, 1);
          } else {
            val.Name = that.selectColum.Name;

            that.chooseArr.push(val);
          }
          if (that.getAllBet() > 0) {
            bus.emit("showBet");
          }
          if (that.chooseArr.length == 0) {
            bus.emit("allClean");
          }
          return;
        }

        if (that.chooseArr.length && val.SubPlayCode != "FUNNY_DX_DS") {
          var contain = false;
          var containIndex = -1;
          var sameObj = null;
          for (var i = 0; i < that.chooseArr.length; i++) {
            var obj = that.chooseArr[i];
            if (obj.SubPlayCode == val.SubPlayCode) {
              contail = true;
              sameObj = obj;
              containIndex = i;
              break;
            }
            // else {
            // 	val.Name = that.selectColum.Name
            // 	that.chooseArr.push(val)
            // }
          }

          if (contail) {
            var numArr;
            sameObj.num += "";
            if (sameObj.num.indexOf(",") >= 0) {
              numArr = sameObj.num.split(",");
            } else {
              numArr = [sameObj.num];
            }
            if (numArr.indexOf(val.num + "") >= 0) {
              for (var k = 0; k < numArr.length; k++) {
                if (numArr[k] == val.num) {
                  numArr.splice(k, 1);
                  break;
                }
              }
            } else {
              numArr.push(val.num);
            }

            if (numArr.length == 0) {
              that.chooseArr.splice(i, 1);
            } else {
              that.chooseArr[i].num = numArr.join(",");
            }
          } else {
            val.Name = that.selectColum.Name;

            that.chooseArr.push(val);
          }
        } else {
          var contain = false;
          var containIndex = -1;
          for (var i = 0; i < that.chooseArr.length; i++) {
            if (
              that.chooseArr[i].num == val.num &&
              that.chooseArr[i].SubPlayCode == val.SubPlayCode
            ) {
              contail = true;
              containIndex = i;
              break;
            }
          }
          if (contail) {
            that.chooseArr.splice(containIndex, 1);
          } else {
            val.Name = that.selectColum.Name;

            that.chooseArr.push(val);
          }
        }
      } else if (val.betType == "VIP") {
        if (that.chooseArr.length) {
          var contail = false;
          for (var i = 0; i < that.chooseArr.length; i++) {
            var obj = that.chooseArr[i];
            if (obj.SubPlayCode == val.SubPlayCode) {
              if (obj.num == val.num) {
                contail = true;
              }
            }
          }
          if (contail) {
            for (var i = 0; i < that.chooseArr.length; i++) {
              var obj = that.chooseArr[i];
              if (obj.SubPlayCode == val.SubPlayCode) {
                that.chooseArr.splice(i, 1);
                break;
              }
            }
          } else {
            that.chooseArr.push(val);
          }
        } else {
          that.chooseArr.push(val);
        }
      }

      if (that.getAllBet() > 0) {
        bus.emit("showBet");
      }
      if (that.chooseArr.length == 0) {
        bus.emit("allClean");
      }
    });
  },
  methods: {

    dealBet(val) { },
    cancel() {
      this.$emit("close");
    },
    formatNum(num) {
      var numeral = require("numeral");
      return numeral(num).format("0,000");
    },
    showInfo(item) {
      // this.selectInfo = item
      this.showBetInfo = true;
    },
    getName(item) {
      for (var key in this.lotteryConfig.Lotteries) {
        for (var obj of this.lotteryConfig.Lotteries[key]) {
          if (obj.LotteryCode == item.LotteryCode) {
            return obj.LotteryName;
          }
        }
      }
    },
    topClick(index) {
      this.selectIndex = index;
      if (index == 1) {
        this.getData(1);
      } else if (index == 2) {
        this.getData(9);
      }
    },
    getData(index) {
      var obj = {
        offset: 0,
        limit: 20,
        status: index - 1,
      };
      var that = this;
      mqant.request(
        topic.getBetRecordList,
        obj,
        function (data, topicName, msg) {
          if (index == 1) {
            that.wkjData = data.Data.List;
          } else {
            that.ykjData = data.Data.List;
          }
        }
      );
    },
    showColum() {
      //this.showChoose = true
    },
    light(index) {
      this.actIndex = index;
    },
    noLight(index) {
      this.actIndex = -1;
    },
    setDefault(val, index) {
      //console.log(val)
      var UnitBetAmount = parseInt(
        this.chooseArr[0].UnitBetAmount.split(",").join("")
      );
      UnitBetAmount += val.num;
      this.chooseArr[0].UnitBetAmount = this.formatNum(UnitBetAmount);

      var that = this
      this.anIndex = index;
      var second = (val.num + '').length * 0.2 + 0.1
      setTimeout(() => {
        that.anIndex = -1
      }, second * 1000);
      //this.bigDanwei = val.num
      //sessionStorage.setItem('defalutM', val.num)
    },
    checkNum(data) {
      if (data) {
        return (data = this.formatNum(Number(data.replace(/[^\d.%\\]/g, ""))));
      }
    },
    getCanNum() {
      var canNum = 0;
      for (var i = 0; i < this.chooseArr.length; i++) {
        var data = this.chooseArr[i];
        if (this.getWinNum(data)) {
          canNum++;
        }
      }
      return canNum;
    },

    doBet() {
      // this.$message({
      // 	message: 'asdasdh',
      // 	type: 'error'
      // });
      if (this.getCanNum() == 0) {
        return;
      }

      // if (!this.canClick) {
      // 	return
      // }
      this.canClick = false;
      var that = this;

      for (var i = 0; i < this.chooseArr.length; i++) {
        var data = this.chooseArr[i];
        if (!this.getWinNum(data)) {
          continue;
        }

        if (parseInt(data.UnitBetAmount.split(",").join("")) < 1000) {
          this.$message({
            message: "Không được cược thấp hơn ₫1,000",
            type: "error",
          });
          return;
        }
        var obj = {
          TotalAmount:
            parseInt(data.UnitBetAmount.split(",").join("")) *
            this.getNumFromCode(data),
          LotteryCode: this.selectLottry.LotteryCode,
          SubPlayCode: data.SubPlayCode,
          Code: this.getEndCodeNum(data) + "",
          UnitBetAmount: parseInt(data.UnitBetAmount.split(",").join("")),
          CProfit: this.getWinNum(data),
        };

        if (obj.Code == "Tài") {
          obj.Code = "big";
          if (obj.SubPlayCode.indexOf("FUNNY") >= 0) {
            obj.SubPlayCode = "FUNNY_DX_DS";
          }
        } else if (obj.Code == "Xỉu") {
          obj.Code = "small";
          if (obj.SubPlayCode.indexOf("FUNNY") >= 0) {
            obj.SubPlayCode = "FUNNY_DX_DS";
          }
        } else if (obj.Code == "Lẻ") {
          obj.Code = "single";
          if (obj.SubPlayCode.indexOf("FUNNY") >= 0) {
            obj.SubPlayCode = "FUNNY_DX_DS";
          }
        } else if (obj.Code == "Chẵn") {
          obj.Code = "pair";
          if (obj.SubPlayCode.indexOf("FUNNY") >= 0) {
            obj.SubPlayCode = "FUNNY_DX_DS";
          }
        } else if (obj.Code == "Trên") {
          obj.Code = "up";
        } else if (obj.Code == "Hoà") {
          obj.Code = "draw";
        } else if (obj.Code == "Dưới") {
          obj.Code = "down";
        } else if (obj.Code == "Kim") {
          obj.Code = "jin";
        } else if (obj.Code == "Mộc") {
          obj.Code = "mu";
        } else if (obj.Code == "Thủy") {
          obj.Code = "shui";
        } else if (obj.Code == "Hỏa") {
          obj.Code = "huo";
        } else if (obj.Code == "Thổ") {
          obj.Code = "tu";
        } else if (obj.Code == "Tài Lẻ") {
          obj.Code = "bigSingle";
        } else if (obj.Code == "Xỉu Lẻ") {
          obj.Code = "smallSingle";
        } else if (obj.Code == "Tài Chẵn") {
          obj.Code = "bigPair";
        } else if (obj.Code == "Xỉu Chẵn") {
          obj.Code = "smallPair";
        }

        // this.getEndCodeNum(data)
        // return

        var count = 0;
        var index = i;
        obj.Code = obj.Code.replace(" ", "");
        var tmpNum = this.getCanNum();
        mqant_lottery.request(topic.addBet2, obj, function (data, topicName, msg) {

          if (data.Code == 0) {

            that.$tools.success(that.$t('下注成功'));



            that.canClick = true;

            bus.emit("allClean");
            that.chooseArr = [];

            //that.chooseArr.splice(index,1)
          } else {
            that.$tools.toast(data.ErrMsg);

            that.canClick = true;
          }
        });
      }
    },
    getEndCodeNum(item) {
      if (item.betType == "random" || item.betType == "fast") {
        return item.numArr.join("-");
      } else if (item.betType == "group") {
        if (item.SubPlayCode.indexOf("1D") >= 0) {
          return item.num.join("-");
        }

        var str = this.permutate(item.numArr).join("-");
        return str;
      } else if (item.betType == "Tap") {
        return item.num.split(",").join("-");
      } else {
        return item.num;
      }
    },
    permutate(arr) {
      let res = arr[0].slice();

      for (let i = 1; i < arr.length; i++) {
        const pre = res.slice();
        res = [];
        pre.forEach((item) => {
          arr[i].forEach((curr) => {
            res.push(item + "" + curr);
          });
        });
      }
      return res;
    },

    doCombination(arr) {
      var count = arr.length - 1; //数组长度(从0开始)
      var tmp = [];
      var totalArr = []; // 总数组

      return doCombinationCallback(arr, 0); //从第一个开始
      //js 没有静态数据，为了避免和外部数据混淆，需要使用闭包的形式
      function doCombinationCallback(arr, curr_index) {
        for (val of arr[curr_index]) {
          tmp[curr_index] = val; //以curr_index为索引，加入数组
          //当前循环下标小于数组总长度，则需要继续调用方法
          if (curr_index < count) {
            doCombinationCallback(arr, curr_index + 1); //继续调用
          } else {
            totalArr.push(tmp); //(直接给push进去，push进去的不是值，而是值的地址)
          }

          //js  对象都是 地址引用(引用关系)，每次都需要重新初始化，否则 totalArr的数据都会是最后一次的 tmp 数据；
          oldTmp = tmp;
          tmp = [];
          for (index of oldTmp) {
            tmp.push(index);
          }
        }
        return totalArr;
      }
    },

    getAllBet() {
      var num = 0;
      for (var obj of this.chooseArr) {
        if (this.getWinNum(obj)) {
          num += parseInt(
            this.getNumFromCode(obj) *
            parseInt(obj.UnitBetAmount.split(",").join(""))
          );
        }
      }
      return num;
    },
    getAllWin() {
      var num = 0;
      for (var obj of this.chooseArr) {
        if (this.getWinNum(obj)) {
          num +=
            (parseInt(obj.Odds) *
              parseInt(obj.UnitBetAmount.split(",").join(""))) /
            1000;
        }
      }
      return num;
    },
    allClean() {
      this.chooseArr = [];
      bus.emit("allClean");
    },
    changeBet(val) {
      for (var obj of this.chooseArr) {
        obj.UnitBetAmount = this.bigDanwei;
      }
    },
    remove(index) {
      this.chooseArr.splice(index, 1);
    },
    getWinNum(item) {
      if (item.betType == "Tap" || item.betType == "VIP") {
        var UnitBetAmount = item.UnitBetAmount;
        if (!UnitBetAmount) {
          UnitBetAmount = "0";
        }
        return (
          (parseInt(UnitBetAmount.split(",").join("")) * item.Odds) / 1000 -
          parseInt(UnitBetAmount.split(",").join(""))
        );
      }
      var hasZero = false;

      for (var arr of item.numArr) {
        if (arr.length == 0) {
          hasZero = true;
          break;
        }
      }
      if (hasZero && item.SubPlayCode.indexOf("1D") < 0) {
        return "";
      } else {
        if (item.betType == "random" || item.betType == "fast") {
          if (item.PlayCode == "3DTW" || item.PlayCode == "4D" || "FUNNY") {
            var UnitBetAmount = item.UnitBetAmount;
            if (!UnitBetAmount) {
              UnitBetAmount = "0";
            }
            return (
              (item.Odds / 1000) * parseInt(UnitBetAmount.split(",").join("")) -
              parseInt(UnitBetAmount.split(",").join(""))
            );
          }
          var num =
            item.numArr.length *
            item.OpenCodeCount *
            (item.Odds / 1000) *
            parseInt(item.UnitBetAmount.split(",").join("")) -
            item.numArr.length *
            item.OpenCodeCount *
            parseInt(item.UnitBetAmount.split(",").join(""));
          if (item.SubPlayCode.indexOf("ZH") >= 0) {
            num = num / parseInt(item.UnitBetAmount.split(",").join(""));
          }

          if (item.SubPlayCode == "2DTW_L7") {
            var UnitBetAmount = item.UnitBetAmount;
            if (!UnitBetAmount) {
              UnitBetAmount = "0";
            }
            num =
              item.numArr.length *
              (item.Odds / 1000) *
              parseInt(UnitBetAmount.split(",").join("")) -
              item.numArr.length * parseInt(UnitBetAmount.split(",").join(""));
          }

          return parseInt(num);
        } else {
          if (
            item.PlayCode == "1D" ||
            item.PlayCode == "3DTW" ||
            item.PlayCode == "4D"
          ) {
            var UnitBetAmount = item.UnitBetAmount;
            if (!UnitBetAmount) {
              UnitBetAmount = "0";
            }
            return (
              (item.Odds / 1000) * parseInt(UnitBetAmount.split(",").join("")) -
              parseInt(UnitBetAmount.split(",").join(""))
            );
          }

          var total = 1;
          for (var arr of item.numArr) {
            total = total; // * arr.length
          }
          if (item.SubPlayCode == "2DTW_L7") {
            var UnitBetAmount = item.UnitBetAmount;
            if (!UnitBetAmount) {
              UnitBetAmount = "0";
            }
            return (
              total * item.Odds -
              parseInt(UnitBetAmount.split(",").join("")) * item.OpenCodeCount
            );
          }

          var UnitBetAmount = item.UnitBetAmount;
          if (!UnitBetAmount) {
            UnitBetAmount = "0";
          }
          return (
            (total * item.Odds * parseInt(UnitBetAmount.split(",").join(""))) /
            1000 -
            parseInt(UnitBetAmount.split(",").join("")) *
            total *
            item.OpenCodeCount
          );
        }
      }
    },
    getCodeNum(item) {
      if (item.betType == "random" || item.betType == "fast") {
        return item.numArr.join(",");
      } else if (item.betType == "group") {
        if (item.SubPlayCode.indexOf("1D") >= 0) {
          return item.numArr.join(",");
        }
        var str = "";
        for (var i = 0; i < item.numArr.length; i++) {
          var arr = item.numArr[i];
          str += arr.join(",");
          if (arr.length) {
            str += "|";
          }
        }
        str = str.substr(0, str.length - 1);
        return str;
      } else {
        return item.num;
      }
    },
    getNumFromCode(item) {
      if (!item) {
        return;
      }
      if (item.betType == "Tap" || item.betType == "VIP") {
        if (item.betType == "Tap") {
          item.num += "";
          return item.num.split(",").length;
        }
        return 1;
      }
      var hasZero = false;
      for (var arr of item.numArr) {
        if (arr.length == 0) {
          hasZero = true;
          break;
        }
      }
      if (hasZero && item.SubPlayCode.indexOf("1D") < 0) {
        return 1;
      } else {
        if (item.betType == "random" || item.betType == "fast") {
          var num = item.numArr.length * item.OpenCodeCount;

          if (item.SubPlayCode.indexOf("ZH") >= 0) {
            num = num / item.UnitBetCodeCount;
          }

          return num;
        } else {
          if (item.SubPlayCode.indexOf("1D") >= 0) {
            return item.num.length;
          }
          var total = 1;
          for (var arr of item.numArr) {
            total = total * arr.length;
          }
          total = total * item.OpenCodeCount;
          return total;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
input:active {
  background-color: transparent;
}

input {
  background-color: white;
  width: 84px;
  height: 30px;
  text-align: center;
  color: black;
}

input {
  outline: none;
  -webkit-tap-highlight-color: black;
  /*-webkit-appearance: none;*/
}

.nol {
  background-color: #28479f;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e9cfa4;
  width: 100%;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 0 4.266667vw;
  height: 9.6vw;
  position: relative;
  font-size: 3.2vw;
  font-weight: 500;

  i {
    color: #e9cfa4;
    font-size: 4.266667vw;
    margin-right: 1.6vw;
    width: 4.266667vw;
    margin-left: 1.066667vw;
  }
}

.secondTitle:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(180deg, transparent 40%, #90a2dc);
}

.secondTitle {
  padding: 0 4.266667vw;
  height: 9.6vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-size: 3.2vw;
  color: #90a2dc;
  font-weight: 500;

  i {
    color: #21e06b;
    margin-left: 1.066667vw;
  }

  .van-icon-edit {
    color: #e9cfa4;
    margin-left: 1.066667vw;
  }
}

.quickSelect {
  height: 29.866667vw;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 4.266667vw 3.733333vw;

  .moneyItem {
    width: 14.933333vw;
    margin-right: 4vw;
    margin-top: 2.666667vw;
    height: 8.533333vw;
    line-height: 8.533333vw;
    background: #293356;
    box-shadow: 0 0.8vw 0.533333vw 0 rgba(0, 0, 0, 0.2);
    border-radius: 1.066667vw;
    font-size: 4vw;
    color: #fff;
    text-align: center;
    font-weight: 400;
  }

  .active {
    background-color: #90a2dc;
  }
}

.quickSelect:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(180deg, transparent 40%, #90a2dc);
}

.txtAniBox {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  i {
    position: absolute;
    opacity: 0;
    left: 0;
    bottom: 0;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-timing-function: cubic-bezier(0.95, 0.03, 1, 1.01);
    animation-timing-function: cubic-bezier(0.95, 0.03, 1, 1.01);
    //animation-name: toInput4;
  }


}

.inputGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3.2vw;
  height: 8.533333vw;
  padding: 0 4.266667vw;

  i {
    font-size: 4vw;
    color: #e9cfa4;
    margin-left: 0.533333vw;
    font-weight: 500;
  }

  input {
    width: 25.333333vw;
    height: 8.533333vw;
    text-align: center;
    border: 0;
    margin-left: 1.066667vw;
    outline: 0;
    background: #fff;
    border-radius: 1.066667vw;
    font-size: 4vw;
    color: #1b233d;
    font-weight: 500;
  }

  span {
    font-size: 3.733333vw;
    color: #fff;
    font-weight: 500;
  }
}

.cancelBtn {
  width: 28.533333vw;
  height: 40px;
  border: 1px solid #90a2dc;
  border-radius: 2.133333vw;
  font-size: 3.733333vw;
  color: #90a2dc;
  text-align: center;
  font-weight: 400;
  line-height: 38px;
  background-color: #1b233d;
}

.sure {
  color: white;
  background: linear-gradient(90deg, rgb(113, 70, 255) 0%, rgb(74, 105, 255) 100%);
  border: 0px;
  width: 59.466667vw;
  height: 40px;
  border-radius: 2.133333vw;
  font-size: 3.733333vw;
  text-align: center;

  font-weight: 600;

}
</style>
<style >
@keyframes toInput0 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 68vw;
    bottom: -30.133333vw;
    opacity: 1;
  }
}


@keyframes toInput1 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 65.333333vw;
    bottom: -30.133333vw;
    opacity: 1;
  }
}

@keyframes toInput2 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 44vw;
    bottom: -30.133333vw;
    opacity: 1;
  }
}

@keyframes toInput3 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 22.666667vw;
    bottom: -30.133333vw;
    opacity: 1;
  }
}

@keyframes toInput4 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 2.666667vw;
    bottom: -30.133333vw;
    opacity: 1;
  }
}

@keyframes toInput5 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 68vw;
    bottom: -14.133333vw;
    opacity: 1;
  }
}

@keyframes toInput6 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 60vw;
    bottom: -14.133333vw;
    opacity: 1;
  }
}

@keyframes toInput7 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 44vw;
    bottom: -14.133333vw;
    opacity: 1;
  }
}

@keyframes toInput8 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 22.666667vw;
    bottom: -14.133333vw;
    opacity: 1;
  }
}

@keyframes toInput9 {
  0% {
    left: 5.333333vw;
    bottom: -8.533333vw;
    opacity: 0;
  }

  0.1% {
    opacity: 1;
  }

  100% {
    position: absolute;
    left: 2.666667vw;
    bottom: -14.133333vw;
    opacity: 1;
  }
}
</style>