import { img } from "@/utils/img"

const imgs = {
  Slot: {
    apiAeGame: {
      img: img("factory_apiAeGame.png"),
      imgAct: img("factory_apiAeGame_act.png"),
    },
    apiSpadeGame: {
      img: img("factory_apiSpadeGame.png"),
      imgAct: img("factory_apiSpadeGame_act.png"),
    },
    apiDs: {
      img: img("factory_apiDs.png"),
      imgAct: img("factory_apiDs_act.png"),
    },
    apiJdb: {
      img: img("factory_jdb.png"),
      imgAct: img("factory_jdb_act.png"),
    },
    apiJili: {
      img: img("factory_apiJili.png"),
      imgAct: img("factory_apiJili_act.png"),
    },
    apiPg: {
      img: img("factory_apiPg.png"),
      imgAct: img("factory_apiPg_act.png"),
    },
    apiPp: {
      img: img("factory_apiPp.png"),
      imgAct: img("factory_apiPp_act.png"),
    },
    apiRich88: {
      img: img("factory_apiRich88.png"),
      imgAct: img("factory_apiRich88_act.png"),
    },
  },
  Card: {
    apiSpadeGame: {
      img: img("factory_apiSpadeGame.png"),
      imgAct: img("factory_apiSpadeGame_act.png"),
    },
    apiDs: {
      img: img("factory_apiDs.png"),
      imgAct: img("factory_apiDs_act.png"),
    },
    apiJdb: {
      img: img("factory_jdb.png"),
      imgAct: img("factory_jdb_act.png"),
    },
    apiJili: {
      img: img("factory_apiJili.png"),
      imgAct: img("factory_apiJili_act.png"),
    },
    apiPg: {
      img: img("factory_apiPg.png"),
      imgAct: img("factory_apiPg_act.png"),
    },
    apiPp: {
      img: img("factory_apiPp.png"),
      imgAct: img("factory_apiPp_act.png"),
    },
    apiRich88: {
      img: img("factory_apiRich88.png"),
      imgAct: img("factory_apiRich88_act.png"),
    },
  },
  Fish: {
    apiAwc: {
      img: img("factory_apiAwc.png"),
      imgAct: img("factory_apiAwc_act.png"),
    },
    apiSpadeGame: {
      img: img("factory_apiSpadeGame.png"),
      imgAct: img("factory_apiSpadeGame_act.png"),
    },
    apiDs: {
      img: img("factory_apiDs.png"),
      imgAct: img("factory_apiDs_act.png"),
    },
    apiJdb: {
      img: img("factory_jdb.png"),
      imgAct: img("factory_jdb_act.png"),
    },
    apiJili: {
      img: img("factory_apiJili.png"),
      imgAct: img("factory_apiJili_act.png"),
    },
    apiPg: {
      img: img("factory_apiPg.png"),
      imgAct: img("factory_apiPg_act.png"),
    },
    apiPp: {
      img: img("factory_apiPp.png"),
      imgAct: img("factory_apiPp_act.png"),
    },
    apiRich88: {
      img: img("factory_apiRich88.png"),
      imgAct: img("factory_apiRich88_act.png"),
    },
  },
  Lottery: {
    apiVsl:{
      img: img("lottery_bg.png"),
      imgAct: img("lottery_icon.png"),
    }
  }
}

export default imgs