<template>
	<div  @click="cancel()" class="wrap" style="width: 100%;height: 100%;font-weight: bold;position: fixed;z-index: 999;background-color: rgba(0, 0, 0, .4);top: 0;left: 0;">
		<div @click.stop="" style="position: relative;width: 400px;margin: 0 auto;background-color: white;height: 800px;margin-top: calc(50vh - 400px);border-radius: 10px;">
			<div style="border-bottom:1px solid rgb(240,240,240);background-color: white;height: 60px;position: relative;line-height: 60px;font-size: 18px;font-weight: bold;color: #1b233d;text-align: center;">Phạm vi số cược
				
				<i class="x-btn iconfont icon-icon_close_white" @click="cancel()"></i>
				
			</div>
			<div style="height: 570px;padding-top: 30px;">
				<div v-for="(item,index) in datas" :key="item" @click="selectIndex=index" class="flexs_r_c canClick" style="width:300px;height: 40px;margin-left: 50px;border-bottom: 1px solid #EEEEEE;height: 60px;position: relative;">
					<div style="width: 120px;height: 40px;line-height: 40px;text-align: center;color:#1b2c5e ;border-radius: 5px;border: 1px solid #293356;">
						{{item}}
					</div>
					<div style="width: 32px;height: 32px;background-color: #1b2c5e;border-radius: 5px;color: white;margin-left: 30px;text-align: center;line-height: 32px;">
						{{countNumData(index)}}
					</div>

					<i  class="iconfont icon-icon_succeed" v-if="selectIndex==index" style="color: #5cae50;margin-left:50px;"></i>
					<!-- <img src="@/assets/pc/lottery/check.png" alt="" v-if="selectIndex==index" style="width: 32px;margin-left: 60px;position: absolute;right: 0;top: 16.5px;"> -->
				</div>
				<div style="width:300px;margin-left: 50px;margin-top: 20px;" class="flexs_sb">
					<div class="canClick" @click="cancel()" style="width: 140px;height: 40px;text-align: center;line-height: 40px;color: #1b2c5e;border-radius: 5px;border: 1px solid #1b2c5e;">
						Huỷ
					</div>
					<div class="canClick" @click="confirm()" style="width: 140px;height: 40px;text-align: center;line-height: 40px;color: white;border-radius: 5px;    background-image: linear-gradient(90deg,#7146ff,#4a69ff);">
						Xác nhận
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { bus, busName } from "@/components/core/bus";
	
	export default {
		data(){
			return {
				
				datas:['000-099','100-199','200-299','300-399','400-499','500-599','600-699','700-799','800-899','900-999'],
				selectIndex:0
			}
		},
		props:{
			numData:{//1.1键全选 2.全部
				
			},
			title:{
				
			},
			defauleIndex:{
				
			}
		
		},
		created() {
			//console.log('zzzcz',this.defauleIndex)
			this.selectIndex = this.defauleIndex
		},
		methods:{
			countNumData(index){
				var count = 0
				for(var num of this.numData){
					if(num>=this.datas[index].split('-')[0] &&num<=this.datas[index].split('-')[1]){
						count ++
					}
				}
				
				return count
			},
			confirm(){
				this.$emit('sure',this.selectIndex)
				
			},
			cancel(){
				this.$emit('close')
				
			}
			
		}
	}
</script>

<style scoped lang="scss">
	.iconfont{
		font-size: 16px;
		font-style: normal;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.x-btn{
		position: absolute;
    right: 24px;
    cursor: pointer;
	
	color:#1b233d;
	font-family: iconfont!important;
    
	}
</style>