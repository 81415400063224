<template>
	<!-- <red-header :title="$t('会员')" @rightClick="toHistory()" :rightText="$t('历史记录')" :showBack="true" /> -->

	<div>
		<topHeader title="Phúc lợi Vip" :showRight="false" :showBack="true"></topHeader>

		<div style="position: relative;overflow: hidden;height:90%;" class="wrap" v-if="configData">

			<div style="text-align:center;margin-top: 3vw;">
				<img :src="$img('vip/' + 'VIP' + configData.CurVipLevel + '.png')" alt="" style="width: 70vw;margin:0 auto;">

			</div>
			<div @click="toVipInfo()"
				style="width: 90%;margin-left: 5%;height: 14vw;border-radius: 4px;background-color:rgb(53,65,109) ;text-align: center;margin-top: 5vw;"
				class="">

				<img src="@/assets/mobile/vip/userVip.png" alt="" style="width: 8vw;margin: 0 auto;margin-top: -3vw;">
				<div class="whiteWord phoneMiddle" style="margin-top: 1.5vw;color:white ;">{{ $t("贵宾介绍") }}</div>

			</div>

			<div class="level">
				<div class="flexs_sb">
					<div style="margin-top: 2vw;margin-left: 2vw;color:white;" class="middleSize whiteWord">
						{{ $t("升级") + " VIP " + (configData.CurVipLevel + 1) + $t("条件") }}</div>

				</div>
				<div style="height: .5px;width: 100%;background-color:rgb(75,88,135) ;margin-top: 2vw;"></div>

				<div class="smallSize" style="color: rgb(138,159,220);margin-top: 5vw;margin-left: 2vw;">{{ $t("当前积分") }}
				</div>
				<div class="flexs_sb" style="margin-top: 3px;padding: 0 2vw;">
					<div class="smallSize" style="color: #f2ca5d;">{{ $tools.formatNum2k(configData.CurVipBet) }}/ <span
							style="color:white;">{{ $tools.formatNum2k(configData.GoalVipBet) }}</span></div>
					<div class="smallSize" style="color: rgb(138,159,220);">{{ $t("还需要") }} <span
							style="color:white;">{{ $tools.formatNum2k((configData.GoalVipBet -
								configData.CurVipBet) > 0 ? (configData.GoalVipBet - configData.CurVipBet) : 0) }}</span>
					</div>

				</div>
				<div
					style="margin-left: 2vw;width: 96%;height: 5px;background-color: rgb(30,38,70);border-radius: 2.5px;margin-top: 3px;overflow: hidden;">
					<div style="height: 5px;background-color: rgb(235,183,118);" :style="progressWidth"></div>
				</div>

				<div class="smallSize" style="color: #9c9c9c;margin-top: 8px;margin-left: 2vw;">{{ $t("升级押金") }}</div>

				<div class="flexs_sb" style="margin-top: 3px;padding: 0 2vw;">
					<div class="smallSize" style="color: #83bd26;">{{ $tools.formatNum2k(configData.CurVipCharge) }}/ <span
							style="color:white;">{{ $tools.formatNum2k(configData.GoalVipCharge) }}</span></div>
					<div class="smallSize" style="color: rgb(138,159,220);">{{ $t("还需要") }} <span
							style="color:white;">{{ $tools.formatNum2k((configData.GoalVipCharge -
								configData.CurVipCharge) > 0 ? (configData.GoalVipCharge - configData.CurVipCharge) : 0) }}</span>
					</div>

				</div>
				<div
					style="margin-left: 2vw;width: 96%;height: 5px;background-color: rgb(30,38,70);border-radius: 2.5px;margin-top: 3px;overflow: hidden;">
					<div style="height: 5px;background-color: rgb(235,183,118);" :style="progressWidth2"></div>
				</div>
			</div>

			<div style="color: white;vertical-align: middle;width: 90vw;margin-left: 5vw;margin-top: 6vw;" class="bigSize">
				{{ $t("特权奖励") }}</div>
			<div class="flexs_sb" style="width: 90vw;margin-top:22px;margin-left: 5vw;margin-bottom: 4vw;">


				<div
					style="width: 43vw;height: 120px;text-align: center;background-color:rgb(53,65,109);border-radius:5px;">
					<img src="@/assets/mobile/vip/user_jiang.png" alt="" style="height: 48px;margin-top: 10px;">
					<div class="whiteWord smallSize" style="margin-top: 5px;color:white;">{{ $t("升级奖励") }}</div>

					<div class="whiteWord smallSize columBtn enable" v-if="configData.VipDoneNum > 0"
						style="margin-top: 10px;" @click="getVIPScore()">{{ $t("领取") }}</div>
					<div class="whiteWord smallSize columBtn disable" v-else style="margin-top: 10px;">{{ $t("已领取") }}</div>
				</div>

				<div
					style="width: 43vw;height: 120px;text-align: center;background-color:rgb(53,65,109);border-radius:5px;">
					<img src="@/assets/mobile/vip/user_gift.png" alt="" style="height: 48px;margin-top: 10px;">
					<div class="whiteWord smallSize" style="margin-top: 5px;color:white;">{{ $t("每月奖金") }}</div>

					<div class="whiteWord smallSize columBtn enable" @click="getMonthGift()"
						v-if="configData.WeekStatus === 'Done'" style="margin-top: 10px;">{{ $t("领取") }}</div>
					<div class="whiteWord smallSize columBtn disable" v-else style="margin-top: 10px;">{{ $t("已领取") }}</div>
				</div>

			</div>


		</div>
	</div>
</template>

<script>
import {
	mapGetters,
	mapActions,
	mapMutations
} from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import { bus, busName } from "@/components/core/bus";
import i18n from "@/language/i18n";
import {
	theme
} from '@/views/theme'

import topHeader from "@/views/mobile/components/lottery/topHeader.vue";

import {
	req,
	qs
} from "@/utils/request";
import {
	topic
} from "@/components/protocol/api";
export default {
	components: {
		topHeader
	},
	data() {
		return {
			selectIndex: 0,
			progressWidth: {
				width: "50%"
			},
			progressWidth2: {
				width: "50%"
			},
			endDate: '',
			startDate: '',
			page: 1,
			configData: null,
			recordData: [],
			headerArr: [this.$t('时间'), this.$t('类型'), this.$t('金额'), this.$t('等级')]
		}
	},
	computed: {
		...mapGetters("tcp", ["isLogged"]),

	},

	created() {
		if (this.isLogged) {
			this.getRecord()
			this.getVipConfig()

		}
		var that = this;
		bus.on("onConnected", () => {
			that.getRecord()
			that.getVipConfig()
		});
		var that = this
		bus.off('receiveGift')
		bus.on('receiveGift', (val) => {
			if (val.data.Code == 0) {
				that.getRecord()
				that.getVipConfig()
				that.$message({
					message: that.$t('领取成功') + '!',
					type: 'success'
				});
			} else {
				that.$message({
					message: val.data.ErrMsg,
					type: 'error'
				});
			}
		})
		bus.off('receiveGift2')
		bus.on('receiveGift2', (val) => {
			if (val.data.Code == 0) {
				that.getRecord()
				that.getVipConfig()
				that.$message({
					message: that.$t('领取成功') + '!',
					type: 'success'
				});
			} else {
				that.$message({
					message: val.data.ErrMsg,
					type: 'error'
				});
			}
		})

	},
	methods: {
		toVipInfo() {
			this.$router.push({ name: 'vipInfo' });
		},
		toHistory() {

			this.$router.push({ name: 'vipRecord' });
		},
		currentChange(e) {
			this.page = e
		},
		getRecord() {

			var that = this

			mqant.request(topic.getGiftRecord, {}, function (data, topicName, msg) {
				//console.log(11111,data)
				that.recordData = data.Data
			});
		},
		getVIPScore() {
			var that = this

			mqant.request(topic.getVIPScore, {}, function (data, topicName, msg) {
				that.getVipConfig()
			});
		},
		getMonthGift() {
			var that = this

			mqant.request(topic.getMonthGift, {
				'level': this.configData.CurVipLevel,
				'language': i18n.global.locale
			}, function (data, topicName, msg) {

			});
		},
		getVipConfig() {
			var that = this

			mqant.request(topic.getVIPConfig, {}, function (data, topicName, msg) {

				that.configData = data.Data

				if (that.configData.CurVipBet > that.configData.GoalVipBet) {
					that.progressWidth.width = '100%'

				} else {
					that.progressWidth.width = that.configData.CurVipBet / parseFloat(that.configData
						.GoalVipBet) * 100 + '%'
				}
				if (that.configData.CurVipCharge > that.configData.GoalVipCharge) {
					that.progressWidth2.width = '100%'

				} else {
					that.progressWidth2.width = that.configData.CurVipCharge / parseFloat(that.configData
						.GoalVipCharge) * 100 + '%'
				}
			});
		},
		endDateChange(val) {
			this.endDate = this.dateFormat(val)
		},
		dateChange(val) {
			this.startDate = this.dateFormat(val)
		},

	},

};
</script>

<style scoped lang="scss">
.columBtn {
	background-color: rgb(89, 102, 150);
	color: white;
	border-radius: 3px;
	height: 25px;
	line-height: 25px;
	text-align: center;
	width: 86%;
	margin-left: 7%;
	border-radius: 12.5px;
}

.level {
	width: 90%;
	margin-left: 5%;
	padding: 2vw;

	border-radius: 4px;
	background-color: rgb(53, 65, 109);
	margin-top: 4vw;
	// padding: 2vw;
	padding-bottom: 5vw;
}

.select {
	background-color: #fdb000;
	color: white;
}

.btn {
	padding: 3px 30px;
	margin-right: 10px;
	border-radius: 4px;
}

.nolmal {
	background-color: #323232;
	color: #c6c6c6;
}

.enable {
	background: linear-gradient(to right, rgb(110, 74, 255), rgb(80, 100, 255));
	cursor: pointer;
}

.disable {
	background-color: gray;
}

.wrap {
	padding-top: 50px;
}
</style>
