<template>
  <div style="margin-bottom: 6.4vw;padding-left:4.266667vw;">
    <div class="titleBox">
      <span class="title">{{ title }}</span>
      <div  class="dxqqo">
        <button  size="mini" v-for="(item, index) in dxdsArr" @click="rightClick(index)" :key="item">{{item}}</button
        >
      </div>
    </div>
    <ul class="cloumn">
      <li class="ball" v-for="(item, index) in 10"
     
      @click="numClick(index)"
      :class="containNum(index) ? 'active' : ''" >
      
      <span
      v-if="isR"
      style="
       
      "
      :class="{
        red: recordOriginData[index] >= 10,
        hui: recordOriginData[index] || !recordOriginData[index] <= 5,
        blue:
          recordOriginData[index] <= 9 && recordOriginData[index] >= 6,
      }"
    >
      {{ recordOriginData[index] ? recordOriginData[index] : "0" }}
    </span>
      {{index}}</li>
    </ul>
  
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";

export default {
  data() {
    return {
      dxdsArr: ["Tất Cả", "Tài", "Xỉu", "Lẻ", "Chẵn", "Xoá"],

      selectArr: [],
      isR: false,
      recordOriginData: [],
      type: "",
    };
  },
  props: {
    title: {},
    recordData: {},
    selectSub: {},
    isSouth: {},
    betNum: {},
  },
  created() {
    if (this.title == "Ngàn") {
      this.type = 4;
    } else if (this.title == "Trăm") {
      this.type = 3;
    } else if (this.title == "Chục") {
      this.type = 2;
    } else if (this.title == "Đơn Vị") {
      this.type = 1;
    }
    if (
      this.selectSub &&
      (this.selectSub.SubPlayCode.indexOf("2DTW") >= 0 ||
        this.selectSub.SubPlayCode.indexOf("1D") >= 0 ||
        this.selectSub.SubPlayCode.indexOf("3DTW") >= 0 ||
        this.selectSub.SubPlayCode.indexOf("4D") >= 0)
    ) {
      this.isR = true;
      this.getRecord();
    }
    var num = 0;

    if (this.recordData) {
      for (var obj of this.recordData) {
        var obj2 = obj.OpenCode;
        for (var arr in obj2) {
          for (var number in arr) {
            num++;
          }
        }
        //console.log('asdasdzzz',arr)
        //for(var number of obj)
      }
    }

    var that = this;
    bus.on("clean", (val) => {
      that.selectArr = [];
    });
    bus.on("playChange", (val) => {
      that.selectArr = [];
    });

    bus.on("allClean", (val) => {
      that.selectArr = [];
    });
    bus.on("freshRecord", (val) => {
     
        that.getRecord(val);

      
    });
  },
  methods: {
    getRecord(val) {
      //console.log('ddddd1111')
      if (!this.selectSub) {
        return;
      }
      if (!this.recordData) {
        return;
      }
     
      var selectSub = this.selectSub;
      if (val) {
        selectSub = val;
      }
      var data = {};
     // console.log('asdadasd',this.recordData)

      for (var recordObj of this.recordData) {
        var obj = recordObj.OpenCode;
        var key;
        var key2;
        if (
          selectSub.SubPlayCode == "2DTW_WEI" ||
          selectSub.SubPlayCode == "2DTW_TOU" ||
          selectSub.SubPlayCode.indexOf("1D") >= 0 ||
          selectSub.SubPlayCode == "3DTW_TJ" ||
          selectSub.SubPlayCode == "4D_TJ" ||
          selectSub.SubPlayCode == "3DTW_7T"
        ) {
          key = "L0";
        } else if (
          selectSub.SubPlayCode == "2DTW_WEI_L1" ||
          selectSub.SubPlayCode == "2DTW_TOU_L1" ||
          selectSub.SubPlayCode == "3DTW_TJ_L1"
        ) {
          key = "L1";
        } else if (selectSub.SubPlayCode == "2DTW_L7") {
          key = "L7";
        } else if (selectSub.SubPlayCode == "3DTW_7J") {
          key = "L8";
        }
        
        if (this.isSouth == 1) {
          if (selectSub.SubPlayCode == "2DTW_WEI") {
            key = "L8";
          } else if (selectSub.SubPlayCode == "2DTW_TOU") {
            key = "L0";
          } else if (selectSub.SubPlayCode == "2DTW_TOUWEI") {
            key = "L0";
            key2 = "L8";
          } else if (selectSub.SubPlayCode == "3DTW_7T") {
            key = "L0";

            key2 = "L8";
          }
        }
        if(!key){
        return
      }
        var arr = obj[key];
        var arr1 = obj[key2];

        for (var num of arr) {
          var str;
          if (
            selectSub.SubPlayCode == "2DTW_L7" ||
            selectSub.SubPlayCode == "2DTW_WEI" ||
            selectSub.SubPlayCode == "2DTW_WEI_L1"
          ) {
            str = num.substr(num.length - 2, 2);
            if (this.type == 1) {
              str = str.substr(0, 1);
            } else {
              str = str.substr(1, 1);
            }
          } else if (
            selectSub.SubPlayCode == "2DTW_TOU" ||
            selectSub.SubPlayCode == "2DTW_TOU_L1"
          ) {
            str = num.substr(0, 2);
            if (this.type == 1) {
              str = str.substr(0, 1);
            } else {
              str = str.substr(1, 1);
            }
          } else if (selectSub.SubPlayCode == "1D_TJ4") {
            str = num.substr(3, 1);
          } else if (selectSub.SubPlayCode == "1D_TJ5") {
            str = num.substr(4, 1);
          } else if (
            selectSub.SubPlayCode == "3DTW_TJ" ||
            selectSub.SubPlayCode == "3DTW_TJ_L1"
          ) {
            if (this.type == 3) {
              str = num.substr(num.length - 3, 1);
            } else if (this.type == 2) {
              str = num.substr(num.length - 2, 1);
            } else {
              str = num.substr(num.length - 1, 1);
            }
          } else if (selectSub.SubPlayCode == "3DTW_7J") {
            if (this.type == 3) {
              str = num.substr(num.length - 3, 1);
            } else if (this.type == 2) {
              str = num.substr(num.length - 2, 1);
            } else {
              str = num.substr(num.length - 1, 1);
            }
          } else if (selectSub.SubPlayCode == "4D_TJ") {
            if (this.type == 4) {
              str = num.substr(num.length - 4, 1);
            } else if (this.type == 3) {
              str = num.substr(num.length - 3, 1);
            } else if (this.type == 2) {
              str = num.substr(num.length - 2, 1);
            } else {
              str = num.substr(num.length - 1, 1);
            }
          }

          if (this.isSouth == 1) {
            if (
              selectSub.SubPlayCode == "2DTW_TOUWEI" ||
              selectSub.SubPlayCode == "3DTW_7T"
            ) {
              var str1 = arr1[0].substr(0, 1);
              if (data[str1]) {
                data[str1]++;
              } else {
                data[str1] = 1;
              }
            }
          }

          if (data[str]) {
            data[str]++;
          } else {
            data[str] = 1;
          }
        }
      }
      this.recordOriginData = data;
    },
    rightClick(index) {
      if (index == 0) {
        this.selectArr = [];
        for (var i = 0; i < 10; i++) {
          this.selectArr.push(i);
        }
      } else if (index == 3) {
        this.selectArr = [];
        for (var i = 0; i < 10; i++) {
          if (i % 2 == 1) {
            this.selectArr.push(i);
          }
        }
      } else if (index == 4) {
        this.selectArr = [];
        for (var i = 0; i < 10; i++) {
          if (i % 2 == 0) {
            this.selectArr.push(i);
          }
        }
      } else if (index == 2) {
        this.selectArr = [];
        for (var i = 0; i < 10; i++) {
          if (i <= 4) {
            this.selectArr.push(i);
          }
        }
      } else if (index == 1) {
        this.selectArr = [];
        for (var i = 0; i < 10; i++) {
          if (i >= 5) {
            this.selectArr.push(i);
          }
        }
      } else if (index == 5) {
        this.selectArr = [];
      }
      this.$emit("numChange", this.selectArr);

      var obj = {
        num: this.selectArr,
        type: this.type,
        betType: "group",
        SubPlayCode: this.selectSub.SubPlayCode,
      };

      bus.emit("BET", obj);
    },
    numClick(num) {
      var contain = false;
      for (var i = 0; i < this.selectArr.length; i++) {
        if (this.selectArr[i] == num) {
          contain = true;
          this.selectArr.splice(i, 1);

          break;
        }
      }
      if (!contain) {
        this.selectArr.push(num);
      }

      if (this.title == "Ngàn") {
        this.type = 4;
      } else if (this.title == "Trăm") {
        this.type = 3;
      } else if (this.title == "Chục") {
        this.type = 2;
      } else if (this.title == "Đơn Vị") {
        this.type = 1;
      }
      var obj = {
        num: this.selectArr,
        type: this.type,
        betType: "group",
        SubPlayCode: this.selectSub.SubPlayCode,
      };
      bus.emit("BET", obj);
    },
    containNum(num) {
      //var contain = false
      for (var nums of this.selectArr) {
        if (num == nums) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  width: 42px;
  height: 42px;
  border-radius: 21px;
  position: relative;
  line-height: 42px;
  text-align: center;
  font-size: 21px;
  margin-top: 10px;
}
.select {
  background-color: #173e83;
  color: white;
}
.nolmal {
  background-color: white;
  color: black;
}
.rightBtn {
  width: 41px;
  height: 30px;
  border-radius: 2.5px;
  line-height: 30px;
  text-align: center;
  font-size: 10px;
  color: #aac5f2;
  border: 1px solid #aac5f2;
}
.rightBtn:hover {
  color: #ffd477;
}
.hui {
  background-color: #6b6c6f;
}
.red {
  background-color: #ff0000;
}
.blue {
  background-color: #6581e2;
}
.titleBox {
  display: flex;
  margin-top: 2.133333vw;
  align-items: center;
  justify-content: flex-start;
  .dxqqo {
    display: flex;
    button {
      padding: 0 2.133333vw;
      height: 5.866667vw;
      border: 1px solid #90a2dc;
      border-radius: 1.066667vw;
      font-size: 3.2vw;
      color: #90a2dc;
      text-align: center;
      font-weight: 400;
      background-color: #293356;
      margin-left: 1.066667vw;
  }
  }
  .title {
    display: inline-block;
    width: 13.333333vw;
    font-size: 3.466667vw;
    color: #fff;
    text-align: center;
    font-weight: 500;
  }
}
.cloumn {
  margin-left: 14.666667vw;
  display: flex;
  flex-wrap: wrap;
  .ball.active {
    color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    background-image: linear-gradient(135deg,#7146ff,#4a69ff);
}
  .ball {
    width: 11.733333vw;
    height: 11.733333vw;
    line-height: 11.733333vw;
    margin-right: 3.2vw;
    position: relative;
    margin-top: 4.266667vw;
    box-shadow: 0 0.533333vw 1.066667vw 0 rgba(0,0,0,.2);
    border-radius: 50%;
    font-size: 6.4vw;
    color: #000;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    background-position: bottom;
    box-sizing: border-box;
    background-color: #fff;
    span {
      position: absolute;
      display: block;
      font-size: 3.2vw;
      width: 4.266667vw;
      height: 4.266667vw;
      border-radius: 50%;
      color: #fff;
      line-height: 4.266667vw;
      top: 0;
      right: 0;
  }
}
}
</style>
