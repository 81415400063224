//用户名
export function nameRule(rule,value,callback){
    const reg = /^[a-zA-Z]\w{5,14}$/
    if(value === ''){
        callback(new Error('Tài khoản không được để trống'))
    }else if (!reg.test(value)){
        callback(new Error('6-15 ký tự chữ và số, bắt đầu bằng chữ cái')) 
    }else{
        callback();
    }
}
//密码
export function passRule(rule,value,callback){
    const reg = /^[a-zA-Z0-9]{6,15}$/
    if(value === ''){
        callback(new Error('Mật khẩu không được để trống'))
    }else if (!reg.test(value)){
        callback(new Error('6-15 ký tự chữ và số')) 
    }else{
        callback();
    }
}
//手机
export function phoneRule(rule,value,callback){
    // const reg = /^[0-9]{10}$/
    if(value === ''){
        callback(new Error('Nhập số điện thoại'))
    // }else if (!reg.test(value)){
    //     callback(new Error('Số điện thoại không chính xác')) 
    }else{
        callback();
    }
}
//真名
export function realNameRule(rule,value,callback){
    if(value === ''){
        callback(new Error('Nhập họ tên'))
    }else{
        callback();
    }
}