<template>
  <div class="winning-notice-wp winning-notice-leave" :class="show?'show':''">
    <div  class="winning-notice">
      <div  class="title-wp">
        <div  class="title">Thông báo thanh toán</div>
        <div  class="btn-close" @click="show=false"></div>
      </div>
      <div  class="panel" v-if="rewardData">
        <div  class="date">{{getName()}}&nbsp;&nbsp;&nbsp;&nbsp;{{rewardData.Number}}</div>
        <div  class="result-wrapper">
          <div  class="result">
            Số tiền: <span  :class="{loss:rewardData.Sprofit<0}">{{rewardData.Sprofit}}</span>
          </div>
          <div  class="see-detail" @click="toDetail()">
            Chi tiết
            <i  class="iconfont icon-icon_more_blue_16"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { topic } from "@/components/protocol/api";
import { mqant_lottery } from "@/components/protocol/mqantlib";

export default {
  data() {
    return {
      title: ["Tên kiểu cược", "Đặt cược", "Tiền thưởng"],
	  show:false,
	  rewardData:null
    };
  },
  props: {
	
  },
  computed: {
    ...mapGetters("tcp_lottery", ["Lotteries", "Plays", "Timestamp"]),
  },
  created() {
	var that = this
	
	bus.on('betResult', (val) => {
    if(val.PlayCode=="FUNNY"||val.PlayCode=="TS"){
      that.rewardData = val
		that.show = true
    setTimeout(() => {
      that.show = false
    }, 8000);
    }
	
	})

},
  methods: {
    toDetail(){
      this.show = false
      var obj = {
          offset: 0,
          limit: 50,
          status: 8,
        };
        var that = this;
        mqant_lottery.request(
          topic.getBetRecordList,
          obj,
          function (data, topicName, msg) {
            var recordList = data.Data.List;
            for( var obj of recordList){
              if(obj.Number == that.rewardData.Number&&obj.LotteryCode==that.rewardData.LotteryCode){
                that.$router.push({
						name: 'RecordInfo',
						params: {
							data: JSON.stringify(obj),
							name:that.getName()
							
						}
					
					
					})
              }
            }
          }
        );
    },
    getName() {
      for (var key in this.Lotteries) {
        for (var obj of this.Lotteries[key]) {
          if (obj.LotteryCode == this.rewardData.LotteryCode) {
            return obj.LotteryName;
          }
        }
      }
    },

    dealWord(item) {
      if (item == "big") {
        return "Tài";
      } else if (item == "small") {
        return "Xỉu";
      } else if (item == "single") {
        return "Lẻ";
      } else if (item == "pair") {
        return "Chẵn";
      } else if (item == "up") {
        return "Trên";
      } else if (item == "draw") {
        return "Hoà";
      } else if (item == "down") {
        return "Dưới";
      } else if (item == "jin") {
        return "Kim";
      } else if (item == "mu") {
        return "Mộc";
      } else if (item == "shui") {
        return "Thủy";
      } else if (item == "huo") {
        return "Hỏa";
      } else if (item == "tu") {
        return "Thổ";
      } else if (item == "bigSingle") {
        return "Tài Lẻ";
      } else if (item == "smallSingle") {
        return "Xỉu Lẻ";
      } else if (item == "bigPair") {
        return "Tài Chẵn";
      } else if (item == "smallPair") {
        return "Xỉu Chẵn";
      } else {
        return item;
      }
    },
    getSubName() {
      if (this.rewardData.PlayCode == "FUNNY") {
        for (var key in this.Plays) {
          var arr = this.Plays[key];
          for (var obj of arr) {
            if (obj.PlayCode == this.rewardData.PlayCode) {
              var arr2 = obj.SubPlays;
              for (var obj2 of arr2) {
                if (obj2.SubPlayCode == this.rewardData.SubPlayCode) {
                  return "[Lô 2 Số Giải ĐB]" + obj2.SubName;
                }
              }
            }
          }
        }
      }
      var str = "";
      for (var key in this.Plays) {
        var arr = this.Plays[key];
        for (var obj of arr) {
          if (obj.PlayCode == this.rewardData.PlayCode) {
            var arr2 = obj.SubPlays;
            for (var obj2 of arr2) {
              if (obj2.SubPlayCode == this.rewardData.SubPlayCode) {
                str += "[" + obj2.SubName + "]" + "Kèo đôi";
                return str;
              }
            }
          }
        }
      }
    },
    cancel() {
      this.$emit("close");
    },
    checkNum(data) {
      if (data) {
        return (data = this.formatNum(Number(data.replace(/[^\d.%\\]/g, ""))));
      }
    },
    sure() {
      var subStr = (this.defaultNum + "").substr(this.defaultNum.length - 2, 2);
      //console.log('xx',subStr)
      for (var i = 0; i < subStr.length; i++) {
        if (parseInt(subStr[i]) != 0) {
          this.$message({
            message:
              "Số tiền đặt cược bắt buộc phải là bôi số của 100, ví dụ 1,000, 1,500",
            type: "info",
          });
          return;
        }
      }
      bus.emit("defaultNum", this.defaultNum);
      localStorage.setItem("defaultNum", this.defaultNum);
      this.cancel();
    },
    formatNum(num) {
      var numeral = require("numeral");
      return numeral(num).format("0,000");
    },
  },
};
</script>

<style scoped lang="scss">
.winning-notice-wp {
    position: absolute;
    z-index: 9999;
   top: -26.4vw;
    left: 4.266667vw;
    width: 91.466667vw;
    height: 22.933333vw;
    background: #fbfbfb;
    border-radius: 2.133333vw;
    transition: top .5s,display .5s;
	.winning-notice {
		background: url("@/assets/mobile/lottery/openBG.png") no-repeat;
		border-radius: 2.133333vw;
		padding: 2.133333vw 4.266667vw;
		width: 91.466667vw;
		height: 26.4vw;

		.panel {
			margin: 1.066667vw 0;
			.result-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin: 0 0.533333vw 0 12vw;
				.see-detail {
					background-image: linear-gradient(270deg,#4c4ff7,#972ff0);
					box-shadow: inset 0 0 1.6vw 0 #fff;
					border-radius: 3.733333vw;
					color: #fff;
					font-size: 3.733333vw;
					line-height: 3.733333vw;
					padding: 1.6vw 2.666667vw;
				}
				.result {
					font-size: 4.266667vw;
					color: #8e4eff;
					line-height: 4.266667vw;
					font-weight: 600;
          span{
						color: #0c6;
          }
					span.loss {
					//	color: #0c6;
					}
				}
			}
			.date {
				font-size: 3.733333vw;
				color: #333;
				text-align: center;
				line-height: 3.733333vw;
				font-weight: 400;
				margin: 1.866667vw auto;
			}
		}
 .title-wp{
    position: relative;
    display: flex;
    justify-content: center;
	.btn-close {
		background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAMAAADW3miqAAAAXVBMVEUAAABcZppkcqRdZppcZ5pdZ5ldZ5pcZ5leZ5tfaJtfaZxlbaJdZppcZppdZplcZplcZpldZppeaZxcZ5pcZppdZ5peaJteaJpeaZtdZ5pdZ5pdZ5phaJ5cZ5pcZpkja2IuAAAAHnRSTlMA9QmqdZdghUw1HhDr5eHYzowlvLFUQjwtf2udF8YpqKrQAAABOUlEQVQ4y32TB5KDMAxFZdNC7yVAdP9jrtcScXDhzWQGnIf1kQUAJNMADxTqV46IIpNBJUK1RYT/1C+vUvaIGAHICTWJZ7NXjYoYFBlZbW4p8kCF4BqzIC2+OWujH92AyWuyImmcWK+MpVk5P2R1xVWK3qc3ilnEioIVrb5LwSJB/ObMK7506BH58ZfQzgIeMraosljBy5stDhcg/jr1BkF2dsTjXIxsZXwfzM5HFM5kSJ8TMbPXSenPnXL5mzkjn2nZsuW2YaGhmtTlUJHVnJazkdNJPW+I5s5Q1NTowsTjfQ1XjPVncOypkx+7g7Jz2hW5mw+CrfW+d1N6OoJVcTuM3Jln84pp4OBlg0Rktm2l015k3nBYxdyC40CRuCHeggko9BdUlf65MFG3Bnfw0qk8F+cBfhZy/gAYPTJ80H0V7wAAAABJRU5ErkJggg==) 50%/100%;
		cursor: pointer;
		position: absolute;
		right: 0;
		top: 1.066667vw;
		width: 3.733333vw;
		height: 3.733333vw;
	}
	.title {
		color: #333;
		font-size: 4.266667vw;
		font-weight: 600;
		line-height: 4.266667vw;
		margin-top: 0.8vw;
	}
}
	}
	
}
.show{
	top:50px;
}
</style>
