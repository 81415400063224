import { 
  Toast,
  Tab, 
  Tabs ,
  Swipe,
  SwipeItem,
  Popover,
  Search,
  Popup,
  Tabbar, 
  TabbarItem,
  Collapse,
  CollapseItem,
  ConfigProvider,
  Grid, 
  GridItem ,
  Button,
  NavBar,
  Overlay,
  Field,
  CellGroup,
  Picker,
  Loading,
  DatetimePicker,
  PullRefresh,
  Dialog,
  Uploader,
  Switch,
  Lazyload,
  Image as VanImage ,
  RadioGroup,
  Radio,
  Circle,
  Icon
} from 'vant';
import '@/assets/css/mobile.css'
import 'vant/es/toast/style';
import 'vant/lib/index.css';
import 'vant/es/dialog/style';
import 'vant/es/image-preview/style';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
let install = function (app) {
  app.use(ElementPlus)
  app.use(Toast);
  app.use(Tab);
  app.use(Tabs);
  app.use(Swipe);
  app.use(SwipeItem);
  app.use(Popover);
  app.use(Search);
  app.use(Popup);
  app.use(Tabbar);
  app.use(TabbarItem);
  app.use(Collapse);
  app.use(CollapseItem);
  app.use(ConfigProvider);
  app.use(Grid);
  app.use(GridItem);
  app.use(Button);
  app.use(NavBar);
  app.use(Overlay);
  app.use(Field);
  app.use(CellGroup);
  app.use(Picker);
  app.use(DatetimePicker);
  app.use(PullRefresh);
  app.use(Loading);
  app.use(Dialog);
  app.use(Uploader);
  app.use(Switch);
  app.use(Lazyload);
  app.use(VanImage);
  app.use(Radio);
  app.use(RadioGroup);
  app.use(Circle);
  app.use(Icon);
}

export {
  install
}