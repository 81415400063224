export default {
  install(app) {
    defaneDrag(app);
  },
};

const defaneDrag = (app) => {
  app.directive("drag", {
    mounted(el) {
      // 获取可移动元素的父节点
      let parentNode = el.parentNode.parentNode.parentNode;
      // 设置父节点定位
      parentNode.style.position = "relative";
      el.parentNode.parentNode.style.position = "absolute";

      // 设置鼠标hover效果:移动上前去显示可移动的提示效果，并且禁用页面可选择，离开恢复正常
      el.onmouseover = () => {
        el.style.cursor = "pointer";
      };
      el.onmouseout = () => {
        el.style.cursor = "none";
      };
      // 防止选中移动块上的文字等
      parentNode.onmouseover = () => {
        document.onselectstart = () => {
          // return false;
        };
      };
      parentNode.onmouseout = () => {
        document.onselectstart = () => {
          return true;
        };
      };

      el.onmousedown = (event) => {
        //event的兼容,同时得到clientX,的值
        var event = event || window.event;
        let x = event.clientX - el.parentNode.parentNode.offsetLeft;
        let y = event.clientY - el.parentNode.parentNode.offsetTop; //得到小段的偏移
        let minOffset = 5;//最小边界
        // 将移动事件绑定到 document 上，防止拖动过快脱离开
        document.onmousemove = (event) => {
          let xx = event.clientX - x; //当移动的时候，用它的鼠标值减去偏移量
          let yy = event.clientY - y;
          if (xx <= 0) {
            //判定边界值 0，就在最边上了,
            xx = minOffset;
          }
          if (xx >= parentNode.clientWidth - el.parentNode.parentNode.clientWidth) {
            //大于整个盒子的宽度-小盒子的宽度
            xx = parentNode.clientWidth - el.parentNode.parentNode.clientWidth -minOffset;
          }
          if (yy <= 0) {
            yy = minOffset;
          }
          if (yy >= parentNode.clientHeight - el.parentNode.parentNode.clientHeight) {
            yy = parentNode.clientHeight - el.parentNode.parentNode.clientHeight -minOffset;
          }

          el.parentNode.parentNode.style.left = xx + "px";
          el.parentNode.parentNode.style.top = yy + "px";
        };
        el.onmouseup = () => {
          // 取消事件
          document.onmousemove = null;
          el.onmouseup = null;
        };
      };
    },
  });
};