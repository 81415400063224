<template>
  <div style="border: 1px solid rgba(144, 162, 220, 0.4); border-bottom: none" class="flex_r">
    <div style="width: 16%">
      <div v-for="item in titleArrN" class="boderBottom boderRight flex_colum"
        :style="'height:' + item.lineHeight * 33 + 'px'"
        style="text-align: center; font-size: 14.5px; color: #aac5f2; position: relative">
        <div style="position: absolute; top: 50%; transform: translateY(-50%)">
          {{ item.title }}
        </div>
      </div>
    </div>
    <div style="width: 64%">
      <div v-for="(item, index) in titleArrN" class="boderBottom boderRight"
        :style="'height:' + item.lineHeight * 33 + 'px'" style="font-size: 14.5px; color: #aac5f2">
        <div v-if="index == 0">
          <div class="flex_r_c" style="padding-top: 4px">
            <div style="
                position: relative;
                margin-left: 19px;
                width: 89px;
                height: 21px;
                color: #8696cc;
                text-align: center;
                line-height: 21px;
                font-size: 12px;
              ">
              Tài Xỉu
            </div>
            <div v-for="(item, index) in 5" v-if="data" :key="index">
              <div class="tag blueTag" v-if="isBig(data.L0[0].substr(index, 1))" style="margin-left: 10px">
                Tài
              </div>
              <div class="redTag tag" v-else style="margin-left: 10px">Xỉu</div>
            </div>
          </div>
          <div class="flex_r_c" style="margin-top: 7px">
            <div style="
                position: relative;
                margin-left: 19px;
                width: 89px;
                height: 21px;
                color: #8696cc;
                text-align: center;
                line-height: 21px;
                font-size: 12px;
              ">
              Chẵn lẻ
            </div>

            <div v-for="(item, index) in 5" v-if="data" :key="index">
              <div class="blueTag tag" v-if="isSingle(data.L0[0].substr(index, 1))" style="margin-left: 10px">
                Lẻ
              </div>
              <div class="redTag tag" v-else style="margin-left: 10px">Chẵn</div>
            </div>
          </div>
          <div class="flex_r_c" style="margin-top: 7px">
            <div style="
                position: relative;
                margin-left: 19px;
                width: 89px;
                height: 21px;
                color: #8696cc;
                text-align: center;
                line-height: 21px;
                font-size: 12px;
              ">
              Tổng
            </div>

            <div class="tag all" style="margin-left: 10px">
              {{
                parseInt(data.L0[0].substr(0, 1)) +
                parseInt(data.L0[0].substr(1, 1)) +
                parseInt(data.L0[0].substr(2, 1)) +
                parseInt(data.L0[0].substr(3, 1)) +
                parseInt(data.L0[0].substr(4, 1))
              }}
            </div>

            <div class="tag blueTag" v-if="parseInt(data.L0[0].substr(0, 1)) +
                parseInt(data.L0[0].substr(1, 1)) +
                parseInt(data.L0[0].substr(2, 1)) +
                parseInt(data.L0[0].substr(3, 1)) +
                parseInt(data.L0[0].substr(4, 1)) >=
                23
                " style="margin-left: 10px">
              Tài
            </div>
            <div class="redTag tag" v-else style="margin-left: 10px">Xỉu</div>
            <div class="blueTag tag" v-if="(parseInt(data.L0[0].substr(0, 1)) +
              parseInt(data.L0[0].substr(1, 1)) +
              parseInt(data.L0[0].substr(2, 1)) +
              parseInt(data.L0[0].substr(3, 1)) +
              parseInt(data.L0[0].substr(4, 1))) %
              2 ==
              1
              " style="margin-left: 10px">
              Lẻ
            </div>
            <div class="redTag tag" v-else style="margin-left: 10px">Chẵn</div>
          </div>
        </div>
        <div class="flex_r_c" v-else style="width: 100%; height: 100%">
          <div style="width: 58%; height: 100%; position: relative" class="boderRight">
            <div class="flex_r_c" style="justify-content: center; align-items: center; font-size: 14px"
              :style="'margin-top:' + (item.lineHeight * 33 - 28) / 2 + 'px'">
              <div v-for="(item1, index1) in data['L' + (index - 1)]"
                v-html="brightenKeyword(item1, index1, data['L' + (index - 1)], index)"></div>
              <!-- {{data['L'+(index-1)].join(',')}} -->

              <!-- <span v-for="(item1,index1) in data['L'+(index-1)].join(',')" :key="index1"  :class="'num'+index+'_'+index1" :ref="'num'+index+'_'+index1" style="height: 14.5px;" :style="'margin-top:'+(item.lineHeight*33-14.5-2)/2+'px'">
								 {{item1}}
							 </span> -->
            </div>
          </div>

          <div v-if="index == 4 || index == 6" style="width: 7%; height: 100%; position: relative" class="boderRight">
            <div v-if="index == 4" style="width: 100%; height: 100%">
              <div style="height: 50%; position: relative" class="boderBottom">
                <div style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 100%;
                  ">
                  {{ index - 1 }}
                </div>
              </div>
              <div style="height: 50%; position: relative">
                <div style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 100%;
                  ">
                  {{ index }}
                </div>
              </div>
            </div>
            <div v-else style="width: 100%; height: 100%">
              <div style="height: 50%; position: relative" class="boderBottom">
                <div style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 100%;
                  ">
                  {{ index }}
                </div>
              </div>
              <div style="height: 50%; position: relative">
                <div style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 100%;
                  ">
                  {{ index + 1 }}
                </div>
              </div>
            </div>
          </div>
          <div v-else style="width: 7%; height: 100%; position: relative" class="boderRight">
            <div v-if="index < 4" style="
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                width: 100%;
              ">
              {{ index - 1 }}
            </div>
            <div v-else-if="index > 4 && index <= 6" style="
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                width: 100%;
              ">
              {{ index }}
            </div>
            <div v-else style="
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                width: 100%;
              ">
              {{ index + 1 }}
            </div>
          </div>

          <div v-if="index == 4 || index == 6" style="width: 35%; height: 100%; position: relative" class="">
            <div v-if="index == 4" style="width: 100%; height: 100%">
              <div @mouseenter="hoverEvent(3)" @mouseleave="outEvent()" style="height: 50%; position: relative"
                class="boderBottom">
                <div style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 100%;
                  " v-if="numArr.length">
                  {{ numArr[3].join(",") }}
                </div>
              </div>
              <div @mouseenter="hoverEvent(4)" @mouseleave="outEvent()" style="height: 50%; position: relative">
                <div style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 100%;
                  " v-if="numArr.length">
                  {{ numArr[4].join(",") }}
                </div>
              </div>
            </div>
            <div v-else style="width: 100%; height: 100%">
              <div @mouseenter="hoverEvent(6)" @mouseleave="outEvent()" style="height: 50%; position: relative"
                class="boderBottom">
                <div style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 100%;
                  " v-if="numArr.length">
                  {{ numArr[6].join(",") }}
                </div>
              </div>
              <div @mouseenter="hoverEvent(7)" @mouseleave="outEvent()" style="height: 50%; position: relative">
                <div style="
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    text-align: center;
                    width: 100%;
                  " v-if="numArr.length">
                  {{ numArr[7].join(",") }}
                </div>
              </div>
            </div>
          </div>
          <div v-else style="width: 35%; height: 100%; position: relative" class="">
            <div @mouseenter="hoverEvent(index - 1)" @mouseleave="outEvent()" v-if="index < 4 && numArr.length" style="
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                width: 100%;
              ">
              {{ numArr[index - 1].join(",") }}
            </div>
            <div @mouseenter="hoverEvent(index)" @mouseleave="outEvent()"
              v-else-if="index > 4 && index <= 6 && numArr.length" style="
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                width: 100%;
              ">
              {{ numArr[index].join(",") }}
            </div>
            <div @mouseenter="hoverEvent(index + 1)" @mouseleave="outEvent()" v-else-if="index > 6 && numArr.length"
              style="
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                width: 100%;
              ">
              {{ numArr[index + 1].join(",") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 20%">
      <div v-for="(item, index) in titleArrN" :key="item" class="boderBottom"
        :style="'height:' + item.lineHeight * 33 + 'px'"
        style="text-align: center; font-size: 14.5px; color: #aac5f2; position: relative">
        <div v-if="index == 0" style="width: 100%; height: 100%">
          <div @click="chooseQi(0)" style="
              height: 33px;
              text-align: center;
              line-height: 33px;
              position: relative;
            " :style="selectIndex == 0 ? 'background-color:#4a69ff;' : ''" class="boderBottom canClick">
            <img v-if="selectIndex == 0" src="@/assets/pc/lottery/leftArrow.webp" alt=""
              style="width: 8px; position: absolute; left: 2px; top: 9.5px" />
            {{ recordData[0].Number }}
          </div>
          <div @click="chooseQi(1)" v-if="recordData.length > 1" style="
              height: 33px;
              text-align: center;
              line-height: 33px;
              position: relative;
            " :style="selectIndex == 1 ? 'background-color:#4a69ff;' : ''" class="boderBottom canClick">
            <img v-if="selectIndex == 1" src="@/assets/pc/lottery/leftArrow.webp" alt=""
              style="width: 8px; position: absolute; left: 2px; top: 9.5px" />
            {{ recordData[1].Number }}
          </div>
          <div @click="chooseQi(2)" v-if="recordData.length > 2" style="
              height: 33px;
              text-align: center;
              line-height: 33px;
              position: relative;
            " :style="selectIndex == 2 ? 'background-color:#4a69ff;' : ''" class="canClick">
            <img v-if="selectIndex == 2" src="@/assets/pc/lottery/leftArrow.webp" alt=""
              style="width: 8px; position: absolute; left: 2px; top: 9.5px" />
            {{ recordData[2].Number }}
          </div>
        </div>
        <div v-else-if="index == 4 || index == 6" style="width: 100%; height: 100%">
          <div v-if="index == 4">
            <div @click="chooseQi(6)" v-if="recordData.length > 6" style="
                height: 33px;
                text-align: center;
                line-height: 33px;
                position: relative;
              " :style="selectIndex == 6 ? 'background-color:#4a69ff;' : ''" class="boderBottom canClick">
              <img v-if="selectIndex == 6" src="@/assets/pc/lottery/leftArrow.webp" alt=""
                style="width: 8px; position: absolute; left: 2px; top: 9.5px" />
              {{ recordData[6].Number }}
            </div>

            <div @click="chooseQi(7)" v-if="recordData.length > 7" style="
                height: 33px;
                text-align: center;
                line-height: 33px;
                position: relative;
              " :style="selectIndex == 7 ? 'background-color:#4a69ff;' : ''" class="canClick">
              <img v-if="selectIndex == 7" src="@/assets/pc/lottery/leftArrow.webp" alt=""
                style="width: 8px; position: absolute; left: 2px; top: 9.5px" />
              {{ recordData[7].Number }}
            </div>
          </div>
          <div v-else>
            <div @click="chooseQi(9)" v-if="recordData.length > 9" style="
                height: 33px;
                text-align: center;
                line-height: 33px;
                position: relative;
              " :style="selectIndex == 9 ? 'background-color:#4a69ff;' : ''" class="boderBottom canClick">
              <img v-if="selectIndex == 9" src="@/assets/pc/lottery/leftArrow.webp" alt=""
                style="width: 8px; position: absolute; left: 2px; top: 9.5px" />
              {{ recordData[9].Number }}
            </div>

            <div @click="chooseQi(10)" v-if="recordData.length > 10" style="
                height: 33px;
                text-align: center;
                line-height: 33px;
                position: relative;
              " :style="selectIndex == 10 ? 'background-color:#4a69ff;' : ''" class="canClick">
              <img v-if="selectIndex == 10" src="@/assets/pc/lottery/leftArrow.webp" alt=""
                style="width: 8px; position: absolute; left: 2px; top: 9.5px" />
              {{ recordData[10].Number }}
            </div>
          </div>
        </div>
        <div v-else style="width: 100%; height: 100%">
          <!-- <div @click="chooseQi(2)" style="height: 33px;text-align: center;line-height: 33px;position: relative;" :style="selectIndex==2?'background-color:#4a69ff;':''" class="boderBottom canClick">
						<img src="@/assets/pc/lottery/leftArrow.webp" alt="" style="width: 8px;position: absolute;left: 2px;top: 9.5px;">
					{{recordData[2].Number}}</div> -->

          <div @click="chooseQi(index + 2)" :style="selectIndex == index + 2 ? 'background-color:#4a69ff;' : ''"
            v-if="index < 4 && numArr.length && recordData.length > index + 2" style="
              height: 33px;
              text-align: center;
              line-height: 33px;
              position: relative;
            " class="boderBottom canClick">
            <img v-if="selectIndex == index + 2" src="@/assets/pc/lottery/leftArrow.webp" alt=""
              style="width: 8px; position: absolute; left: 2px; top: 9.5px" />

            {{ recordData[index + 2].Number }}
          </div>
          <div @click="chooseQi(index + 3)" :style="selectIndex == index + 3 ? 'background-color:#4a69ff;' : ''"
            v-else-if="index > 4 && index <= 6 && numArr.length && recordData.length > index + 3
              " style="
              height: 33px;
              text-align: center;
              line-height: 33px;
              position: relative;
            " class="boderBottom canClick">
            <img v-if="selectIndex == index + 3" src="@/assets/pc/lottery/leftArrow.webp" alt=""
              style="width: 8px; position: absolute; left: 2px; top: 9.5px" />

            {{ recordData[index + 3].Number }}
          </div>
          <div @click="chooseQi(index + 4)" :style="selectIndex == index + 4 ? 'background-color:#4a69ff;' : ''"
            v-else-if="index > 6 && numArr.length && recordData.length > index + 4" style="
              height: 33px;
              text-align: center;
              line-height: 33px;
              position: relative;
            " class="boderBottom canClick">
            <img v-if="selectIndex == index + 4" src="@/assets/pc/lottery/leftArrow.webp" alt=""
              style="width: 8px; position: absolute; left: 2px; top: 9.5px" />

            {{ recordData[index + 4].Number }}
          </div>

          <!-- <div style="height: 33px;text-align: center;line-height: 33px;" class="boderBottom">3546</div> -->
        </div>
      </div>
    </div>
  </div>

  <vipInfo :info="selectInfo" v-if="showVip" @close="showVip = false"></vipInfo>
</template>

<script>
import { bus, busName } from "@/components/core/bus";

import group from "./group.vue";
import random from "./random.vue";
import fastChoose from "./fastChoose.vue";
import ProgressBar from "./ProgressBar.vue";
import vipInfo from "./vipInfo.vue";

export default {
  components: {
    group,
    random,
    fastChoose,
    ProgressBar,
    vipInfo,
  },
  data() {
    return {
      titleArrN: [
        {
          title: "Giải 5 chữ số",
          lineHeight: 3,
        },
        {
          title: "Giải đặc biệt",
          lineHeight: 1,
        },
        {
          title: "Giải nhất",
          lineHeight: 1,
        },
        {
          title: "Giải nhì",
          lineHeight: 1,
        },
        {
          title: "Giải ba",
          lineHeight: 2,
        },
        {
          title: "Giải tư",
          lineHeight: 1,
        },
        {
          title: "Giải năm",
          lineHeight: 2,
        },
        {
          title: "Giải sáu",
          lineHeight: 1,
        },
        {
          title: "Giải bảy",
          lineHeight: 1,
        },

        // {title:'Giải bảy',lineHeight:1},
      ],
      selectIndex: 0,
      numArr: [],
      showVip: false,
      selectInfo: "",
      currentData: null,
      refList: [],
      highType: null,
      highArr: [],
      selectColumIndex: 0,
      selectColum: null,
      selectSubIndex: 0,
      selectSub: null,
      funcArr: ["Chọn Số", "Nhập Số", "Chọn Số Nhanh"], //选择号码 输入号码 快选
      funcIndex: 0,
      bottomIndex: 0,
      bigSmallArr: [],
      doubleSingleArr: [],
      selectTS: [],
    };
  },

  props: {
    data: {},
    recordData: {},
    playData: {},
  },
  setup() { },
  computed: {},
  mounted() {
    var that = this;
    bus.off("freshNData");
    bus.on("freshNData", (val) => {
      that.countCurrent(val);
    });

    bus.on("selectSub", (val) => {
      if (val.selectColum) {
        that.selectColum = val.selectColum;
      }
      that.selectSub = val.selectSub;
      that.configHighType();
    });

    this.countCurrent(null);
    this.selectColum = this.playData[0];

    this.selectSub = this.selectColum.SubPlays[0];


    //bus.emit("selectColum", this.selectColum);
    //bus.emit("selectSub", this.selectSub);

    bus.on("deleteItem", (val) => {
      for (var i = 0; i < that.selectTS.length; i++) {
        if (
          that.selectTS[i].substr(that.selectTS[i].length - 1, 1) ==
          val.num.substr(0, 1).toLocaleUpperCase() &&
          val.SubPlayCode.indexOf("FUNNY") >= 0
        ) {
          that.selectTS.splice(i, 1);
        }
      }

      if (val.SubPlayCode == "FUNNY_NUMBER") {
        for (var i = 0; i < that.selectTS.length; i++) {
          if (parseInt(that.selectTS[i].split("R")[1]) == parseInt(val.num)) {
            that.selectTS.splice(i, 1);
          }
        }
      }
    });

    bus.on("allClean", (val) => {
      that.selectTS = [];
    });
    this.configHighType();
  },
  methods: {
    getNoBCZH() {
      var arr = [];
      for (var obj of this.selectColum.SubPlays) {
        if (obj.SubPlayCode.indexOf("BCZH_10") < 0) {
          arr.push(obj);
        }
      }
      return arr;
    },
    getFunnyDXDSODD(subplays) {
      for (var obj of subplays) {
        if (obj.SubPlayCode == "FUNNY_DX_DS") {
          return obj.Odds / 1000;
        }
      }
    },
    getFunnyNumberODD(subplays) {
      for (var obj of subplays) {
        if (obj.SubPlayCode == "FUNNY_NUMBER") {
          return obj.Odds / 1000;
        }
      }
    },
    showInfo(text) {
      this.selectInfo = text;
      this.showVip = true;
    },
    tsClick(str, code, sub, odd) {
      var contain = false;
      var newStr = str + code;
      for (var i = 0; i < this.selectTS.length; i++) {
        if (this.selectTS[i] == newStr) {
          contain = true;
          this.selectTS.splice(i, 1);

          break;
        }
      }
      if (!contain) {
        this.selectTS.push(newStr);
      }

      var num;
      //var odd = 1999
      if (code == "T") {
        num = "Tài";
      } else if (code == "X") {
        num = "Xỉu";
      } else if (code == "L") {
        num = "Lẻ";
      } else if (code == "C") {
        num = "Chẵn";
      } else {
        num = code;
        //odd = 9990
        if (this.selectColum.PlayCode == "FUNNY") {
          //odd = 99500
          num = num < 10 ? "0" + num : num;
        }
      }


      var obj = {
        num: num,
        Odds: odd,
        betType: "Tap",
        SubPlayCode: str ? str : this.selectSub.SubPlayCode,
        SubName: sub,

        UnitBetAmount: 1000,
      };
      bus.emit("BET", obj);
    },
    containTS(str) {
      //var contain = false
      for (var item of this.selectTS) {
        if (str == item) {
          return true;
        }
      }
      return false;
    },
    bottomClick(index) {
      this.bottomIndex = index;
      var type1 = this.highType == "sum" ? "0" : "1";
      if (this.bottomIndex == 0) {
        this.bigSmallArr = this.countTSData(type1, 0);
      } else {
        this.doubleSingleArr = this.countTSData(type1, 1);
      }
    },
    getPersent(type) {
      //0大 1小 2单 3双
      var arr;
      if (type == 0 || type == 1) {
        arr = this.bigSmallArr;
      } else if (type == 2 || type == 3) {
        arr = this.doubleSingleArr;
      }

      var count = 0;
      var total = 0;

      for (var i = 0; i < (arr.length >= 16 ? 16 : arr.length); i++) {
        var tmpArr = arr[i];

        for (var j = 0; j < tmpArr.length; j++) {
          var str = tmpArr[j];
          total++;

          if (type == 0) {
            if (str == "big") {
              count++;
            }
          } else if (type == 1) {
            if (str == "small") {
              count++;
            }
          } else if (type == 2) {
            if (str == "single") {
              count++;
            }
          } else if (type == 3) {
            if (str == "double") {
              count++;
            }
          }
        }
      }

      return (count / total) * 100;
    },

    countTSData(type, type2) {
      var data = this.recordData;
      var index = 0;
      var indexArr = [];
      var tmpArr = [];
      var lastArr = [];

      var lastStr;
      for (var i = 0; i < data.length; i++) {
        var num = data[i].OpenCode.L0[0];
        tmpArr.push(num);
      }
      for (var num of tmpArr) {
        var str;
        if (type == 0) {
          var total = 0;
          for (var i = 0; i < num.length; i++) {
            total += parseInt(num[i]);
          }

          if (type2 == 0) {
            if (total >= 23) {
              str = "big";
            } else {
              str = "small";
            }
          } else {
            if (total % 2 == 0) {
              str = "double";
            } else {
              str = "single";
            }
          }
          if (lastStr == str) {
            if (indexArr.length < 6) {
              indexArr.push(str);
              lastArr[index] = indexArr;
            } else {
              indexArr = [];
              index++;
              indexArr.push(str);
            }
          } else {
            indexArr = [];
            if (lastStr) {
              index++;
            }
            indexArr.push(str);
          }
          lastArr[index] = indexArr;

          lastStr = str;
        } else {
          var sub = this.highType.substr(3, 1);
          var tmpNum = num.substr(5 - sub, 1);

          if (type2 == 0) {
            if (tmpNum >= 5) {
              str = "big";
            } else {
              str = "small";
            }
          } else {
            if (tmpNum % 2 == 0) {
              str = "double";
            } else {
              str = "single";
            }
          }
          if (lastStr == str) {
            if (indexArr.length < 6) {
              indexArr.push(str);
              lastArr[index] = indexArr;
            } else {
              indexArr = [];
              index++;
              indexArr.push(str);
            }
          } else {
            indexArr = [];
            if (lastStr) {
              index++;
            }
            indexArr.push(str);
          }
          lastArr[index] = indexArr;

          lastStr = str;
        }
      }
      if (lastArr.length > 16) {
        lastArr = lastArr.slice(0, 16);
      }
      var arrs = [];
      for (var i = lastArr.length - 1; i >= 0; i--) {
        arrs.push(lastArr[i]);
      }

      return arrs;
    },

    subClick(item, index) {
      this.selectSubIndex = index;
      this.selectSub = item;
      bus.emit("selectSub", this.selectSub);

      this.funcIndex = 0;

      this.configHighType();
      if (this.selectColum.PlayCode == "TS") {
        this.bottomIndex = 0;
        var type1 = this.highType == "sum" ? 0 : 1;
        this.bigSmallArr = this.countTSData(type1, 0);
      }
      if (this.selectColum.PlayCode == "ZH" || this.selectColum.PlayCode == "BCZH") {
        this.funcIndex = 1;
      }
      if (this.selectColum.PlayCode == "2DTW") {
        this.funcIndex = 0;

        bus.emit("freshRecord", this.selectSub);
      }
      if (this.selectColum.PlayCode == "3DTW") {
        this.funcIndex = 0;

        bus.emit("freshRecord", this.selectSub);
      }

      if (this.selectColum.PlayCode == "BZ") {
        this.funcArr = ["Chọn Số", "Nhập Số", "Chọn Số Nhanh"];
        if (this.selectSub.SubPlayCode == "BZ_C4") {
          this.funcArr = ["Chọn Số", "Nhập Số"];
        }
      }

      bus.emit("clean");
    },
    configHighType() {
      if (this.selectColum.PlayCode == "BZ") {
        if (this.selectSub.SubPlayCode == "BZ_C2_HEAD") {
          this.highType = "leftTwo";
        } else if (
          this.selectSub.SubPlayCode == "BZ_C2" ||
          this.selectSub.SubPlayCode == "BZ_C2_1K"
        ) {
          this.highType = "lastTwo";
        } else if (this.selectSub.SubPlayCode == "BZ_C3") {
          this.highType = "lastThree";
        } else if (this.selectSub.SubPlayCode == "BZ_C4") {
          this.highType = "lastFour";
        }
      } else if (
        this.selectColum.PlayCode == "ZH" ||
        this.selectColum.PlayCode == "BCZH"
      ) {
        this.highType = "lastTwo";
      } else if (this.selectColum.PlayCode == "TS") {
        if (this.selectSub.SubPlayCode == "TS_SUM_DX_DS") {
          this.highType = "sum";
        } else {
          this.highType =
            "num" +
            this.selectSub.SubPlayCode.substr(this.selectSub.SubPlayCode.length - 1, 1);
        }
      } else if (
        this.selectColum.PlayCode == "2DTW" ||
        this.selectColum.PlayCode == "1D" ||
        this.selectColum.PlayCode == "3DTW" ||
        this.selectColum.PlayCode == "4D"
      ) {
        this.highType = this.selectSub.SubPlayCode;
        console.log("yyyy", this.highType);
      } else if (this.selectColum.PlayCode == "FUNNY") {
        this.highType = "FUNNY";
      }
    },
    columClick(index) {
      this.selectColumIndex = index;
      this.selectColum = this.playData[index];

      this.selectTS = [];
      this.selectSubIndex = 0;
      this.funcIndex = 0;
      this.funcArr = ["Chọn Số", "Nhập Số", "Chọn Số Nhanh"];
      this.selectSub = this.selectColum.SubPlays[0];
      bus.emit("selectColum", this.selectColum);

      this.configHighType();
      if (this.selectColum.PlayCode == "TS") {
        this.bigSmallArr = this.countTSData(0, 0);
      }

      if (this.selectColum.PlayCode == "BZ") {
        this.funcArr = ["Chọn Số", "Nhập Số", "Chọn Số Nhanh"];
      }
      if (this.selectColum.PlayCode == "FUNNY" || this.selectColum.PlayCode == "4D") {
        this.funcArr = ["Chọn Số", "Nhập Số"];
        this.funcIndex = 0;
      }
      if (this.selectColum.PlayCode == "ZH" || this.selectColum.PlayCode == "BCZH") {
        this.funcArr = ["Nhập Số", "Chọn Số Nhanh"];
        this.funcIndex = 1;
      }
      if (this.selectColum.PlayCode == "1D") {
        this.funcArr = ["Chọn Số"];
        this.funcIndex = 0;
      }
    },
    brightenKeyword(item, index, datas, Lindex) {
      if (!this.highType) {
        if (index != datas.length - 1) {
          return item + ",";
        } else {
          return item;
        }
      }
      if (this.highType == "hover") {
        var contain = false;
        this.highArr.forEach((item1) => {
          if (item.substr(item.length - 2, 2) == item1) {
            contain = true;
          }
        });
        if (contain) {
          if (index != datas.length - 1) {
            return (
              item.substr(0, item.length - 2) +
              '<font style="background-color: white;">' +
              item.substr(item.length - 2, 2) +
              "</font>" +
              ","
            );
          } else {
            return (
              item.substr(0, item.length - 2) +
              '<font style="background-color: white;">' +
              item.substr(item.length - 2, 2) +
              "</font>"
            );
          }
        } else {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }
      } else if (this.highType == "leftTwo") {
        if (Lindex != 8) {
          if (index != datas.length - 1) {
            return (
              '<font style="color:#4A69FF;">' +
              item.substr(0, 2) +
              "</font>" +
              item.substr(2, item.length - 2) +
              ","
            );
          } else {
            return (
              '<font style="color:#4A69FF;">' +
              item.substr(0, 2) +
              "</font>" +
              item.substr(2, item.length - 2)
            );
          }
        } else {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }
      } else if (this.highType == "lastTwo") {
        if (index != datas.length - 1) {
          return (
            item.substr(0, item.length - 2) +
            '<font style="color:#4A69FF;">' +
            item.substr(item.length - 2, 2) +
            "</font>" +
            ","
          );
        } else {
          return (
            item.substr(0, item.length - 2) +
            '<font style="color:#4A69FF;">' +
            item.substr(item.length - 2, 2) +
            "</font>"
          );
        }
      } else if (this.highType == "lastThree") {
        if (item.length < 3) {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }

        if (index != datas.length - 1) {
          return (
            item.substr(0, item.length - 3) +
            '<font style="color:#4A69FF;">' +
            item.substr(item.length - 3, 3) +
            "</font>" +
            ","
          );
        } else {
          return (
            item.substr(0, item.length - 3) +
            '<font style="color:#4A69FF;">' +
            item.substr(item.length - 3, 3) +
            "</font>"
          );
        }
      } else if (this.highType == "lastFour") {
        if (item.length < 4) {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }

        if (index != datas.length - 1) {
          return (
            item.substr(0, item.length - 4) +
            '<font style="color:#4A69FF;">' +
            item.substr(item.length - 4, 4) +
            "</font>" +
            ","
          );
        } else {
          return (
            item.substr(0, item.length - 4) +
            '<font style="color:#4A69FF;">' +
            item.substr(item.length - 4, 4) +
            "</font>"
          );
        }
      } else if (this.highType == "sum") {
        if (Lindex == 1) {
          return '<font style="color:#4A69FF;">' + item + "</font>";
        } else {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }
      } else if (this.highType.indexOf("num") >= 0) {
        var num = this.highType.substr(3, 1);
        if (Lindex == 1) {
          return (
            item.substr(0, 5 - num) +
            '<font style="color:#4A69FF;">' +
            item.substr(5 - num, 1) +
            "</font>" +
            item.substr(5 - num + 1, num - 1)
          );
        } else {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }
      } else if (this.highType == "FUNNY") {
        if (Lindex == 1) {
          return (
            item.substr(0, item.length - 2) +
            '<font style="color:#4A69FF;">' +
            item.substr(item.length - 2, 2) +
            "</font>"
          );
        } else {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }
      } else if (this.highType.indexOf("2DTW") >= 0) {
        if (
          (this.highType == "2DTW_TOU" && Lindex == 1) ||
          (this.highType == "2DTW_TOU_L1" && Lindex == 2) ||
          (this.highType == "2DTW_L7" && Lindex == 8)
        ) {
          if (index != datas.length - 1) {
            return (
              '<font style="color:#4A69FF;">' +
              item.substr(0, 2) +
              "</font>" +
              item.substr(2, item.length - 2) +
              ","
            );
          } else {
            return (
              '<font style="color:#4A69FF;">' +
              item.substr(0, 2) +
              "</font>" +
              item.substr(2, item.length - 2)
            );
          }
        } else if (
          (this.highType == "2DTW_WEI" && Lindex == 1) ||
          (this.highType == "2DTW_WEI_L1" && Lindex == 2)
        ) {
          return (
            item.substr(0, item.length - 2) +
            '<font style="color:#4A69FF;">' +
            item.substr(item.length - 2, 2) +
            "</font>"
          );
        } else {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }
      } else if (this.highType.indexOf("1D") >= 0) {
        if (Lindex == 1) {
          if (this.highType == "1D_TJ4") {
            return (
              item.substr(0, 3) +
              '<font style="color:#4A69FF;">' +
              item.substr(3, 1) +
              "</font>" +
              item.substr(4, 1)
            );
          } else {
            return (
              item.substr(0, 4) +
              '<font style="color:#4A69FF;">' +
              item.substr(4, 1) +
              "</font>"
            );
          }
        } else {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }
      } else if (this.highType.indexOf("3DTW") >= 0) {
        if (Lindex == 1) {
          if (this.highType == "3DTW_TJ") {
            return (
              item.substr(0, item.length - 3) +
              '<font style="color:#4A69FF;">' +
              item.substr(item.length - 3, 3) +
              "</font>"
            );
          } else {
            return item;
          }
        } else if (Lindex == 2) {
          if (this.highType == "3DTW_TJ_L1") {
            return (
              item.substr(0, item.length - 3) +
              '<font style="color:#4A69FF;">' +
              item.substr(item.length - 3, 3) +
              "</font>"
            );
          } else {
            return item;
          }
        } else {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }
      } else if (this.highType == "4D_TJ") {
        if (Lindex == 1) {
          return (
            item.substr(0, item.length - 4) +
            '<font style="color:#4A69FF;">' +
            item.substr(item.length - 4, 4) +
            "</font>"
          );
        } else {
          if (index != datas.length - 1) {
            return item + ",";
          } else {
            return item;
          }
        }
      }
    },
    setItemRef(el) {
      this.refList.push(el);
    },
    outEvent() {
      //this.highType = null
    },
    hoverEvent(index) {
      // 			var arr = []
      // for(var str of this.numArr[index]){
      // 	arr.push(index+str)
      // }
      // this.highType = 'hover'
      // this.highArr = arr
    },
    chooseQi(index) {
      this.selectIndex = index;

      this.$emit("changeIndex", {
        index: index,
      });
      //this.data = this.recordData[index]
      this.countCurrent();
    },
    countCurrent(recordData) {
      var arr = [];
      var obj;
      if (recordData) {
        obj = recordData[this.selectIndex].OpenCode;
      } else {
        obj = this.recordData[this.selectIndex].OpenCode;
      }
      for (let key in obj) {
        arr = arr.concat(obj[key]);
      }

      this.numArr = [];
      for (var j = 0; j < 10; j++) {
        var tmpArr = [];
        this.numArr.push(tmpArr);
      }
      for (var i = 0; i < arr.length; i++) {
        if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 0) {
          this.numArr[0].push(arr[i].substr(arr[i].length - 1, 1));
        } else if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 1) {
          this.numArr[1].push(arr[i].substr(arr[i].length - 1, 1));
        } else if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 2) {
          this.numArr[2].push(arr[i].substr(arr[i].length - 1, 1));
        } else if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 3) {
          this.numArr[3].push(arr[i].substr(arr[i].length - 1, 1));
        } else if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 4) {
          this.numArr[4].push(arr[i].substr(arr[i].length - 1, 1));
        } else if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 5) {
          this.numArr[5].push(arr[i].substr(arr[i].length - 1, 1));
        } else if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 6) {
          this.numArr[6].push(arr[i].substr(arr[i].length - 1, 1));
        } else if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 7) {
          this.numArr[7].push(arr[i].substr(arr[i].length - 1, 1));
        } else if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 8) {
          this.numArr[8].push(arr[i].substr(arr[i].length - 1, 1));
        } else if (parseInt(arr[i].substr(arr[i].length - 2, 1)) == 9) {
          this.numArr[9].push(arr[i].substr(arr[i].length - 1, 1));
        }
      }
    },

    isSingle(num) {
      if (num % 2 == 0) {
        return false; //'Chẵn'
      } else {
        return true; //'Lẻ'
      }
    },
    isBig(num) {
      if (num > 4) {
        return true; //'Tài'
      } else {
        return false; //'Xỉu'
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.canClick {
  cursor: pointer;
}

.boderRight {
  border-right: 1px solid rgba(144, 162, 220, 0.4);
}

.boderBottom {
  border-bottom: 1px solid rgba(144, 162, 220, 0.4);
}

.flex_colum {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blueTag {
  color: #f94168;
  border: 1px solid #f94168;
  //background-color: white;
}

.redTag {
  color: #26a2ff;
  border: 1px solid #26a2ff;
  //background-color: white;
}

.all {
  border: 1px solid #fff;
  color: #fff;
  background-image: linear-gradient(136deg, #7146ff, #4a69ff);
}

.tag {
  width: 48px;
  height: 20px;
  border-radius: 10px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
}

.flex_r_c {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex_sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex_r {
  display: flex;
  flex-direction: row;
}

.selectColum {
  background-color: #1f4a97;
}

.nolColum {
  background-color: #1b2c5e;
}

.colum {
  text-align: center;
  line-height: 56px;
  height: 56px;
  font-size: 15px;
  color: #aac5f2;
}

.selectSub {
  background-color: #aac5f2;
  color: black;
}

.nolSub {
  background-color: #173e83;
  color: white;
}

.fourbtn {
  width: 200px;
  height: 71px;
  text-align: center;
  border-radius: 3px;
  color: white;
  font-size: 14.5px;
}

.threeBtn {
  width: 270px;
  height: 71px;
  text-align: center;
  color: white;
  font-size: 14.5px;
  border-radius: 3px;
}

.fiveBtn {
  width: 155px;
  height: 71px;
  text-align: center;
  color: white;
  font-size: 14.5px;
  border-radius: 3px;
}

.tenBtn {
  width: 69px;
  height: 49px;
  text-align: center;

  color: white;
  font-size: 14.5px;
  border-radius: 5px;
}

.playSelect {
  background-color: #173e83;
}

.playNolmal {
  background-color: #4a5fa9;
}

.bottomBtn {
  height: 62px;
  width: 50%;
  line-height: 62px;
  color: white;
  font-size: 14.5px;
  text-align: center;
}

.bottomSe {
  background-color: #1b2c5e;
}

.bottomNol {
  background-color: #3752f5;
}

.corner {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: white;
  font-size: 25px;
  text-align: center;
  line-height: 40px;
}
</style>
