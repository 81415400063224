<template>
  <div
    class=""
    style="
      padding: 10px;
      background: #1e242c;
      margin: 0 13px;
      border-radius: 12px;
	  margin-top:32px;
      border: 1px solid rgba(214, 217, 224, 0.29);
	 
    "
  >
    <div class="row1">
      <div class="reselected" @click="clean()">
        <button
          type="button"
          class="el-button el-button--default"
        >
          <span>Chọn lại</span>
        </button>
      </div>

      <div class="nums" @click="showChoose=true">{{getRange()}} <span class="miss hot">{{selectItems.length}}</span></div>
      <div class="tips">Hướng dẫn</div>
    </div>
    <div class="flexs_r_c row2">
      <div class="titleBtn">Chọn ngẫu nhiên</div>

      <div class="flexs_r_c" style="margin-left: 20px">
        <div
          v-for="(item, index) in numArr2"
          v-if="isR"
          :class="selectRight == item ? 'select' : 'nolmal'"
          @click="itemClick(item)"
          class="btn canClick"
          :style="index > 0 ? 'margin-left:6px' : ''"
        >
          {{ item }}
        </div>
        <div
          v-for="(item, index) in numArr1"
          v-else
          :class="selectRight == item ? 'select' : 'nolmal'"
          @click="itemClick(item)"
          class="btn canClick"
          :style="index > 0 ? 'margin-left:6px' : ''"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div v-if="!isZH">
      <div class="flexs_r_c row2">
        <div class="titleBtn">Chọn số</div>

        <div class="flexs_r_c" style="margin-left: 20px">
          <div
            v-for="(item, index) in dxdsArr"
            :class="selectRight == item ? 'select' : 'nolmal'"
            @click="itemClick(item)"
            class="btn canClick"
            :style="index > 0 ? 'margin-left:6px' : ''"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <div class="flexs_r_c row2">
        <div class="titleBtn">Trường long</div>

        <div class="flexs_r_c" style="margin-left: 20px">
          <div
            v-for="(item, index) in topArr"
            :class="selectRight == item ? 'select' : 'nolmal'"
            @click="itemClick(item)"
            class="btn canClick"
            :style="index > 0 ? 'margin-left:6px' : ''"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>


	<div
			style="display: flex;flex-direction: row;flex-wrap: wrap;padding-top: 13px;margin-left:12px;">
			<div class="item canClick" v-for="(item,index) in numArr" v-if="numCount==2" @click="numClick(item)"
				:class="containNum(item)?'select':'nolmalNum'" >
				{{item}}
				<div class="tag"
					:class="{  redTag: recordOriginData[item]>=10, huiTag: recordOriginData[item]<=5, blueTag: recordOriginData[item]>5&&recordOriginData[item]<10 }">
					{{recordOriginData[item]}}
				</div>
			</div>
			<div class="item canClick" v-for="(item,index) in getCurrentArr()" v-else @click="numClick(item)"
				:class="containNum(item)?'select':'nolmalNum'" >
				{{item}}
				
				<div class="tag" 
					:class="{  redTag: recordOriginData[item]>=10, huiTag: recordOriginData[item]<=5, blueTag: recordOriginData[item]>5&&recordOriginData[item]<10 }">
					{{recordOriginData[item]}}
				</div>
			</div>
		</div>
		
  </div>
  
	<chooseArea v-if="showChoose&&numCount==3" @close='showChoose=false' :numData='selectItems' @sure='changeIndex' :defauleIndex='numIndex'></chooseArea>

</template>

<script>
import chooseArea from "./chooseArea.vue";
import { bus, busName } from "@/components/core/bus";

export default {
  components: {
    chooseArea,
  },
  data() {
    return {
      numArr1: ["1số", "2số", "3số", "5số", "10số"],
      numArr2: ["10số", "20số", "30số", "40số", "50số"],
      dxdsArr: ["Kép bằng", "Chẵn", "Lẻ", "Tài", "Xỉu"],
      topArr: ["Top 1", "Top 2", "Top 5", "Top 10", "Top 20"],
      showChoose: false,
      rightIndex1: -1,
      rightIndex2: -1,
      rightIndex3: -1,
      selectItems: [],
      selectRight: null,
      recordCount: [],
      recordOriginData: [],
      numCount: 2,
      numArr: [],
      numIndex: 0,
      isZH: false,
      isR: false,
    };
  },
  props: {
    type: {
      //1.1键全选 2.全部
      type: Number,
      default: 1,
    },
    recordData: {},
    selectSub: {},
  },
  created() {
	



    var that = this;
    bus.on("selectSub", (val) => {
      that.selectItems = [];
      this.selectRight = null;
    });

    bus.on("allClean", (val) => {
      that.selectItems = [];
      this.selectRight = null;
    });
    bus.on("deleteItem", (val) => {
      if (!val.numArr) {
        return;
      }
      //if(val.type==that.type){

      var tmpArr = that.selectItems;
      var indexArr = [];
      var arr = val.numArr;
      for (var j = 0; j < arr.length; j++) {
        var num = arr[j];

        for (var i = 0; i < tmpArr.length; i++) {
          if (num == tmpArr[i]) {
            that.selectItems.splice(i--, 1);
            j--;
          }
        }
      }
    });

    if (this.selectSub.SubPlayCode.indexOf("ZH") >= 0) {
      this.numArr1 = [this.numArr1[0]];
      this.isZH = true;
    }
    if (
      this.selectSub.SubPlayCode.indexOf("2DTW") >= 0 ||
      this.selectSub.SubPlayCode.indexOf("3DTW") >= 0
    ) {
      this.isR = true;
    }
    this.getTopData();

    if (
      this.selectSub.SubPlayCode == "BZ_C3" ||
      this.selectSub.SubPlayCode.indexOf("3DTW") >= 0
    ) {
      this.numCount = 3;
      this.dxdsArr = [this.dxdsArr[0]];

      for (var i = 0; i < 1000; i++) {
        var num = i;
        if (num < 10) {
          num = "00" + num;
        } else if (num >= 10 && num < 100) {
          num = "0" + num;
        }
        this.numArr.push(num);
      }
    } else {
      for (var i = 0; i < 100; i++) {
        var num = i;
        if (num < 10) {
          num = num < 10 ? "0" + num : num;
        }
        this.numArr.push(num);
      }
    }
  },
  methods: {
    clean() {
      this.selectItems = [];
      this.selectRight = null;
      var obj = {
        numArr: this.selectItems,

        betType: "fast",
        SubPlayCode: this.selectSub.SubPlayCode,
      };

      bus.emit("BET", obj);
    },
    changeIndex(val) {
      this.numIndex = val;
      this.showChoose = false;
    },
    getRange() {
      if (this.numCount == 3) {
        var begin = this.numIndex * 100;
        if (begin == 0) {
          begin = "000";
        }
        var end = this.numIndex * 100 + 99;
        if (begin == 0) {
          end = "099";
        }
        return begin + "-" + end;
      } else {
        return "00-99";
      }
    },
    getCurrentArr() {
      return this.numArr.slice(this.numIndex * 100, 100 + this.numIndex * 100);
    },
    itemClick(str) {
      if (this.selectRight == str) {
        this.selectRight = null;
        this.selectItems = [];
        var obj = {
          numArr: this.selectItems,

          betType: "fast",
          SubPlayCode: this.selectSub.SubPlayCode,
        };

        bus.emit("BET", obj);
        return;
      }

      this.selectRight = str;
      if (str == "1số") {
        var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

        if (this.numCount == 3) {
          if (num < 10) {
            num = "00" + num;
          } else if (num > 10 && num < 100) {
            num = "0" + num;
          }
          this.selectItems = [num];
        } else {
          if (this.isZH) {
            var forNum = this.selectSub.UnitBetCodeCount;
            var arr = [];
            for (var i = 0; i < 100; i++) {
              var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

              num = num < 10 ? "0" + num : num;

              if (arr.indexOf(num) < 0) {
                arr.push(num);
                if (arr.length == forNum) {
                  break;
                }
              }
            }
            this.selectItems = arr;
          } else {
            this.selectItems = [num < 10 ? "0" + num : num];
          }
        }

        //this.text = num<10?'0'+num:num
      } else if (str == "2số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)
          if (this.numCount == 3) {
            if (num < 10) {
              num = "00" + num;
            } else if (num > 10 && num < 100) {
              num = "0" + num;
            }
          } else {
            num = num < 10 ? "0" + num : num;
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 2) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "3số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)
          if (this.numCount == 3) {
            if (num < 10) {
              num = "00" + num;
            } else if (num > 10 && num < 100) {
              num = "0" + num;
            }
          } else {
            num = num < 10 ? "0" + num : num;
          }

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 3) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "5số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)
          if (this.numCount == 3) {
            if (num < 10) {
              num = "00" + num;
            } else if (num > 10 && num < 100) {
              num = "0" + num;
            }
          } else {
            num = num < 10 ? "0" + num : num;
          }

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 5) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "10số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)
          if (this.numCount == 3) {
            if (num < 10) {
              num = "00" + num;
            } else if (num > 10 && num < 100) {
              num = "0" + num;
            }
          } else {
            num = num < 10 ? "0" + num : num;
          }

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 10) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "20số") {
        var arr = [];
        for (var i = 0; i < 10000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

          num = num < 10 ? "0" + num : num;

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 20) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "30số") {
        var arr = [];
        for (var i = 0; i < 10000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

          num = num < 10 ? "0" + num : num;

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 30) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "40số") {
        var arr = [];
        for (var i = 0; i < 10000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

          num = num < 10 ? "0" + num : num;

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 40) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "50số") {
        var arr = [];
        for (var i = 0; i < 10000; i++) {
          var num = this.done(this.numCount, 9); //Math.round(Math.random()*100)

          num = num < 10 ? "0" + num : num;

          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 50) {
              break;
            }
          }
        }
        this.selectItems = arr;
      } else if (str == "Kép bằng") {
        this.selectItems = [0, 11, 22, 33, 44, 55, 66, 77, 88, 99];
        if (this.numCount == 3) {
          this.selectItems = [0, 111, 222, 333, 444, 555, 666, 777, 888, 999];
        }
      } else if (str == "Chẵn") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = i;
          if (num % 2 == 0) {
            num = num < 10 ? "0" + num : num;
            arr.push(num);
          }
        }
        this.selectItems = arr;
      } else if (str == "Lẻ") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = i;
          if (num % 2 == 1) {
            num = num < 10 ? "0" + num : num;
            arr.push(num);
          }
        }
        this.selectItems = arr;
      } else if (str == "Tài") {
        var arr = [];
        for (var i = 50; i < 100; i++) {
          var num = i;

          num = num < 10 ? "0" + num : num;
          arr.push(num);
        }
        this.selectItems = arr;
      } else if (str == "Xỉu") {
        var arr = [];
        for (var i = 0; i < 50; i++) {
          var num = i;

          num = num < 10 ? "0" + num : num;
          arr.push(num);
        }
        this.selectItems = arr;
      } else if (str == "Top 1") {
        this.selectItems = this.getTopData(1);
      } else if (str == "Top 2") {
        this.selectItems = this.getTopData(2);
      } else if (str == "Top 5") {
        this.selectItems = this.getTopData(3);
      } else if (str == "Top 10") {
        this.selectItems = this.getTopData(4);
      } else if (str == "Top 20") {
        this.selectItems = this.getTopData(5);
      }

      var obj = {
        numArr: this.selectItems,

        betType: "fast",
        SubPlayCode: this.selectSub.SubPlayCode,
      };

      bus.emit("BET", obj);
    },
    numClick(num) {
      var contain = false;
      for (var i = 0; i < this.selectItems.length; i++) {
        if (this.selectItems[i] == num) {
          contain = true;
          this.selectItems.splice(i, 1);

          break;
        }
      }

      if (!contain) {
        if (this.selectItems.length >= this.selectSub.MaxBetNumber) {
          return;
        }
        this.selectItems.push(num);
      }
      var obj = {
        numArr: this.selectItems,

        betType: "fast",
        SubPlayCode: this.selectSub.SubPlayCode,
      };

      bus.emit("BET", obj);
      this.$emit("numChange", this.selectItems);
    },
    containNum(num) {
      //num = num < 10 ? '0' + num : num

      //var contain = false
      for (var nums of this.selectItems) {
        if (num == nums) {
          return true;
        }
      }
      return false;
    },
    reset() {
      this.text = "";
      this.selectItem = null;
      var obj = {
        numArr: this.selectItems,

        betType: "fast",
        SubPlayCode: this.selectSub.SubPlayCode,
      };

      bus.emit("BET", obj);
    },

    done(len, max) {
      var arr = [];
      var result = "";
      var count = 0;
      while (count < len) {
        var n = Math.floor(Math.random() * (max + 1));
        if (arr.join().indexOf(n) == -1) {
          arr.push(n);
          count++;
        }
      }
      for (let index = 0; index < arr.length; index++) {
        result = result + arr[index];
      }
      return parseInt(result);
    },
    getTopData(type) {
      var data = {};
	var recordData = this.recordData.slice(0,50)
      for (var recordObj of recordData) {
        var obj = recordObj.OpenCode;
        var forNum = 10;
        if (this.isR) {
          forNum = 1;
        }
        if (!obj) {
          continue;
        }
        for (var i = 0; i < forNum; i++) {
          if (obj["L" + i]) {
            var arr = obj["L" + i];
            if (
              this.selectSub.SubPlayCode == "2DTW_TOU" ||
              this.selectSub.SubPlayCode == "2DTW_WEI" ||
              this.selectSub.SubPlayCode == "3DTW_TJ"
            ) {
              arr = obj.L0;
            }

            if (
              this.selectSub.SubPlayCode == "2DTW_TOU_L1" ||
              this.selectSub.SubPlayCode == "2DTW_WEI_L1" ||
              this.selectSub.SubPlayCode == "3DTW_TJ_L1"
            ) {
              arr = obj.L1;
            }
            if (this.selectSub.SubPlayCode == "2DTW_L7") {
              arr = obj.L7;
            }

            for (var num of arr) {
              var str;
              if (
                this.selectSub.SubPlayCode == "BZ_C2_HEAD" ||
                this.selectSub.SubPlayCode.indexOf("2DTW_TOU") >= 0 ||
                this.selectSub.SubPlayCode == "2DTW_L7"
              ) {
                str = num.substr(0, 2);
              } else if (
                this.selectSub.SubPlayCode == "BZ_C2" ||
                this.selectSub.SubPlayCode == "BZ_C2_1K" ||
                this.isZH ||
                this.selectSub.SubPlayCode.indexOf("2DTW_WEI") >= 0
              ) {
                str = num.substr(num.length - 2, 2);
              } else if (
                this.selectSub.SubPlayCode == "BZ_C3" ||
                this.selectSub.SubPlayCode.indexOf("3DTW") >= 0
              ) {
                if (num.length >= 3) {
                  str = num.substr(num.length - 3, 3);
                }
              } else if (this.selectSub.SubPlayCode == "BZ_C4") {
                if (num.length >= 4) {
                  str = num.substr(num.length - 4, 4);
                }
              }

              if (data[str]) {
                data[str]++;
              } else {
                data[str] = 1;
              }
            }
          }
        }
      }
      this.recordOriginData = data;

      let newArr = Object.entries(data).sort((a, b) => b[1] - a[1]); //Object.fromEntries(Object.entries(data).sort((a, b) => a[1]  - b[1]));
      this.recordCount = newArr;
      if (type == 1) {
        return [newArr[0][0]];
      } else if (type == 2) {
        var tmpArr = [];
        for (var i = 0; i < 2; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);
        return tmpArr;
      } else if (type == 3) {
        var tmpArr = [];
        for (var i = 0; i < 5; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr;
      } else if (type == 4) {
        var tmpArr = [];
        for (var i = 0; i < 10; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr;
      } else if (type == 5) {
        var tmpArr = [];
        for (var i = 0; i < 20; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.select {
  background-color: #4a69ff;
  color: white;
}
.nolmal {
  background-color: #292e3b;
  color: white;
}


  .nolmalNum {
	background-color: #363d4e;
	color: white;
  }

.tag {
  width: 16px;
  height: 12px;
  text-align: center;
  line-height: 12px;
  font-size: 9.5px;
  color: white;
  position: absolute;
  right: 0;
  top: 0;
}

.redTag {
  background-color: #ff0000;
}

.huiTag {
  background-color: #6b6c6f;
}

.blueTag {
  background-color: #4a5fa9;
}

.rightBtn {
  width: 69px;
  height: 24px;
  border-radius: 3px;
  line-height: 24px;
  text-align: center;
  color: white;
  font-size: 14.5px;
}

.rightSelect {
  background-color: #173e83;
}

.rightNolmal {
  background-color: #4a5fa9;
}
.row2 {
  height: 52px;
  background-color: #363d4e;
  display: flex;
  margin-bottom: 6px;
  border-radius: 12px;
}
.titleBtn {
  height: 52px;
  line-height: 52px;
  background-image: none;
  color: #8491a5;
  width: 112px;
  position: relative;
  font-size: 12px;
  padding: 0 10px;
  text-align: center;
}
.titleBtn:after {
  content: "";
  position: absolute;
  right: 0;
  top: 16px;
  display: inline-block;
  width: 1px;
  height: 20px;
  background-color: #677684;
}
.btn {
  margin-right: 8px;
  height: 32px;
  line-height: 32px;
  width: 68px;
  text-align: center;

  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  user-select: none;
  font-size: 14px;
  font-weight: 500;
}
.row1 {
  justify-content: normal;
  display: flex;
  position: relative;
	margin-bottom:15px;
  // justify-content: space-between;
  align-items: center;
  .reselected{
	right: 0px;
    top: 2px;
    bottom: auto;
	text-align: center;
    position: absolute;
    
    margin: 0 auto;
	button{
		background: #363d4e;
		box-shadow: 0 0 4px 0 rgba(0,0,0,.1);
		border-radius: 18px;
		color: #4a69ff;
		height: 36px;
		width: 96px;
		line-height: 36px;
		font-size: 12px;
		padding: 0;
		border: none;
	}

  }
  .tips {
    height: 20px;
   
    border: 1px solid #4a69ff;
    border-radius: 4px;
    color: #4a69ff;
    margin-left: 12px;
    font-size: 12px;
    text-align: right;
    cursor: pointer;
    padding: 2px 3px;
  }
  .nums {
    height: 36px;
    line-height: 36px;
    background: #292e3b;
    width: 105px;
    border-radius: 18px;
    font-size: 13px;
    color: #fff;
    text-align: center;
    font-weight: 400;
    padding: 0 10px;
    position: relative;
    cursor: pointer;
    user-select: none;
	.miss{
		top: -5px;
		border-radius: 50%;
		width: 15px;
		text-align: center;
		line-height: 15px;
		height: 15px;
		background-color: #f93e3e;
		zoom: .9;
		display: block;
		color: #fff;
		right: 0;
		position: absolute;

		font-size: 12px;

	}
  }
  .nums:after {
    content: "";
    position: absolute;
    right: 16px;
    top: 13px;
    width: 5px;
    height: 5px;
    display: block;
    border-color: #fff #fff transparent transparent;
    border-style: solid;
    border-width: 1px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
	
  }
}

.item{
	//background-color: #363d4e;
    //color: #fff;
    box-shadow: 0 0 3px 0 rgba(0,0,0,.1);
    width: 66px;
    height: 44px;
    line-height: 44px;
    border-radius: 7.36px;
    margin: 8px 8px 0 0;
	user-select: none;
    cursor: pointer;
	font-size: 14px;
	position:relative;	
	text-align: center;
}
</style>
