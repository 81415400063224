<template>
	<div style="position: relative;overflow-x: hidden;padding-top:30px;">
		<div class="flexs_r_c">
			<div style="margin-left:100px;">
				<span style="color:white;vertical-align: middle;font-size:20px;" class="bigSize">{{ $t("贵宾级别") }}</span>

			</div>
			<div style="margin-left:430px;">
				<span style="color: white;vertical-align: middle;font-size:20px;" class="bigSize">{{ $t("特权奖励") }}</span>

			</div>
		</div>
		<div
			style="width: 100%;padding-top:23px ;margin:27px 21px;background-color:#232733;border-radius:8px;padding-bottom:28px;">
			<div class="flexs_r_c">
				<div class="flexs_r_c" style="margin-left:76px;">
					<img v-if="configData" :src="$img('user/' + 'VIP' + configData.CurVipLevel + '.png')" alt=""
						style="width: 320px;height: 136px;">

					<div
						style="width: 154px;height: 128px;text-align: center;border: 1px solid rgba(82,90,104,0.8);margin-left: 8px;">
						<img src="@/assets/pc/user/userVip.png" alt="" style="width: 58px;margin-top: 13px;">
						<div class="whiteWord smallSize" style="margin-top: 20px;font-size:14px;">{{ $t("贵宾介绍") }}</div>
					</div>
				</div>
				<div class="flexs_r_c">
					<div
						style="width: 154px;height: 128px;text-align: center;border: 1px solid rgba(82,90,104,0.8);margin-left: 30px;">
						<img src="@/assets/pc/user/user_jiang.png" alt="" style="width: 54px;margin-top: 8px;">
						<div class="whiteWord smallSize" style="margin-top: 3px;font-size:14px;">{{ $t("升级奖励") }}</div>

						<div class="whiteWord smallSize columBtn enable" v-if="configData.VipDoneNum > 0"
							style="margin-top: 10px;" @click="getVIPScore()">{{ $t("领取") }}</div>
						<div class="whiteWord smallSize columBtn disable" v-else style="margin-top: 10px;">{{ $t("已领取") }}
						</div>
					</div>

					<div
						style="width: 154px;height: 128px;text-align: center;border: 1px solid rgba(82,90,104,0.8);margin-left: 8px;">
						<img src="@/assets/pc/user/user_gift.png" alt="" style="width: 60px;margin-top: 7px;">
						<div class="whiteWord smallSize" style="margin-top: 3px;">{{ $t("每月奖金") }}</div>

						<div class="whiteWord smallSize columBtn enable" @click="getMonthGift()"
							v-if="configData.WeekStatus === 'Done'" style="margin-top: 10px;">{{ $t("领取") }}</div>
						<div class="whiteWord smallSize columBtn disable" v-else style="margin-top: 10px;">{{ $t("已领取") }}
						</div>
					</div>
				</div>
			</div>

			<div class="level">
				<div class="flexs_sb">
					<div v-show="configData" style="font-size:20px;" class="middleSize whiteWord">{{ $t("升级") + " VIP " +
						(configData.CurVipLevel + 1) + $t("条件") }}</div>

				</div>
				<div class="smallSize" style="color: #9c9c9c;margin-top: 25px;">{{ $t("当前积分") }}</div>
				<div class="flexs_sb" style="margin-top: 8px;">
					<div class="smallSize" style="color: #ffaf13;">{{ $tools.formatNum2k(configData.CurVipBet) }}/ <span
							style="color:white;">{{ $tools.formatNum2k(configData.GoalVipBet) }}</span></div>
					<div class="smallSize" style="color: #9c9c9c;">{{ $t("还需要") }} <span style="color:white;">{{
						$tools.formatNum2k((configData.GoalVipBet -
							configData.CurVipBet) > 0 ? (configData.GoalVipBet - configData.CurVipBet) : 0) }}</span></div>

				</div>
				<div
					style="width: 100%;height: 5px;background-color: black;border-radius: 2.5px;margin-top: 5px;overflow: hidden;">
					<div style="height: 5px;background-color: #f2b85d;" :style="progressWidth"></div>
				</div>

				<div class="smallSize" style="color: #9c9c9c;margin-top: 8px;">{{ $t("升级押金") }}</div>

				<div class="flexs_sb" style="margin-top: 8px;">
					<div class="smallSize" style="color: #ffaf13;">{{ $tools.formatNum2k(configData.CurVipCharge) }}/ <span
							style="color:white;">{{ $tools.formatNum2k(configData.GoalVipCharge) }}</span></div>
					<div class="smallSize" style="color: #9c9c9c;">{{ $t("还需要") }} <span style="color:white;">{{
						$tools.formatNum2k((configData.GoalVipCharge -
							configData.CurVipCharge) > 0 ? (configData.GoalVipCharge - configData.CurVipCharge) : 0)
					}}</span>
					</div>

				</div>
				<div
					style="width: 100%;height: 5px;background-color: black;border-radius: 2.5px;margin-top: 5px;overflow: hidden;">
					<div style="height: 5px;background-color: #f2b85d;" :style="progressWidth2"></div>
				</div>
			</div>
		</div>







		<div style="margin:20px 21px;border-radius:8px;" v-if="recordData.length">
			<div class="flexs_r_c">
				<div v-for="item in headerArr.slice((this.page - 1) * 8, this.page * 8)  "
					class="header middleSize boldSize" style="width: 25%;">{{ item }}</div>
			</div>
			<div class="flexs_r_c" style="background-color:#1e242c;" v-for="item in recordData">
				<div class="header middleSize">{{ $tools.formatDate(item.CreateAt, "yyyy-MM-dd") }}
					{{ $tools.formatDate(item.CreateAt, "hh:mm") }}</div>
				<div class="header middleSize">
					<div v-if="item.ActivityID == 'Week'">
						{{ $t('每月奖金') }}

					</div>
					<div v-else>
						{{ $t('升级奖励') }}

					</div>

				</div>
				<div class="header middleSize">
					{{ $tools.formatNum(item.Get) }}
				</div>
				<div class="header middleSize">
					{{ item.Level }}
				</div>
			</div>
		</div>
		<el-pagination style="margin-top: 10px;" v-if="recordData.length / 8.0 > 1" :page-size="8"
			@current-change='currentChange' @size-change="changeSizeHandler" :current-page="page" layout="prev, pager, next"
			:total="recordData.length">
		</el-pagination>


	</div>
</template>

<script>
import {
	mapGetters,
	mapActions,
	mapMutations
} from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import { bus } from "@/components/core/bus";
import i18n from "@/language/i18n";

import {
	req,
	qs
} from "@/utils/request";
import {
	topic
} from "@/components/protocol/api";
export default {
	components: {

	},
	data() {
		return {
			selectIndex: 0,
			progressWidth: {
				width: "50%"
			},
			progressWidth2: {
				width: "50%"
			},
			endDate: '',
			startDate: '',
			page: 1,
			configData: '',
			recordData: [],
			headerArr: [this.$t('时间'), this.$t('类型'), this.$t('金额'), this.$t('等级')]
		}
	},
	computed: {
		...mapGetters("tcp", ["isLogged"]),

	},

	created() {
		var that = this
		if (this.isLogged) {
			this.getRecord()
			this.getVipConfig()
		} else {
			bus.on('onConnected', () => {
				that.getRecord()
				that.getVipConfig()
			})
		}

		bus.off('receiveGift')
		bus.on('receiveGift', (val) => {
			//console.log('555',val)
			if (val.data.Code == 0) {
				that.getRecord()
				that.getVipConfig()
				that.$message({
					message: that.$t('领取成功') + '!',
					type: 'success'
				});
			} else {
				that.$message({
					message: val.data.ErrMsg,
					type: 'error'
				});
			}
		})
		bus.off('receiveGift2')
		bus.on('receiveGift2', (val) => {
			//console.log('666',val)
			if (val.data.Code == 0) {
				that.getRecord()
				that.getVipConfig()
				that.$message({
					message: that.$t('领取成功') + '!',
					type: 'success'
				});
			} else {
				that.$message({
					message: val.data.ErrMsg,
					type: 'error'
				});
			}
		})

	},
	methods: {
		currentChange(e) {
			this.page = e
		},
		getRecord() {

			var that = this

			mqant.request(topic.getGiftRecord, {}, function (data, topicName, msg) {
				//console.log(11111,data)
				that.recordData = data.Data
			});
		},
		getVIPScore() {
			var that = this

			mqant.request(topic.getVIPScore, {}, function (data, topicName, msg) {
				that.getVipConfig()
			});
		},
		getMonthGift() {
			var that = this

			mqant.request(topic.getMonthGift, { 'level': this.configData.CurVipLevel, 'language': i18n.global.locale }, function (data, topicName, msg) {

			});
		},
		getVipConfig() {
			var that = this

			mqant.request(topic.getVIPConfig, {}, function (data, topicName, msg) {

				that.configData = data.Data
				console.log('fff', data)
				if (that.configData.CurVipBet > that.configData.GoalVipBet) {
					that.progressWidth.width = '100%'

				} else {
					that.progressWidth.width = that.configData.CurVipBet / parseFloat(that.configData.GoalVipBet) * 100 + '%'
				}
				if (that.configData.CurVipCharge > that.configData.GoalVipCharge) {
					that.progressWidth2.width = '100%'

				} else {
					that.progressWidth2.width = that.configData.CurVipCharge / parseFloat(that.configData.GoalVipCharge) * 100 + '%'
					console.log('ggg', that.progressWidth2)
				}
			});
		},
		endDateChange(val) {
			this.endDate = this.dateFormat(val)
		},
		dateChange(val) {
			this.startDate = this.dateFormat(val)
		},

	},

};
</script>

<style scoped lang="scss">
.columBtn {
	margin: 0 auto;
	margin-top: 5px;
	color: white;
	border-radius: 2px;
	height: 23px;
	line-height: 23px;
	text-align: center;
	width: 133px;

}

.level {
	width: 483px;

	border-radius: 4px;
	background-color: #292e3b;
	margin-top: 16px;
	padding: 10px;
	padding-bottom: 23px;
	margin-left: 76px;
}

.select {
	background-color: #fdb000;
	color: white;
}

.btn {
	padding: 3px 30px;
	margin-right: 10px;
	border-radius: 4px;
}

.nolmal {
	background-color: #323232;
	color: #c6c6c6;
}

.enable {
	background: linear-gradient(to right, #5063ff, #6b4cff);
	cursor: pointer;
}

.disable {
	background-color: #363d4e;
}

.header {
	text-align: center;
	width: 25%;
	color: white;
	height: 35px;
	line-height: 35px;
	background-color: #363d4e;
}
</style>