<template>
  <div class="historyRecord" v-if="historyObj.list.length > 0 || showHistoryRecord">
    <div :class="isShow ? 'show-list' : 'hide-list'" @click="changeState()"></div>
    <div class="content" :class="isShow ? 'set-height' : ''">
      <swiper :slides-per-view="5" :slides-per-group="5" :autoplay="{ delay: 5000, disableOnInteraction: false }"
        :space-between="8">
        <swiper-slide v-for="(item, index) in historyObj.list" :key="item" @click="itemClick(item)">
          <div class="scroll-item" v-show="isShow">
            <img :src="getImg(item)" alt="">
            <span>{{ item.GameName }}</span>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { mqant } from "@/components/protocol/mqantlib";
import { Dialog } from "vant";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from 'swiper'
import "swiper/swiper.css";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
let modules = reactive([Autoplay]);
let isShow = ref(false);
let historyObj = reactive({
  list: getHistoryList(),
})
let showHistoryRecord = ref(false);
bus.on(bus.event.showHistoryRecord, (bool) => {
  showHistoryRecord.value = bool;
})
function changeState() {
  historyObj.list = getHistoryList();
  isShow.value = !isShow.value;
}
function getHistoryList() {
  let list = [];
  if (localStorage.getItem('history')) {
    list = JSON.parse(localStorage.getItem('history'));
  }
  return list;
}
function language() {
  return i18n.global.locale;
}
function getImg(item) {
  let str = lobbyInfo.value.HotDomain + '/gameIcon2' + '/' + item.FactoryName + '/' + language() + '/' + item.GameCode + '.png';
  return str;
}
function itemClick(item) {
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  let url = getApiLoginUrl(item.FactoryName, item.GameCode);
  $act.openPage(url);
}
</script>

<style lang="scss" scoped>
.historyRecord {
  position: fixed;
  width: 100%;
  bottom: 50px;
  z-index: 4;

  .show-list {
    position: absolute;
    right: 10px;
    top: -24px;
    width: 100px;
    height: 24px;
    background: url('@/assets/mobile/historyRecord/xiala.png');
    background-size: 100% 100%;
  }

  .hide-list {
    position: absolute;
    right: 10px;
    top: -24px;
    width: 100px;
    height: 24px;
    background: url('@/assets/mobile/historyRecord/shangla.png');
    background-size: 100% 100%;
  }

  .set-height {
    height: 88px !important;
    padding: 8px 5px;
  }

  .content {
    height: 0px;
    background: url('@/assets/mobile/historyRecord/game_list_history_bg.png');
    background-size: 100% 100%;
    transition: all 0.3s;

    .scroll-item {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }

      span {
        width: 100%;
        font-size: 3.2vw;
        text-align: center;
        color: #fcde8d;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}
</style>
