<template>
		<div @click.stop=""
			style="border-radius: 10px;position: absolute;width: 380px;padding-bottom:10px;right:10px;bottom:10px; background-color: white;border-radius:10px;z-index:100002">
			<div class="flexs_sb" style="height:55px;border-bottom:1px solid rgba(144, 162, 220, .4);padding:0 15px;">
				<div class="flexs_r_c">
					<img src="@/assets/pc/lottery/liwu.png" alt="" style="width: 40px;">
					<div style="font-size: 18px;margin-left: 10px;">Thông báo trúng thưởng</div>

				</div>

				<img src="@/assets/pc/lottery/close-1.png" alt="" @click="cancel()" class="canClick"
					style="width: 18px;">

			</div>
			<div style="margin-top:15px;font-size:14px;text-align:center;">{{getName()}}&nbsp;-&nbsp;{{rewardData.Number}}</div>
			<div style="border:1px solid #000;margin: 15px 20px;">
				<div class="flexs_r_c">
					<div v-for="(item,index) in title" :style="index==2?'border-right:none':''" class="cell two">{{item}}</div>
				</div>
				<div class="flexs_r_c">
					<div class="cell one" style="border-bottom:none;">
						{{getSubName()}}</div>
					<div class="cell two" style="border-bottom:none;">{{dealWord(rewardData.Code)}}</div>
					<div class="cell two"  style="border-right:none;border-bottom:none;color:green;">{{rewardData.Sprofit}}</div>
				</div>
			</div>
		</div>


</template>

<script>
	import { bus, busName } from "@/components/core/bus";
	import { mapGetters, mapActions, mapMutations } from "vuex";

	export default {
		data() {
			return {
				title: ['Tên kiểu cược','Đặt cược','Tiền thưởng'],
			}
		},
		props: {
			rewardData:null

		},
		computed: {
    ...mapGetters("tcp_lottery", ["Lotteries", "Plays", "Timestamp"]),
  },
		created() {
		},
		methods: {
			 getName() {
      for (var key in this.Lotteries) {
        for (var obj of this.Lotteries[key]) {
          if (obj.LotteryCode == this.rewardData.LotteryCode) {
            return obj.LotteryName;
          }
        }
      }
    },

	dealWord(item) {
      if (item == "big") {
        return "Tài";
      } else if (item == "small") {
        return "Xỉu";
      } else if (item == "single") {
        return "Lẻ";
      } else if (item == "pair") {
        return "Chẵn";
      } else if (item == "up") {
        return "Trên";
      } else if (item == "draw") {
        return "Hoà";
      } else if (item == "down") {
        return "Dưới";
      } else if (item == "jin") {
        return "Kim";
      } else if (item == "mu") {
        return "Mộc";
      } else if (item == "shui") {
        return "Thủy";
      } else if (item == "huo") {
        return "Hỏa";
      } else if (item == "tu") {
        return "Thổ";
      } else if (item == "bigSingle") {
        return "Tài Lẻ";
      } else if (item == "smallSingle") {
        return "Xỉu Lẻ";
      } else if (item == "bigPair") {
        return "Tài Chẵn";
      } else if (item == "smallPair") {
        return "Xỉu Chẵn";
      } else {
        return item;
      }
    },
	getSubName(){
		if(this.rewardData.PlayCode=="FUNNY"){
			for(var key in this.Plays){
			var arr = this.Plays[key]
			for(var obj of arr){
				if(obj.PlayCode==this.rewardData.PlayCode){
					
					var arr2 = obj.SubPlays
					for(var  obj2 of arr2){
						if(obj2.SubPlayCode==this.rewardData.SubPlayCode){
							return "[Lô 2 Số Giải ĐB]"+obj2.SubName
						}
					}
				}
			}
		}
		
			
		}
		var str = ''
		for(var key in this.Plays){
			var arr = this.Plays[key]
			for(var obj of arr){
				if(obj.PlayCode==this.rewardData.PlayCode){
					
					var arr2 = obj.SubPlays
					for(var  obj2 of arr2){
						if(obj2.SubPlayCode==this.rewardData.SubPlayCode){
							str += ('['+obj2.SubName+']')+'Kèo đôi'
							return str
						}
					}
				}
			}
		}
	},
			cancel() {
				this.$emit('close')
			},
			checkNum(data) {

				if (data) {

					return data = this.formatNum(Number(data.replace(/[^\d.%\\]/g, '')))

				}

			},
			sure(){
				var subStr = (this.defaultNum+'' ).substr(this.defaultNum.length-2,2)
				//console.log('xx',subStr)
				for(var i=0;i<subStr.length;i++){
					if(parseInt(subStr[i])!=0){
						this.$message({
							message: 'Số tiền đặt cược bắt buộc phải là bôi số của 100, ví dụ 1,000, 1,500',
							type: 'info'
						});
						return
					}
				}
				bus.emit('defaultNum',this.defaultNum)
					localStorage.setItem('defaultNum',this.defaultNum)
					this.cancel()
			},
			formatNum(num) {

				var numeral = require('numeral');
				return numeral(num).format('0,000');
			},

		}
	}
</script>

<style scoped lang="scss">
	.btn{
		width: 137px;
		height: 43px;
		text-align: center;
		line-height: 43px;
		font-size: 14.5px;
		border-radius: 5px;
	}
	.left{
		border: 1px solid black;
		color: black;
	}
	.right{
		background-image: linear-gradient(90deg,#7146ff,#4a69ff);
		color: white;
	}
	.cell{
		width:33.33%;
		border-right:1px solid #000;
		border-bottom:1px solid #000;
		text-align:center;
		padding:15px 0;
		font-size:14px;
	
	}
	
</style>
