<template>
  <div class="redeemCodeView">
    <div class="card">
      <div class="input-class">
        <van-field v-model="obj.code" type="text" placeholder="Vui lòng nhập mã quà tặng" :border="false" />
      </div>
    </div>
    <div class="card">
      <div class="pay-btn" @click="onSubmit">
        <span> XÁC NHẬN </span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch, defineProps } from "vue";
import { Toast } from "vant";
import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const loading = ref(true);
const props = defineProps({
  data: {}
})
let showPicker = ref(false);
let obj = reactive({
  code: '',
})
bus.off('giftCodeResult')
bus.on('giftCodeResult', (val) => {
  if (val.data.Code == 0) {
    obj.code = ''
    Toast.success(val.data.ErrMsg)
  } else {
    Toast.fail(val.data.ErrMsg);
  }
})

function onSubmit() {
  if (!obj.code) {
    Toast.fail('Vui lòng nhập mã đổi');
    return;
  }
  let objSubmit = {
    code: obj.code
  }
  log.info('objSubmit', objSubmit)
  mqant.request(topic.giftCode, objSubmit, function (data, topicName, msg) {
    tools.dismissLoading();
  })
}
</script>

<style lang="scss" scoped>
.redeemCodeView {
  .card {
    padding: 4.266667vw 0 0;
    display: table;
    width: 100%;

    .channelTitle {
      height: 4.8vw;
      font-family: PingFangSC-Regular;
      font-size: 3.466667vw;
      color: #fff;
      font-weight: 400;
      margin-bottom: 2.666667vw;
    }

    :deep(.van-cell) {
      background-color: #1e2646;
      border-radius: 8px;
      font-size: 12px;
      --van-field-label-color: #424f81;
      --van-field-input-text-color: #fff;
    }
  }

  .less-pay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 21px;
    font-size: 12px;
    width: 100%;
    height: 82px;
    padding: 21px 12px;
    background-color: #2f395f;
    border-radius: 8px;

    >span {
      color: #fff;
    }

    span+span {
      color: #8a9fdc;
    }
  }

  .select-bank {
    width: 100%;
    height: 49px;
    padding: 0 16px;
    background-color: #1e2646;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .span-1 {
      font-size: 12px;
      color: #424f81;
    }

    .span-2 {
      font-size: 12px;
      color: #ffffff;
    }

    img {
      height: 11px;
    }
  }

  .bank-info {
    margin-top: 12px;
    background-color: #2f395f;
    border-radius: 5px;
    height: 167px;
    padding: 13px 17px;

    .bank-info-item {
      height: 30px;
      border-bottom: solid 2px #434f78;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 11px;

      span {
        font-size: 13px;
        color: #8a9fdc
      }

      img {
        width: 17px;
        height: 17px;
      }
    }

    .bank-tips {
      margin-top: 12px;
      font-size: 10px;
      color: #ee0a24;
    }
  }

  .input-class {
    margin-top: 30px;

    .input-money {
      margin: 9px;
      font-size: 10px;
      color: #e9cfa4;
    }
  }

  .pay-btn {
    position: relative;
    height: 49px;
    background-image: linear-gradient(90deg,
        #6e4aff 17%,
        #5064ff 100%),
      linear-gradient(#0e1525,
        #0e1525);
    background-blend-mode: normal,
      normal;
    border-radius: 25px;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 14px;
    }
  }
}
</style>
