import Fingerprint from "fingerprintjs2";
import log from "@/utils/logger";
import { http, topic } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import tools from "@/utils/tools";
import { bus, busName } from "@/components/core/bus";
import native from "@/components/core/native.js";
import {theme} from '@/views/theme'

export const chat = {
  namespaced: true,
  state: {
    maxNum:100,
    list:[],
    pinnedList:[],//置顶列表
    redBag:{
      show:false,
      money:0,//抢到了多少钱
      state:0,//0:已抢完，1:已抢过，2:可以抢  
      data:{
        Oid:''
      }
    },
    redDot:{
      num:0,//数量
      state:true,//是否显示红点
    }
  },
  mutations: {
    setList(state,list){
      state.list = list;
      bus.emit(bus.event.chatListPush);
      if(state.redDot.state){
        state.redDot.num++;
      }
    },
    setRedDot(state,bool){
      state.redDot.state = bool;
      if(!bool){
        state.redDot.num = 0;
      }
    },
    setPinnedList(state,list){
      state.pinnedList = list;
    },
    setRedShow(state,bool){
      state.redBag.show = bool;
      if(bool){
        bus.emit(bus.event.chatNewRedbag);
      }
    },
    setRedState(state,_state){
      state.redBag.state = _state;
    },
    setRedBag(state,data){
      state.redBag.data = data;
    }
  },
  actions: {
    dealList(context,data){
      if(data.appendInfo ){
        //该字段特殊处理一下
        data.appendInfo = JSON.parse(data.appendInfo);
        if(data.msgType == 'MSG_LOTTERY_PLAY_RECOMMEND'){
          //该类型加一个剩余倒计时，方便响应式
          let nowTime = new Date().getTime();
          let openTime = new Date(data.appendInfo.OpenTime).getTime()
          data.appendInfo['lessTime'] = openTime - nowTime;
        }
      }
      //红包消息和其他显示消息
      if(data.msgType == 'MSG_REDEVNELOP' || data.msgType == 'MSG_REDEVNELOP_UPDATE'){
        if(context.state.redBag.data.Oid !== data.Oid){
          context.commit('setRedShow',true);//显示红包
        }
        let state = 0;
        context.state.redBag.data = data;
        if(data.appendInfo.state == 0){
          state= 0;
          context.commit('setRedShow',false);
        }else if(data.appendInfo.state == 1){//是否可以抢，去列表中查找
          state= 2;
          let isLogged = context.rootGetters['tcp/isLogged'];
          if(isLogged){
            let userOid = context.rootGetters['user/userInfo'].Oid;
            for(let key in  data.appendInfo.robUsers){
              if(userOid == data.appendInfo.robUsers[key]){
                //存在则表示抢过了
                state= 1;
                context.commit('setRedShow',false);
                break;
              }
            }
          }
        }
        context.commit('setRedState',state);
        context.commit('setRedBag',data);
      }else{
        //置顶列表
        if(data.appendInfo && data.appendInfo.isTop){
          context.state.pinnedList.push(data);
          if(context.state.pinnedList.length >10){
            context.state.pinnedList.splice(0,context.state.pinnedList.length - 10);
          }
          context.commit('setPinnedList',context.state.pinnedList);
        }
        //显示消息
        context.state.list.push(data);
        if(context.state.list.length >context.state.maxNum){
          context.state.list.splice(0,context.state.list.length - context.state.maxNum);
        }
        context.commit('setList',context.state.list);
      }
    },
    setRedBagState(context,data){
      let state = 0;
      data = context.state.redBag.data;
      if(!data || (data.appendInfo==null || data.appendInfo==undefined)) return;
      if(data.appendInfo.state == 0){
        state= 0;
        context.commit('setRedShow',false);
      }else if(data.appendInfo.state == 1){//是否可以抢，去列表中查找
        state= 2;
        let isLogged = context.rootGetters['tcp/isLogged'];
        if(isLogged){
          let userOid = context.rootGetters['user/userInfo'].Oid;
          for(let key in  data.appendInfo.robUsers){
            if(userOid == data.appendInfo.robUsers[key]){
              //存在则表示抢过了
              state= 1;
              context.commit('setRedShow',false);
              break;
            }
          }
        }
      }
      context.commit('setRedState',state);
    },
    setMyList(context,data){
      let curRedBag = context.state.redBag;
      if(!curRedBag.data) return;
      if(data.msgId !== curRedBag.data.msgId) return;
      if(data.appendInfo.code== 0){
        //成功则保存抢到的钱
        context.state.redBag.money = data.appendInfo.vndBalance;
      }
    },
    setHistory(context,list){
      let newlist = list.reverse();
      if(newlist.length >context.state.maxNum){
        newlist.splice(0,newlist.list.length - context.state.maxNum);
      }
      context.state.list = newlist;
      context.commit('setList',context.state.list);
    }
  },
  getters: {
    chatList(state){
      return state.list;
    },
    pinnedList(state){
      return state.pinnedList;
    },
    redBag(state){
      return state.redBag;
    },
    redDot(state){
      return state.redDot
    }
  }
}
