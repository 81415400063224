<template>
  <div style="overflow:hidden;">
    <topHeader title="Lịch sử mở thưởng" :showRight="false"></topHeader>
    <div class="day-selector-wp">
      <div class="day-wp" @click="showPicker(0)">
        <span class="iconfont icon-icon_calendar"></span>
        {{ startDate }}
        <span class="iconfont icon-icon_drop_down_solid"></span>
      </div>
      -
      <div class="day-wp" @click="showPicker(1)">
        <span class="iconfont icon-icon_calendar"></span>
        {{ endDate }}
        <span class="iconfont icon-icon_drop_down_solid"></span>
      </div>
      <div class="search" @click="getRecord()">Tìm kiếm</div>
    </div>
    <div class="days-picker">
      <van-datetime-picker confirm-button-text="Xác nhận" cancel-button-text="Huỷ" @cancel="showChooseDate = false"
        v-model="curDate" :max-date="maxDate" v-if="showChooseDate" @confirm="timeSure" type="date" />
    </div>
    <div class="lottery-selector-wp">
      <div class="tit">Chọn trò chơi</div>
      <div class="lottery-selector" v-if="curLo" @click="showChooseLo = true">
        {{ curLo.LotteryName }}
        <span class="iconfont icon-icon_more_blue_16"></span>
      </div>
    </div>
    <div class="chenageTab" style="" v-if="curLo && curLo.AreaCode == 'North'">
      <div class="issue">kỳ/Ngày</div>
      <div class="btn" @click="selectIndex = index" :class="selectIndex == index ? 'active' : ''"
        v-for="(item, index) in northFuc">
        {{ item }}
      </div>
    </div>
    <div class="results-list-wp">
      <div class="wrapper-content">
        <pull-refresh @refresh="refresh" v-if="recordData.length">
          <div @click="showInfo(item)" class="results-item-wp" v-for="(item, index) in recordData">
            <div class="time">
              <p>{{ item.Number }}</p>
              <p>{{ $tools.formatDate(item.OpenTime, "yyyy/MM/dd hh:mm:ss") }}</p>
            </div>
            <div class="balls-wp" v-if="selectIndex == 0 && curLo.AreaCode == 'Vip'">

              <div class="tie">{{ getCurrentType(item.OpenCode.L0, 1) }}</div>
              <div class="vipTag">{{ getCurrentType(item.OpenCode.L0, 1) >= 811 ? 'Tài' : 'Xỉu' }}</div>
              <div class="vipTag">{{ getCurrentType(item.OpenCode.L0, 1) % 2 == 0 ? 'Chẵn' : 'Lẻ' }}</div>
              <div class="vipTag" v-if="getCurrentType(item.OpenCode.L0, 2) == 'top'">Trên</div>
              <div class="vipTag" v-else-if="getCurrentType(item.OpenCode.L0, 2) == 'center'">Hoà</div>
              <div class="vipTag" v-else>Dưới</div>

              <div class="vipTag" v-if="getCurrentType(item.OpenCode.L0, 3) == 'jin'">Kim</div>
              <div class="vipTag" v-else-if="getCurrentType(item.OpenCode.L0, 3) == 'mu'">Mộc</div>
              <div class="vipTag" v-else-if="getCurrentType(item.OpenCode.L0, 3) == 'shui'">Thủy</div>

              <div class="vipTag" v-else-if="getCurrentType(item.OpenCode.L0, 3) == 'huo'">Hoà</div>

              <div class="vipTag" v-else>Thổ</div>





            </div>
            <div class="balls-wp" v-if="selectIndex == 0 && curLo.AreaCode != 'Vip'">
              <div class="ball" v-for="(item1, index1) in item.OpenCode.L0[0].length" :key="item1">
                <span>{{ item.OpenCode.L0[0].substr(index1, 1) }}</span>
              </div>
            </div>
            <div class="balls-wp" v-if="selectIndex == 1">
              <div class="tag" :class="parseInt(item.OpenCode.L0[0].substr(index1, 1)) > 5 ? 'redTag' : 'blueTag'"
                v-for="(item1, index1) in item.OpenCode.L0[0].length" :key="item1">
                {{ parseInt(item.OpenCode.L0[0].substr(index1, 1)) > 5 ? 'Tài' : 'Xỉu' }}
              </div>

            </div>
            <div class="balls-wp" v-if="selectIndex == 2">
              <div class="tag" :class="parseInt(item.OpenCode.L0[0].substr(index1, 1)) % 2 == 0 ? 'blueTag' : 'redTag'"
                v-for="(item1, index1) in item.OpenCode.L0[0].length">
                {{ parseInt(item.OpenCode.L0[0].substr(index1, 1)) % 2 == 0 ? 'Chẵn' : 'Lẻ' }}
              </div>

            </div>
            <div class="balls-wp" v-if="selectIndex == 3">
              <div class="number tag">{{ getSum(item.OpenCode.L0[0]) }}</div>
              <div class="tag" :class="getSum(item.OpenCode.L0[0]) > 10 ? 'redTag' : 'blueTag'">
                {{ getSum(item.OpenCode.L0[0]) > 10 ? 'Tài' : 'Xỉu' }}
              </div>

              <div class="tag" :class="getSum(item.OpenCode.L0[0]) % 2 == 0 ? 'blueTag' : 'redTag'">
                {{ getSum(item.OpenCode.L0[0]) % 2 == 0 ? 'Chẵn' : 'Lẻ' }}
              </div>
            </div>

          </div>
        </pull-refresh>
      </div>
    </div>

    <div class="van-overlay" v-show="showChooseLo" @click="showChooseLo = false">
      <div class="overlay-wrapper lottery-type-list" @click.stop="wrapClick()">
        <div class="title">
          Chọn xổ số
          <span class="overlay-xbtn iconfont icon-icon_close_white" @click="showChooseLo = false"></span>
        </div>
        <div class="panel">
          <div v-for="(item, index) in allLottery" @click="shooseEvent(item)" :key="item" role="radio" tabindex="0"
            aria-checked="true" class="panel-item van-radio">
            <div v-if="curLo == item" class="van-radio__icon van-radio__icon--round van-radio__icon--checked">
              <span class="radio-style iconfont icon-icon_radio_button_sel1"></span>
            </div>
            <div v-else class="van-radio__icon van-radio__icon--round">
              <span class="radio-style iconfont icon-icon_radio_button_nor"></span>
            </div>
            <span class="van-radio__label"> {{ item.LotteryName }} </span>
          </div>
        </div>
      </div>
    </div>

    <rewardHistory :recordData="selectRecord" :type="curLo.AreaCode" @close="showRecordInfo = false"
      v-if="showRecordInfo"></rewardHistory>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";
import moment from "moment";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mqant_lottery } from "@/components/protocol/mqantlib";
import { http, topic } from "@/components/protocol/api";
import PullRefresh from "./lottery/pull-refresh.vue";
import rewardHistory from "./lottery/rewardHistory.vue";

export default {
  data() {
    return {
      startDate: "",
      endDate: "",
      LotteryCode: "",
      showChooseDate: false,
      chooseType: null,
      maxDate: new Date(),
      curDate: "",
      startDate: "",
      endDate: "",
      curLo: null,
      selectIndex: 0,
      northFuc: ["Số", "Tài xỉu", "Chẵn lẻ", "Tổng"],
      recordData: [],
      showChooseLo: false,
      allLottery: [],
      pageSize: 1,
      showRecordInfo: false,
      selectRecord: null,
      keyArr: ['North', 'South', 'Central', 'Vip']
    };
  },
  components: { topHeader, PullRefresh, rewardHistory },
  props: {},
  created() {
    if (this.$route.query.LotteryCode) {
      this.LotteryCode = this.$route.query.LotteryCode;
    } else {
      this.LotteryCode = 'mien-bac'


    }

    var date = moment();
    this.startDate = date.subtract(6, "days").format("YYYY/MM/DD");
    this.endDate = moment(moment().valueOf()).format("YYYY/MM/DD");
    for (var key of this.keyArr) {
      var arr = this.Lotteries[key];
      for (var obj of arr) {
        this.allLottery.push(obj);
        if (obj.LotteryCode == this.LotteryCode) {
          this.curLo = obj;
          this.getRecord();
        }
      }
    }
  },
  computed: {
    ...mapGetters("tcp_lottery", [
      "Lotteries",
      "Plays",
      "Timestamp",
    ]),
  },
  methods: {
    getSum(code) {
      var sum = 0
      for (var i = 0; i < code.length; i++) {
        var num = parseInt(code.substr(i, 1))
        sum += num
      }
      return sum

    },
    getCurrentType(val, type) {
      var total = 0;
      var arr = val
      var topCount = 0;
      var bottomCount = 0;

      for (var num of arr) {
        total += parseInt(num);
        if (num > 0 && num < 41) {
          topCount++;
        } else if (num >= 41) {
          bottomCount++;
        }
      }

      if (type == 2) {
        if (topCount > 10) {
          return "top";
        } else if (bottomCount > 10) {
          return "bottom";
        } else {
          return "center";
        }
      } else if (type == 3) {
        if (total >= 210 && total <= 695) {
          return "jin";
        } else if (total >= 696 && total <= 763) {
          return "mu";
        } else if (total >= 764 && total <= 855) {
          return "shui";
        } else if (total >= 856 && total <= 923) {
          return "huo";
        } else if (total >= 924 && total <= 1410) {
          return "tu";
        }
      } else {
        return total
      }

    },
    wrapClick() { },
    shooseEvent(val) {
      this.curLo = val;
      this.showChooseLo = false;

      this.getRecord();
    },
    showInfo(val) {
      this.selectRecord = val
      this.showRecordInfo = true
    },
    refresh() {
      this.pageSize += 1
      this.getRecord();
    },
    timeSure(val) {
      this.showChooseDate = false
      var date = moment(val);
      if (this.chooseType == 0) {

        this.startDate = moment(moment(val).valueOf()).format("YYYY/MM/DD");
      } else {
        this.endDate = moment(moment(val).valueOf()).format("YYYY/MM/DD");

      }

    },
    getRecord() {
      var obj = {
        LotteryCode: this.curLo.LotteryCode,
        limit: this.limit,
        pageSize: this.pageSize,
        startTime: this.startDate.split("/").join("-"),
        endTime: this.endDate.split("/").join("-"),
      };
      var that = this;
      that.recordData = []
      mqant_lottery.request(topic.record, obj, function (data, topicName, msg) {
        console.log("recordData", data);

        that.recordData = data.Data;
      });
    },
    showPicker(type) {
      if (type == 0) {
        this.curDate = new Date(
          this.startDate.split("/")[0],
          this.startDate.split("/")[1],
          this.startDate.split("/")[2]
        );
      } else {
        this.curDate = new Date(
          this.endDate.split("/")[0],
          this.endDate.split("/")[1],
          this.endDate.split("/")[2]
        );
      }

      this.chooseType = type;
      this.showChooseDate = true;
    },
  },
};
</script>

<style scoped lang="scss">
.day-selector-wp {
  margin-top: 50px;
  padding: 4.266667vw 4.266667vw 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 3.2vw;
  font-weight: 400;

  .search {
    width: 20.266667vw;
    height: 8vw;
    line-height: 8vw;
    background: linear-gradient(90deg, #7146ff, #4a69ff);
    border-radius: 1.6vw;
    text-align: center;
  }

  .day-wp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.6vw 0 2.666667vw;
    width: 30.933333vw;
    height: 8vw;
    line-height: 8vw;
    border: 1px solid #fff;
    border-radius: 1.066667vw;
    text-align: center;

    span {
      font-size: 2.666667vw;
    }
  }
}

.days-picker {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

.lottery-selector-wp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4.266667vw;
  height: 19.2vw;
  color: #fff;
  font-size: 3.733333vw;

  .lottery-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4.266667vw;
    width: 66.666667vw;
    height: 9.6vw;
    background: #1e2646;
    border-radius: 2.133333vw;
    font-size: 3.2vw;

    .iconfont {
      font-size: 3.2vw;
    }
  }

  .tit {
    font-size: 3.2vw;
  }
}

.chenageTab {
  display: flex;
  justify-content: space-between;
  padding-right: 4.266667vw;
  align-items: center;
  height: 10.666667vw;
  background: #222b49;

  .issue {
    font-size: 3.466667vw;
    color: hsla(0, 0%, 100%, 0.9);
    text-align: center;
    font-weight: 400;
    width: 23.466667vw;
  }

  .btn {
    width: 14.933333vw;
    height: 6.4vw;
    line-height: 6.4vw;
    background: #384369;
    border-radius: 3.2vw;
    font-size: 3.466667vw;
    color: #90a2dc;
    text-align: center;
    font-weight: 400;
  }

  .btn.active {
    background: #90a2dc;
    color: #1b233d;
  }
}

.results-list-wp {
  height: calc(100vh - 46.4vw - 50px);
  padding: 0 4.266667vw;
  overflow: hidden;

  .results-item-wp {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 15.466667vw;
    border-bottom: 1px solid rgba(144, 162, 220, 0.5019607843137255);
    padding: 1.333333vw 0;

    .balls-wp {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .ball {
        display: flex;
        flex: 1;

        span {
          border-radius: 50%;
          width: 8.533333vw;
          height: 8.533333vw;
          line-height: 8.533333vw;
          background: url("@/assets/mobile/home/result-ball.png") no-repeat 50%/100%;
          color: #292b31;
          text-align: center;
          font-size: 3.2vw;
          font-weight: 500;
        }
      }
    }

    .time {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding-right: 2.666667vw;
      line-height: 4.533333vw;
      font-size: 3.2vw;
      color: #f9f9f9;
      font-weight: 400;
      justify-content: space-around;
      flex-direction: column;

      p {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 26.666667vw;
      }
    }
  }

  .wrapper-content {
    padding-bottom: 32vw;
  }
}

.van-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);

  .overlay-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 72.266667vw;
    background: #1a223b;
    border-radius: 2.133333vw;
    overflow: hidden;

    .panel {
      height: 74.666667vw;
      overflow: scroll;

      .panel-item {
        font-size: 3.733333vw;
        font-weight: 400;
        box-shadow: inset 0 0.5px 0 0 rgba(144, 162, 220, 0.5);
        height: 10.666667vw;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        line-height: 10.666667vw;
        display: flex;
      }

      .van-radio {
        justify-content: space-around;
        flex-direction: row-reverse;

        .van-radio__label {
          width: 40vw;
          color: #fff;
          text-align: center;
          margin-left: 0;
          line-height: 5.333333vw;
        }

        .van-radio__icon {
          color: #e9cfa4;
          -webkit-box-flex: 0;
          -webkit-flex: none;
          flex: none;
          height: 1em;
          font-size: 5.333333vw;
          line-height: 1em;
          cursor: pointer;
        }
      }
    }

    .title {
      width: 100%;
      border-top-left-radius: 2.133333vw;
      border-top-right-radius: 2.133333vw;
      background: #1a223b;
      color: #fff;
      text-align: center;
      font-size: 4.266667vw;
      height: 10.666667vw;
      line-height: 10.666667vw;
      font-weight: 500;

      .overlay-xbtn {
        position: absolute;
        right: 3.733333vw;
        font-size: 3.04vw;
      }
    }
  }
}

.tag {
  width: 9.866667vw;
  height: 5.333333vw;
  line-height: 5.333333vw;
  border-radius: 0.533333vw;
  font-size: 3.2vw;
  color: #fff;
  text-align: center;
  font-weight: 400;
}

.redTag {
  background: #f94168;
}

.blueTag {
  background: #26a2ff;

}

.number {
  background: linear-gradient(136deg, #7146ff, #4a69ff);
}

.vipTag {
  width: 12.533333vw;
  height: 6.933333vw;
  line-height: 6.933333vw;
  border-radius: 0.533333vw;
  text-align: center;
  font-size: 3.2vw;
  color: #8fa1dc;
  background: #28314f;
  margin-left: 2px;
  box-shadow: inset 0 -1px 0.533333vw 0 #8e9fda, inset 0 1px 0.533333vw 0 #8d9ed9;
}

.tie {
  width: 10.666667vw;
  height: 6.933333vw;
  line-height: 6.933333vw;
  background: #28314f;
  box-shadow: inset 0 -1px 0.533333vw 0 #fff, inset 0 1px 0.533333vw 0 hsla(0, 0%, 100%, .51);
  border-radius: 1.066667vw;
  color: #fff;
  text-align: center;
  font-size: 3.733333vw;
  font-weight: 500;
}
</style>

<style >
.van-picker__toolbar {
  height: 10.666667vw;
  background-color: #1b233d;
  box-shadow: inset 0 -0.5px 0 0 rgba(144, 162, 220, 0.5);
}

.van-picker__confirm {
  color: #fff !important;
}

.van-picker__cancel {
  color: #fff !important;
}

.van-picker__mask {
  background-image: unset !important;
}

.van-picker-column__item--selected {
  color: #fff !important;
}

.days-picker .van-picker .van-picker-column__item {
  color: grey;
  background-color: #1b233d;
}

.van-ellipsis {
  font-weight: 600;
}

.van-picker {
  color: #fff;
  background: #1b233d !important;
}
</style>
