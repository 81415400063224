<template>
  <div id="pc-root" :style="'transform:scale(' +
    scale +
    ');width:' +
    100 / scale +
    '%;height:' +
    100 / scale +
    '%;'
    ">
    <Header />
    <Main />
    <Chat v-if="chatState" />
    <ServiceFloat />
    <!-- <MinigamesFloat /> -->
    <Avatar v-if="avatarState" />
    <Qmenu />
    <!-- <div class="flexs_r_c" :style="showAct ? 'width:280px;height:370px' : ''"
      style="position: fixed; top: calc(50vh - 60px); left: 50px; z-index: 10" @mouseenter="showAct = true"
      @mouseleave="showAct = false">
      <div style="position: relative">
        <img class="canClick" :src="$img('home/rootActivity.gif')" alt=""
          style="width: 140px; position: absolute; left: 0px; z-index: 10"
          :style="showAct ? 'top:-50px;' : 'top:135px;'" />

        <img class="canClick" :class="showAct ? 'openZP' : 'closeZP'" @mouseenter="hoverIndex = 0" @click="toturnTable()"
          :src="$img('home/icon_zhuanpan.png')" alt="" style="width: 140px; position: absolute; left: 0px; z-index: 0" />
        <img class="canClick hoverImg" v-if="showAct && hoverIndex == 0" @mouseleave="hoverIndex = -1"
          @click="toturnTable()" :src="$img('home/icon_zhuanpan.png')" alt=""
          style="width: 140px; position: absolute; left: 0px; top: -200px" />

        <img class="canClick" @click="toJBP" @mouseenter="hoverIndex = 2" :class="showAct ? 'openJBP' : 'closeJBP'"
          :src="$img('home/icon_jubaopen.png')" alt="" style="width: 140px; left: 0px; position: absolute; z-index: 0" />

        <img class="canClick hoverImg" v-if="showAct && hoverIndex == 2" @mouseleave="hoverIndex = -1" @click="toJBP"
          :src="$img('home/icon_jubaopen.png')" alt="" style="width: 140px; left: 0px; position: absolute; top: 75px" />
      </div>
    </div> -->

    <JuBaoPen v-if="showJBP" @handleClose="showJBP = false"></JuBaoPen>
    <rewardOpen v-if="showResult" :rewardData="rewardData" @close="showResult = false"></rewardOpen>
  </div>
</template>



<script setup>
import { ref, onMounted, reactive } from "vue";
import Header from "@/views/pc/components/Header.vue";
import Main from "@/views/pc/components/Main.vue";
import Chat from "@/views/pc/components/Chat.vue";
import Qmenu from "@/views/pc/components/Qmenu.vue";
import JuBaoPen from "@/views/pc/components/JuBaoPen.vue";
import rewardOpen from "@/views/pc/components/lottery/rewardOpen.vue";

import ServiceFloat from "@/views/pc/components/ServiceFloat.vue";
import MinigamesFloat from "@/views/pc/components/MinigamesFloat.vue";
import Avatar from "@/views/pc/components/Avatar.vue";
import { bus, busName } from "@/components/core/bus";
import { mqant_lottery } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import axios from "axios"; // 引入axios

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { useRouter } from "vue-router";
import { useRoute } from "vue-router";
import log from "@/utils/logger";

const router = useRouter();
const route = useRoute();

const { isLogged } = useGetters("tcp", ["isLogged", "isConnect", "notice"]);
const { isConnect } = useGetters("tcp", ["isConnect"]);



const { connect } = useActions("tcp_lottery", ["connect"]);
const { init } = useActions("global", ["init"]);
const { autoLogin } = useActions("user", ["autoLogin"]);
const { login } = useActions("tcp_lottery", ["login"]);

const { setLotteries, setPlays, setTimestamp, setLogged } = useMutations(
  "tcp_lottery",
  ["setLotteries", "setPlays", "setTimestamp", "setLogged"]
);

let showAct = ref(false);
let showJBP = ref(false);
let showResult = ref(false)
let hoverIndex = ref(-1);
let rewardData = ref(null);

let scale = ref(1);
let avatarState = ref(false);
let chatState = ref(false);

onMounted(() => {
  let deviceWidth = window.outerWidth;
  scale.value = deviceWidth / 1920;
  if (scale.value <= 0.764) {
    scale.value = 0.764;
  }
});
bus.on(bus.event.avatarState, (bool) => {
  avatarState.value = bool;
});
//

bus.on(bus.event.chatState, (bool) => {
  chatState.value = bool;
})
bus.off("setLotteries");
bus.on("setLotteries", (val) => {
  // console.log('ffgggg',val.North[0].Countdown)
  setLotteries(val);
});

bus.off("lotteryConnect");
bus.on("lotteryConnect", () => {
  getLottryConfig();
});

bus.on('showJBP', (val) => {
  showJBP.value = true
})

bus.on('betResult', (val) => {
  if (val.PlayCode == "FUNNY" || val.PlayCode == "TS") {
    rewardData.value = val
    showResult.value = true
    setTimeout(() => {
      showResult.value = false
    }, 8000);
  }


})

const getLottryConfig = () => {
  var that = this;
  mqant_lottery.request(
    topic.guestGetInfo,
    {},
    function (data, topicName, msg) {
      log.info("getLottery-----", data);
      setLotteries(data.Data.Lotteries);

      bus.emit("getLottery", data.Data.Lotteries);

      setPlays(data.Data.Plays);

      var lotterys = data.Data.Lotteries;
      mqant_lottery.request(
        topic.syncTime,
        {},
        function (data, topicName, msg) {

          setTimestamp(data.Data.Timestamp);

          var CountDownTime = data.Data.CountDownTime;

          for (var key in lotterys) {
            var arr = lotterys[key];
            for (var i = 0; i < arr.length; i++) {
              var obj = arr[i];

              if (CountDownTime[obj.LotteryCode]) {
                obj.Countdown = CountDownTime[obj.LotteryCode];
              }

              arr[i] = obj;
            }

            lotterys[key] = arr;
          }
          setLotteries(lotterys);

          commit("setLotteries", lotterys);
        }
      );
    }
  );
};
</script>

<style lang="scss">
#pc-root {
  min-width: 1420px;
  transform-origin: 0 0;
  position: relative;
}

#pc-root ::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

#pc-root ::-webkit-scrollbar-thumb {
  border-radius: 6px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #3c3f43;
}

#pc-root ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  background: #282a2d;
}
</style>
