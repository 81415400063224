<template>
  <div class="footer">
    <van-tabbar v-model="tabList.active" @change="onChange">
      <van-tabbar-item v-for="(item, index) in tabList.list"
        :badge="(index == 2 && redDot.num > 0 && redDot.state) ? (redDot.num > 99 ? '99+' : redDot.num) : ''">
        <span>{{ item.lable }}</span>
        <template #icon="props">
          <img :src="props.active ? item.icon.active : item.icon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <HistoryRecord />
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { mqant } from "@/components/protocol/mqantlib";
import { Dialog } from "vant";
import HistoryRecord from "@/views/mobile/components/HistoryRecord.vue"
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { redDot } = useGetters("chat", ["redDot"]);
const router = useRouter();
const curRouter = useRoute();
let tabList = reactive({
  active: 0,
  list: [
    {
      lable: "Trò chơi",
      icon: {
        active: require('@/assets/mobile/footer/nav_zy_2.png'),
        inactive: require('@/assets/mobile/footer/nav_zy_1.png'),
      },
      router: "/",
    },
    {
      lable: "Nạp tiền",
      icon: {
        active: require('@/assets/mobile/footer/nav_cz_2.png'),
        inactive: require('@/assets/mobile/footer/nav_cz_1.png'),
      },
      router: "/recharge",
    },
    {
      lable: "Phòng chat",
      // num: '99+',
      icon: {
        active: require('@/assets/mobile/footer/nav_chat_2.png'),
        inactive: require('@/assets/mobile/footer/nav_chat_1.png'),
      },
      router: "/chat",
    },
    {
      lable: "Ưu đãi",
      icon: {
        active: require('@/assets/mobile/footer/nav_qx_2.png'),
        inactive: require('@/assets/mobile/footer/nav_qx_1.png'),
      },
      router: "/",
    },
    {
      lable: "Của tôi",
      icon: {
        active: require('@/assets/mobile/footer/nav_center_2.png'),
        inactive: require('@/assets/mobile/footer/nav_center_1.png'),
      },
      router: "/userCenter",
    },
  ],
});

const onChange = (index) => {
  localStorage.removeItem('ChargeNum')
  if (index == 0 || index == 2) {
    let routerName = tabList.list[index].router;
    if (!routerName) return;
    router.push({
      path: routerName,
      query: {},
    });
  } else {
    if (!isLogged.value) {
      router.push({ name: "LoginRegist" });
      return;
    }
    if (index == 3) {
      setTimeout(() => {
        bus.emit("jumpFromUserCenter");
      }, 50);
      router.push({ path: "/" });
      // mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
      //   if (!data.Data.DoudouPwd.length) {
      //     Dialog.confirm({
      //       title: "Xin lưu ý",
      //       message:
      //         "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
      //       confirmButtonText: 'Xác nhận',
      //       cancelButtonText: 'Hủy bỏ',
      //     })
      //       .then(() => {
      //         router.push({ path: "/transactionPwd" });
      //       })
      //       .catch(() => { });
      //   } else if (!data.Data.myDouDouBT.length) {
      //     Dialog.confirm({
      //       title: "Xin lưu ý",
      //       message: 'Cần điền thông tin ngân hàng',
      //       confirmButtonText: 'Xác nhận',
      //       cancelButtonText: 'Hủy bỏ',
      //     })
      //       .then(() => {
      //         router.push({ path: "/addBankCard" });
      //       })
      //       .catch(() => { });
      //   } else {
      //     let routerName = tabList.list[index].router;
      //     if (!routerName) return;
      //     router.push({
      //       path: routerName,
      //       query: {},
      //     });
      //   }
      // });
      return;
    } else if (index == 1) {
      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        if (!data.Data.DoudouPwd.length) {
          Dialog.confirm({
            title: "Xin lưu ý",
            message:
              "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ',
          })
            .then(() => {
              router.push({ path: "/transactionPwd" });
            })
            .catch(() => { });
        } else
          if (!data.Data.myDouDouBT.length) {
            Dialog.confirm({
              title: "Xin lưu ý",
              message: 'Cần điền thông tin ngân hàng',
              confirmButtonText: 'Xác nhận',
              cancelButtonText: 'Hủy bỏ',
            })
              .then(() => {
                router.push({ path: "/addBankCard" });
              })
              .catch(() => { });
          } else {
            let routerName = tabList.list[index].router;
            if (!routerName) return;
            router.push({
              path: routerName,
              query: {},
            });
          }
      });
      return;
    } else {
      let routerName = tabList.list[index].router;
      if (!routerName) return;
      router.push({
        path: routerName,
        query: {},
      });
    }
  };
}
//处理一下选中路由
setSelectIndex();
function setSelectIndex() {
  let routerPah = curRouter.path;
  tabList.active = Number(getActiveIndex());
  function getActiveIndex() {
    for (let key in tabList.list) {
      let info = tabList.list[key];
      if (info.router && info.router == routerPah) {
        return key;
      }
    }
  }
}
watch(() => {
  curRouter.path, setSelectIndex();
});
</script>

<style lang="scss" scoped>
.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #1b233d;
  z-index: 10;

  :deep(.van-tabbar) {
    z-index: 1;
    display: flex;
    box-sizing: content-box;
    width: 100%;
    height: 13.333333vw;
    background-color: #1b233d;



    .van-tabbar-item {
      display: flex;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      flex: 1;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      justify-content: center;
      color: rgb(144, 162, 220);
      font-size: 3.2vw;
      line-height: 1;
      cursor: pointer;
    }

    .van-tabbar-item--active {
      color: #fff !important;
      background-color: #1b233d;
    }

    .van-tabbar-item__icon {
      img {
        width: 26px;
        height: 26px;
      }
    }
  }

  :deep(.van-hairline--top-bottom:after),
  :deep(.van-hairline-unset--top-bottom:after) {
    border-width: 0px;
  }
}
</style>
