<template>
  <div class="phone">
    <div class="card">
      <p class="channelTitle">Số tiền nạp</p>
      <div class="select-bank">
        <div v-for="(item, index) in payBankList" class="select-bank-item"
          :class="index == curPaySelectIndex ? 'is-select' : ''" @click="itemClick(index)">
          <img :src="$img('userCenter/' + item.icon, 'mobile')" alt="">
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="select-money" @click="showPicker = true">
        <span class="span-1">Chọn mệnh giá</span>
        <span class="span-2">{{ $tools.formatNum(obj.curItemObj.Amount) }}</span>
        <img :src="$img('userCenter/icon_jiantou.png')" alt="">
      </div>
      <van-field class="input-bank" v-model="obj.account" type="number" placeholder="Vui lòng nhập mã thẻ cào"
        :border="false" />
      <van-field class="input-number" v-model="obj.cardNumber" placeholder="Vui lòng nhập số sê-ri" :border="false" />
      <div class="card">
        <div class="pay-btn" @click="onSubmit">
          <span> Nạp ngay </span>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="info-class">
        <div class="title">
          <div class="title-text line">SỐ TIỀN</div>
          <div class="title-text">Quy đổi</div>
        </div>
        <div v-for="(item, index) in payBankList[curPaySelectIndex].data" class="title-item"
          :class="index % 2 == 0 ? 'singel-color' : ''">
          <div class="child-item line">{{ $tools.formatNum(item.Amount) }}</div>
          <div class="child-item right-item">{{ $tools.formatNum(item.Amount - item.FeePerThousand * item.Amount / 1000.0)
          }}</div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker :columns="obj.amountList" @cancel="showPicker = false" @confirm="onConfirm"
        confirm-button-text="confirm" cancel-button-text="cancel" />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch, defineProps } from "vue";
import { Toast } from "vant";
import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const loading = ref(true);
const props = defineProps({
  data: {}
})
let showPicker = ref(false);
let obj = reactive({
  amountList: [],
  curItemObj: '',
  account: '',
  cardNumber: '',
  MethodId: ''
})
let curPaySelectIndex = ref(0);
let payBankList = reactive([
  { icon: 'yinh_VH.png', text: '', data: [] },
  { icon: 'yinh_mb.png', text: '', data: [] },
  { icon: 'yinh_fh.png', text: '', data: [] },
])
onMounted(() => {
  if (Object.keys(props.data).length <= 0) return;
  for (let i = 0; i < props.data.phoneChargeConf.length; i++) {
    let info = props.data.phoneChargeConf[i];
    if (info.Name == "VIETTEL") {
      payBankList[2].data.push(info)
    } else if (info.Name == "VINAPHONE") {
      payBankList[0].data.push(info)
    } else {
      payBankList[1].data.push(info)
    }
  }
  refreshDataByIndex();
  for (let key in props.data.payList) {
    let info = props.data.payList[key];
    if (info.MethodType == "naptudong") {
      obj.MethodId = info.MethodId;
    }
  }
})

function refreshDataByIndex() {
  //reset
  obj.amountList = [];
  obj.account = '';
  obj.cardNumber = '';
  for (let i = 0; i < payBankList[curPaySelectIndex.value].data.length; i++) {
    let info = payBankList[curPaySelectIndex.value].data[i];
    if (i == 0) {
      obj.curItemObj = info;
    }
    obj.amountList.push(info.Amount);
  }
}

function itemClick(index) {
  curPaySelectIndex.value = index;
  refreshDataByIndex();
}
function onConfirm(value) {
  for (let i = 0; i < payBankList[curPaySelectIndex.value].data.length; i++) {
    let info = payBankList[curPaySelectIndex.value].data[i];
    if (value == info.Amount) {
      obj.curItemObj = info;
      break;
    }
  }
  showPicker.value = false;
}
function onSubmit() {
  if (!obj.account) {
    Toast.fail('请输入电话卡号');
    return;
  }
  if (!obj.cardNumber) {
    Toast.fail('请输入序列号');
    return;
  }
  let objSubmit = {
    amount: obj.curItemObj.Amount,
    methodId: obj.MethodId,
    serial: obj.cardNumber+'',
    code: obj.account,
    telco: obj.curItemObj.Name,
  }
  // log.info('objSubmit', objSubmit)
  tools.loading()
  mqant.request(topic.payCharge, objSubmit, function (data, topicName, msg) {
    tools.dismissLoading()
    if (data.Code == 0) {
      obj.account = '';
      obj.cardNumber = '';
      Toast.success(data.ErrMsg)
    } else {
      Toast.fail(data.ErrMsg)
    }
  })
}
</script>

<style lang="scss" scoped>
.phone {
  .card {
    padding: 4.266667vw 0 0;
    display: table;
    width: 100%;

    .channelTitle {
      height: 4.8vw;
      font-family: PingFangSC-Regular;
      font-size: 3.466667vw;
      color: #fff;
      font-weight: 400;
      margin-bottom: 2.666667vw;
    }

    :deep(.van-cell) {
      background-color: #1e2646;
      border-radius: 8px;
      font-size: 12px;
      --van-field-label-color: #424f81;
      --van-field-input-text-color: #fff;
    }
  }

  .select-bank {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;

    .select-bank-item {
      width: 166px;
      height: 76px;
      background-color: #2f395f;
      border: solid 1px #2f395f;
      display: flex;
      border-radius: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      img {
        margin-top: 4px;
        width: 154px;
        height: 43px;
      }

      span {
        margin-bottom: 8px;
        font-size: 12px;
        color: #8a9fdc;
      }
    }

    .is-select {
      background-color: #394674;
      border: solid 1px #90a2dc;

      span {
        color: #fff;
      }
    }
  }

  .select-money {
    width: 100%;
    height: 49px;
    padding: 0 16px;
    background-color: #1e2646;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .span-1 {
      font-size: 12px;
      color: #424f81;
    }

    .span-2 {
      font-size: 12px;
      color: #ffffff;
    }

    img {
      height: 11px;
    }
  }

  .input-bank {
    margin-top: 10px;
  }

  .input-number {
    margin-top: 10px;
  }

  .pay-btn {
    position: relative;
    height: 49px;
    background-image: linear-gradient(90deg,
        #6e4aff 17%,
        #5064ff 100%),
      linear-gradient(#0e1525,
        #0e1525);
    background-blend-mode: normal,
      normal;
    border-radius: 25px;

    span {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: #ffffff;
      font-size: 14px;
    }
  }

  .info-class {
    margin-top: 25px;
    width: 346px;
    background-color: #232733;
    border-radius: 5px;
    border: solid 1px #465380;

    .line {
      border-right: 1px solid #465380;
    }

    .title {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 100%;
      height: 29px;
      background-color: #222b4a;
      display: flex;

      .title-text {
        width: 50%;
        height: 100%;
        font-size: 12px;
        color: #8a9fdc;
        text-align: center;
        line-height: 29px;
      }
    }

    .singel-color {
      background-color: #303c68 !important;
    }

    .title-item {
      width: 100%;
      display: flex;
      height: 29px;
      background-color: #374371;
      border-bottom: 1px solid #465380;



      .child-item {
        width: 50%;
        height: 100%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 29px;
      }

      .right-item {
        color: rgb(235, 195, 107);
      }
    }
  }
}
</style>
