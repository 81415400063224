<template>
  <div style="padding: 0 10px; padding-top: 50px">
    <topHeader title="Thắng thua" :showRight="false" :showBack="true"></topHeader>
    <div class="bet-summary-wp">
      <div class="logo">
        <img alt="" :src="$img('lottery/' + data.LotteryCode + '.png')" />
      </div>
      <div class="summary-wp">
        <div>
          <div class="lottery-name">{{ name }}</div>
          <div class="bet-amount">{{ $tools.formatNum2k(data.UnitBetAmount) }}</div>
        </div>
        <div class="win-loss">
          <span :class="data.SProfit > 0 ? 'win' : 'loss'">{{ $tools.formatNum2k(data.SProfit) }}</span>
        </div>
      </div>
    </div>

    <div style="
        margin-top: 15px;
        border-radius: 5px;
        background-color: #1b233d;
       
      ">
      <div class="flexs_sb colum">
        <div>Số kỳ</div>
        <div>{{ data.Number }}</div>
      </div>
      <div class="flexs_sb colum">
        <div>Trò chơi đã chọn</div>
        <div>{{ data.SubPlayName }}</div>
      </div>
      <div class="flexs_sb colum">
        <div>Tỉ lệ</div>
        <div>{{ data.Odds / 1000 }}</div>
      </div>
      <div class="flexs_sb colum" style="height: auto; padding: 5px 8px">
        <div>Thông tin cược</div>
        <div style="text-align: right; width: 230px">
          {{ dealWord(data.Code) }}
        </div>
      </div>
      <div class="flexs_sb colum">
        <div>Tiền cược</div>
        <div>
          {{ formatNum(data.UnitBetAmount) }}
        </div>
      </div>
      <div class="flexs_sb colum">
        <div>Số đơn</div>
        <div>{{ data.Code.split("-").length }}</div>
      </div>

      <div class="flexs_sb colum">
        <div>Thưởng</div>
        <div>{{ data.SProfit < 0 ? "0" : formatNum(data.SProfit) }}</div>
        </div>
        <div class="flexs_sb colum">
          <div>Chi tiết</div>
          <div class="flexs_r_c" @click="showR()">
            <div style="color: #8c8c8c; margin-right: 10px">Xem chi tiết</div>
            <img src="@/assets/mobile/lottery/arrowRight.png" alt="" style="width: 6.5px" />
          </div>
        </div>
        <div class="flexs_sb colum">
          <div>Hoàn trả</div>
          <div>0</div>
        </div>
        <div class="flexs_sb colum">
          <div>Thời gian đặt cược</div>
          <div>{{ data.OpenTime }}</div>
        </div>
        <div class="flexs_sb colum" style="border-bottom: none">
          <div>Mã giao dịch</div>
          <div>{{ data._id }}</div>
        </div>
      </div>
      <recordInfo @close="showRecordInfo = false" v-if="showRecordInfo" :betData="data.Code" :subData="playData"
        :title="getTitle()" :type="data.AreaCode" :recordData="data"></recordInfo>
    </div>
</template>

<script>
import { emitter } from "@/utils/bus.js";
import { http, topic } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";
import log from "@/utils/logger";
import mqant from "@/components/protocol/mqantlib";
import recordInfo from "@/views/mobile/components/lottery/recordInfo.vue";
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";

export default {
  data() {
    return {
      data: {},
      name: "",
      showRecordInfo: false,
      playData: null,
      selectRecord: null,
    };
  },
  components: {
    recordInfo,
    topHeader,
  },
  computed: {
    ...mapGetters("tcp_lottery", [
      "Lotteries",
      "Plays",
      "Timestamp",
    ]),
  },
  props: {
    info: {},
  },
  created() {
    //JSON.parse(this.$route.params.data)
    if (this.$route.params.data) {
      this.data = JSON.parse(this.$route.params.data);
      console.log('asd',this.data)
      this.name = this.$route.params.name;
      this.getSub();

    } else {
      this.$router.push({ name: 'home' })
    }

  },
  methods: {
    showR() {
      //data.OpenCode?showRecordInfo=true:''
      if (!this.data.OpenCode) {
        this.$tools.toast(this.$t("暂未开奖"));

        return;
      }
      this.showRecordInfo = true;
    },
    formatNum(num) {
      var numeral = require("numeral");
      return numeral(num).format("0,000");
    },
    dealWord(item) {
      if (item == "big") {
        return "Tài";
      } else if (item == "small") {
        return "Xỉu";
      } else if (item == "single") {
        return "Lẻ";
      } else if (item == "pair") {
        return "Chẵn";
      } else if (item == "up") {
        return "Trên";
      } else if (item == "draw") {
        return "Hoà";
      } else if (item == "down") {
        return "Dưới";
      } else if (item == "jin") {
        return "Kim";
      } else if (item == "mu") {
        return "Mộc";
      } else if (item == "shui") {
        return "Thủy";
      } else if (item == "huo") {
        return "Hỏa";
      } else if (item == "tu") {
        return "Thổ";
      } else if (item == "bigSingle") {
        return "Tài Lẻ";
      } else if (item == "smallSingle") {
        return "Xỉu Lẻ";
      } else if (item == "bigPair") {
        return "Tài Chẵn";
      } else if (item == "smallPair") {
        return "Xỉu Chẵn";
      } else {
        return item;
      }
    },
    getTitle() {
      return "Chi tiết " + parseInt(this.data.Number);
    },
    getSub() {
      for (var key in this.Plays) {
        for (var obj of this.Plays[key]) {
          var arr = obj.SubPlays;
          for (var lastObj of arr) {
            if (lastObj.SubName == this.data.SubPlayName) {
              this.playData = lastObj;
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.colum {
  height: 36px;
  padding: 0 8px;
  font-size: 12.5px;
  color: white;
  box-shadow: inset 0 0.5px 0 0 rgba(144, 162, 220, .5);
}

.bet-summary-wp {
  display: flex;
  margin-top: 5.333333vw;
  padding: 4.266667vw;
  background: #1b233d;
  box-shadow: 0 1.6vw 1.866667vw -0.533333vw rgba(27, 35, 61, .6);
  border-radius: 3.2vw;

  .win-loss {
    height: 10.666667vw;
    line-height: 10.666667vw;
    text-align: right;
    color: #e9cfa4;

    .win {
      color: #f93e3e;
    }

    .loss {
      color: #21e06b;
    }
  }

  .bet-amount {
    height: 4.8vw;
    line-height: 4.8vw;
    color: #fff;
    font-size: 3.733333vw;
    font-weight: 400;
  }

  .lottery-name {
    height: 5.866667vw;
    line-height: 5.866667vw;
    color: #e9cfa4;
    font-size: 3.733333vw;
    font-weight: 400;
  }

  .logo {
    width: 10.666667vw;
    height: 10.666667vw;
    border-radius: 50%;
    margin-right: 2.666667vw;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .summary-wp {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

}
</style>
