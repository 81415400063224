<template>
  <div style="overflow: hidden; position: relative">
    <topHeader title="Kết quả thắng thua" :showRight="false" :showBack="true"></topHeader>
    <div class="flexs_r_c" style="justify-content: center">
      <div class="topItem" :class="selectIndex == 0 ? 'van-tab--active' : ''" @click="topClick(0)">
        Xổ số
      </div>
      <div class="topItem" :class="selectIndex == 1 ? 'van-tab--active' : ''" @click="topClick(1)">
        Bên thứ 3
      </div>
    </div>
    <div class="bet-record-selector-wp">
      <div class="day-selector-wp" @click="chooseClick(0)">
        {{
          selectIndex == 0
          ? LoTypeArr[selectLeftIndex].name
          : lobbyInfo.FactoryList[selectLeftIndex].vi
        }}
        <span class="iconfont icon-icon_drop_down_solid"></span>
      </div>
      <div class="day-selector-wp" @click="chooseClick(1)">
        {{ timeArr[selectTimeIndex] }}
        <span class="iconfont icon-icon_drop_down_solid"></span>
      </div>
    </div>

    <div class="no-data" v-if="recordList && !recordList.length">{{ 'Không có dữ liệu' }}</div>

    <div style="overflow-y: scroll; height: calc(100vh - 48.53333vw)" v-if="recordList && recordList.length">
      <div v-if="selectIndex == 0">
        <div class="flexs_r">
          <div v-for="(item, index) in lotteryHeader" class="header" :style="'width:' + getLoWidth(index)">
            {{ item }}
          </div>
        </div>
        <div @click="toLotteryInfo(item)" v-for="(item, index) in recordList" class="flexs_r"
          :class="index % 2 == 0 ? 'single' : 'double'">
          <div class="cell" :style="'width:' + getLoWidth(0)" style="color: #90a2dc">
            {{ getName(item) }}
          </div>
          <div class="cell" :style="'width:' + getLoWidth(1)">
            {{ item.TotalAmount / item.UnitBetAmount }}
          </div>
          <div class="cell" :style="'width:' + getLoWidth(2)">
            {{ $tools.formatNum2k(item.TotalAmount) }}
          </div>
          <div class="cell" :class="item.SProfit > 0 ? 'win' : 'loss'" :style="'width:' + getLoWidth(3)">
            {{ $tools.formatNum2k(item.SProfit) }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="flexs_r">
          <div v-for="(item, index) in titleArr" class="header" :style="'width:' + getWidth(index)">
            {{ item }}
          </div>
        </div>
        <div v-for="(item, index) in recordList" class="flexs_r" :class="index % 2 == 0 ? 'single' : 'double'">
          <div class="cell" style="color: #90a2dc" :style="'width:' + getWidth(0)">
            {{ $tools.formatDate(item.create_at, "MM-dd") }}
            {{ $tools.formatDate(item.create_at, "hh:mm") }}
          </div>
          <div class="cell" :style="'width:' + getWidth(1)">
            {{ item.factory_name + "/" + item.game_type }}
          </div>
          <div class="cell" :style="'width:' + getWidth(2)">
            {{ $tools.formatNum2k(item.bet_amount) }}
          </div>
          <div class="cell" :class="item.SProfit > 0 ? 'win' : 'loss'" :style="'width:' + getWidth(3)">
            {{ $tools.formatNum2k(item.income) }}
          </div>
        </div>
      </div>
    </div>
    <div class="totalData showBottom" style="bottom: 0">
      <div class="total">
        <span>Tổng tiền cược：{{ $tools.formatNum2k(getTotol()[0]) }}</span>
      </div>
      <div class="loseOrWin">
        <span>Tổng thắng thua：{{ $tools.formatNum2k(getTotol()[1]) }}</span>
      </div>
    </div>
    <div class="van-overlay" v-show="showChooseWrap" @click="showChooseWrap = false">
      <div class="overlay-wrapper lottery-type-list" @click.stop="wrapClick()">
        <div class="title">
          {{ showType == 0 ? "Phương thức" : "Chọn thời gian" }}
          <span class="overlay-xbtn iconfont icon-icon_close_white" @click="showChooseWrap = false"></span>
        </div>
        <div class="panel">
          <div v-for="(item, index) in currentArr()" @click="shooseEvent(index)" :key="item" role="radio" tabindex="0"
            aria-checked="true" class="panel-item van-radio">
            <div v-if="(showType == 1 && selectTimeIndex == index) ||
              (showType == 0 && selectLeftIndex == index)
              " class="van-radio__icon van-radio__icon--round van-radio__icon--checked">
              <span class="radio-style iconfont icon-icon_radio_button_sel1"></span>
            </div>
            <div v-else class="van-radio__icon van-radio__icon--round">
              <span class="radio-style iconfont icon-icon_radio_button_nor"></span>
            </div>
            <span class="van-radio__label">
              {{
                showType == 1 ? item : selectIndex == 0 ? item.name : item.vi
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";
import { bus, busName } from "@/components/core/bus";
import moment from "moment";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { mqant_lottery } from "@/components/protocol/mqantlib";

export default {
  name: "",
  data() {
    return {
      selectIndex: 0,
      recordList: [],
      timeArr: ["Hôm nay", "Hôm qua", "7 ngày gần đây", "30 ngày gần đây"],
      LoTypeArr: [
        { name: "Toàn bộ", value: "" },
        { name: "Miền Bắc", value: "" },
        { name: "Miền Nam", value: "" },
        { name: "Miền Trung", value: "" },
        { name: "Keno", value: "" },
      ],
      selectTimeIndex: 2,
      selectLeftIndex: 0,
      showType: 1,
      showChooseWrap: false,
      lotteryHeader: ["Đầy màu sắc", "Số đơn", "Tiền cược", "Thắng thua"],
      titleArr: ["NGÀY", "LOẠI GAME", "CƯỢC", "THẮNG THUA"], //'MÃ CƯỢC',
    };
  },
  props: {},
  components: { topHeader },

  computed: {
    ...mapGetters("global", ["lobbyInfo"]),
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("tcp_lottery", [
      "Lotteries",
      "Plays",
      "Timestamp",
    ]),
  },
  created() {
    // bus.emit(bus.event.showFooter, false);

    if (this.isLogged) {
      this.getData();
    }
    var that = this;
    bus.on("onConnected", () => {
      that.getData();
    });
  },
  methods: {
    getTotol() {
      var num1 = 0;
      var num2 = 0;
      if (this.recordList) {
        if (this.selectIndex == 0) {
          for (var obj of this.recordList) {
            num1 += obj.TotalAmount;
            num2 += obj.SProfit;
          }
        } else {
          for (var obj of this.recordList) {
            num1 += obj.bet_amount;
            num2 += obj.income;
          }
        }
      }

      return [num1, num2];
    },

    topClick(index) {
      if (this.selectIndex != index) {
        this.selectIndex = index;
        this.selectLeftIndex = 0;
        this.selectTimeIndex = 2;
        this.recordList = [];
        this.getData();
      }
    },
    currentArr() {
      if (this.selectIndex == 0) {
        if (this.showType == 0) {
          return this.LoTypeArr;
        } else {
          return this.timeArr;
        }
      } else {
        if (this.showType == 0) {
          return this.lobbyInfo.FactoryList;
        } else {
          return this.timeArr;
        }
      }
    },
    chooseClick(type) {
      this.showType = type;
      this.showChooseWrap = true;
    },
    getName(item) {
      for (var key in this.Lotteries) {
        for (var obj of this.Lotteries[key]) {
          if (obj.LotteryCode == item.LotteryCode) {
            return obj.LotteryName;
          }
        }
      }
    },
    toLotteryInfo(val) {
      this.$router.push({
        name: "RecordInfo",
        params: {
          name: this.getName(val),
          data: JSON.stringify(val),
        },
      });
    },
    shooseEvent(index) {
      if (this.showType == 1) {
        this.selectTimeIndex = index;
      } else {
        this.selectLeftIndex = index;
      }
      this.showChooseWrap = false;
      this.getData();
    },
    getLoWidth(index) {
      if (index == 0) {
        return "40%";
      } else if (index == 1) {
        return "16%";
      } else if (index == 2) {
        return "22%";
      } else {
        return "22%";
      }
    },
    getWidth(index) {
      if (index == 0) {
        return "25%";
      } else if (index == 1) {
        return "30%";
      } else if (index == 2) {
        return "20%";
      } else {
        return "25%";
      }
    },
    getData() {
      if (this.selectIndex == 0) {
        var obj = {
          offset: 0,
          limit: 1000,
          status: "",
        };
        if (this.selectLeftIndex == 1) {
          obj.AreaCode = "North";
        } else if (this.selectLeftIndex == 2) {
          obj.AreaCode = "South";
        } else if (this.selectLeftIndex == 3) {
          obj.AreaCode = "Central";
        } else if (this.selectLeftIndex == 4) {
          obj.AreaCode = "Vip";
        }
        var date = moment();
        if (this.defaultTimeIndex == 0) {
          obj.startTime =
            moment(moment().valueOf()).format("YYYY-MM-DD") + " 00:00:00";
        } else if (this.defaultTimeIndex == 1) {
          obj.startTime =
            date.subtract(1, "days").format("YYYY-MM-DD") + " 00:00:00";
        } else if (this.defaultTimeIndex == 2) {
          obj.startTime =
            date.subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00";
        } else {
          obj.startTime =
            date.subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00";
        }
        obj.endTime =
          moment(moment().valueOf()).format("YYYY-MM-DD") + " 23:59:59";
        var that = this;
        mqant_lottery.request(
          topic.getBetRecordList,
          obj,
          function (data, topicName, msg) {
            that.recordList = data.Data.List;
          }
        );
      } else {
        var that = this;
        var obj = {
          FactoryName: this.lobbyInfo.FactoryList[this.selectLeftIndex].Factory,
          startDate: "",
          endDate: "",
        };

        var date = moment();
        if (this.defaultTimeIndex == 0) {
          obj.startDate =
            moment(moment().valueOf()).format("YYYY-MM-DD") + " 00:00:00";
        } else if (this.defaultTimeIndex == 1) {
          obj.startDate =
            date.subtract(1, "days").format("YYYY-MM-DD") + " 00:00:00";
        } else if (this.defaultTimeIndex == 2) {
          obj.startDate =
            date.subtract(7, "days").format("YYYY-MM-DD") + " 00:00:00";
        } else {
          obj.startDate =
            date.subtract(30, "days").format("YYYY-MM-DD") + " 00:00:00";
        }
        obj.endDate =
          moment(moment().valueOf()).format("YYYY-MM-DD") + " 23:59:59";
        var that = this;
        mqant.request(topic.gameRecord, obj, function (data, topicName, msg) {
          console.log("ddccc", data);

          that.recordList = data.Data.BetRecord;
        });
      }
    },
    wrapClick() { },
  },
};
</script>

<style lang="scss" scoped>
.topItem {
  border-radius: 1.6vw;
  height: 8vw !important;
  line-height: 8vw !important;
  color: #fff;
  font-size: 3.733333vw;
  width: 106px;
  text-align: center;
  background-color: rgb(47, 57, 95);
}

.van-tab--active {
  background-image: linear-gradient(135deg, #7146ff, #4a69ff);
}

.flexs_r_c {
  padding-top: 65px;
}

.bet-record-selector-wp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 4.266667vw;
  height: 11.733333vw;

  .day-selector-wp {
    font-size: 3.2vw;
    color: #fff;
    font-weight: 600;
  }
}

.van-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);

  .overlay-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 72.266667vw;
    background: #1a223b;
    border-radius: 2.133333vw;
    overflow: hidden;

    .panel {
      height: 74.666667vw;
      overflow: scroll;

      .panel-item {
        font-size: 3.733333vw;
        font-weight: 400;
        box-shadow: inset 0 0.5px 0 0 rgba(144, 162, 220, 0.5);
        height: 10.666667vw;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
        line-height: 10.666667vw;
        display: flex;
      }

      .van-radio {
        justify-content: space-around;
        flex-direction: row-reverse;

        .van-radio__label {
          width: 40vw;
          color: #fff;
          text-align: center;
          margin-left: 0;
          line-height: 5.333333vw;
        }

        .van-radio__icon {
          color: #e9cfa4;
          -webkit-box-flex: 0;
          -webkit-flex: none;
          flex: none;
          height: 1em;
          font-size: 5.333333vw;
          line-height: 1em;
          cursor: pointer;
        }
      }
    }

    .title {
      width: 100%;
      border-top-left-radius: 2.133333vw;
      border-top-right-radius: 2.133333vw;
      background: #1a223b;
      color: #fff;
      text-align: center;
      font-size: 4.266667vw;
      height: 10.666667vw;
      line-height: 10.666667vw;
      font-weight: 500;

      .overlay-xbtn {
        position: absolute;
        right: 3.733333vw;
        font-size: 3.04vw;
      }
    }
  }
}

.header {
  height: 9.6vw;
  line-height: 9.6vw;
  background: rgb(47, 57, 95);
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 14px;
}

.cell {
  height: 9.6vw;
  line-height: 9.6vw;

  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.single {
  background-color: #414e7e;
}

.double {
  background-color: #3e4a78;
}

.loss {
  color: #21e06b;
}

.win {
  color: #f93e3e;
}

.totalData {
  position: fixed;
  margin-bottom: env(safe-area-inset-bottom);
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.08);
  width: 100%;
  height: 12.8vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 3.466667vw;
  color: #e9cfa4;
  padding: 4.266667vw;
  font-weight: 400;
  background: #1a233d;
}

.no-data {
  padding-top: 6.4vw;
  line-height: 5.333333vw;
  opacity: .5;
  color: #fff;
  text-align: center;
  font-size: 3.733333vw;
  font-weight: 500;
}
</style>
