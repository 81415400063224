<template>
	<div >
		<!-- <iframe :src="url" frameborder="0" style="width:100vw;height:100vh;"></iframe> -->
    <iframe
  ref="iframe"
  id="bi_iframe"
  :src="url"
  frameborder="no"
  scrolling="auto"
  @load="adjustIframe"
/>
	</div>
</template>

<script>
	import {encryptBy,decryptBy} from '@/utils/crypto';

export default {
  components: {
   
  },
  data() {
    return {
      url:''
    };
  },
  computed: {},
  created(){

  },
  mounted() {
    this.url = decryptBy(this.$route.query.url)
    this.adjustIframe()

    window.addEventListener("orientationchange", ()=> {
        switch (screen.orientation.type) {
          case "landscape-primary":
            console.log("That looks good.");
            this.adjustIframe()
            break;
          case "landscape-secondary":
            console.log("Mmmh… the screen is upside down!");
            this.adjustIframe()

            break;
          case "portrait-secondary":
          this.adjustIframe()

          case "portrait-primary":
            console.log("Mmmh… you should rotate your device to landscape");
            this.adjustIframe()

            break;
          default:
            console.log("The orientation API isn't supported in this browser :(");
        }
      });
      




    
},
  methods: {
    adjustIframe() {
      setTimeout(() => {
        var ifm = document.getElementById("bi_iframe");
      ifm.height = document.documentElement.clientHeight;
      ifm.width = document.documentElement.clientWidth;
      }, 200);
     
    },
  },
};
</script>

<style lang="scss" scoped>

	
</style>

