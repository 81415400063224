<template>
  <div class="personalInfo">
    <div class="header">
      <div class="van-nav-bar van-nav-bar--fixed" style="z-index: 1; box-shadow: none; background-color: transparent">
        <div class="van-nav-bar__content">
          <div class="van-nav-bar__left" @click="back()">
            <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"><!----></i>
          </div>
          <div class="van-nav-bar__title van-ellipsis">Trang cá nhân</div>
        </div>
      </div>
    </div>
    <div class="personal">
      <div class="userInfo">
        <div class="avatar-container">
          <div class="avatar-container" @click="openAvatar()">
            <img class="avatar-img" :src="require('@/assets/common/avatar/' + userInfo.Avatar + '.png')" lazy="loaded" />
          </div>
        </div>
        <div class="user">
          <p class="userName">
            <span class="hi"></span>{{ userInfo.Account }}

          </p>
          <p class="joinDay">Gia nhập ngày thứ {{ getDay() }}</p>
        </div>
      </div>
    </div>
    <div class="scrollList">
      <div class="wrapper-tontent">
        <div class="personalBody">
          <div class="list bottomBorder">
            <div class="listLeft" style="flex: 1 1 0%">
              <!---->
              Họ tên
            </div>
            <div class="listRight" style="flex: 2 1 0%">
              <span class="bound">{{ RealName }}</span><!---->
            </div>
          </div>
          <div class="list bottomBorder">
            <div class="listLeft" style="flex: 1 1 0%">
              <!---->
              Sinh nhật
            </div>
            <div class="listRight" style="flex: 2 1 0%">
              <span class="bound" v-if="countDate(infoData.Birthday).indexOf('1970-01-01') < 0">{{
                countDate(infoData.Birthday).split(' ')[0] }}</span><!---->

              <span class="bound" v-else @click="showChooseDate = true">{{ $t('设置生日') }}</span>
              <span v-if="countDate(infoData.Birthday).indexOf('1970-01-01') >= 0"
                class="iconfont icon-icon_more_blue_16"></span>


            </div>
          </div>


          <div class="list bottomBorder">
            <div class="listLeft" style="flex: 1 1 0%">
              <!---->
              Số điện thoại
            </div>
            <div class="listRight" style="flex: 2 1 0%">
              <span class="bound">{{ userInfo.Phone }}</span>
            </div>
          </div>
          <div class="list bottomBorder">
            <div class="listLeft" style="flex: 1 1 0%">
              <!---->
              Tài khoản ngân hàng
            </div>
            <div class="listRight" style="flex: 2 1 0%" @click="toAddBank()">

              <span class="bound" v-if="infoData.myDouDouBT.length">số lượng tài khoản{{ infoData.myDouDouBT.length
              }}/3</span>

              <span class="unbound" v-else>Thêm vào</span><span class="iconfont icon-icon_more_blue_16"></span>
            </div>
          </div>
          <div class="list bottomBorder">
            <div class="listLeft" style="flex: 1 1 0%">
              <!---->
              Thay đổi mật khẩu
            </div>
            <div class="listRight" style="flex: 2 1 0%" @click="toChangePw()">
              <span class="bound">Mật khẩu &amp; Mật khẩu rút tiền</span><span
                class="iconfont icon-icon_more_blue_16"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="custom-horizontal-scrollbar" style="display: none">
        <div class="custom-horizontal-indicator"></div>
      </div>
    </div>
    <div class="avatar_list"><!----></div>

    <div class="pickerWrap">
      <van-datetime-picker confirm-button-text="Xác nhận" cancel-button-text="Huỷ" @cancel="showChooseDate = false"
        v-model="curDate" v-if="showChooseDate" :min-date="minDate" :max-date="maxDate" @confirm="timeSure" type="date" />
    </div>

  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import { Dialog } from "vant";
import "vant/es/dialog/style";
import moment from "moment";
import { topic } from "@/components/protocol/api";
export default {
  data() {
    return {
      oldJYPw: '',
      RealName: '',
      showChooseDate: false,
      infoData: {
        myDouDouBT: []
      },
      maxDate: new Date(),
      minDate: new Date('1950-01-01')
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged", "wallet", "basicInfo"]),
    ...mapGetters("user", ["userInfo"]),
  },
  components: {},
  created() {
    var that = this;
    if (this.isLogged) {
      this.getUserInfo();
    }
    var that = this;
    bus.on("onConnected", () => {
      that.getUserInfo();
    });
  },
  methods: {
    back() {
      window.history.back();
    },
    countDate(val) {
      if (!val) {
        return ''
      }
      return val.split('T')[0] + ' ' + val.split('T')[1].substr(0, 8)
    },
    countYear(birthday) {
      var dateBegin = new Date(birthday);
      var dateEnd = new Date();

      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
      if (dayDiff / 365.0 < 18) {
        return false
      } else {
        return true
      }
    },
    onConfirm() {




      let that = this;
      var parmes = {
        date: this.birthday
      }
      mqant.request(topic.setBirthday, parmes, function (res) {


        if (res.Code == 0) {
          that.showSetBirth = false
          that.getUserInfo()
          that.$message({
            message: that.$t('修改成功'),
            type: 'success'
          });
        } else {
          that.$message({
            message: reject.ErrMsg,
            type: 'error'
          });

        }
      });
    },
    dateFormat(dateData) {
      var date = new Date(dateData)
      var y = date.getFullYear()
      var m = date.getMonth() + 1
      m = m < 10 ? ('0' + m) : m
      var d = date.getDate()
      d = d < 10 ? ('0' + d) : d
      const time = y + '-' + m + '-' + d
      return time
    },

    timeSure(val) {
      this.showChooseDate = false
      if (!this.countYear(this.dateFormat(val))) {
        this.$message({
          message: this.$t('生日必须超过18岁'),
          type: 'error'
        });
        return
      }
      this.birthday = this.dateFormat(val)

      this.showSetBirth = true
      this.onConfirm()

    },


    toAddBank() {
      if (this.infoData.myDouDouBT.length == 3) return
      if (!this.oldJYPw.length) {
        this.$dialog
          .confirm({
            title: "Xin lưu ý",
            message:
              "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ'
          })
          .then(() => {
            this.$router.push({ path: "/transactionPwd" });
          })
          .catch(() => { });

        return
      }
      this.$router.push({ name: 'bankList' })

    },
    getUserInfo() {
      var that = this;
      this.$tools.loading();

      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        that.$tools.dismissLoading();
        that.infoData = data.Data
        that.RealName = data.Data.realName
        that.oldJYPw = data.Data.DoudouPwd;
      });
    },
    toChangePw() {
      this.$router.push({ name: 'changePassword' })
    },
    getDay() {
      let dateSpan, tempDate, iDays

      var date = moment();
      var sDate1 = this.$tools.formatDate(this.userInfo.CreateAt, "yyyy-MM-dd")
      var sDate2 = moment(moment().valueOf()).format("YYYY-MM-DD")

      sDate1 = Date.parse(sDate1)
      sDate2 = Date.parse(sDate2)
      dateSpan = sDate2 - sDate1
      dateSpan = Math.abs(dateSpan)
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000))
      return iDays + 1

    },
    openAvatar() {
      bus.emit(bus.event.avatarState, true);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 13.333333vw;
}

.personal {
  height: 54.4vw;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-image: url('@/assets/mobile/userCenter/bg_personal_data.png');
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  .userInfo {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 8vw;

    .user {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      color: #fff;
      padding-top: 2.666667vw;

      .joinDay {
        font-size: 3.2vw;
        color: #e9cfa4;
      }

      p {
        line-height: 1.5;
        font-size: 4.266667vw;
      }
    }

    .avatar-container {
      width: 16vw;
      height: 16vw;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid hsla(0, 0%, 100%, .22);
      border-radius: 8vw;
      overflow: hidden;

      .avatar-img {
        max-width: 16vw;
        max-height: 16vw;
        -o-object-fit: cover;
        object-fit: cover;
        box-sizing: border-box;
      }
    }
  }
}

.scrollList {
  overflow: hidden;
  position: absolute;
  top: 64vw;
  bottom: 0;
  left: 0;
  right: 0;

  .wrapper-tontent {
    margin-bottom: 5.333333vw;

    .personalBody {
      background: #293356;
      padding: 0 4.266667vw;

      .list:after {
        content: " ";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background: rgba(144, 162, 220, .5);
        -webkit-transform: scaleY(.5);
        transform: scaleY(.5);
      }

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 11.733333vw;
        color: #fff;
        position: relative;

        .listRight {
          display: flex;
          justify-self: end;
          text-align: right;
          justify-content: flex-end;
          height: 5.333333vw;
          line-height: 5.333333vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 0;

          .bound {
            font-size: 3.2vw;
            color: #90a2dc;
            text-align: right;
            font-weight: 400;
            margin-right: 1.066667vw;
          }
        }

        .listLeft {
          text-align: left;
          font-weight: 400;
          color: #fff;
          height: 5.333333vw;
          line-height: 5.333333vw;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 3.2vw !important;
          width: 34.666667vw !important;
          flex: unset !important;
        }
      }
    }
  }
}

.van-icon {
  color: white !important;
}

.van-nav-bar__title {
  color: white !important;

}

.pickerWrap {
  position: fixed;
  left: 0;
  bottom: 50px;
  width: 100vw;
}
</style>
