<template>
  <div
    @click="cancel()"
    class="wrap"
    style="
      width: 100%;
      height: 100%;
      font-weight: bold;
      position: fixed;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
    "
  >
    <div
      @click.stop=""
      style="
        position: relative;
        width: 600px;
        margin: 0 auto;
        background-color: #292e3b;
        height: 700px;
        margin-top: calc(50vh - 350px);
      "
    >
      <div
        style="
          background-color: #292e3b;
          height: 60px;
          position: relative;
          line-height: 60px;
          font-size: 18px;
          font-weight: bold;
          color: white;
          border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
          text-align: center;
        "
      >
        Cách chơi
        <img
          src="@/assets/pc/lottery/close.png"
          alt=""
          @click="cancel()"
          class="canClick"
          style="width: 25px; position: absolute; right: 30px; top: 17.5px"
        />
      </div>
      <div
        style="
          height: 570px;
          width: 90%;
          margin-left: 5%;
          padding: 30px 10px;
          overflow-y: auto;
          font-size: 14px;
          color: #aac5f2;
          line-height: 26px;
          white-space: nowrap;
          white-space: break-spaces;
          word-break: keep-all;
        "
      >
        {{ info }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    info: {},
  },
  created() {},
  methods: {
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss"></style>
