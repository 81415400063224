<template>
  <div style="width: 100%; height: 100%" v-if="showAll">
    <div v-if="type == 'North'">
      <div class="flexs_r_c" style="
          height: 56px;
          position: relative;
          border-bottom: 1px solid rgba(144, 162, 220, 0.4);
        ">
        <span @click="nextClick(0)" class="el-tabs__nav-prev is-disabled" style="left: 0"><i
            class="el-icon-arrow-left"></i></span>
        <span @click="nextClick(1)" class="el-tabs__nav-next" style="right: 0"><i class="el-icon-arrow-right"></i></span>
        <div style="padding: 0 20px" class="flexs_r_c playWrap" id="playWrap" @touchmove.prevent @mousewheel.prevent>
          <div v-for="(item, index) in playData" @click="columClick(index)" class="colum canClick"
            :class="selectColumIndex == index ? 'selectColum' : 'nolColum'">
            {{ item.Name }}
            <img :src="$img('lottery/hot.png')" v-if="item.PlayCode=='FUNNY'||item.PlayCode=='TS'" class="hotImg" alt="">
          </div>
        </div>
      </div>
      <div v-if="selectColum && selectColum.PlayCode == 'BZ'" style="margin-top: 24px; padding-bottom: 20px">
        <div class="flexs_sb">
          <div class="flexs_r_c" style="margin-left: 10px">
            <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
              :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
              {{ item.SubName }}
            </div>
          </div>
          <div class="flexs_r_c" style="margin-right: 13px">
            <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
              :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                border-radius: 5px;
                margin-left: 3px;
                width: 96px;
                text-align: center;
              ">
              {{ item }}
            </div>
          </div>
        </div>
        <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
          <div style="font-size: 14px">Tỉ lệ :{{ selectSub.Odds / 1000 }}</div>
          <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
            <!-- <img src="@/assets/pc/lottery/information.webp" alt="" style="width: 21px;"> -->

            <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
              class="iconfont icon-icon_remind"></i>

            <div style="font-size: 14px">Cách chơi</div>
          </div>
        </div>
        <div v-if="funcIndex == 0">
          <group title="Ngàn" v-if="selectSub.SubPlayCode == 'BZ_C4'" style="margin-top: 15px" :selectSub="selectSub">
          </group>
          <group title="Trăm" v-if="selectSub.SubPlayCode == 'BZ_C3' || selectSub.SubPlayCode == 'BZ_C4'"
            style="margin-top: 15px" :selectSub="selectSub"></group>

          <group title="Chục" style="margin-top: 15px" :selectSub="selectSub"></group>
          <group title="Đơn Vị" style="margin-top: 15px" :selectSub="selectSub"></group>
        </div>
        <div v-if="funcIndex == 1" style="margin-top: 32px">
          <random :recordData="recordData" :selectSub="selectSub"></random>
        </div>
        <div v-if="funcIndex == 2 && selectSub" style="margin-top: 32px">
          <fastChoose :recordData="recordData" :selectSub="selectSub"></fastChoose>
        </div>
      </div>
      <div v-if="selectColum && selectColum.PlayCode == 'TS'" style="margin-top: 24px; padding-bottom: 20px">
        <div class="flexs_r_c" style="margin-left: 10px">
          <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
            :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
              height: 33px;
              line-height: 33px;
              font-size: 12px;
              padding: 0 8px;
              border-radius: 5px;
              margin-left: 3px;
            ">
            {{ item.SubName }}
          </div>
        </div>

        <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)"
          style="margin-top: 20px; margin-left: 13px; color: #4a69ff">
          <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
            class="iconfont icon-icon_remind"></i>

          <div style="font-size: 14px">Cách chơi</div>
        </div>

        <div>
          <div class="tctBox">
            <div class="title">Kèo đôi</div>

            <div style="padding: 0 10px" class="flexs_sb">
              <div class="tctList playNolmal canClick" @click="
                tsClick(selectSub.SubPlayCode, 'T', selectSub.SubName, selectSub.Odds)
                " :style="containTS(selectSub.SubPlayCode + 'T')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                Tài
                <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>
              </div>
              <div class="tctList playNolmal canClick" @click="
                tsClick(selectSub.SubPlayCode, 'X', selectSub.SubName, selectSub.Odds)
                " :style="containTS(selectSub.SubPlayCode + 'X')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                Xỉu
                <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>
              </div>
              <div class="tctList playNolmal canClick" @click="
                tsClick(selectSub.SubPlayCode, 'L', selectSub.SubName, selectSub.Odds)
                " :style="containTS(selectSub.SubPlayCode + 'L')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                Lẻ
                <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>
              </div>
              <div class="tctList playNolmal canClick" @click="
                tsClick(selectSub.SubPlayCode, 'C', selectSub.SubName, selectSub.Odds)
                " :style="containTS(selectSub.SubPlayCode + 'C')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                Chẵn
                <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>
              </div>
            </div>
          </div>
          <div v-if="selectSub.SubPlayCode == 'TS_SUM_DX_DS'">
            <div class="tctBox">
              <div class="title">Số đầu</div>

              <div style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_5', 'T', 'Số đầu', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_5' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Tài
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_5', 'X', 'Số đầu', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_5' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Xỉu
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_5', 'L', 'Số đầu', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_5' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Lẻ
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_5', 'C', 'Số đầu', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_5' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Chẵn
                </div>
              </div>
            </div>
            <!-- <div class="flexs_r_c" style="padding: 0 20px;flex-wrap: wrap;margin-left: -15px;margin-top: -10px;">
							<div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 14px;margin-top: 10px;" @click="tsClick('TS_DX_DS_5',index,'Số đầu')" :style="containTS('TS_DX_DS_5'+index)?'background-color: #4a69ff;':'background-color: #363d4e;'">
								<div style="margin-top: 15px;">{{index}}</div>
								9.99
							</div>
						
						</div> -->

            <div class="tctBox">
              <div class="title">Số hai</div>

              <div style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_4', 'T', 'Số hai', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_4' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>
                  Tài
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_4', 'X', 'Số hai', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_4' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Xỉu
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_4', 'L', 'Số hai', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_4' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Lẻ
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_4', 'C', 'Số hai', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_4' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Chẵn
                </div>
              </div>
            </div>
            <!-- <div class="flexs_r_c" style="padding: 0 20px;flex-wrap: wrap;margin-left: -15px;margin-top: -10px;">
							<div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 14px;margin-top: 10px;" @click="tsClick('TS_DX_DS_4',index,'Số hai')" :style="containTS('TS_DX_DS_4'+index)?'background-color: #4a69ff;':'background-color: #363d4e;'">
								<div style="margin-top: 15px;">{{index}}</div>
								9.99
							</div>
		
						</div> -->

            <div class="tctBox">
              <div class="title">Số ba</div>

              <div style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_3', 'T', 'Số ba', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_3' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Tài
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_3', 'X', 'Số ba', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_3' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Xỉu
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_3', 'L', 'Số ba', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_3' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Lẻ
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_3', 'C', 'Số ba', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_3' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Chẵn
                </div>
              </div>
            </div>
            <!-- <div class="flexs_r_c" style="padding: 0 20px;flex-wrap: wrap;margin-left: -15px;margin-top: -10px;">
							<div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 14px;margin-top: 10px;"  @click="tsClick('TS_DX_DS_3',index,'Số ba')" :style="containTS('TS_DX_DS_3'+index)?'background-color: #4a69ff;':'background-color: #363d4e;'">
								<div style="margin-top: 15px;">{{index}}</div>
								9.99
							</div>
		
						</div> -->

            <div class="tctBox">
              <div class="title">Số bốn</div>

              <div style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_2', 'T', 'Số bốn', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_2' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Tài
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_2', 'X', 'Số bốn', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_2' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Xỉu
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_2', 'L', 'Số bốn', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_2' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Lẻ
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_2', 'C', 'Số bốn', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_2' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Chẵn
                </div>
              </div>
            </div>
            <!-- <div class="flexs_r_c" style="padding: 0 20px;flex-wrap: wrap;margin-left: -15px;margin-top: -10px;">
							<div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 14px;margin-top: 10px;"  @click="tsClick('TS_DX_DS_2',index,'Số bốn')" :style="containTS('TS_DX_DS_2'+index)?'background-color: #4a69ff;':'background-color: #363d4e;'">
								<div style="margin-top: 15px;">{{index}}</div>
								9.99
							</div>
		
						</div> -->

            <div class="tctBox">
              <div class="title">Số năm</div>

              <div style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_1', 'T', 'Số năm', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_1' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Tài
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_1', 'X', 'Số năm', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_1' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Xỉu
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_1', 'L', 'Số năm', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_1' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Lẻ
                </div>
                <div class="tctList playNolmal canClick" @click="tsClick('TS_DX_DS_1', 'C', 'Số năm', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_1' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ selectSub.Odds / 1000 }}</div>

                  Chẵn
                </div>
              </div>
            </div>
            <!-- <div class="flexs_r_c" style="padding: 0 20px;flex-wrap: wrap;margin-left: -15px;margin-top: -10px;">
							<div class="fiveBtn playNolmal canClick" v-for="(item,index) in 10"
								style="margin-left: 14px;margin-top: 10px;" @click="tsClick('TS_DX_DS_1',index,'Số năm')" :style="containTS('TS_DX_DS_1'+index)?'background-color: #4a69ff;':'background-color: #363d4e;'">
								<div style="margin-top: 15px;">{{index}}</div>
								9.99
							</div>
		
						</div> -->
          </div>

          <div v-else>
            <!-- <div
							style="background-color: #1b2c5e;height: 42px;text-align: center;line-height: 42px;color: white;font-size: 12px;margin-top: 0px;">
							Số đầu
						</div>
						<div class="flexs_r_c" style="padding: 0 20px;flex-wrap: wrap;margin-left: -15px;margin-top: 10px;">
							<div class="fiveBtn playNolmal canClick" @click="tsClick(selectSub.SubPlayCode,index,'Số đầu')" :style="containTS(selectSub.SubPlayCode+index)?'background-color: #4a69ff;':'background-color: #363d4e;'" v-for="(item,index) in 10" 
								style="margin-left: 14px;margin-top: 10px;">
								<div style="margin-top: 15px;">{{index}}</div>
								9.99
							</div>
						
						</div> -->
          </div>

          <div class="tctBox">
            <div class="title">Xu hướng</div>
            <div class="" style="border-top: 1px solid rgba(214, 217, 224, 0.29); height: 40px">
              <div class="flexs_r_c" style="justify-content: center">
                <div class="bottomBtn canClick" @click="bottomClick(0)"
                  :class="bottomIndex == 0 ? 'bottomSe' : 'bottomNol'">
                  Tài xỉu
                </div>
                <div class="bottomBtn canClick" @click="bottomClick(1)"
                  :class="bottomIndex == 1 ? 'bottomSe' : 'bottomNol'">
                  Chẵn lẻ
                </div>
              </div>
            </div>
            <div v-if="bottomIndex == 0" style="position: relative; padding-bottom: 50px">
              <div class="flexs_r_c" style="margin: 0 auto; margin-top: 20px; position: relative; width: 180px">
                <div class="flexs_r_c" style="
                    width: 80px;
                    height: 36px;
                    border-radius: 18px;
                    background-color: #363d4e;
                    margin-right: 20px;
                  ">
                  <el-progress style="color: rgb(249, 65, 104) !important" stroke-width="3" type="circle"
                    color="rgb(249, 65, 104)" width="50" :percentage="parseInt(getPersent(0))" />

                  <div style="margin-left: 0px; color: rgb(249, 65, 104); font-size: 15px">
                    Tài
                  </div>
                </div>

                <div class="flexs_r_c" style="
                    width: 80px;
                    height: 36px;
                    border-radius: 18px;
                    background-color: #363d4e;
                  ">
                  <el-progress style="color: #26a1ff !important" stroke-width="3" type="circle" color="#26a1ff" width="50"
                    :percentage="parseInt(getPersent(1))" />

                  <div style="margin-left: 0px; color: #26a1ff; font-size: 15px">Xỉu</div>
                </div>
              </div>

              <div style="margin: 0 auto; margin-top: 25px; justify-content: center" class="flexs_r">
                <div v-for="(item, index) in 18" :key="item">
                  <div v-for="item1 in bigSmallArr[index]" :key="item1">
                    <div class="corner" v-if="item1 == 'big'"
                      style="margin-top: 2px; margin-left: 2px; background-color: #f94168">
                      T
                    </div>
                    <div class="corner" v-else style="margin-top: 2px; margin-left: 2px; background-color: #26a1ff">
                      X
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="bottomIndex == 1" style="padding-bottom: 50px">
              <div class="flexs_r_c" style="margin: 0 auto; margin-top: 20px; position: relative; width: 180px">
                <div class="flexs_r_c" style="
                    width: 80px;
                    height: 36px;
                    border-radius: 18px;
                    background-color: #363d4e;
                    margin-right: 20px;
                  ">
                  <el-progress style="color: rgb(249, 65, 104) !important" stroke-width="3" type="circle"
                    color="rgb(249, 65, 104)" width="50" :percentage="parseInt(getPersent(2))" />

                  <div style="margin-left: 0px; color: rgb(249, 65, 104); font-size: 15px">
                    Lẻ
                  </div>
                </div>

                <div class="flexs_r_c" style="
                    width: 80px;
                    height: 36px;
                    border-radius: 18px;
                    background-color: #363d4e;
                  ">
                  <el-progress style="color: #26a1ff !important" stroke-width="3" type="circle" color="#26a1ff" width="50"
                    :percentage="parseInt(getPersent(3))" />

                  <div style="margin-left: 0px; color: #26a1ff; font-size: 15px">
                    Chẵn
                  </div>
                </div>
              </div>

              <div style="justify-content: center; margin-top: 25px" class="flexs_r">
                <div v-for="(item, index) in 18" :key="item">
                  <div v-for="item1 in doubleSingleArr[index]" :key="item1">
                    <div class="corner" v-if="item1 == 'single'"
                      style="margin-top: 2px; margin-left: 2px; background-color: #f94168">
                      L
                    </div>
                    <div class="corner" v-else style="margin-top: 2px; margin-left: 2px; background-color: #26a1ff">
                      C
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectColum && selectColum.PlayCode == 'FUNNY'" style="margin-top: 24px; padding-bottom: 20px">
        <div class="flexs_sb">
          <div class="nolSub" style="
              height: 33px;
              line-height: 33px;
              font-size: 12px;
              padding: 0 8px;
              border-radius: 5px;
              margin-left: 13px;
              background-color: #4a69ff;
              color: white;
            ">
            Lô 2 Số Giải ĐB
          </div>
          <div class="flexs_r_c" style="margin-right: 13px">
            <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
              :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 13px;
                border-radius: 5px;
                margin-left: 3px;
              ">
              {{ item }}
            </div>
          </div>
        </div>

        <div v-if="funcIndex == 0" class="flexs_r_c canClick" @click="showInfo(selectSub.Description)"
          style="margin-top: 20px; margin-left: 13px; color: #4a69ff">
          <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
            class="iconfont icon-icon_remind"></i>

          <div style="font-size: 14px">Cách chơi</div>
        </div>
        <div class="flexs_sb" v-else style="margin-top: 26px; padding: 0 20px; color: #4a69ff">
          <div style="font-size: 14px">Tỉ lệ Cược :99.5</div>
          <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
            <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
              class="iconfont icon-icon_remind"></i>

            <div style="font-size: 14px">Cách chơi</div>
          </div>
        </div>

        <div v-if="funcIndex == 0">
          <div class="tctBox">
            <div class="title">{{getFunnyDXDS('North').SubName}}</div>

            <div style="padding: 0 10px" class="flexs_sb">
              <div style="width: 133px" class="tctList playNolmal canClick" @click="
                tsClick(
                  'FUNNY_DX_DS',
                  'T',
                  'Kèo đôi',
                  getFunnyDXDSODD(selectColum.SubPlays) * 1000
                )
                " :style="containTS('FUNNY_DX_DS' + 'T')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                <div class="rightNum">{{ getFunnyDXDSODD(selectColum.SubPlays) }}</div>
                Tài
              </div>
              <div class="tctList playNolmal canClick" @click="
                tsClick(
                  'FUNNY_DX_DS',
                  'X',
                  'Kèo đôi',
                  getFunnyDXDSODD(selectColum.SubPlays) * 1000
                )
                " :style="containTS('FUNNY_DX_DS' + 'X')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                <div class="rightNum">{{ getFunnyDXDSODD(selectColum.SubPlays) }}</div>

                Xỉu
              </div>
              <div class="tctList playNolmal canClick" @click="
                tsClick(
                  'FUNNY_DX_DS',
                  'L',
                  'Kèo đôi',
                  getFunnyDXDSODD(selectColum.SubPlays) * 1000
                )
                " :style="containTS('FUNNY_DX_DS' + 'L')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                <div class="rightNum">{{ getFunnyDXDSODD(selectColum.SubPlays) }}</div>
                Lẻ
              </div>
              <div class="tctList playNolmal canClick" @click="
                tsClick(
                  'FUNNY_DX_DS',
                  'C',
                  'Kèo đôi',
                  getFunnyDXDSODD(selectColum.SubPlays) * 1000
                )
                " :style="containTS('FUNNY_DX_DS' + 'C')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                <div class="rightNum">{{ getFunnyDXDSODD(selectColum.SubPlays) }}</div>

                Chẵn
              </div>
            </div>
          </div>

          <div class="tctBox">
            <div class="title">{{getFunnyNumber('North').SubName}}</div>

            <div class="flexs_r_c" style="
                padding-bottom: 20px;
                flex-wrap: wrap;
                justify-content: center;
                margin-left: 4px;
              ">
              <div class="tenBtn playNolmal canClick" v-for="(item, index) in 100" style="margin-top: 8px" @click="
                tsClick(
                  'FUNNY_NUMBER',
                  index,
                  'Số Đơn',
                  getFunnyNumberODD(selectColum.SubPlays) * 1000
                )
                " :style="containTS('FUNNY_NUMBER' + index)
      ? 'background-color: #3752f5;'
      : 'background-color: #363d4e;'
    ">
                <div style="margin-top: 0px; font-size: 14px; color: white; font-weight: 600">
                  {{ index < 10 ? "0" + index : index }} </div>
                    {{ getFunnyNumberODD(selectColum.SubPlays) }}
                </div>
              </div>
            </div>
          </div>
          <div v-else style="margin-top: 30px">
            <random :recordData="recordData" :selectSub="selectSub"></random>
          </div>
        </div>
        <div v-if="selectColum && selectColum.PlayCode == 'ZH'" style="margin-top: 24px; padding-bottom: 20px">
          <div class="flexs_sb">
            <div class="flexs_r_c" style="margin-left: 10px">
              <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 13px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item.SubName }}
              </div>
            </div>
            <div class="flexs_r_c" style="margin-right: 13px">
              <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 13px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item }}
              </div>
            </div>
          </div>

          <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
            <div style="font-size: 14px">Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}</div>
            <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
              <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                class="iconfont icon-icon_remind"></i>

              <div style="font-size: 14px">Cách chơi</div>
            </div>
          </div>
          <div v-if="funcIndex == 0 && selectSub" style="margin-top: 32px">
            <random :recordData="recordData" :selectSub="selectSub"></random>
          </div>
          <div v-if="funcIndex == 1 && selectSub" style="margin-top: 32px">
            <fastChoose :recordData="recordData" :selectSub="selectSub"></fastChoose>
          </div>
        </div>
        <div v-if="selectColum && selectColum.PlayCode == '2DTW'" style="margin-top: 24px; padding-bottom: 20px">
          <div class="flexs_sb">
            <div class="flexs_r_c" style="margin-left: 10px">
              <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item.SubName }}
              </div>
            </div>
            <div class="flexs_r_c" style="margin-right: 13px">
              <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item }}
              </div>
            </div>
          </div>

          <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
            <div style="color: #4a69ff; font-size: 14px">
              Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
            </div>
            <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
              <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                class="iconfont icon-icon_remind"></i>

              <div style="font-size: 14px">Cách chơi</div>
            </div>
          </div>

          <div v-if="funcIndex == 0">
            <group title="Chục" style="margin-top: 30px" :recordData="recordData" :selectSub="selectSub" :type="1">
            </group>
            <group title="Đơn Vị" style="margin-top: 30px" :recordData="recordData" :selectSub="selectSub" :type="2">
            </group>
          </div>
          <div v-if="funcIndex == 1">
            <random :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></random>
          </div>
          <div v-if="funcIndex == 2">
            <fastChoose :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></fastChoose>
          </div>
        </div>
        <div v-if="selectColum && selectColum.PlayCode == '1D'" style="margin-top: 24px; padding-bottom: 20px">
          <div class="flexs_sb">
            <div class="flexs_r_c" style="margin-left: 10px">
              <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item.SubName }}
              </div>
            </div>
            <div class="flexs_r_c" style="margin-right: 13px">
              <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
            <div style="color: #4a69ff; font-size: 14px">
              Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
            </div>
            <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
              <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                class="iconfont icon-icon_remind"></i>

              <div style="font-size: 14px">Cách chơi</div>
            </div>
          </div>

          <group title="Chục" v-if="selectSub.SubPlayCode == '1D_TJ4'" style="margin-top: 30px" :recordData="recordData"
            :selectSub="selectSub" :type="2"></group>
          <group title="Đơn Vị" v-else style="margin-top: 30px" :recordData="recordData" :selectSub="selectSub" :type="1">
          </group>
        </div>
        <div v-if="selectColum && selectColum.PlayCode == '3DTW'" style="margin-top: 24px; padding-bottom: 20px">
          <div class="flexs_sb">
            <div class="flexs_r_c" style="margin-left: 10px">
              <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item.SubName }}
              </div>
            </div>
            <div class="flexs_r_c" style="margin-right: 13px">
              <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item }}
              </div>
            </div>
          </div>

          <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
            <div style="color: #4a69ff; font-size: 14px">
              Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
            </div>
            <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
              <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                class="iconfont icon-icon_remind"></i>

              <div style="font-size: 14px">Cách chơi</div>
            </div>
          </div>

          <div v-if="funcIndex == 0">
            <group title="Trăm" style="margin-top: 30px" :recordData="recordData" :selectSub="selectSub" :type="3">
            </group>
            <group title="Chục" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub" :type="2">
            </group>
            <group title="Đơn Vị" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub" :type="1">
            </group>
          </div>

          <div v-if="funcIndex == 1">
            <random :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></random>
          </div>
          <div v-if="funcIndex == 2">
            <fastChoose :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></fastChoose>
          </div>
        </div>
        <div v-if="selectColum && selectColum.PlayCode == '4D'" style="margin-top: 24px; padding-bottom: 20px">
          <div class="flexs_sb">
            <div class="flexs_r_c" style="margin-left: 10px">
              <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item.SubName }}
              </div>
            </div>
            <div class="flexs_r_c" style="margin-right: 13px">
              <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
            <div style="color: #4a69ff; font-size: 14px">
              Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
            </div>
            <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
              <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                class="iconfont icon-icon_remind"></i>

              <div style="font-size: 14px">Cách chơi</div>
            </div>
          </div>
          <div v-if="funcIndex == 0">
            <group title="Ngàn" style="margin-top: 30px" :recordData="recordData" :selectSub="selectSub" :type="4">
            </group>

            <group title="Trăm" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub" :type="3">
            </group>
            <group title="Chục" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub" :type="2">
            </group>
            <group title="Đơn Vị" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub" :type="1">
            </group>
          </div>
          <div v-if="funcIndex == 1">
            <random :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></random>
          </div>
        </div>
        <div v-if="selectColum && selectColum.PlayCode == 'BCZH'" style="margin-top: 24px; padding-bottom: 20px">
          <div class="flexs_sb">
            <div class="flexs_r_c" style="margin-left: 10px">
              <div v-for="(item, index) in getNoBCZH()" @click="subClick(item, index)" class="canClick"
                :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item.SubName }}
              </div>
            </div>
            <div class="flexs_r_c" style="margin-right: 13px">
              <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
            <div style="color: #4a69ff; font-size: 14px">
              Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
            </div>
            <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
              <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                class="iconfont icon-icon_remind"></i>

              <div style="font-size: 14px">Cách chơi</div>
            </div>
          </div>

          <div v-if="funcIndex == 0">
            <random :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></random>
          </div>
          <div v-if="funcIndex == 1">
            <fastChoose :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></fastChoose>
          </div>
        </div>
      </div>
      <div v-if="type == 'South' || type == 'Central'">
        <div class="flexs_r_c" style="
          height: 56px;
          position: relative;
          border-bottom: 1px solid rgba(144, 162, 220, 0.4);
        ">
          <span @click="nextClick(0)" class="el-tabs__nav-prev is-disabled" style="left: 0"><i
              class="el-icon-arrow-left"></i></span>
          <span @click="nextClick(1)" class="el-tabs__nav-next" style="right: 0"><i
              class="el-icon-arrow-right"></i></span>
          <div style="padding: 0 20px" class="flexs_r_c playWrap" id="playWrap" @touchmove.prevent @mousewheel.prevent>
            <div v-for="(item, index) in playData" @click="columClick(index)" class="colum canClick" style=""
              :class="selectColumIndex == index ? 'selectColum' : 'nolColum'">
              {{ item.Name }}
              <img :src="$img('lottery/hot.png')" v-if="item.PlayCode=='FUNNY'||item.PlayCode=='TS'" class="hotImg" alt="">

            </div>
          </div>
        </div>
        <div v-if="selectColum && selectColum.PlayCode == 'BZ'" style="margin-top: 24px; padding-bottom: 20px">
          <div class="flexs_sb">
            <div class="flexs_r_c" style="margin-left: 10px">
              <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 13px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item.SubName }}
              </div>
            </div>
            <div class="flexs_r_c" style="margin-right: 13px">
              <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 13px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item }}
              </div>
            </div>
          </div>
          <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
            <div style="color: #4a69ff; font-size: 14px">
              Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
            </div>
            <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
              <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                class="iconfont icon-icon_remind"></i>

              <div style="font-size: 14px">Cách chơi</div>
            </div>
          </div>
          <div v-if="funcIndex == 0">
            <group title="Ngàn" isSouth="1" v-if="selectSub.SubPlayCode == 'BZ_C4'" style="margin-top: 30px"
              :selectSub="selectSub"></group>
            <group title="Trăm" isSouth="1" v-if="selectSub.SubPlayCode == 'BZ_C3' || selectSub.SubPlayCode == 'BZ_C4'"
              style="margin-top: 30px" :selectSub="selectSub"></group>

            <group title="Chục" isSouth="1" style="margin-top: 30px" :selectSub="selectSub"></group>
            <group title="Đơn Vị" isSouth="1" style="margin-top: 30px" :selectSub="selectSub"></group>
          </div>
          <div v-if="funcIndex == 1" style="margin-top: 32px">
            <random :recordData="recordData" :selectSub="selectSub"></random>
          </div>
          <div v-if="funcIndex == 2 && selectSub" style="margin-top: 32px">
            <fastChoose :recordData="recordData" :selectSub="selectSub"></fastChoose>
          </div>
        </div>
        <div v-if="selectColum && selectColum.PlayCode == 'TS'" style="margin-top: 24px; padding-bottom: 20px">
          <div class="flexs_r_c" style="margin-left: 10px">
            <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
              :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
              height: 33px;
              line-height: 33px;
              font-size: 12px;
              padding: 0 13px;
              border-radius: 5px;
              margin-left: 3px;
            ">
              {{ item.SubName }}
            </div>
          </div>

          <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)"
            style="margin-top: 20px; margin-left: 13px; color: #4a69ff">
            <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
              class="iconfont icon-icon_remind"></i>

            <div style="font-size: 14px">Cách chơi</div>
          </div>

          <div>
            <div style="
              background-color: #1b2c5e;
              height: 42px;
              text-align: center;
              line-height: 42px;
              color: white;
              font-size: 12px;
              margin-top: 28px;
            ">
              Kèo đôi
            </div>
            <div style="background-color: #1f4a97; height: 106px; padding: 0 20px" class="flexs_sb">
              <div class="fourbtn playNolmal canClick" @click="
                tsClick(selectSub.SubPlayCode, 'T', selectSub.SubName, selectSub.Odds)
                " :style="containTS(selectSub.SubPlayCode + 'T')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                <div style="margin-top: 15px">Tài</div>
                {{ selectSub.Odds / 1000 }}
              </div>
              <div class="fourbtn playNolmal canClick" @click="
                tsClick(selectSub.SubPlayCode, 'X', selectSub.SubName, selectSub.Odds)
                " :style="containTS(selectSub.SubPlayCode + 'X')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                <div style="margin-top: 15px">Xỉu</div>
                {{ selectSub.Odds / 1000 }}
              </div>
              <div class="fourbtn playNolmal canClick" @click="
                tsClick(selectSub.SubPlayCode, 'L', selectSub.SubName, selectSub.Odds)
                " :style="containTS(selectSub.SubPlayCode + 'L')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                <div style="margin-top: 15px">Lẻ</div>
                {{ selectSub.Odds / 1000 }}
              </div>
              <div class="fourbtn playNolmal canClick" @click="
                tsClick(selectSub.SubPlayCode, 'C', selectSub.SubName, selectSub.Odds)
                " :style="containTS(selectSub.SubPlayCode + 'C')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                <div style="margin-top: 15px">Chẵn</div>
                {{ selectSub.Odds / 1000 }}
              </div>
            </div>

            <div v-if="selectSub.SubPlayCode == 'TS_SUM_DX_DS'">
              <div style="
                background-color: #1b2c5e;
                height: 42px;
                text-align: center;
                line-height: 42px;
                color: white;
                font-size: 12px;
                margin-top: 0px;
              ">
                Số đầu
              </div>
              <div style="background-color: #1f4a97; height: 106px; padding: 0 20px" class="flexs_sb">
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_5', 'T', 'Số đầu', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_5' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Tài</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_5', 'X', 'Số đầu', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_5' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Xỉu</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_5', 'L', 'Số đầu', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_5' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Lẻ</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_5', 'C', 'Số đầu', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_5' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Chẵn</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
              </div>
              <div class="flexs_r_c" style="
                padding: 0 20px;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-top: -10px;
              ">
                <div class="fiveBtn playNolmal canClick" v-for="(item, index) in 10"
                  style="margin-left: 14px; margin-top: 10px" @click="tsClick('TS_DX_DS_5', index, 'Số đầu')" :style="containTS('TS_DX_DS_5' + index)
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">{{ index }}</div>
                  9.99
                </div>
              </div>

              <div style="
                background-color: #1b2c5e;
                height: 42px;
                text-align: center;
                line-height: 42px;
                color: white;
                font-size: 12px;
                margin-top: 15px;
              ">
                Số hai
              </div>
              <div style="background-color: #1f4a97; height: 106px; padding: 0 20px" class="flexs_sb">
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_4', 'T', 'Số hai', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_4' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Tài</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_4', 'X', 'Số hai', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_4' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Xỉu</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_4', 'L', 'Số hai', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_4' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Lẻ</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_4', 'C', 'Số hai', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_4' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Chẵn</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
              </div>
              <div class="flexs_r_c" style="
                padding: 0 20px;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-top: -10px;
              ">
                <div class="fiveBtn playNolmal canClick" v-for="(item, index) in 10"
                  style="margin-left: 14px; margin-top: 10px" @click="tsClick('TS_DX_DS_4', index, 'Số hai')" :style="containTS('TS_DX_DS_4' + index)
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">{{ index }}</div>
                  9.99
                </div>
              </div>

              <div style="
                background-color: #1b2c5e;
                height: 42px;
                text-align: center;
                line-height: 42px;
                color: white;
                font-size: 12px;
                margin-top: 15px;
              ">
                Số ba
              </div>
              <div style="background-color: #1f4a97; height: 106px; padding: 0 20px" class="flexs_sb">
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_3', 'T', 'Số ba', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_3' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Tài</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_3', 'X', 'Số ba', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_3' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Xỉu</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_3', 'L', 'Số ba', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_3' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Lẻ</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_3', 'C', 'Số ba', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_3' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Chẵn</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
              </div>
              <div class="flexs_r_c" style="
                padding: 0 20px;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-top: -10px;
              ">
                <div class="fiveBtn playNolmal canClick" v-for="(item, index) in 10"
                  style="margin-left: 14px; margin-top: 10px" @click="tsClick('TS_DX_DS_3', index, 'Số ba')" :style="containTS('TS_DX_DS_3' + index)
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">{{ index }}</div>
                  9.99
                </div>
              </div>

              <div style="
                background-color: #1b2c5e;
                height: 42px;
                text-align: center;
                line-height: 42px;
                color: white;
                font-size: 12px;
                margin-top: 15px;
              ">
                Số bốn
              </div>
              <div style="background-color: #1f4a97; height: 106px; padding: 0 20px" class="flexs_sb">
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_2', 'T', 'Số bốn', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_2' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Tài</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_2', 'X', 'Số bốn', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_2' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Xỉu</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_2', 'L', 'Số bốn', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_2' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Lẻ</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_2', 'C', 'Số bốn', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_2' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Chẵn</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
              </div>
              <div class="flexs_r_c" style="
                padding: 0 20px;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-top: -10px;
              ">
                <div class="fiveBtn playNolmal canClick" v-for="(item, index) in 10"
                  style="margin-left: 14px; margin-top: 10px" @click="tsClick('TS_DX_DS_2', index, 'Số bốn')" :style="containTS('TS_DX_DS_2' + index)
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">{{ index }}</div>
                  9.99
                </div>
              </div>

              <div style="
                background-color: #1b2c5e;
                height: 42px;
                text-align: center;
                line-height: 42px;
                color: white;
                font-size: 12px;
                margin-top: 15px;
              ">
                Số năm
              </div>
              <div style="background-color: #1f4a97; height: 106px; padding: 0 20px" class="flexs_sb">
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_1', 'T', 'Số năm', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_1' + 'T')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Tài</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_1', 'X', 'Số năm', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_1' + 'X')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Xỉu</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_1', 'L', 'Số năm', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_1' + 'L')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Lẻ</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
                <div class="fourbtn playNolmal canClick" @click="tsClick('TS_DX_DS_1', 'C', 'Số năm', selectSub.Odds)"
                  :style="containTS('TS_DX_DS_1' + 'C')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">Chẵn</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
              </div>
              <div class="flexs_r_c" style="
                padding: 0 20px;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-top: -10px;
              ">
                <div class="fiveBtn playNolmal canClick" v-for="(item, index) in 10"
                  style="margin-left: 14px; margin-top: 10px" @click="tsClick('TS_DX_DS_1', index, 'Số năm')" :style="containTS('TS_DX_DS_1' + index)
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div style="margin-top: 15px">{{ index }}</div>
                  9.99
                </div>
              </div>
            </div>

            <div v-else>
              <div style="
                background-color: #1b2c5e;
                height: 42px;
                text-align: center;
                line-height: 42px;
                color: white;
                font-size: 12px;
                margin-top: 0px;
              ">
                Số đầu
              </div>
              <div class="flexs_r_c" style="
                padding: 0 20px;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-top: 10px;
              ">
                <div class="fiveBtn playNolmal canClick"
                  @click="tsClick(selectSub.SubPlayCode, index, 'Số đầu', selectSub.Odds)" :style="containTS(selectSub.SubPlayCode + index)
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    " v-for="(item, index) in 10" style="margin-left: 14px; margin-top: 10px">
                  <div style="margin-top: 15px">{{ index }}</div>
                  {{ selectSub.Odds / 1000 }}
                </div>
              </div>
            </div>

            <div class="flexs_r" style="margin-top: 20px">
              <div class="bottomBtn canClick" @click="bottomClick(0)"
                :class="bottomIndex == 0 ? 'bottomSe' : 'bottomNol'">
                Tài xỉu
              </div>
              <div class="bottomBtn canClick" @click="bottomClick(1)"
                :class="bottomIndex == 1 ? 'bottomSe' : 'bottomNol'">
                Chẵn lẻ
              </div>
            </div>
            <div v-if="bottomIndex == 0">
              <div class="flexs_r_c" style="margin-top: 31px">
                <div style="
                  margin-left: 171px;
                  width: 78px;
                  height: 43px;
                  text-align: center;
                  line-height: 43px;
                  border-radius: 5px;
                  color: white;
                  font-size: 19px;
                  background-color: #f94168;
                ">
                  Tài
                </div>
                <progress-bar style="margin-left: 11px" :target="getPersent(0)" :colorTop="'#f94168'"
                  :percentageColor="'white'" :colorBg="'transparent'" :percentageSize="14" :textSize="14" :sizeOut="30"
                  :sizeIn="30" :total="100" :encumbrances="0" />

                <div style="
                  margin-left: 296px;
                  width: 78px;
                  height: 43px;
                  text-align: center;
                  line-height: 43px;
                  border-radius: 5px;
                  color: white;
                  font-size: 19px;
                  background-color: #26a1ff;
                ">
                  Xỉu
                </div>

                <progress-bar style="margin-left: 17px" :target="getPersent(1)" :colorTop="'#26a1ff'"
                  :percentageColor="'white'" :colorBg="'transparent'" :percentageSize="14" :textSize="14" :sizeOut="30"
                  :sizeIn="30" :total="100" :encumbrances="0" />
              </div>

              <div style="margin-left: 87px; margin-top: 25px" class="flexs_r">
                <div v-for="(item, index) in 16" :key="item">
                  <div v-for="item1 in bigSmallArr[index]" :key="item1">
                    <div class="corner" v-if="item1 == 'big'"
                      style="margin-top: 2px; margin-left: 2px; background-color: #f94168">
                      T
                    </div>
                    <div class="corner" v-else style="margin-top: 2px; margin-left: 2px; background-color: #26a1ff">
                      X
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="bottomIndex == 1">
              <div class="flexs_r_c" style="margin-top: 31px">
                <div style="
                  margin-left: 171px;
                  width: 78px;
                  height: 43px;
                  text-align: center;
                  line-height: 43px;
                  border-radius: 5px;
                  color: white;
                  font-size: 19px;
                  background-color: #f94168;
                ">
                  Lẻ
                </div>
                <progress-bar style="margin-left: 11px" :target="getPersent(2)" :colorTop="'#f94168'"
                  :percentageColor="'white'" :colorBg="'transparent'" :percentageSize="14" :textSize="14" :sizeOut="30"
                  :sizeIn="30" :total="100" :encumbrances="0" />

                <div style="
                  margin-left: 296px;
                  width: 78px;
                  height: 43px;
                  text-align: center;
                  line-height: 43px;
                  border-radius: 5px;
                  color: white;
                  font-size: 19px;
                  background-color: #26a1ff;
                ">
                  Chẵn
                </div>

                <progress-bar style="margin-left: 17px" :target="getPersent(3)" :colorTop="'#26a1ff'"
                  :percentageColor="'white'" :colorBg="'transparent'" :percentageSize="14" :textSize="14" :sizeOut="30"
                  :sizeIn="30" :total="100" :encumbrances="0" />
              </div>

              <div style="margin-left: 87px; margin-top: 25px" class="flexs_r">
                <div v-for="(item, index) in 16" :key="item">
                  <div v-for="item1 in doubleSingleArr[index]" :key="item1">
                    <div class="corner" v-if="item1 == 'single'"
                      style="margin-top: 2px; margin-left: 2px; background-color: #f94168">
                      L
                    </div>
                    <div class="corner" v-else style="margin-top: 2px; margin-left: 2px; background-color: #26a1ff">
                      C
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="selectColum && selectColum.PlayCode == 'FUNNY'" style="margin-top: 24px; padding-bottom: 20px">
          <div class="flexs_sb">
            <div class="nolSub" style="
              height: 33px;
              line-height: 33px;
              font-size: 12px;
              padding: 0 13px;
              border-radius: 5px;
              margin-left: 13px;
              background-color: #4a69ff;
              color: white;
            ">
              Lô 2 Số Giải ĐB
            </div>
            <div class="flexs_r_c" style="margin-right: 13px">
              <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 13px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                {{ item }}
              </div>
            </div>
          </div>

          <div v-if="funcIndex == 0" class="flexs_r_c canClick" @click="showInfo(selectSub.Description)"
            style="margin-top: 20px; margin-left: 13px; color: #4a69ff">
            <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
              class="iconfont icon-icon_remind"></i>

            <div style="font-size: 14px">Cách chơi</div>
          </div>
          <div class="flexs_sb" v-else style="margin-top: 26px; padding: 0 20px; color: #4a69ff">
            <div style="color: #4a69ff; font-size: 14px">Tỉ lệ Cược :99.5</div>
            <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
              <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                class="iconfont icon-icon_remind"></i>

              <div style="font-size: 14px">Cách chơi</div>
            </div>
          </div>

          <div v-if="funcIndex == 0">
            <div class="tctBox">
              <div class="title"> {{getFunnyDXDS('South').SubName}}</div>

              <div style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="
                  tsClick(
                    selectSub.SubPlayCode,
                    'T',
                    selectSub.SubName,
                    getFunnyDXDSODD(selectColum.SubPlays) * 1000
                  )
                  " :style="containTS(selectSub.SubPlayCode + 'T')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getFunnyDXDSODD(selectColum.SubPlays) }}</div>

                  Tài
                </div>
                <div class="tctList playNolmal canClick" @click="
                  tsClick(
                    selectSub.SubPlayCode,
                    'X',
                    selectSub.SubName,
                    getFunnyDXDSODD(selectColum.SubPlays) * 1000
                  )
                  " :style="containTS(selectSub.SubPlayCode + 'X')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getFunnyDXDSODD(selectColum.SubPlays) }}</div>

                  Xỉu
                </div>
                <div class="tctList playNolmal canClick" @click="
                  tsClick(
                    selectSub.SubPlayCode,
                    'L',
                    selectSub.SubName,
                    getFunnyDXDSODD(selectColum.SubPlays) * 1000
                  )
                  " :style="containTS(selectSub.SubPlayCode + 'L')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getFunnyDXDSODD(selectColum.SubPlays) }}</div>

                  Lẻ
                </div>
                <div class="tctList playNolmal canClick" @click="
                  tsClick(
                    selectSub.SubPlayCode,
                    'C',
                    selectSub.SubName,
                    getFunnyDXDSODD(selectColum.SubPlays) * 1000
                  )
                  " :style="containTS(selectSub.SubPlayCode + 'C')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getFunnyDXDSODD(selectColum.SubPlays) }}</div>

                  Chẵn
                </div>
              </div>
            </div>

            <div class="tctBox">
              <div class="title"> {{getFunnyNumber('South').SubName}}</div>

              <div class="flexs_r_c" style="
                padding-bottom: 20px;
                flex-wrap: wrap;
                justify-content: center;
                margin-left: 4px;
              ">
                <div class="tenBtn playNolmal canClick" v-for="(item, index) in 100" style="margin-top: 8px" @click="
                  tsClick(
                    'FUNNY_NUMBER',
                    index,
                    selectSub.SubName,
                    getFunnyNumberODD(selectColum.SubPlays) * 1000
                  )
                  " :style="containTS('FUNNY_NUMBER' + index)
      ? 'background-color: #3752f5;'
      : 'background-color: #363d4e;'
    ">
                  <div style="margin-top: 0px; font-size: 14px; color: white; font-weight: 600">
                    {{ index < 10 ? "0" + index : index }} </div>
                      {{ getFunnyNumberODD(selectColum.SubPlays) }}
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="margin-top: 30px">
              <random :recordData="recordData" :selectSub="selectSub"></random>
            </div>
          </div>
          <div v-if="selectColum && selectColum.PlayCode == 'ZH'" style="margin-top: 24px; padding-bottom: 20px">
            <div class="flexs_sb">
              <div class="flexs_r_c" style="margin-left: 10px">
                <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                  :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 13px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item.SubName }}
                </div>
              </div>
              <div class="flexs_r_c" style="margin-right: 13px">
                <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                  :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 13px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item }}
                </div>
              </div>
            </div>

            <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
              <div style="color: #4a69ff; font-size: 14px">
                Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
              </div>
              <div class="flexs_r_c canClick">
                <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                  class="iconfont icon-icon_remind"></i>

                <div style="font-size: 14px">Cách chơi</div>
              </div>
            </div>
            <div v-if="funcIndex == 0 && selectSub" style="margin-top: 32px">
              <random :recordData="recordData" :selectSub="selectSub"></random>
            </div>
            <div v-if="funcIndex == 1 && selectSub" style="margin-top: 32px">
              <fastChoose :recordData="recordData" :selectSub="selectSub"></fastChoose>
            </div>
          </div>
          <div v-if="selectColum && selectColum.PlayCode == '2DTW'" style="margin-top: 24px; padding-bottom: 20px">
            <div class="flexs_sb">
              <div class="flexs_r_c" style="margin-left: 10px">
                <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                  :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item.SubName }}
                </div>
              </div>
              <div class="flexs_r_c" style="margin-right: 13px">
                <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                  :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item }}
                </div>
              </div>
            </div>

            <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
              <div style="color: #4a69ff; font-size: 14px">
                Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
              </div>
              <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
                <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                  class="iconfont icon-icon_remind"></i>

                <div style="font-size: 14px">Cách chơi</div>
              </div>
            </div>

            <div v-if="funcIndex == 0">
              <group isSouth="1" title="Chục" style="margin-top: 30px" :recordData="recordData" :selectSub="selectSub"
                :type="1"></group>
              <group isSouth="1" title="Đơn Vị" style="margin-top: 30px" :recordData="recordData" :selectSub="selectSub"
                :type="2"></group>
            </div>
            <div v-if="funcIndex == 1">
              <random :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></random>
            </div>
            <div v-if="funcIndex == 2">
              <fastChoose :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></fastChoose>
            </div>
          </div>
          <div v-if="selectColum && selectColum.PlayCode == '1D'" style="margin-top: 24px; padding-bottom: 20px">
            <div class="flexs_sb">
              <div class="flexs_r_c" style="margin-left: 10px">
                <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                  :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item.SubName }}
                </div>
              </div>
              <div class="flexs_r_c" style="margin-right: 13px">
                <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                  :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
              <div style="color: #4a69ff; font-size: 14px">
                Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
              </div>
              <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
                <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                  class="iconfont icon-icon_remind"></i>

                <div style="font-size: 14px">Cách chơi</div>
              </div>
            </div>

            <group isSouth="1" title="Chục" v-if="selectSub.SubPlayCode == '1D_TJ4'" style="margin-top: 30px"
              :recordData="recordData" :selectSub="selectSub" :type="2"></group>
            <group isSouth="1" title="Đơn Vị" v-else style="margin-top: 30px" :recordData="recordData"
              :selectSub="selectSub" :type="1"></group>
          </div>
          <div v-if="selectColum && selectColum.PlayCode == '3DTW'" style="margin-top: 24px; padding-bottom: 20px">
            <div class="flexs_sb">
              <div class="flexs_r_c" style="margin-left: 10px">
                <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                  :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item.SubName }}
                </div>
              </div>
              <div class="flexs_r_c" style="margin-right: 13px">
                <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                  :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item }}
                </div>
              </div>
            </div>

            <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
              <div style="color: #4a69ff; font-size: 14px">
                Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
              </div>
              <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
                <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                  class="iconfont icon-icon_remind"></i>

                <div style="font-size: 14px">Cách chơi</div>
              </div>
            </div>

            <div v-if="funcIndex == 0">
              <group isSouth="1" title="Trăm" style="margin-top: 30px" :recordData="recordData" :selectSub="selectSub"
                :type="3"></group>
              <group isSouth="1" title="Chục" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub"
                :type="2"></group>
              <group isSouth="1" title="Đơn Vị" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub"
                :type="1"></group>
            </div>

            <div v-if="funcIndex == 1">
              <random :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></random>
            </div>
            <div v-if="funcIndex == 2">
              <fastChoose :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></fastChoose>
            </div>
          </div>
          <div v-if="selectColum && selectColum.PlayCode == '4D'" style="margin-top: 24px; padding-bottom: 20px">
            <div class="flexs_sb">
              <div class="flexs_r_c" style="margin-left: 10px">
                <div v-for="(item, index) in selectColum.SubPlays" @click="subClick(item, index)" class="canClick"
                  :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item.SubName }}
                </div>
              </div>
              <div class="flexs_r_c" style="margin-right: 13px">
                <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                  :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
              <div style="color: #4a69ff; font-size: 14px">
                Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
              </div>
              <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
                <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                  class="iconfont icon-icon_remind"></i>

                <div style="font-size: 14px">Cách chơi</div>
              </div>
            </div>
            <div v-if="funcIndex == 0">
              <group isSouth="1" title="Ngàn" style="margin-top: 30px" :recordData="recordData" :selectSub="selectSub"
                :type="4"></group>

              <group isSouth="1" title="Trăm" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub"
                :type="3"></group>
              <group isSouth="1" title="Chục" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub"
                :type="2"></group>
              <group isSouth="1" title="Đơn Vị" style="margin-top: 20px" :recordData="recordData" :selectSub="selectSub"
                :type="1"></group>
            </div>
            <div v-if="funcIndex == 1">
              <random :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></random>
            </div>
          </div>
          <div v-if="selectColum && selectColum.PlayCode == 'BCZH'" style="margin-top: 24px; padding-bottom: 20px">
            <div class="flexs_sb">
              <div class="flexs_r_c" style="margin-left: 10px">
                <div v-for="(item, index) in getNoBCZH()" @click="subClick(item, index)" class="canClick"
                  :class="selectSubIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item.SubName }}
                </div>
              </div>
              <div class="flexs_r_c" style="margin-right: 13px">
                <div v-for="(item, index) in funcArr" class="canClick" @click="funcIndex = index"
                  :class="funcIndex == index ? 'selectSub' : 'nolSub'" style="
                height: 33px;
                line-height: 33px;
                font-size: 12px;
                padding: 0 8px;
                border-radius: 5px;
                margin-left: 3px;
              ">
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="flexs_sb" style="margin-top: 25px; padding: 0 20px; color: #4a69ff">
              <div style="color: #4a69ff; font-size: 14px">
                Tỉ lệ Cược :{{ selectSub.Odds / 1000 }}
              </div>
              <div class="flexs_r_c canClick" @click="showInfo(selectSub.Description)">
                <i style="position: relative; top: 1px; margin-right: 4px; font-size: 16px" id="info_rule"
                  class="iconfont icon-icon_remind"></i>

                <div style="font-size: 14px">Cách chơi</div>
              </div>
            </div>

            <div v-if="funcIndex == 0">
              <random :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></random>
            </div>
            <div v-if="funcIndex == 1">
              <fastChoose :recordData="recordData" style="margin-top: 30px" :selectSub="selectSub"></fastChoose>
            </div>
          </div>
        </div>
        <div v-if="type == 'Vip'">
          <div style="height: 72px" class="flexs_r_c">
            <div class="flexs_r_c canClick" @click="showInfo(playData[0].SubPlays[0].Description)">
              <img src="@/assets/pc/lottery/info.webp" alt="" style="width: 21px; margin-left: 25px" />
              <div style="margin-left: 5px; font-size: 12px; color: #aac5f2">Cách chơi</div>
            </div>
          </div>
          <div v-for="(item, index) in playData">
            <div class="tctBox">
              <div class="title">{{ item.Name }}</div>
              <div v-if="item.PlayCode == 'SUM'" style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Tài', item.SubPlays[0].Odds / 1000, 'SUM_DXDS')
                  " :style="containTS('Tài')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ item.SubPlays[0].Odds / 1000 }}</div>

                  Tài
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Xỉu', item.SubPlays[0].Odds / 1000, 'SUM_DXDS')
                  " :style="containTS('Xỉu')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ item.SubPlays[0].Odds / 1000 }}</div>

                  Xỉu
                </div>
                <div class="tctList playNolmal canClick"
                  @click="vTsClick(item.Name, 'Lẻ', item.SubPlays[0].Odds / 1000, 'SUM_DXDS')" :style="containTS('Lẻ')
                      ? 'background-color: #4a69ff;'
                      : 'background-color: #363d4e;'
                    ">
                  <div class="rightNum">{{ item.SubPlays[0].Odds / 1000 }}</div>

                  Lẻ
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Chẵn', item.SubPlays[0].Odds / 1000, 'SUM_DXDS')
                  " :style="containTS('Chẵn')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ item.SubPlays[0].Odds / 1000 }}</div>

                  Chẵn
                </div>
              </div>
              <div v-if="item.PlayCode == 'DXDS'" style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Tài Lẻ', item.SubPlays[0].Odds / 1000, 'DXDS_T')
                  " :style="containTS('Tài Lẻ')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ item.SubPlays[0].Odds / 1000 }}</div>

                  Tài Lẻ
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Xỉu Lẻ', item.SubPlays[0].Odds / 1000, 'DXDS_T')
                  " :style="containTS('Xỉu Lẻ')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ item.SubPlays[0].Odds / 1000 }}</div>

                  Xỉu Lẻ
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Tài Chẵn', item.SubPlays[0].Odds / 1000, 'DXDS_T')
                  " :style="containTS('Tài Chẵn')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ item.SubPlays[0].Odds / 1000 }}</div>

                  Tài Chẵn
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Xỉu Chẵn', item.SubPlays[0].Odds / 1000, 'DXDS_T')
                  " :style="containTS('Xỉu Chẵn')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ item.SubPlays[0].Odds / 1000 }}</div>

                  Xỉu Chẵn
                </div>
              </div>

              <div v-if="item.PlayCode == 'ELE5'" style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Kim', getJMSHT('J', item.SubPlays) / 1000, 'ELE5_JT')
                  " :style="containTS('Kim')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getJMSHT("J", item.SubPlays) / 1000 }}</div>

                  Kim
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Mộc', getJMSHT('M', item.SubPlays) / 1000, 'ELE5_MH')
                  " :style="containTS('Mộc')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getJMSHT("M", item.SubPlays) / 1000 }}</div>

                  Mộc
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Thủy', getJMSHT('S', item.SubPlays) / 1000, 'ELE5_S')
                  " :style="containTS('Thủy')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getJMSHT("S", item.SubPlays) / 1000 }}</div>

                  Thủy
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Hỏa', getJMSHT('H', item.SubPlays) / 1000, 'ELE5_MH')
                  " :style="containTS('Hỏa')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getJMSHT("H", item.SubPlays) / 1000 }}</div>

                  Hỏa
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(item.Name, 'Thổ', getJMSHT('T', item.SubPlays) / 1000, 'ELE5_JT')
                  " :style="containTS('Thổ')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getJMSHT("T", item.SubPlays) / 1000 }}</div>

                  Thổ
                </div>
              </div>
              <div v-if="item.PlayCode == 'UPDOWN'" style="padding: 0 10px" class="flexs_sb">
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(
                    item.Name,
                    'Trên',
                    getSZX('S', item.SubPlays) / 1000,
                    'UPDOWN_UP_DOWN'
                  )
                  " :style="containTS('Trên')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getSZX("S", item.SubPlays) / 1000 }}</div>

                  Trên
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(
                    item.Name,
                    'Hoà',
                    getSZX('Z', item.SubPlays) / 1000,
                    'UPDOWN_DRAW'
                  )
                  " :style="containTS('Hoà')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getSZX("Z", item.SubPlays) / 1000 }}</div>

                  Hoà
                </div>
                <div class="tctList playNolmal canClick" @click="
                  vTsClick(
                    item.Name,
                    'Dưới',
                    getSZX('X', item.SubPlays) / 1000,
                    'UPDOWN_UP_DOWN'
                  )
                  " :style="containTS('Dưới')
      ? 'background-color: #4a69ff;'
      : 'background-color: #363d4e;'
    ">
                  <div class="rightNum">{{ getSZX("X", item.SubPlays) / 1000 }}</div>

                  Dưới
                </div>
              </div>
            </div>
          </div>
        </div>

        <vipInfo v-if="showVip" :info="selectInfo" @close="showVip = false"></vipInfo>
      </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import group from "./group.vue";
import random from "./random.vue";
import fastChoose from "./fastChoose.vue";
import vipInfo from "./vipInfo.vue";
import ProgressBar from "./ProgressBar.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      selectColumIndex: 0,
      selectColum: null,
      selectSubIndex: 0,
      selectSub: null,
      funcArr: ["Chọn Số", "Nhập Số", "Chọn Số Nhanh"], //选择号码 输入号码 快选
      funcIndex: 0,
      bottomIndex: 0,
      bigSmallArr: [],
      doubleSingleArr: [],
      selectTS: [],
      selectInfo: null,
      showVip: false,
      highType: "",
      showAll: true
    };
  },
  components: { group, random, fastChoose, vipInfo, ProgressBar },
  props: {
    type: "",
    playData: [],
    recordData: [],
    selectSubId: null,
  },
  computed: {
    ...mapGetters("tcp_lottery", ["Lotteries", "Plays", "Timestamp"]),
  },
  created() {
    var that = this;
    bus.on("lotteryChange", (val) => {



      that.selectColum = this.playData[0];
      that.selectSub = this.selectColum.SubPlays[0];
      that.selectColumIndex = 0;
      that.selectSubIndex = 0;
    });
    if (this.selectSubId) {
      for (var i = 0; i < this.playData.length; i++) {
        if (this.selectSubId.indexOf(this.playData[i].PlayCode) >= 0) {
          this.selectColum = this.playData[i];

          that.selectColumIndex = i;
          var subPlays = this.playData[i].SubPlays;
          for (var j = 0; j < subPlays.length; j++) {
            if (subPlays[j].SubPlayCode == this.selectSubId) {
              that.selectSub = subPlays[j];
              that.selectSubIndex = j;
            }
          }
        }
      }
    }
    bus.on("allClean", (val) => {
      that.selectTS = [];
    });

   var arr = this.playData
   arr = arr.sort((a, b) => a.PlaySort - b.PlaySort)

    this.selectColum = arr[0];

    this.selectSub = this.selectColum.SubPlays[0];


            console.log('vvvv',this.playData,this.playData[0])

    this.configHighType();
  },
  methods: {
    showInfo(text) {
      this.selectInfo = text;
      this.showVip = true;
    },
    containTS(str) {
      //var contain = false
      for (var item of this.selectTS) {
        if (str == item) {
          return true;
        }
      }
      return false;
    },
    nextClick(type) {
      if (type == 0) {
        document.getElementById("playWrap").scrollLeft -= 300;
      } else {
        document.getElementById("playWrap").scrollLeft += 300;
      }
    },
    getJMSHT(type, data) {
      if (type == "J" || type == "T") {
        for (var obj of data) {
          if (obj.SubPlayCode == "ELE5_JT") {
            return obj.Odds;
          }
        }
      } else if (type == "M" || type == "H") {
        for (var obj of data) {
          if (obj.SubPlayCode == "ELE5_MH") {
            return obj.Odds;
          }
        }
      } else {
        for (var obj of data) {
          if (obj.SubPlayCode == "ELE5_S") {
            return obj.Odds;
          }
        }
      }
    },
    getFunnyDXDSODD(subplays) {
      for (var obj of subplays) {
        if (obj.SubPlayCode == "FUNNY_DX_DS") {
          return obj.Odds / 1000;
        }
      }
    },
    getFunnyNumberODD(subplays) {
      for (var obj of subplays) {
        if (obj.SubPlayCode == "FUNNY_NUMBER") {
          return obj.Odds / 1000;
        }
      }
    },
    getFunnyDXDS(type){
      var arr = this.Plays[type]


      for(var obj of arr){
        if(obj.PlayCode=="FUNNY"){
          var subPlays = obj.SubPlays
          for(var obj2 of subPlays){
            if(obj2.SubPlayCode=="FUNNY_DX_DS"){
              return obj2
        }
          }
        }
       
      }
    },
    getFunnyNumber(type){
      var arr = this.Plays[type]

      for(var obj of arr){
        if(obj.PlayCode=="FUNNY"){
          var subPlays = obj.SubPlays
          for(var obj2 of subPlays){
            if(obj2.SubPlayCode=="FUNNY_NUMBER"){
              return obj2
        }
          }
        }
       
      }
    },
    getPersent(type) {
      //0大 1小 2单 3双
      var arr;
      if (type == 0 || type == 1) {
        arr = this.bigSmallArr;
      } else if (type == 2 || type == 3) {
        arr = this.doubleSingleArr;
      }

      var count = 0;
      var total = 0;

      for (var i = 0; i < (arr.length >= 16 ? 16 : arr.length); i++) {
        var tmpArr = arr[i];

        for (var j = 0; j < tmpArr.length; j++) {
          var str = tmpArr[j];
          total++;

          if (type == 0) {
            if (str == "big") {
              count++;
            }
          } else if (type == 1) {
            if (str == "small") {
              count++;
            }
          } else if (type == 2) {
            if (str == "single") {
              count++;
            }
          } else if (type == 3) {
            if (str == "double") {
              count++;
            }
          }
        }
      }

      return (count / total) * 100;
    },

    getNoBCZH() {
      var arr = [];
      for (var obj of this.selectColum.SubPlays) {
        if (obj.SubPlayCode.indexOf("BCZH_10") < 0) {
          arr.push(obj);
        }
      }
      return arr;
    },
    getSZX(type, data) {
      if (type == "S" || type == "X") {
        for (var obj of data) {
          if (obj.SubPlayCode == "UPDOWN_UP_DOWN") {
            return obj.Odds;
          }
        }
      } else {
        for (var obj of data) {
          if (obj.SubPlayCode == "UPDOWN_DRAW") {
            return obj.Odds;
          }
        }
      }
    },
    configHighType() {
      if (this.selectColum.PlayCode == "BZ") {
        if (this.selectSub.SubPlayCode == "BZ_C2_HEAD") {
          this.highType = "leftTwo";
        } else if (
          this.selectSub.SubPlayCode == "BZ_C2" ||
          this.selectSub.SubPlayCode == "BZ_C2_1K"
        ) {
          this.highType = "lastTwo";
        } else if (this.selectSub.SubPlayCode == "BZ_C3") {
          this.highType = "lastThree";
        } else if (this.selectSub.SubPlayCode == "BZ_C4") {
          this.highType = "lastFour";
        }
      } else if (
        this.selectColum.PlayCode == "ZH" ||
        this.selectColum.PlayCode == "BCZH"
      ) {
        this.highType = "lastTwo";
      } else if (this.selectColum.PlayCode == "TS") {
        if (this.selectSub.SubPlayCode == "TS_SUM_DX_DS") {
          this.highType = "sum";
        } else {
          this.highType =
            "num" +
            this.selectSub.SubPlayCode.substr(this.selectSub.SubPlayCode.length - 1, 1);
        }
      } else if (
        this.selectColum.PlayCode == "2DTW" ||
        this.selectColum.PlayCode == "1D" ||
        this.selectColum.PlayCode == "3DTW" ||
        this.selectColum.PlayCode == "4D"
      ) {
        this.highType = this.selectSub.SubPlayCode;
      } else if (this.selectColum.PlayCode == "FUNNY") {
        this.highType = "FUNNY";
      }
    },
    tsClick(str, code, sub, odd) {
      var contain = false;
      var newStr = str + code;
      for (var i = 0; i < this.selectTS.length; i++) {
        if (this.selectTS[i] == newStr) {
          contain = true;
          this.selectTS.splice(i, 1);

          break;
        }
      }
      if (!contain) {
        this.selectTS.push(newStr);
      }

      var num;
      //var odd = 1999
      if (code == "T") {
        num = "Tài";
      } else if (code == "X") {
        num = "Xỉu";
      } else if (code == "L") {
        num = "Lẻ";
      } else if (code == "C") {
        num = "Chẵn";
      } else {
        num = code;
        //odd = 9990
        if (this.selectColum.PlayCode == "FUNNY") {
          //odd = 99500
          num = num < 10 ? "0" + num : num;
        }
      }

      var obj = {
        num: num,
        Odds: odd,
        betType: "Tap",
        SubPlayCode: str ? str : this.selectSub.SubPlayCode,
        SubName: sub,

        UnitBetAmount: 1000,
      };
      bus.emit("BET", obj);
    },
    subClick(item, index) {
      this.selectSubIndex = index;
      this.selectSub = item;
      bus.emit("selectSub", {
        selectSub: this.selectSub,
      });

      this.funcIndex = 0;

      this.configHighType();
      if (this.selectColum.PlayCode == "TS") {
        this.bottomIndex = 0;
        var type1 = this.highType == "sum" ? 0 : 1;
        this.bigSmallArr = this.countTSData(type1, 0);
      }
      if (this.selectColum.PlayCode == "ZH" || this.selectColum.PlayCode == "BCZH") {
        this.funcIndex = 1;
      }
      if (this.selectColum.PlayCode == "2DTW") {
        this.funcIndex = 0;

        bus.emit("freshRecord", this.selectSub);
      }
      if (this.selectColum.PlayCode == "3DTW") {
        this.funcIndex = 0;

        bus.emit("freshRecord", this.selectSub);
      }

      if (this.selectColum.PlayCode == "BZ") {
        this.funcArr = ["Chọn Số", "Nhập Số", "Chọn Số Nhanh"];
        if (this.selectSub.SubPlayCode == "BZ_C4") {
          this.funcArr = ["Chọn Số", "Nhập Số"];
        }
      }

      bus.emit("clean");
    },
    vTsClick(str, code, odd, code1) {
      var contain = false;
      var newStr = code;
      for (var i = 0; i < this.selectTS.length; i++) {
        if (this.selectTS[i] == newStr) {
          contain = true;
          this.selectTS.splice(i, 1);

          break;
        }
      }
      if (!contain) {
        this.selectTS.push(newStr);
      }

      var obj = {
        num: code,
        Odds: odd * 1000,
        betType: "VIP",
        SubPlayCode: code1,
        Name: str,

        UnitBetAmount: 1000,
      };
      bus.emit("BET", obj);
    },
    bottomClick(index) {
      this.bottomIndex = index;
      var type1 = this.highType == "sum" ? "0" : "1";
      if (this.bottomIndex == 0) {
        this.bigSmallArr = this.countTSData(type1, 0);
      } else {
        this.doubleSingleArr = this.countTSData(type1, 1);
      }
    },
    columClick(index) {
      this.selectColumIndex = index;
      this.selectColum = this.playData[index];

      this.selectTS = [];
      this.selectSubIndex = 0;
      this.funcIndex = 0;
      this.funcArr = ["Chọn Số", "Nhập Số", "Chọn Số Nhanh"];
      this.selectSub = this.selectColum.SubPlays[0];

      console.log('ggggg', this.selectSub)
      bus.emit("selectColum", this.selectColum);
      bus.emit("selectSub", {
        selectColum: this.selectColum,
        selectSub: this.selectSub,
      });

      this.configHighType();
      if (this.selectColum.PlayCode == "TS") {
        this.bigSmallArr = this.countTSData(0, 0);
      }

      if (this.selectColum.PlayCode == "BZ") {
        this.funcArr = ["Chọn Số", "Nhập Số", "Chọn Số Nhanh"];
      }
      if (this.selectColum.PlayCode == "FUNNY" || this.selectColum.PlayCode == "4D") {
        this.funcArr = ["Chọn Số", "Nhập Số"];
        this.funcIndex = 0;
      }
      if (this.selectColum.PlayCode == "ZH" || this.selectColum.PlayCode == "BCZH") {
        this.funcArr = ["Nhập Số", "Chọn Số Nhanh"];
        this.funcIndex = 1;
      }
      if (this.selectColum.PlayCode == "1D") {
        this.funcArr = ["Chọn Số"];
        this.funcIndex = 0;
      }
      this.showAll = false
      var that = this
      setTimeout(() => {
        that.showAll = true
      }, 100);
    },
    countTSData(type, type2) {
      var data = this.recordData;
      var index = 0;
      var indexArr = [];
      var tmpArr = [];
      var lastArr = [];

      var lastStr;
      for (var i = 0; i < data.length; i++) {
        var num = data[i].OpenCode.L0[0];
        tmpArr.push(num);
      }
      for (var num of tmpArr) {
        var str;
        if (type == 0) {
          var total = 0;
          for (var i = 0; i < num.length; i++) {
            total += parseInt(num[i]);
          }

          if (type2 == 0) {
            if (total >= 23) {
              str = "big";
            } else {
              str = "small";
            }
          } else {
            if (total % 2 == 0) {
              str = "double";
            } else {
              str = "single";
            }
          }
          if (lastStr == str) {
            if (indexArr.length < 6) {
              indexArr.push(str);
              lastArr[index] = indexArr;
            } else {
              indexArr = [];
              index++;
              indexArr.push(str);
            }
          } else {
            indexArr = [];
            if (lastStr) {
              index++;
            }
            indexArr.push(str);
          }
          lastArr[index] = indexArr;

          lastStr = str;
        } else {
          var sub = this.highType.substr(3, 1);
          var tmpNum = num.substr(5 - sub, 1);

          if (type2 == 0) {
            if (tmpNum >= 5) {
              str = "big";
            } else {
              str = "small";
            }
          } else {
            if (tmpNum % 2 == 0) {
              str = "double";
            } else {
              str = "single";
            }
          }
          if (lastStr == str) {
            if (indexArr.length < 6) {
              indexArr.push(str);
              lastArr[index] = indexArr;
            } else {
              indexArr = [];
              index++;
              indexArr.push(str);
            }
          } else {
            indexArr = [];
            if (lastStr) {
              index++;
            }
            indexArr.push(str);
          }
          lastArr[index] = indexArr;

          lastStr = str;
        }
      }
      if (lastArr.length > 18) {
        lastArr = lastArr.slice(0, 18);
      }
      var arrs = [];
      for (var i = lastArr.length - 1; i >= 0; i--) {
        arrs.push(lastArr[i]);
      }

      return arrs;
    },
  },
};
</script>

<style scoped lang="scss">
.selectColum {
  color: #fff;
  background-image: linear-gradient(180deg, #81a2ff, #4a69ff);
}

.nolColum {
  //background-color: #1b2c5e;
  color: #677684;
}

.colum {
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 16px;
  box-shadow: inset 0 -2px 3px 0 rgba(0, 0, 0, 0.06);
  border-radius: 28px;
  margin: 0 3px;
  position:relative;
  border: 1px solid #525252;
  overflow:visible;
}
.hotImg{

    position: absolute;
    right: 0;
    top: -4px;
    width: 36px;
    height: 14px;
}
.selectSub {
  background: #4a69ff;
  color: #fff;
}

.nolSub {
  background: #1e222c;
  color: #fff;
}

.fourbtn {
  width: 200px;
  height: 71px;
  text-align: center;
  border-radius: 3px;
  color: white;
  font-size: 12px;
}

.threeBtn {
  width: 270px;
  height: 71px;
  text-align: center;
  color: white;
  font-size: 12px;
  border-radius: 3px;
}

.fiveBtn {
  width: 155px;
  height: 71px;
  text-align: center;
  color: white;
  font-size: 12px;
  border-radius: 3px;
}

.tenBtn {
  position: relative;
  box-shadow: inset 0 -1px 3px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 66px;
  height: 44px;
  line-height: 22px;
  border-radius: 7.36px;
  color: #677684;
  font-size: 12px;
  margin: 8px 8px 0 0;
  text-align: center;
}

.playSelect {
  background-color: #4a69ff;
}

.playNolmal {
  background-color: #363d4e;
}

.bottomBtn {
  height: 40px;
  line-height: 40px;
  width: 62px;
  text-align: center;
}

.bottomSe {
  background-image: none;
  border-bottom: 1px solid #fff;
  color: #fff;
}

.bottomNol {
  color: #8491a5;
}

.corner {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 24px;
}

.playWrap {
  overflow: scroll;
  scroll-behavior: smooth;
  white-space: nowrap;
  height:100%;
}

.playWrap::-webkit-scrollbar {
  display: none;
}

.playWrap {
  /* 隐藏滚动条 */
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

.tctBox {
  margin: 0 15px;
  margin-top: 20px;
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border: 1px solid rgba(214, 217, 224, 0.29);
  background: #1e242c;
  padding-bottom: 8px;

  .title {
    padding-left: 12px;
    height: 32px;
    width: 100%;
    line-height: 32px;
    font-size: 14px;
    color: #8491a5;
    background: #1e242c;
    text-align: left;
  }

  .title:before {
    content: "";
    width: 2px;
    height: 12px;
    display: inline-block;
    margin-right: 6px;
    background-color: #4a69ff;
  }

  .tctList {
    .rightNum {
      position: absolute;
      top: 6px;
      right: 6px;
      color: hsla(0, 0%, 100%, 0.3);
      font-size: 12px;
    }

    position: relative;
    box-shadow: inset 0 -1px 3px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-sizing: border-box;
    border: 1px solid transparent;
    width: 175px;
    height: 48px;
    margin: 6px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-size: 18px;
    flex: 1;
  }
}

.playSelect {
  background-color: #4a69ff;
}

.playNolmal {
  background-color: #363d4e;
}
</style>
<style>
.el-tabs__nav-next,
.el-tabs__nav-prev {
  position: absolute;
  cursor: pointer;
  line-height: 44px;
  font-size: 12px;
  color: #90a2dc;
  background-color: #292e3b;
  z-index:5;
}

.el-progress {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
}

.elProgress1 {
  color: rgb(249, 65, 104);
}

.elProgress2 {
  color: rgb(249, 65, 104);
}

.el-progress__text {
  color: inherit !important;
}
</style>
