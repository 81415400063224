<template>
  <div class="sport">
    <div class="liveAndSports">
      <div class="noBetterScroll list-effect">
        <div v-for="(item, index) in listData" class="item list-item-effect"
          @click="openFactory(item.Factory.FactoryName)">
          <img :src="$img(`sport/${item.Factory.FactoryName}_icon_2.png`, 'mobile')" alt="">
          <p>Phí hoàn tối đa 1.7% </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.css";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const { version } = useGetters("global", ["version"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
let listData = getFactorDataBytype('LiveSports');
function getFactorDataBytype(type) {
  let data = lobbyInfo.value.StaticPicture[type];
  let rusult = [];
  if (!data) return;
  for (let key in data) {
    let info = data[key];
    if (info.Factory.Status == 1) {
      rusult.push(info);
    }
  }
  rusult.sort((a, b) => {
    return b.Factory.Sort - a.Factory.Sort
  })
  return rusult;
}
const openFactory = (factoryName) => {
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  if (factoryName) {
    let url = getApiLoginUrl(factoryName, '');
    $act.openPage(url);
  }
}
</script>

<style lang="scss" scoped>
.sport {
  position: relative;
  width: 100%;

  .liveAndSports {
    position: relative;
    background: #35416d;
    padding-left: 4.266667vw;
    padding-right: 4.266667vw;

    .noBetterScroll {
      padding: 1.6vw 0;
      padding-bottom: 8vw;
      display: flex;
      flex-wrap: wrap;

      .list-item-effect {
        margin-bottom: 1.6vw;
      }

      .item {
        width: 91.466667vw;
        height: 36.266667vw;
        margin: 1.6vw 0;
        position: relative;
        color: #fff;
        font-size: 3.2vw;
        display: inline-block;
        text-align: center;

        img {
          width: 100%;
          height: 100%;
          border-radius: 1.066667vw;
        }

        p {
          width: 40vw;
          position: absolute;
          top: 16.533333vw;
          right: 4.8vw;
          text-align: center;
          -webkit-transform: scale(.9);
          transform: scale(.9);
        }
      }
    }

    .list-effect {
      -webkit-animation: list-effect-ani .5s ease-in-out forwards;
      animation: list-effect-ani .5s ease-in-out forwards;

      .list-item-effect {
        transition: all .3s ease-in-out;
      }
    }
  }

  @keyframes list-effect-ani {
    0% {
      opacity: 0;
      -webkit-transform: translateY(5.333333vw);
      transform: translateY(5.333333vw);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}
</style>