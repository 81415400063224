<template>
  <div class="pop">
    <van-popup v-model:show="show" round @close="close" :lock-scroll="false">
      <div class="search-bar">
        <input v-model="searchObj.searchKey" class="input" type="seach" placeholder="Tìm kiếm" v-on:input="searchFun">
        <span class="iconfont icon-icon_search"></span>
      </div>
      <div class="game-list-wp">
        <div class="van-list">
          <div class="slotFishGameList">
            <div class="gameLists">
              <ul class="game_list">
                <li v-if="searchObj.isSearch" v-for="(item, index) in searchObj.data" @click="itemClick(item)">
                  <img class="gameIcon" :src="getImg(item)" alt="">
                  <p>{{ item.GameName }}</p>
                </li>
                <li v-else v-for="(item, index) in props.data" @click="itemClick(item)">
                  <img class="gameIcon" :src="getImg(item)" alt="">
                  <p>{{ item.GameName }}</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import HomeHeader from "@/views/mobile/components/HomeHeader.vue"
import Recommend from "@/views/mobile/components/home/Recommend.vue"
import LiveCasino from "@/views/mobile/components/home/LiveCasino.vue"
import Sport from "@/views/mobile/components/home/Sport.vue"
import Chess from "@/views/mobile/components/home/Chess.vue"
import Fish from "@/views/mobile/components/home/Fish.vue"
import CockFight from "@/views/mobile/components/home/CockFight.vue"
import Slot from "@/views/mobile/components/home/Slot.vue"
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
let searchObj = reactive({
  searchKey: '',
  isSearch: false,
  data: []
});
let show = ref(true);
const props = defineProps({
  data: {
    type: [],
    default: [],
  }
})
function searchFun(key) {
  if (searchObj.searchKey.length == 0) {
    searchObj.isSearch = false;
    return;
  }
  searchObj.data = [];
  props.data.forEach(item => {
    if (item.GameName) {
      if (item.GameName.toLowerCase().indexOf(searchObj.searchKey.toLowerCase()) >= 0) {
        searchObj.data.push(item);
      }
    }
  });
  searchObj.isSearch = true;
}
function language() {
  return i18n.global.locale;
}
function getImg(item) {
  let str = lobbyInfo.value.HotDomain + '/gameIcon2' + '/' + item.FactoryName + '/' + language() + '/' + item.GameCode + '.png';
  return str;
}
function itemClick(item) {
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  let url = getApiLoginUrl(item.FactoryName, item.GameCode);
  $act.openPage(url);
  if (item.GameType == 'Slot' || item.GameType == 'Fish' || item.GameType == 'Card') {
    addHistory(item);
  }
}
function addHistory(item) {
  let hisArr = [];
  if (localStorage.getItem('history')) {
    hisArr = JSON.parse(localStorage.getItem('history'))
  }
  let isHave = false;//是否已存在
  for (let key in hisArr) {
    let info = hisArr[key];
    if (item.GameCode == info.GameCode) {
      isHave = true;
      break;
    }
  }
  if (!isHave) {
    hisArr = [item].concat(hisArr);
    localStorage.setItem('history', JSON.stringify(hisArr));
    bus.emit(bus.event.showHistoryRecord, true);
  }
}

const close = () => {
  bus.emit(bus.event.showPop, false);
}
</script>

<style lang="scss" scoped>
.pop {
  :deep(.van-popup) {
    min-height: 200px;
  }
}


.search-bar {
  width: 200px;
  margin: 10px 0 0 10px;
  position: relative;

  .input {
    position: relative;
    width: 100%;
    background: #12182e;
    color: #fff;
    box-shadow: none;
    border-radius: 2.933333vw;
    border: none;
    min-width: 200px;
    height: 30px;
    font-size: 16px;
    padding: 0 30px;
    box-sizing: border-box;
  }

  .iconfont {
    position: absolute;
    color: #90a2dc;
    top: 50%;
    transform: translateY(-50%);
    margin-left: -30px;
    font-size: 22px;
  }
}

.game-list-wp {
  max-height: 66vh;
  overflow-y: auto;

  .van-list {
    .slotFishGameList {
      -webkit-transform: scale(0);
      transform: scale(0);
      transition: all .3s;
      overflow-y: auto;
      -webkit-transform: scale(1);
      transform: scale(1);
      opacity: 1;

      .gameLists {
        background: hsla(0, 0%, 100%, .8);
        background-image: linear-gradient(90deg, #e6eaff, #fff);
        border-radius: 4.266667vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        height: 100%;
        width: 91.466667vw;
        padding: 0 2.666667vw 1.6vw;
        box-sizing: border-box;

        .game_list {
          display: flex;
          flex-wrap: wrap;
          margin: 0 auto;
          width: 100%;
          justify-content: flex-start;
          box-sizing: border-box;

          li {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            box-sizing: border-box;
            width: 24vw;
            margin-right: 6.933333vw;
            margin-top: 2.666667vw;

            .gameIcon {
              width: 24vw;
              height: 24vw;
              border-radius: 50%;
            }

            p {
              margin-top: 0.533333vw;
              font-size: 3.2vw;
              color: #2a2a2a;
              text-align: center;
              font-weight: 600;
              -webkit-transform: scale(.9);
              transform: scale(.9);
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          li:nth-of-type(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>