<template>
	<div class="wrap flexs_colum " @click="closeSelf">
		<div class="content" @click.stop="">
			<div class="title boldSize">{{ $t("公告") }}</div>
			<img @click="closeSelf" :src="$img('notice/close_blue.png', 'mobile')" alt=""
				style="width: 26px;position: absolute;right: 1vw;top: 5px ;">

			<div class="bottomC" style="overflow: hidden;">
				<van-swipe height="34vh" style="width: 100%;" class="my-swipe" :autoplay="3000" loop
					:show-indicators="false">
					<van-swipe-item v-for="item in notice">
						<div class="bottomC" style="position: relative;display: flex;flex-direction: column;">
							<div class="boldSize phoneMiddle" style="margin-top: 1vh;text-align: center;">{{ item.Title }}
							</div>


							<div class="phoneMiddle"
								style="margin-top: 1vh;line-height: 4vw;word-break: break-all;width: 90%;margin-left: 5%;">
								{{ item.Content }}</div>
							<img :src="item.Url" alt=""
								style="width: 90%;margin-top: 1vh;margin-left: 5%;margin-bottom: 20px;">


						</div>
					</van-swipe-item>
				</van-swipe>
			</div>

		</div>
	</div>
</template>

<script>
import {
	mapGetters,
	mapActions,
	mapMutations
} from "vuex";

export default {
	name: "Message",
	data() {
		return {};
	},
	props: {

	},
	computed: {
		...mapGetters("tcp", ["isLogged", "notice"]),


	},
	created() {
		if (this.isLogged) {
		}
	},
	methods: {
		closeSelf() {
			this.$emit('close')
		}
	},
};
</script>

<style lang="scss" scoped>
.wrap {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, .7);
	z-index: 9999;

}

.flexs_colum {
	display: flex;
	flex-direction: column;
}

.boldSize {
	font-weight: bold;
}

.phoneMiddle {
	font-size: 3.2vw;
}

.content {
	height: 40vh;
	margin-top: 30vh;
	width: 92vw;
	margin-left: 4vw;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	overflow-x: hidden;
	background-color: white;
	position: relative;

	.title {

		height: 6vh;
		background: linear-gradient(to right, rgb(110, 72, 255), rgb(85, 95, 255));
		line-height: 6vh;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		text-align: center;
		color: white;
		font-size: 5vw;

	}

	.bottomC {
		height: 34vh;
		width: 100%;
		// margin-left: 3%;
		overflow-y: auto;
		text-align: center;

	}
}

textarea {
	border: none;
	outline: none;
	/*边线不显示*/
	resize: none;
	/*禁止拉伸*/
	background: white;
	/*带点绿*/
	appearance: none;
}
</style>
