<template>
  <div class="fish">
    <div class="liveAndSports">
      <div class="noBetterScroll list-effect">
        <div v-for="(item, index) in listData" class="item list-item-effect" @click="tabItemClick(index)">
          <img :src="$img(`fish/${item.Factory.FactoryName}_icon_2.png`, 'mobile')" alt="">
        </div>
      </div>
    </div>
  </div>
  <CommonItemPop :data="listData[curSelectIndex].GameList" v-if="showPop" />
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from 'vue-router';
import CommonItemPop from "@/views/mobile/components/CommonItemPop.vue"
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.css";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const { version } = useGetters("global", ["version"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
let curSelectIndex = ref(0);
let showPop = ref(false);
bus.on(bus.event.showPop, (bool) => {
  showPop.value = false;
})
let listData = getFactorDataBytype('Fish');
function getFactorDataBytype(type) {
  let data = lobbyInfo.value.StaticPicture[type];
  let rusult = [];
  if (!data) return;
  for (let key in data) {
    let info = data[key];
    if (info.Factory.Status == 1) {
      rusult.push(info);
    }
  }
  rusult.sort((a, b) => {
    return b.Factory.Sort - a.Factory.Sort
  })
  return rusult;
}
function tabItemClick(index) {
  curSelectIndex.value = index;
  showPop.value = true;
}
</script>

<style lang="scss" scoped>
.fish {
  position: relative;
  width: 100%;

  .liveAndSports {
    position: relative;
    background: #35416d;
    padding-left: 4.266667vw;
    padding-right: 4.266667vw;

    .noBetterScroll {
      padding: 1.6vw 0;
      padding-bottom: 8vw;
      display: flex;
      flex-wrap: wrap;

      .list-item-effect {
        margin-bottom: 1.6vw;
      }

      .item {
        height: 23.466667vw;
        width: 91.466667vw;
        margin: 2.666667vw auto;
        overflow: hidden;
        margin-bottom: 2.666667vw;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .list-effect {
      -webkit-animation: list-effect-ani .5s ease-in-out forwards;
      animation: list-effect-ani .5s ease-in-out forwards;

      .list-item-effect {
        transition: all .3s ease-in-out;
      }
    }
  }

  @keyframes list-effect-ani {
    0% {
      opacity: 0;
      -webkit-transform: translateY(5.333333vw);
      transform: translateY(5.333333vw);
    }

    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
}
</style>