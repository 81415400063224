
<template>
  <div class="gift">
    <div class="flexs_r_c" style="padding-top: 10px;flex-wrap:wrap;width:94%;margin-left:3%;">
      <div v-for="(item, index) in topList" class="canClick topList" @click="topIndex = index"
        :class="topIndex == index ? 'select' : 'nol'">
        {{ item.ActivityClass }}
      </div>
    </div>
    <div class="content">
      <div class="item" v-for="(item, index) in getDataList()">
        <img :src="item.TitleUrl" @click="toInfo(item)" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { bus } from '../../../../components/core/bus';

export default {
  name: "Gift",
  setup() {

  },
  computed: {
    ...mapGetters("global", ["lobbyInfo"]),
  },

  created() {
    var list = this.lobbyInfo.ActivityConf.Rebate.concat(
      this.lobbyInfo.ActivityConf.Bonus
    );
    this.dataList = list
    for (var obj of list) {
      var hasContain = false;
      for (var newObj of this.topList) {
        if (newObj.ActivityClass == obj.ActivityClass) {
          hasContain = true;
        }
      }
      if (!hasContain) {
        this.topList.push(obj);
      }
    }
    this.topList = this.topList.sort((a, b) => a.Sort - b.Sort);
  },
  methods: {
    getDataList() {
      var className = this.topList[this.topIndex].ActivityClass
      var arr = []
      for (var obj of this.dataList) {
        if (obj.ActivityClass == className) {
          arr.push(obj)
        }
      }
      arr.sort((a, b) => a.Sort - b.Sort);
      return arr
    },
    toInfo(item) {
      // this.$router.push({
      //   path: '/giftInfo',
      //   query: {
      //     oid: item.Oid
      //   }
      // });
      bus.emit(bus.event.showGiftInfo, item);
    }
  },
  data() {
    return {
      dataList: [],
      topList: [],
      topIndex: 0
    };
  },
};
</script>

<style lang="scss" scoped>
.gift {

  .topList {
    margin-right: 4%;
    width: 22%;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 3px;
    font-size: 12px;
    margin-top: 10px;
  }

  .topList:nth-child(4n) {
    margin-right: 0;
  }

  .select {
    background: linear-gradient(to right, #6c49ff, #5063ff);
    color: #fff;
  }

  .nol {
    background-color: #35416d;
    color: #84a2dc;
  }

  .content {
    padding: 10px;

    img {
      width: 100%;
      cursor: pointer;
      margin-top: 5px;
    }
  }
}
</style>
<style>
/* .van-cell {
  padding: 0;
} */
.van-collapse-item__content {
  /* padding: 0 5px; */
  /* object-fit: contain;
  border-bottom: solid 3px #31c9ed;
  border-left: solid 3px #31c9ed;
  border-right: solid 3px #31c9ed; */
}
</style>
<style>
/* table {
  width: 100%;
  border-top: 1px solid black;
  border-left: 1px solid black;
}

table td,
table th {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  padding: 3px 5px;
}

table th {
  border-bottom: 1px solid black;
  text-align: center;
} */
</style>