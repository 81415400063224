<template>
  <div
   
    class="wrap"
    style="
      color: black;
      width: 100%;
      height: 100%;
      font-weight: bold;
      position: fixed;
      z-index: 2019;
      background-color: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;
    "
  >
    <div
	
      style="
        border-radius: 2.133333vw !important;
        position: relative;
        width: 72.266667vw;
        margin: 272px auto;
        background-color: white;
      "
    >
      <div class="header" style="">Cài đặt tiền cược mặc định</div>

      <div  class="content">
        <h2 >Xin lưu ý</h2>
        <p >
          1. Chỉ được cài đặt tiền cược là số dương và là bội số của 100
        </p>
        <p >
          2. Cài đặt tiền cược mặc định được áp dụng cho tất cả loại xổ số.
        </p>
        <div  class="inputBox">
          <span > Mặc định</span
          ><input  v-model="defaultNum" type="tel"  @input="defaultNum = checkNum(defaultNum)"/>
        </div>
      </div>

     
      <div class="flexs_sb" style="">
        <div class="btn left canClick" @click="cancel()">Hủy</div>
        <div class="btn right canClick" @click="sure()">Có hiệu lực ngay</div>
      </div>
    </div>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";

export default {
  data() {
    return {
      defaultNum: "",
    };
  },
  props: {},
  created() {
	this.defaultNum = this.formatNum(localStorage.getItem('defaultNum')?localStorage.getItem('defaultNum'):1000)  
  },
  methods: {
    cancel() {
      this.$emit("close");
    },
    checkNum(data) {
      if (data) {
        return (data = this.formatNum(Number(data.replace(/[^\d.%\\]/g, ""))));
      }
    },
    sure() {
      var subStr = (this.defaultNum + "").substr(this.defaultNum.length - 2, 2);
      //console.log('xx',subStr)
      for (var i = 0; i < subStr.length; i++) {
        if (parseInt(subStr[i]) != 0) {
			this.$tools.toast('Số tiền đặt cược bắt buộc phải là bôi số của 100, ví dụ 1,000, 1,500');

         
          return;
        }
      }
      bus.emit("defaultNum", this.defaultNum);
      localStorage.setItem("defaultNum", this.defaultNum);
      this.cancel();
    },
    formatNum(num) {
      var numeral = require("numeral");
      return numeral(num).format("0,000");
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  width: 50%;
  height: 48px;
  text-align: center;
  line-height: 48px;
  font-size: 3.733333vw;
 
}

.left {
 
  color: rgb(160, 160, 160);
}
.right {
  color: rgb(27, 35, 61);
}
.header {
  padding: 2.4vw 0;
  position: relative;
  font-size: 4.266667vw;
  color: #1b233d;
  text-align: center;
  font-weight: 500;
  line-height: 6.4vw;
}
.header:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(180deg, transparent 40%, #90a2dc);
}
.content{
    padding: 6.4vw;
	.inputBox {
		margin-top: 3.2vw;
		display: flex;
		justify-content: space-between;
		align-items: center;
		input {
			width: 41.333333vw;
			height: 8.533333vw;
			padding: 0 3.2vw;
			box-sizing: border-box;
			border: 1px solid #585858;
			border-radius: 1.066667vw;
			font-size: 3.733333vw;
			color: #585858;
			font-weight: 400;
		}
		span {
			font-size: 3.733333vw;
			color: #585858;
			font-weight: 400;
		}
	}
	p {
		font-size: 3.2vw;
		color: #f93e3e;
		font-weight: 400;
		margin-bottom: 1.6vw;
	}
	h2 {
		font-size: 3.733333vw;
		color: #f93e3e;
		font-weight: 400;
		margin-bottom: 3.2vw;
	}
}
</style>
