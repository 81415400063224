<template>
    <div class="record">
        <div class="cardInfo">
            <div class="cardTitle">
                <span class="title">Lịch sử giao dịch</span>
                <span class="titleRight">Có thể xem lịch sử giao dịch 90 ngày gần nhất</span>
            </div>
            <div class="cardBody noBgColor">
                <el-form label-position="left">
                    <el-form-item label="Loại hình:">
                        <el-select v-model="curTypeStr" @change="changeType">
                            <el-option v-for="(item, index) in typeOptions" :label="item.label" :value='item.label' />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Thời gian:">
                        <el-date-picker v-model="timeObj.model" type="daterange" :clearable='false' unlink-panels
                            range-separator="to" :start-placeholder="timeObj.model[0]" :end-placeholder="timeObj.model[1]"
                            @calendar-change="btnSelectIndex = -1" />
                        <el-button-group class="btnGroup">
                            <el-button type="info" v-for="(item, index) in btnGroupOptions"
                                :class="btnSelectIndex == index ? 'active' : ''" @click="changeTime(index)"> {{ item }}
                            </el-button>
                        </el-button-group>
                        <el-button class="el-button submitButton inquire el-button--default el-button--mini"
                            @click="search()"> Tìm kiếm
                        </el-button>
                        <el-button class="el-button submitButton  el-button--default el-button--mini" @click="reset()"> Đặt
                            lại
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="list-info">
            <div class="header">
                <div v-for="item in recordObj.header" class="header-item">{{ item }}</div>
            </div>
            <div class="content">
                <div v-if="recordObj.list.length <= 0" class="not-data">
                    <img :src="$img('user/not_data.png')" alt="">
                    <span>Chưa có dữ liệu</span>
                </div>
                <div v-for="(item, index) in recordObj.list" class="content-row">
                    <div class="content-item">
                        {{ $tools.formatDate(item.CreateAt, "yyyy-MM-dd") }}
                        {{ $tools.formatDate(item.CreateAt, "hh:mm") }}</div>
                    <div class="content-item">{{ $tools.formatNum2k(item.Amount) }} </div>
                    <div class="content-item">{{ item.TradeType }}</div>
                    <div class="content-item">{{ item.Remark }}</div>
                    <div class="content-item">
                        <div v-if="item.Status == 1">Đã được sử dụng</div>
                        <div v-else-if="item.Status == 3" style="color:red">Đang xử lý</div>
                        <div v-else-if="item.Status == 4" style="color:green">Thất bại</div>
                        <div v-else-if="item.Status == 9" class="">Thành công</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pagination">
            <el-pagination v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize"
                :page-sizes="[10, 20, 30, 40, 50]" :background="true" layout=" total, sizes, prev, pager, next, jumper"
                :total="paginationObj.total" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>

<script setup>
import {
    ref,
    onMounted,
    onBeforeUnmount,
    reactive,
    nextTick,
    watch,
} from "vue";

import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import sf_games_btn_02_svga from "@/assets/pc/svga/sf_games_btn_02.svga";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { nameRule, passRule, phoneRule, realNameRule } from '@/utils/vaildate.js'
import { topic } from "@/components/protocol/api";
import { http } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
    "onLogged",
    "doLogin",
]);

const typeOptions = [
    {
        label: 'Toàn bộ'.toUpperCase(),
    },
    {
        label: 'NẠP TIỀN',
    },
    {
        label: 'RÚT TIỀN',
    },
    {
        label: 'TIỀN TẶNG',
    },
    {
        label: 'HOÀN TRẢ',
    }
]
let curTypeStr = ref(typeOptions[0].label);
let curTypeIndex = ref(0);
let btnSelectIndex = ref(2);//默认7天
const btnGroupOptions = ['Hôm nay', 'Hôm qua', '7 ngày gần đây', '30 ngày gần đây']

let timeObj = reactive({
    model: getDefaultTime(7)
})
let recordObj = reactive({
    header: ['NGÀY', 'SỐ TIỀN', 'LOẠI', 'GHI CHÚ', 'TRẠNG THÁI'],
    allList: [],
    list: []
})

let paginationObj = reactive({
    currentPage: 1,
    pageSize: 10,
    total: 0
})
if (isLogged.value) {
    getBillRecord()
} else {
    bus.on('onConnected', () => {
        getBillRecord()
    })
}
function getDefaultTime(number) {
    let startTime = new Date();//默认7天前
    startTime.setTime(startTime.getTime() - 3600 * 1000 * 24 * number);
    let endTime = new Date();//当前时间
    return [startTime, endTime]
}

const handleSizeChange = (val) => {
    sliceArr();
}
const handleCurrentChange = (val) => {
    sliceArr();
}

function changeType(value) {
    let index = 0;
    for (let i = 0; i < typeOptions.length; i++) {
        if (value === typeOptions[i].label) {
            index = i;
            break;
        }
    }
    curTypeIndex.value = index;
    getBillRecord();
}

function changeTime(index) {
    btnSelectIndex.value = index;
    if (index == 0) {
        timeObj.model = getDefaultTime(0);
    } else if (index == 1) {
        timeObj.model = getDefaultTime(1);
    } else if (index == 2) {
        timeObj.model = getDefaultTime(7);
    } else if (index == 3) {
        timeObj.model = getDefaultTime(30);
    }
    getBillRecord()
}

function search() {
    getBillRecord();
}
function reset() {
    timeObj.model = getDefaultTime(7);
    btnSelectIndex.value = 2;
    paginationObj.currentPage = 1;
    paginationObj.pageSize = 10;
    sliceArr();
}
function sliceArr() {
    let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
    let endIndex = startIndex + paginationObj.pageSize;
    recordObj.list = recordObj.allList.slice(startIndex, endIndex);
    // log.info('sliceArr', recordObj.list)
}
function getBillRecord() {
    let tradeTypeArr = ['all', 'order', 'douDou', 'bonus', 'rebate'];
    let startDate = timeObj.model[0].Format("yyyy-MM-dd") + ' 00:00:00';
    let endDate = timeObj.model[1].Format("yyyy-MM-dd") + ' 23:59:59';
    let obj = {
        TradeType: tradeTypeArr[curTypeIndex.value],
        startDate: startDate,
        endDate: endDate
    }
    mqant.request(topic.billRecord, obj, function (data, topicName, msg) {
        recordObj.allList = []
        recordObj.allList = data.Data.data;
        //记录总数
        paginationObj.total = recordObj.allList.length;
        //分页截取
        sliceArr();
    });
}
</script>

<style lang="scss" scoped>
.record {
    .cardInfo {
        margin-bottom: 0;
        overflow: hidden;
        width: 100%;
        border-radius: 8px;

        .cardTitle {
            display: flex;
            align-items: baseline !important;
            justify-content: flex-start !important;
            padding: 24px 0 21px 24px;
            height: 73px;
            line-height: 28px;
            background-color: #292e3b;
            font-weight: 500;
            color: #fff;

            .title {
                color: #fff;
                font-size: 20px;
                font-weight: 500;
            }

            .titleRight {
                color: #8491a5 !important;
                font-size: 14px;
                margin-left: 15px;
            }
        }

        .cardBody {
            color: #fff;
            border-radius: 8px;
            background-color: transparent !important;
            margin: 0 4px !important;
            padding: 16px;
            font-size: 12px;

            .noPadding {
                padding: 0;
            }

            :deep(.el-form) {
                .el-form-item__label {
                    color: #8491a5 !important;
                    font-size: 14px;
                    line-height: 40px;
                }

                .el-form-item__content {
                    flex: none;
                }

                .el-input__wrapper {
                    background: #363d4e;
                    border: 1px solid transparent;
                    border-radius: 6px;
                    color: #677684 !important;
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    box-shadow: none
                }

                .el-select,
                .el-date-editor {
                    width: 244px !important;

                    span {
                        color: #677684 !important;
                        font-size: 14px;
                        line-height: 40px;
                        height: 40px;
                    }
                }
            }

            .lable {
                color: #8491a5 !important;
                font-size: 14px;
                line-height: 40px;
                text-align: right;
                padding-right: 16px;
            }

            .btnGroup {
                margin-left: 20px;
            }

            :deep(.el-button-group) {
                >.el-button:first-child {
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                >.el-button {
                    background: #363d4e;
                    border: 1px solid transparent !important;
                    line-height: 40px;
                    min-width: 70px;
                    height: 40px;
                    padding: 0 10px;

                    span {
                        color: #677684;
                    }
                }

                .el-button:hover {
                    background: #4a69ff !important;
                    border-color: transparent !important;

                    span {
                        color: #fff;
                    }
                }

                .active {
                    background: #4a69ff !important;
                    border-color: transparent !important;

                    span {
                        color: #fff;
                    }
                }
            }

            .submitButton {
                background: #4a69ff;
                box-shadow: 0 0 6px 0 rgba(74, 105, 255, .4);
                border-radius: 6px;
                color: #fff;
                width: 96px;
                height: 40px;
                border: none;
                font-size: 14px;
            }

            .inquire {
                margin-left: 20px;
                margin-right: 10px;
            }
        }
    }

    .list-info {
        margin: 15px 20px 0;

        .header {
            border-radius: 8px 8px 0 0 !important;
            background: #363d4e !important;
            height: 50px;
            display: flex;

            .header-item {
                width: 20%;
                text-align: center;
                line-height: 50px;
                font-size: 14px;
                color: #8491a5;
                font-weight: bold;
            }
        }

        .content {
            position: relative;
            height: 485px;
            overflow-y: auto;
            border-radius: 0 8px 8px 8px;
            background-color: #232733;

            .content-row {
                background: #232733 !important;
                height: 48px !important;
                border-bottom: 1px solid #363d4e !important;
                display: flex;

                .content-item {
                    width: 20%;
                    line-height: 50px;
                    text-align: center;
                    color: #fff;
                }
            }

            .not-data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                img {
                    width: 248px;
                    height: 198px;
                }

                span {
                    margin-top: 20px;
                    color: #909399;
                    font-size: 14px;
                }
            }
        }
    }

    .pagination {
        text-align: right;
        padding: 30px 20px 30px 20px;

        :deep(.el-pagination) {
            justify-content: center !important;
        }

        :deep(.el-input) {
            .el-input__wrapper {
                background-color: #363d4e !important;
                border-radius: 6px;
                width: 244px;
                color: #677684;
                height: 40px;
                line-height: 40px;
                box-shadow: none;

            }
        }
    }
}
</style>