<template>
  <div class="register">
    <div class="mask"></div>
    <div class="wrapper">
      <div class="header">
        <span>Đăng ký</span>
        <button class="close-btn" @click="close()">
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </button>
      </div>
      <div class="body">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-position="top">
          <el-form-item class="el-form-item--feedback" prop="account">
            <div class="form-layout">
              <el-input v-model="ruleForm.account" type="text" autocomplete="on" placeholder="Tài khoản" name="account" />
              <img class="left-icon" src="@/assets/pc/register/account.png" alt="">
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div class="form-layout">
              <el-input v-model="ruleForm.password" type="text" autocomplete="off" placeholder="6-15 ký tự chữ và số"
                name="password" />
              <img class="left-icon" src="@/assets/pc/register/password.png" alt="">
            </div>
          </el-form-item>
          <el-form-item prop="checkPass">
            <div class="form-layout">
              <el-input v-model="ruleForm.checkPass" type="text" autocomplete="off" placeholder="Xác nhận thêm lần nữa"
                name="checkPass" />
              <img class="left-icon" src="@/assets/pc/register/password.png" alt="">
            </div>
          </el-form-item>
          <el-form-item prop="phone">
            <div class="form-layout">
              <el-input v-model="ruleForm.phone" type="number" autocomplete="on" placeholder="10 chữ số, ví dụ 0988888888"
                name="phone" />
              <img class="left-icon" src="@/assets/pc/register/phone.png" alt="">
            </div>
          </el-form-item>

          <el-form-item prop="realName">
            <div class="form-layout">
              <el-input v-model="ruleForm.realName" type="text" autocomplete="on"
                placeholder="Họ tên phải trùng với ngân hàng được liên kết" name="realName" />
              <img class="left-icon" src="@/assets/pc/register/real_name.png" alt="">

            </div>
          </el-form-item>

          <el-form-item prop="code">
            <div class="form-layout">
              <el-input v-model="ruleForm.code" type="text" autocomplete="on" :placeholder="$t('验证码')" />
              <img class="left-icon" src="@/assets/pc/register/real_name.png" alt="">

              <img :src="ruleForm.codeImg" class="canClick" @click="getCode()" alt=""
                style="position:absolute;right:0;top:0;height:100%;background-color:white;">
            </div>
          </el-form-item>
          <!-- <p class="links">
            <el-checkbox v-model="ruleForm.agreement" label="Tôi đã 18 tuổi, đã đọc và đồng ý quy tắc cá cược ">
            </el-checkbox>
          </p> -->
        </el-form>
      </div>
      <div class="footer">
        <el-button class="registerBtn" @click="registe()">Đăng ký </el-button>
        <div class="to-login" @click="openLogin()">
          Đã có tài khoản? Đăng nhập ngay
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { req, qs } from "@/utils/request";
import { http } from "@/components/protocol/api";
import { ElMessage } from "element-plus";
import { reactive, ref } from "vue";
import tools from "@/utils/tools";
import { nameRule, passRule, phoneRule, realNameRule } from '@/utils/vaildate.js'
import { useGetters, useActions } from '@/store/hooks/storeState/index'
import { bus } from "@/components/core/bus";
import { getCurrentInstance } from 'vue'
import log from "@/utils/logger";
const $tools = getCurrentInstance().appContext.config.globalProperties.$tools
let { uuid } = useGetters("global", ["globalState", "uuid"]);
let { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const ruleFormRef = ref()
const ruleForm = reactive({
  account: '',
  password: '',
  checkPass: "",
  phone: '',
  realName: '',
  code: '',
  codeId: '',
  codeImg: '',
  agreement: false,

})

const checkPassRule = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Xác nhận mật khẩu'))
  } else if (value !== ruleForm.password) {
    callback(new Error("Nhập 2 lần mật khẩu không khớp"))
  } else {
    callback()
  }
}

const codeRule = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('MÃ XÁC NHẬN'))
  } else {
    callback()
  }
}



const rules = reactive({
  account: [{ validator: nameRule, trigger: 'blur' }],
  password: [{ validator: passRule, trigger: 'blur' }],
  checkPass: [{ validator: checkPassRule, trigger: 'blur' }],
  phone: [{ validator: phoneRule, trigger: 'blur' }],
  code: [{ validator: codeRule, trigger: 'blur' }],
  realName: [{ validator: realNameRule, trigger: 'blur' }],

})

const close = () => {
  bus.emit(bus.event.registerState, false);
}

getCode()

async function getCode() {


  let params = {

  };
  let rsp = await req.get(http.captcha, qs.stringify(params));
  if (rsp.data.Code == 0) {
    ruleForm.codeImg = rsp.data.Data.img
    ruleForm.codeId = rsp.data.Data.id
  } else {

  }


}

const registe = () => {
  // if (!ruleForm.agreement) {
  //   ElMessage({
  //     message: 'Tôi đã 18 tuổi, đã đọc và đồng ý quy tắc cá cược',
  //     type: 'error'
  //   });
  //   return
  // }
  ruleFormRef.value.validate((valid) => {
    if (valid) {
      let params = {
        account: ruleForm.account,
        password: ruleForm.password,
        platform: tools.platform(),
        channel: '',
        phone: ruleForm.phone,
        uuid: uuid.value,
        uuid_web: uuid.value,
        code: ruleForm.code,
        codeId: ruleForm.codeId,
        realName: ruleForm.realName
      };
      doLogin({
        params: params,
        url: http.userRegister,
      }).then(
        (resolve) => {
          ElMessage({
            message: 'Đăng ký thành công',
            type: 'success'
          });
          close();
        },
        (reject) => {
          ElMessage({
            message: reject.ErrMsg,
            type: 'error'
          });
        }
      );
    } else {
      return false
    }
  })
}

const openLogin = () => {
  close();
  bus.emit(bus.event.loginState, true);
}
</script>

<style lang="scss" scoped>
.register {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  margin: 0;
  z-index: 10001;

  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: #000;
    z-index: -1;

  }

  .wrapper {
    position: relative;
    margin: 0 auto 50px;
    margin-top: 15vh;
    width: 440px;
    background: #292e3b;
    border-radius: 24px;
    box-shadow: none;

    .header {
      background: url('@/assets/pc/register/bg.png') 0 0 no-repeat !important;
      background-size: cover !important;
      border-radius: 24px 24px 0 0;
      box-shadow: none;
      height: 80px;
      line-height: 60px;
      text-align: center;

      span {
        font-size: 16px;
        line-height: 24px;
        color: white;
        font-weight: bold;
      }

      .close-btn {
        width: 32px;
        height: 32px;
        font-size: 24px;
        position: absolute;
        top: 14px;
        right: 20px;
        padding: 0;
        background: 0 0;
        border: none;
        outline: none;
        cursor: pointer;

        img {
          width: 24px;
          height: 24px;
        }
      }

      .close-btn:hover {
        zoom: 1.02;
      }
    }

    .body {
      background: #292e3b !important;
      padding: 20px 35px;
      color: #fff;
      font-size: 14px;
      word-break: break-all;

      .el-form-item {
        margin-bottom: 5px !important;

        :deep(.el-form-item__content) {
          margin-bottom: 20px;
          line-height: 40px;
          position: relative;
          font-size: 14px;
        }

        .form-layout {
          width: 100%;
          position: relative;

          .left-icon {
            position: absolute;
            left: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 22px;
            height: 24px;
          }

          .el-input {
            background-color: #10131a !important;
            border-radius: 6px !important;
            color: #c0c4cc !important;
            height: 48px;
            padding-left: 40px !important;

            :deep(.el-input__wrapper) {
              width: 100%;
              background-color: #10131a !important;
              box-shadow: none !important;
              padding: 0;
              border-radius: none;

              .el-input__inner {
                width: 100%;
                height: 100%;
                padding: 0;
                border: none;
                background: 0 0;
                color: inherit;
                box-sizing: border-box;
              }

              .el-input__suffix {
                position: absolute;
                height: 100%;
                right: 5px;
                top: 0;
                text-align: center;
                color: #c0c4cc;
                transition: all .3s;
                pointer-events: none;
              }
            }
          }
        }
      }

      .links {
        height: 30px;
        float: left;
        margin-bottom: 10px;
        color: #90a2dc;

        .el-checkbox {
          color: #8491a5 !important;
          margin-right: 0;
          font-size: 14px;
          position: relative;
          cursor: pointer;
          display: inline-block;
          white-space: nowrap;
          user-select: none;

          .el-checkbox__input {
            white-space: nowrap;
            cursor: pointer;
            outline: none;
            display: inline-block;
            line-height: 1;
            position: relative;
            vertical-align: middle;
          }

          :deep(.el-checkbox__inner) {
            border-color: #b3b3b3 !important;
            background-color: #292e3b;
          }

          :deep(.el-checkbox__label) {
            color: #c0c4cc !important;
            display: inline-block;
            padding-left: 10px;
            line-height: 19px;
            font-size: 14px;
          }
        }
      }
    }

    .footer {
      border-radius: 0 0 24px 24px;
      padding: 0 35px 15px;
      background: #292e3b !important;
      text-align: center;
      cursor: pointer;

      .el-button {
        display: block;
        line-height: 40px;
        margin: 0 auto 20px;
        padding: 0;
        width: 100% !important;
      }

      .registerBtn {
        background: linear-gradient(#292e3b, #292e3b) padding-box, linear-gradient(100deg, #94b3ff, #5b7cfe) border-box !important;
        border: 2px solid transparent !important;
        border-radius: 24px !important;
        color: #fff !important;
        height: 48px !important;
      }

      .to-login {
        color: #9cb0f0;
        margin: 0 auto 5px;
        display: inline-block;
        text-decoration: underline;
        font-size: 14px;
      }
    }
  }
}
</style>
