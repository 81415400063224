<template>
    <div class="cockfight">
        <div class="container">
            <div class="cockfightingList">
                <div class="providerList">
                    <ul>
                        <li v-for="(item, index) in lobbyInfo.StaticPicture.CockFight">
                            <img class="sel" :src="$img(`cockFight/${item.Factory.FactoryName}_icon_2.png`)" alt="">
                        </li>
                    </ul>
                </div>
                <img class="bg-img" :src="$img('cockFight/cockfight_bg.png')" alt="">
                <img class="leftImg" :src="$img('cockFight/img_cockfight_left.png')" alt="">
                <img class="rightImg" :src="$img('cockFight/img_cockfight_right.png')" alt="">
                <div class="btn-play" @click="itemClick()">
                    <div class="icon-play"></div>
                    <div class="btn-txt">Play now</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { bus } from "@/components/core/bus";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
const { isLogged } = useGetters("tcp", ["isLogged"]);
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const itemClick = () => {
    let data = lobbyInfo.value.StaticPicture.CockFight;
    if (Object.keys(data).length > 0) {
        let item = null;
        for (let key in data) {
            item = data[key];//先取一个，目前暂时为一个
            break;
        }
        if (item.Factory.Status !== 1) {
            return;
        }
        if (item.Factory.FactoryName) {
            let url = getApiLoginUrl(item.Factory.FactoryName, '');
            $act.openPage(url);
        }
    }
}
</script>

<style lang="scss" scoped>
.cockfight {
    background: #292e3b;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;

    .container {
        width: 1400px;
        height: auto;
        margin: 0 auto;
        position: relative;

        .cockfightingList {
            position: relative;
            text-align: center;
            margin: 0 auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .providerList {
                margin-top: 40px;
                width: 1400px;
                background: rgba(41, 51, 86, .4);
                border-radius: 60px;
                display: flex;
                justify-content: flex-start;
                padding-left: 30px;
                align-items: center;
                position: relative;
                z-index: 9;

                ul {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    padding: 10px 0;

                    li {
                        width: 168px;
                        height: 48px;
                        margin: 12px;
                        cursor: pointer;
                        position: relative;
                        transition: all .3s;

                        .sel {
                            width: 100%;
                            height: 100%;
                            z-index: 4;
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                }
            }

            .bg-img {
                position: absolute;
                height: 100%;
            }

            .leftImg {
                position: absolute;
            }

            .rightImg {
                position: relative;
                z-index: 2;
            }

            .btn-play {
                position: absolute;
                bottom: 235px;
                right: 220px;
                min-width: 208px;
                height: 60px;
                background: linear-gradient(270deg, #f83600, #fe8c00);
                box-shadow: 0 0 12px 0 rgba(0, 0, 0, .16);
                border-radius: 30px;
                display: flex;
                align-items: center;
                color: #fff;
                padding: 0 20px;
                cursor: pointer;
                box-sizing: border-box;
                z-index: 5;
                transition: all 0.3s;

                .icon-play {
                    width: 24px;
                    height: 24px;
                    background: #fff;
                    box-shadow: 0 0 3px 0 rgba(0, 0, 0, .3);
                    border-radius: 12px;
                }

                .icon-play::after {
                    display: inline-block;
                    content: "";
                    border: 6px solid transparent;
                    border-left-color: #fe8c00;
                    margin-left: 10px;
                    margin-top: 6px;
                }

                .btn-txt {
                    flex: 1;
                    text-align: center;
                    font-size: 28px;
                    font-weight: bold;
                }
            }

            .btn-play:hover {
                transform: scale(1.1);
            }
        }
    }
}
</style>