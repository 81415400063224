<template>
        <div>
                <p><span style="font-size: 20px;"><strong><span style="color: rgb(79, 129, 189);">ĐỐI TÁC ĐẠI LÝ CỦA
                                                S6</span></strong></span><span style="font-size: 16px;"><strong><span
                                                style="color: rgb(79, 129, 189);"></span></strong><strong><span
                                                style="font-family: arial, helvetica, sans-serif; color: rgb(79, 129, 189);"></span></strong><span
                                        style="font-family: arial, helvetica, sans-serif;"><span
                                                style="color: rgb(79, 129, 189);"></span><strong><span
                                                        style="color: rgb(79, 129, 189);">&nbsp;</span></strong></span></span>
                </p>
                <p><br></p>
                <p><span style="font-family: 微软雅黑, "><strong>Mọi thắc mắc xin liên hệ chuyên viên tư vấn đại lý:</strong></span>
                </p>
                <p><strong><span style="font-family: arial, helvetica, sans-serif;"><br></span></strong></p>
                <p><span style="font-family: arial, helvetica, sans-serif;"><span
                                        style="font-family: 隶书, SimLi; color: rgb(255, 255, 0);">Telegram user
                                        name:</span>&nbsp;<span
                                        style="font-family: 隶书, SimLi; color:#fff;">@S6ASIANGAME</span></span></p>
                <p><br></p>
                <p><span style="color: rgb(255, 255, 0);">skype username:</span>&nbsp; <span style="color:#fff;">Asian Game
                                S6</span></p>
                <p><br></p>
                <p><span style="font-family: 微软雅黑, "><strong><span style="color: rgb(84, 141, 212);">I.VÌ SAO NÊN THAM GIA
                                                CHƯƠNG
                                                TRÌNH ĐẠI LÝ CỦA S6&nbsp; ?</span></strong></span></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">- Bạn không phải đầu tư vốn, không phải đặt cọc,
                                không
                                mất bất cứ lệ phí nào, không có rủi ro gì.<br></span></p>
                <p><span style="font-family: arial, helvetica, sans-serif;"><br></span></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">- Hợp tác linh động theo thoả thuận, thanh toán
                                nhanh
                                gọn và luôn đúng hẹn.<br></span></p>
                <p><span style="font-family: arial, helvetica, sans-serif;"><br></span></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">- Tỉ lệ chiết khấu hoa hồng hấp dẫn lên đến
                                55%.<br></span></p>
                <p><span style="font-family: arial, helvetica, sans-serif;"><br></span></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">- Đội ngũ chăm sóc khách hàng chuyên nghiệp của
                                chúng
                                tôi sẽ hỗ trợ các đại lý 24/7, sẵn sàng hỗ trợ tư vấn tất cả các vấn đề liên quan.</span></p>
                <p>
                        <img :src="$img('help/help.jpg')" alt="">
                </p>
                <p><span style="font-family: 微软雅黑, "><strong><span style="color: rgb(84, 141, 212);">II. QUY TẮC VÀ ĐIỀU KIỆN
                                                NHẬN
                                                HOA HỒNG</span></strong></span></p>
                <p><br></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">1. HOA HỒNG đại lý được tính như sau:</span></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">HOA HỒNG = LỢI NHUẬN (DOANH THU - PHÍ HÀNH CHÍNH) X
                                PHẦN
                                TRĂM HOA HỒNG</span></p>
                <p><span style="font-family: arial, helvetica, sans-serif;"><strong>Trong đó：</strong> <span
                                        style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">DOANH THU：</span>là tổng số
                                tiền thắng cược
                                đại lý mang về cho công ty. <span style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">PHÍ
                                        HÀNH
                                        CHÍNH：</span>là tổng các loại phí dùng để mời khách hàng trải nghiệm như phí khuyến mãi,
                                hoàn trả, phí
                                dịch vụ ngân hàng, v.v… <span style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">PHẦN
                                        TRĂM HOA
                                        HỒNG：</span>là mức tương ứng từ 30% đến 55%<span
                                        style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">(theo BẢNG HOA HỒNG ĐẠI
                                        LÝ)</span></span></p>
                <p><br></p>
                <p><span style="font-family: arial, helvetica, sans-serif;"><strong>Trong đó：</strong> Đại lý A trong chu kỳ
                                tính
                                hoa hồng có 7 thành viên hoạt động, DOANH THU là <span
                                        style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">600.000.000 VND</span> và Phí
                                khuyến mãi
                                nạp,vòng quay may mắn,lì xì là <span
                                        style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">100.000.000
                                        VND</span>. Khi tính hoa hồ ng của đại lí A, trước tiên ta tính LỢI NHUẬN: <span
                                        style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">LỢI NHUẬN</span>= DOANH THU -
                                Phí khuyến mãi
                                nạp,vòng quay may mắn,lì xì = <span
                                        style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">600.000.000
                                        VND</span> - <span style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">100.000.000
                                        VND</span> =
                                <span style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">500.000.000 VND</span>&nbsp; LỢI
                                NHUẬN này
                                đối chiếu với BẢNG HOA HỒNG ĐẠI LÝ ta có PHẦN TRĂM HOA HỒNG đạt mức 40%. Vậy hoa hồng của đại lý
                                A sẽ là:
                                <span style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">HOA HỒNG</span> = LỢI NHUẬN X
                                PHẦN TRĂM HOA
                                HỒNG = <span style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">500.000.000 VND</span> X
                                40% =<span style="font-family: 隶书, SimLi; color: rgb(84, 141, 212);">200.000.000
                                        VND</span></span></p>
                <p><br></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">2. Có 1 chu kỳ xét hoa hồng trong tháng: Từ ngày 01
                                đến
                                ngày cuối tháng.</span></p>
                <p><br></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">3. Điều kiện thành viên hoạt động: Thành viên có
                                tổng
                                cược hiệu quả ≥ 5.000K.</span></p>
                <p><br></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">4. Tiền hoa hồng sẽ được thanh toán vào ngày 10 của
                                tháng kế tiếp. Chuyên viên đại lý của S6 sẽ liên hệ với các đại lý đạt chỉ tiêu để đối chiếu các
                                số liệu
                                liên quan. Sau khi đã đối chiếu xong, bộ phận tài vụ&nbsp; sẽ chuyển tiền hoa hồng tới số tài
                                khoản ngân
                                hàng mà đại lý đã xác nhận trước đó</span></p>
                <p><br></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">5. Nếu LỢI NHUẬN nhỏ hơn 0 (lợi nhuận bị âm), sẽ bảo
                                lưu
                                cộng dồn số lợi nhuận âm này vào các chu kỳ sau cho đến khi có LỢI NHUẬN là số dương đủ điều
                                kiện thanh toán
                                theo BẢNG HOA HỒNG ĐẠI LÝ thì đại lý tiếp tục được xét nhận hoa hồng bình thường.</span></p>
                <p><br></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">6. Nếu LỢI NHUẬN không bị âm nhưng chưa đạt mức tối
                                thiểu xét hoa hồng theo BẢNG HOA HỒNG ĐẠI LÝ, thì sẽ bị xoá về 0.</span></p>
                <p><br></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">7. Hoa hồng cần đạt mức tối thiểu là 1.000.000 VND
                                mới
                                được thanh toán, nếu chưa đạt mức tối thiểu 1.000.000 VND thì được cộng dồn cho chu kỳ thanh
                                toán kế tiếp.
                                Lưu ý, nếu chu kỳ kế tiếp vẫn chưa đủ điều kiện thanh toán thì hoa hồng này sẽ bị xoá về
                                0.<br></span></p>
                <p><br></p>
                <p><span style="font-family: arial, helvetica, sans-serif;">8. Nếu đại lý có LỢI NHUẬN&nbsp; trong kỳ hiện tại
                                nhưng
                                không đạt điều kiện tối thiểu 3 thành viên và&nbsp; có thêm thành viên mới hiệu quả thì hoa hồng
                                sẽ nhận ở
                                mức tối thiểu. Nếu 2 tháng liên tiếp thì tiền hoa hồng sẽ phải chờ thanh toán cùng kỳ tiếp theo.
                                Lưu ý nếu
                                kỳ tiếp theo vẫn không đủ điều kiện lĩnh hoa hồng thì hoa hồng này sẽ bị xóa về 0.</span></p>
                <p><br></p>
                <p><br></p>
                <p><br></p>
        </div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>