
// using ES6 modules
import mitt from 'mitt'
const emitter = mitt()
emitter.event = {
    showLoading:'showLoading',
    resetGame:'resetGame',
    loginState:'loginState',
    registerState:'registerState',
    noticeState:'noticeState',
    showPop:'showPop',
    showFooter:'showFooter',
    showHistoryRecord:'showHistoryRecord',
    avatarState:'avatarState',
    chatState:'chatState',
    chatListPush:'chatListPush',
    chatNewRedbag:'chatNewRedbag',
    closeCommonRightPop:'closeCommonRightPop',
    openCameraCallBack:'openCameraCallBack',
    showGiftInfo:'showGiftInfo',
}
export default emitter

