<template>
  <div v-if="loadSucess" class="drawMoney">
    <div class="myPurseCardNewTheme draw-purse">
      <div class="cardInfo">
        <div class="cardTitle flexs_sb">
          <div class="topTitle">
            <div class="title">
              <p>Ví tiền của tôi</p>
            </div>
          </div>

          <div class="titleRight">
            <span>Số dư:</span><span class="currency">{{ $tools.formatNum2k(wallet.VndBalance) }}</span>
          </div>

        </div>

      </div>
    </div>
    <div class="bankCard draw-bankcard">
      <div class="cardInfo cardBtnHover">
        <div class="cardTitle">
          <div>
            <span class="account-name">Số tài khoản</span>
            <span class="accountsNumText">
              Số lương tài khoản：{{
                userAll.userData.myDouDouBT.length
              }}/3</span>
          </div>
        </div>
        <div class="cardBody">
          <div class="cardList">
            <div v-for="(item, index) in userAll.userData.myDouDouBT" :class="addBankDialog.selectIndex == index ? 'cardList-active' : ''
              " class="cardItem" @click="addBankDialog.selectIndex = index">
              <div class="cardItems">
                <div class="">
                  <div class="cardInfoLeft">
                    <span class="avatar el-avatar el-avatar--square" style="
                        height: 30px;
                        width: 30px;
                        line-height: 30px;
                        display: none;
                      ">
                    </span>
                    <span class="cardName">{{ item.BtName }}</span>
                  </div>
                </div>
                <div class="cardNum">
                  <div class="cardLists">
                    <!-- <span class="cardSpan"> ***</span>
                    <span class="cardSpan"> ****</span>
                    <span class="cardSpan"> {{ item.CardNum.substr(-4) }}</span> -->
                    <span class="cardSpan"> {{ item.CardNum }}</span>
                  </div>
                </div>
              </div>
              <div class="bg_bank_card"></div>
            </div>
            <div v-if="userAll.userData.myDouDouBT.length < 3" class="cardItem addBankCard" @click="openBindCard()">
              <i size="16" class="iconfont icon-icon_add_bankcard"></i>
              <span>Thêm vào</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="draw-input">
      <el-form label-position="top" style="max-width: 400px">
        <el-form-item label="Số tiền rút:">
          <el-input v-model="withMoneyObj.money" type="number" />
          <div class="withdrawalScope">
            <i class="iconfont icon-icon_remind"></i>
            <!-- <span>Phạm vi rút tiền trên mỗi lệnh :(100,000 ~ 10,000,000,000)</span> -->
            <span>Phạm vi rút tiền trên mỗi lệnh : {{ $tools.formatNum2k(payInfo.doudouMin) }} ~
              {{ $tools.formatNum2k(payInfo.doudouMax) }} VND(K)
            </span>
          </div>
        </el-form-item>
        <el-form-item label="Mật khẩu rút tiền:">
          <el-input v-model="withMoneyObj.withPassword" type="number" />
        </el-form-item>
        <el-button type="button" class="el-button withdrawalBtn el-button--primary el-button--mini" @click="withDraw()">
          <span>Rút ngay</span>
        </el-button>
      </el-form>
    </div>
    <div class="dialog">
      <el-dialog v-model="addBankDialog.show" title="Thêm thông tin ngân hàng" :show-close="false">
        <span class="closeBtn" @click="addBankDialog.show = false">
          <i class="iconfont icon-icon_close_dark"></i>
        </span>
        <div class="dialogBody transactionPwdBody">
          <el-form ref="addBankRef" :model="addBankDialog" :rules="rules" label-position="top">
            <el-form-item label="Chọn tên ngân hàng">
              <el-select v-model="addBankDialog.bankName">
                <el-option v-for="item in addBankDialog.list" :key="item" :value="item.BtName">{{ item.BtName
                }}</el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Vui lòng nhập stk ngân hàng" prop="cardNum">
              <el-input v-model="addBankDialog.cardNum" placeholder="vui lòng nhập số tài khoản ngân hàng" />
            </el-form-item>
            <el-form-item label="Xin điền họ tên" prop="realName">
              <el-input v-model="userAll.userData.realName" style="cursor: not-allowed" placeholder="Xin điền họ tên" />
            </el-form-item>

            <el-form-item label="Vui lòng Mật khẩu rút tiền" prop="DoudouPwd">
              <el-input type="number" v-model="addBankDialog.DoudouPwd" style="cursor: not-allowed"
                placeholder="Vui lòng Mật khẩu rút tiền" />
            </el-form-item>
          </el-form>
          <el-button class="el-button el-button--primary el-button--small" @click="addBankSumit">
            {{ $t("提交") }}</el-button>
        </div>
      </el-dialog>


      <!-- <el-dialog
        v-model="addBankDialog.show"
        title="Thay đổi mật khẩu giao dịch"
        :show-close="false"
      >
        <span class="closeBtn" @click="addBankDialog.show = false">
          <i class="iconfont icon-icon_close_dark"></i>
        </span>
        <div class="dialogBody transactionPwdBody">
          <el-form
            ref="addBankRef"
            :model="addBankDialog"
            :rules="rules"
            label-position="top"
          >
            <el-form-item label="Mật khẩu giao dịch mới">
              <el-select v-model="addBankDialog.bankName">
                <el-option
                  v-for="item in addBankDialog.list"
                  :key="item"
                  :value="item.BtName"
                  >{{ item.BtName }}</el-option
                >
              </el-select>
            </el-form-item>
            <el-form-item label="Mật khẩu giao dịch mới" prop="cardNum">
              <el-input
                v-model="addBankDialog.cardNum"
                placeholder="Mật khẩu rút tiền"
              />
            </el-form-item>
            <el-form-item
              label="Xác nhận lại mật khẩu giao dịch"
              prop="realName"
            >
              <el-input
                v-model="userAll.userData.realName"
                style="cursor: not-allowed"
                placeholder="Mật khẩu rút tiền"
              />
            </el-form-item>
          </el-form>
          <el-button
            class="el-button el-button--primary el-button--small"
            @click="addBankSumit"
          >
            Xác nhận thay đổi</el-button
          >
        </div>
      </el-dialog> -->
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "../../../../components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import sf_games_btn_02_svga from "@/assets/pc/svga/sf_games_btn_02.svga";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { http } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import {
  nameRule,
  passRule,
  phoneRule,
  realNameRule,
} from "@/utils/vaildate.js";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const { payInfo } = useGetters("pay", ["payInfo"])
let inputStr = ref("");
let loadSucess = ref(false);
let userAll = reactive({
  userData: {},
});
let addBankRef = ref(null);
let addBankDialog = reactive({
  show: false,
  list: [],
  selectIndex: 0,
  bankName: "",
  cardNum: "",
  realName: "",
});
let withMoneyObj = reactive({
  money: "",
  withPassword: "",
});
if (isLogged.value) {
  getUserInfo();
} else {
  bus.on('onConnected', () => {
    getUserInfo()
  })
}
function getUserInfo() {
  mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
    log.info("getUserInfo", data.Data);
    userAll.userData = data.Data;
    loadSucess.value = true;
    getBankList();
  });
}
function getBankList() {
  mqant.request(topic.bankList, {}, function (data, topicName, msg) {
    addBankDialog.list = data.Data.douDouBtList;
    addBankDialog.bankName = data.Data.douDouBtList[0].BtName;
    // log.info('getBankList', data.Data)
  });
}

function openBindCard() {
  if (!userAll.userData.DoudouPwd.length) {
    ElMessage({
      message: "Vui lòng đặt mật khẩu rút tiền",
      type: "warning",
    });
    return;
  }
  addBankDialog.show = true;
}
const addBankSumit = () => {
  addBankRef.value.validate((valid) => {
    if (valid) {
      let obj = {
        btName: addBankDialog.bankName,
        cardNum: addBankDialog.cardNum,
        accountName: userAll.userData.realName.toString(),
      };
      mqant.request(topic.bindBank, obj, function (data, topicName, msg) {
        if (data.Code == 0) {
          ElMessage({
            message: "Thiết lập thành công",
            type: "success",
          });
          addBankDialog.show = false;
          getUserInfo();
        } else {
          ElMessage({
            message: data.ErrMsg,
            type: "error",
          });
        }
      });
    } else {
      return false;
    }
  });
};
const checkPassRule = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("Xác nhận mật khẩu"));
  } else if (value !== drawMoneyDialog.password) {
    callback(new Error("Nhập 2 lần mật khẩu không khớp"));
  } else {
    callback();
  }
};
const rules = reactive({
  // account: [{ validator: nameRule, trigger: 'blur' }],
  password: [{ validator: passRule, trigger: "blur" }],
  cardNum: [{ validator: passRule, trigger: "blur" }],
  checkPass: [{ validator: checkPassRule, trigger: "blur" }],
  // phone: [{ validator: phoneRule, trigger: 'blur' }],
  // realName: [{ validator: realNameRule, trigger: 'blur' }],
});
function withDraw() {
  if (!withMoneyObj.money) {
    ElMessage({
      message: "Vui lòng nhập số tiền rút!",
      type: "error",
    });
    return;
  }
  if (!withMoneyObj.withPassword) {
    ElMessage({
      message: "Vui lòng nhập mật khẩu rút tiền",
      type: "error",
    });
    return;
  }
  if (userAll.userData.DoudouPwd !== withMoneyObj.withPassword) {
    ElMessage({
      message: "Mật khẩu rút tiền không chính xác",
      type: "error",
    });
    return;
  }
  var obj = {
    amount: withMoneyObj.money * 1000,
    btOid: userAll.userData.myDouDouBT[addBankDialog.selectIndex].Oid,
  };

  mqant.request(topic.withDraw, obj, function (data, topicName, msg) {
    if (data.Code == 0) {
      withMoneyObj.money = "";
      withMoneyObj.withPassword = "";
      ElMessage({
        message: "Rút tiền thành công !",
        type: "success",
      });
    } else {
      ElMessage({
        message: data.ErrMsg,
        type: "error",
      });
    }
  });
}
</script>

<style lang="scss" scoped>
.titleRight {
  font-size: 14px;

  .currency {
    margin-left: 10px;
    color: #f7b500;
  }
}

.drawMoney {
  padding-bottom: 20px;

  .cardInfo {
    overflow: hidden;
    width: 100%;

    .cardTitle {
      display: flex;
      justify-content: space-between !important;
      padding: 24px 24px 21px;
      height: 73px;
      line-height: 28px;
      background-color: #292e3b;
      color: #fff;
      align-items: baseline !important;

      .topTitle {
        .title {
          color: #fff;
          font-size: 20px;
          font-weight: 500;
        }
      }

      .titleRight {
        color: #8491a5 !important;
        font-size: 14px;
        margin-left: 15px;

        .currency {
          margin-left: 10px;
          color: #f7b500;
        }
      }
    }

    .cardBody {
      color: #fff;
      border-radius: 8px;
      background-color: transparent !important;
      margin: 0 4px !important;
      padding: 16px;
      font-size: 12px;

      .bodyCard {
        background: #262933;
        border: 1px solid #363d4e;
        overflow: hidden;
        width: 100%;
        border-radius: 8px;

        .cardTitle {
          display: flex;
          justify-content: space-between !important;
          height: 73px;
          line-height: 28px;
          background-color: #292e3b;
          font-weight: 500;
          color: #fff;
          background: #363d4e;
          border-radius: 8px 8px 0 0;
          padding: 5px 10px !important;
          align-items: baseline !important;

          .title {
            color: #fff;
            font-size: 20px;
            position: relative;
          }

          .title::before {
            content: "";
            border-left: 2px solid #fff;
            padding-left: 10px;
          }
        }

        .cardBody {
          display: flex;
          justify-content: space-between;
          gap: 15px;
          margin: 0 !important;
          padding: 20px 20px 24px;
          color: #fff;
          border-radius: 8px;

          .myBalance {
            flex: 1;

            div {
              display: flex;
              flex: 1;
              font-size: 16px;

              .myBalanceTitle {
                display: flex;
                align-items: center;
                flex-direction: row !important;
                justify-content: flex-start;
                margin: 10px 0;
                line-height: 22px;

                i {
                  margin-right: 5px;
                  font-size: 20px;
                }
              }

              .currencyBox {
                display: flex;
                align-items: center;

                .currency {
                  line-height: 22px;
                }
              }
            }
          }

          >div {
            background: #363d4e;
            border: 1px solid transparent;
            border-radius: 8px;
            display: flex;
            padding: 10px;
          }
        }
      }
    }
  }

  .draw-bankcard {
    margin: 0 24px;

    .cardInfo {
      border-radius: 0;
      border: 1px solid #363d4e;
      border-radius: 8px;

      .cardTitle {
        height: 42px;
        line-height: 32px;
        padding: 5px 10px !important;
        background: #363d4e;
        border-radius: 8px 8px 0 0;

        .account-name {
          color: #fff;
          font-size: 14px;
          position: relative;
        }

        .account-name ::before {
          content: "";
          border-left: 2px solid #fff;
          height: 100%;
          padding-left: 10px;
          width: 1px;
        }

        .accountsNumText {
          color: #fff;
          font-size: 12px;
        }
      }

      .cardBody {
        margin: 0 !important;
        padding: 20px;
        border-radius: 0;
      }
    }

    .cardList {
      display: flex;
      //justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      border-radius: 8px;
      padding: 20px;
      background: #363d4e;

      .cardList-active {
        border: 1px solid #4a69ff;
      }

      .addBankCard {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        cursor: pointer;
        background: #292e3b;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.06);

        .icon-icon_add_bankcard {
          color: #fff;
        }

        span {
          color: #fff;
          margin-left: 5px;
          font-size: 12px;
        }
      }

      .cardItem {
        width: 218px;
        height: 132px;
        margin: 0 35px 0px 0;
        padding: 20px;
        position: relative;
        box-sizing: border-box;
        border-radius: 8px;

        .cardItems {
          position: absolute;
          z-index: 3;
          left: 0;
          right: 0;
          top: 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 20px;
          height: 100%;

          .cardItemInfo {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .cardInfoLeft {
              display: flex;
              justify-content: center;
              align-items: center;

              .cardName {
                color: #fff;
                font-size: 16px;
                margin-left: 2px;
              }
            }
          }

          .cardNum {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #8491a5;

            .cardLists {
              padding: 0;
              flex: 2;

              .cardSpan {
                margin-right: 6px;
                letter-spacing: 3px;
              }
            }

            .defaultBank {
              flex: 1;

              .defaultBankBtn {
                padding: 0;
                width: 60px;
                border-radius: 2px;
                font-size: 12px;
                font-weight: 400;
                box-sizing: border-box;
                height: 30px;
              }
            }
          }
        }

        .bg_bank_card {
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          padding: 16px;
          background-image: url("@/assets/pc/user/add_bank_bg.png");
          background-position: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          width: 100%;
        }
      }
    }
  }

  .draw-input {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    :deep(.el-form) {
      position: relative;

      .el-form-item__label {
        color: #8491a5 !important;
        font-size: 14px;
      }

      .el-input {
        height: 50px;

        .el-input__wrapper {
          background-color: #2e3342;

          box-shadow: none !important;

          .el-input__inner {
            color: #fff;
          }
        }
      }

      .withdrawalBtn {
        margin-left: 100px;
        width: 200px;
        height: 40px;
        font-size: 14px;
        box-sizing: border-box;
        background-image: linear-gradient(90deg,
            #694dff 0%,
            #595cff 50%,
            #5063ff 100%),
          linear-gradient(#e13c29, #e13c29);
        background-blend-mode: normal, normal;
      }
    }
  }

  .dialog {
    .closeBtn {
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
    }

    :deep(.el-dialog) {
      width: 360px;
      background-color: #fbfbfb;
      color: #1b233d;
      border-radius: 8px;

      .el-dialog__header {
        padding: 15px 24px;
        height: 60px;
        border-bottom: 1px solid #d4d4d4;
        box-sizing: border-box;
        color: #1b233d;
        font-size: 18px;
      }

      .el-dialog__body {
        padding: 30px 24px;

        .el-select {
          width: 100%;
        }

        .dialogBody {
          button {
            font-size: 14px;
            text-align: center;
            width: 312px;
            height: 40px;
            border-radius: 4px;
          }

          // .el-form-item {
          //     margin-bottom: 16px;

          //     .el-form-item__label {
          //         font-size: 14px;
          //         color: #1b233d;
          //         line-height: 40px;
          //         height: 40px;
          //         padding-right: 32px;
          //     }
          // }
        }
      }

      // .el-input__inner {
      //     background: #fbfbfb;
      //     width: 312px;
      //     height: 40px;
      //     border: 1px solid #1b233d;
      //     border-radius: 4px;
      //     color: #000;
      //     font-size: 14px;
      // }
    }

    // :deep(.el-input) {
    //     width: 312px;

    //     .el-input__wrapper {
    //         padding: 0;
    //         width: 312px;
    //     }

    //     .el-input__inner {
    //         background: #fbfbfb;
    //         width: 312px;
    //         height: 40px;
    //         border: 1px solid #1b233d;
    //         border-radius: 4px;
    //         color: #000;
    //         font-size: 14px;
    //     }
    // }

    // :deep(.el-form) {
    //     margin-bottom: 30px;
    // }
  }
}
</style>