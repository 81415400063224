let rootView = require('@/views/mobile/RootView.vue').default
let home = require('@/views/mobile/components/Home.vue').default
let LoginRegist = require('@/views/mobile/components/LoginRegist.vue').default
let Lottery = require('@/views/mobile/components/Lottery.vue').default
let allGameSelect = require('@/views/mobile/components/allGameSelect.vue').default
let userCenter = require('@/views/mobile/components/UserCenter.vue').default
let rewardHistory = require('@/views/mobile/components/rewardHistory.vue').default
let recharge = require('@/views/mobile/components/Recharge.vue').default
let withdraw = require('@/views/mobile/components/Withdraw.vue').default

let betRecord = require('@/views/mobile/components/betRecord.vue').default
let gameRecord = require('@/views/mobile/components/gameRecord.vue').default
let RecordInfo = require('@/views/mobile/components/RecordInfo.vue').default
let billRecord = require('@/views/mobile/components/billRecord.vue').default


let transactionPwd = require('@/views/mobile/components/TransactionPwd.vue').default
let addBankCard= require('@/views/mobile/components/AddBankCard.vue').default
let personalInfo= require('@/views/mobile/components/personalInfo.vue').default
let changePassword= require('@/views/mobile/components/changePassword.vue').default
let messageCenter= require('@/views/mobile/components/messageCenter.vue').default
let vip= require('@/views/mobile/components/Vip.vue').default
let vipInfo= require('@/views/mobile/components/VipInfo.vue').default
let discount= require('@/views/mobile/components/DiscountDraw.vue').default
let turntable= require('@/views/mobile/components/turntable.vue').default
let casino = require('../views/mobile/components/casinoIframe.vue').default
let bankList = require('../views/mobile/components/bankList.vue').default
let giftInfo = require('../views/mobile/components/GiftInfo.vue').default
let chat = require('../views/mobile/components/Chat.vue').default


let userCenterChildren = [
  
]
let rootChilren = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/LoginRegist',
    name: 'LoginRegist',
    component: LoginRegist
  },
  {
    path: '/Lottery',
    name: 'Lottery',
    component: Lottery
  },
  {
    path: '/allGameSelect',
    name: 'allGameSelect',
    component: allGameSelect
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: userCenter,
    children:userCenterChildren
  },
  {
    path: '/rewardHistory',
    name: 'rewardHistory',
    component: rewardHistory
  },
  {
    path: '/betRecord',
    name: 'betRecord',
    component: betRecord
  },
  {
    path: '/gameRecord',
    name: 'gameRecord',
    component: gameRecord
  },
  {
    path: '/RecordInfo',
    name: 'RecordInfo',
    component: RecordInfo
  },
  {
    path: 'personalInfo',
    component: personalInfo,
    name: 'personalInfo'
  },
  {
    path: 'changePassword',
    component: changePassword,
    name: 'changePassword'
  },
  {
    path: 'recharge',
    component: recharge,
    name: 'recharge'
  },
  {
    path: 'withdraw',
    component: withdraw,
    name: 'withdraw'
  },  
  {
    path: 'addBankCard',
    component: addBankCard,
    name: 'addBankCard'
  },
  
  {
    path: 'transactionPwd',
    component: transactionPwd,
    name: 'transactionPwd'
  },
  {
    path: 'billRecord',
    component: billRecord,
    name: 'billRecord'
  },
  {
    path: 'messageCenter',
    component: messageCenter,
    name: 'messageCenter'
  },
  {
    path: 'vip',
    component: vip,
    name: 'vip'
  },
  {
    path: 'vipInfo',
    component: vipInfo,
    name: 'vipInfo'
  },
  {
    path: 'discount',
    component: discount,
    name: 'discount'
  },
  {
    path: 'turntable',
    component: turntable,
    name: 'turntable'
  },
  
  {
		path: '/bankList',
		name: 'bankList',
		component: bankList
		
	},
  {
    path: '/giftInfo',
		name: 'giftInfo',
		component: giftInfo
  },
   {
		path: '/chat',
		name: 'chat',
		component: chat
  }
]

let routes = [
  {
    path: '/',
    name: 'rootView',
    component: rootView,
    children: rootChilren
  },
  {
		path: '/navigator',
		name: 'casino',
		component: casino
		
	},
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }
]
export {
  routes,
} 