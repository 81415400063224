<template>
  <div class="betHistory">
    <div class="cardInfo">
      <div class="cardTitle">
        <span class="title">Lịch sử</span>
      </div>
      <div class="cardBody">
        <div class="tabList">
          <el-tabs v-model="factoryOptions.index" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) in factoryOptions.data">
              <template #label :name="index">
                <div>{{ item.name }}</div>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
        <el-form label-position="left">
          <!-- <el-form-item label="Loại hình:">
                        <el-select v-model="typeOptions.curTypeItem" @change="changeType">
                            <el-option v-for="(item, index) in typeOptions.data" :label="sliceFactoryName(item)"
                                :value='sliceFactoryName(item)' />
                        </el-select>
                    </el-form-item> -->
          <el-form-item label="Thời gian:">
            <el-date-picker v-model="timeObj.model" type="daterange" :clearable="false" unlink-panels range-separator="to"
              :start-placeholder="timeObj.model[0]" :end-placeholder="timeObj.model[1]"
              @calendar-change="btnSelectIndex = -1" />
            <el-button-group class="btnGroup">
              <el-button type="info" v-for="(item, index) in btnGroupOptions"
                :class="btnSelectIndex == index ? 'active' : ''" @click="changeTime(index)">
                {{ item }}
              </el-button>
            </el-button-group>
            <el-button class="el-button submitButton inquire el-button--default el-button--mini" @click="search()">
              Tìm kiếm
            </el-button>
            <el-button class="el-button submitButton el-button--default el-button--mini" @click="reset()">
              Đặt lại
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="list-info">
      <!-- <div class="header" v-if="isLottery">
        <div v-for="(item, index) in lottertRecordObj.header" class="header-item" :style="'width:' + getWidth(index)">
          {{ item }}
        </div>
      </div> -->
      <div class="header">
        <div v-for="item in recordObj.header" class="header-item">{{ item }}</div>
      </div>
      <div class="content">
        <div v-if="recordObj.list.length <= 0 && lottertRecordObj.list.length <= 0" class="not-data">
          <img :src="$img('user/not_data.png')" alt="" />
          <span>Chưa có dữ liệu</span>
        </div>
        <!-- <div v-if="isLottery">
          <div v-for="(item, index) in lottertRecordObj.list" class="content-row">
            <span class="content-item" :style="'width:' + getWidth(0)">{{
              item._id
            }}</span>
            <div class="content-item" :style="'width:' + getWidth(1)">
              {{ item.OpenTime.split(" ")[0] }}<br />{{ item.OpenTime.split(" ")[1] }}
            </div>

            <div class="content-item" :style="'width:' + getWidth(2)">
              {{ getName(item.LotteryCode) }}<br />{{ item.Number }}
            </div>
            <div class="content-item" :style="'width:' + getWidth(3)">
              {{ item.SubPlayName
              }}<span style="color: rgb(233, 207, 164)">@{{ item.Odds / 1000 }}</span>
            </div>
            <div class="content-item" :style="'width:' + getWidth(4)">
              {{ dealWord(item.Code) }}
            </div>
            <div class="content-item" :style="'width:' + getWidth(5)">
              {{ $tools.formatNum(item.TotalAmount) }}
            </div>
            <div class="content-item" :style="'width:' + getWidth(6)">
              {{ item.SProfit > 0 }}
            </div>
            <div class="content-item canClick" :style="'width:' + getWidth(7)" style="color: #4a69ff; font-weight: 700"
              @click="showLotteryInfo(item)">
              Chi tiết
            </div>
          </div>
        </div> -->
        <div>
          <div v-for="(item, index) in recordObj.list" class="content-row">
            <div class="content-item">
              {{ index + 1 }}
            </div>
            <div class="content-item">
              {{ $tools.formatDate(timeObj.model[0], "yyyy-MM-dd") }}
              {{ $tools.formatDate(timeObj.model[0], "hh:mm") }}
            </div>
            <div class="content-item">
              {{ $tools.formatDate(timeObj.model[1], "yyyy-MM-dd") }}
              {{ $tools.formatDate(timeObj.model[1], "hh:mm") }}
            </div>
            <div class="content-item">
              {{ item.factory_name + "/" + item.game_type }}
            </div>
            <div class="content-item">
              {{ $tools.formatNum2k(item.valid_bet_amount) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="totalData">
      <div class="left">
        <div class="loseOrWin">
          <i class="iconfont icon-icon_wal"></i>
          <span>
            Tổng cược hợp lệ:
            <span>{{ $tools.formatNum2k(paginationObj.totalGet) }}</span></span>
        </div>
      </div>
      <div class="right">
        <el-pagination v-model:current-page="paginationObj.currentPage" v-model:page-size="paginationObj.pageSize"
          :pager-count="3" :page-sizes="[10, 20, 30, 40, 50]" :background="true"
          layout=" total, sizes, prev, pager, next, jumper" :total="paginationObj.total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </div>
    <recordInfo @close="allInfo.showInfo = false" v-if="allInfo.showInfo" :betData="allInfo.infoData.Code"
      :subData="getSub()" :title="getTitle()" :type="allInfo.infoData.AreaCode" :recordData="allInfo.infoData">
    </recordInfo>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch } from "vue";

import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import sf_games_btn_02_svga from "@/assets/pc/svga/sf_games_btn_02.svga";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { nameRule, passRule, phoneRule, realNameRule } from "@/utils/vaildate.js";
import { topic } from "@/components/protocol/api";
import { http } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { mqant_lottery } from "@/components/protocol/mqantlib";
import recordInfo from "@/views/pc/components/lottery/recordInfo.vue";

import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries, Plays } = useGetters("tcp_lottery", ["Lotteries", "Plays"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
let isLottery = ref(false);

let allInfo = reactive({
  infoData: {},
  showInfo: false,
});

const factoryOptions = reactive({
  data: getAllFactoryList(),
  index: "0",
});

const typeOptions = reactive({
  data: factoryOptions.data[Number(factoryOptions.index)].data,
  curTypeItem: getSingelFactoryType(0),
});
let btnSelectIndex = ref(2); //默认7天
const btnGroupOptions = ["Hôm nay", "Hôm qua", "7 ngày gần đây", "30 ngày gần đây"];

let timeObj = reactive({
  model: getDefaultTime(7),
});
let recordObj = reactive({
  header: ["THỨ TỰ.", "NGÀY BẮT ĐẦU", "NGÀY KẾT THÚC", "NHÀ CUNG CẤP", "DOANH SỐ CƯỢC"],
  allList: [],
  list: [],
});

let lottertRecordObj = reactive({
  header: [
    "số thứ tự",
    "Thời gian",
    "Loại hình",
    "Cách chơi",
    "Đặt cược",
    "Tiền cược",
    "Thua/ thắng",
    "Thao tác",
  ],
  allList: [],
  list: [],
});

let paginationObj = reactive({
  currentPage: 1,
  pageSize: 10,
  total: 0,
  totalGet: 0,
});
if (isLogged.value) {
  getGameRecord();
} else {
  bus.on('onConnected', () => {
    getGameRecord()
  })
}

function getTitle() {
  return "Chi tiết " + parseInt(allInfo.infoData.Number);
}
function getSub() {
  for (var key in Plays.value) {
    for (var obj of Plays.value[key]) {
      var arr = obj.SubPlays;
      for (var lastObj of arr) {
        if (lastObj.SubName == allInfo.infoData.SubPlayName) {
          return lastObj;
        }
      }
    }
  }
}
function showLotteryInfo(val) {
  allInfo.infoData = val;
  allInfo.showInfo = true;
}

function handleClick(tab, event) {
  factoryOptions.index = tab.index;
  //   if (factoryOptions.data[Number(factoryOptions.index)].data[0].indexOf("Lottery") >= 0) {
  //     getLotteryData();
  //     isLottery.value = true;
  //   } else {
  isLottery.value = false;
  typeOptions.data = factoryOptions.data[Number(factoryOptions.index)].data;
  typeOptions.curTypeItem = getSingelFactoryType(0);
  getGameRecord();
  // }
  //重置
}

function dealWord(item) {
  if (item == "big") {
    return "Tài";
  } else if (item == "small") {
    return "Xỉu";
  } else if (item == "single") {
    return "Lẻ";
  } else if (item == "pair") {
    return "Chẵn";
  } else if (item == "up") {
    return "Trên";
  } else if (item == "draw") {
    return "Hoà";
  } else if (item == "down") {
    return "Dưới";
  } else if (item == "jin") {
    return "Kim";
  } else if (item == "mu") {
    return "Mộc";
  } else if (item == "shui") {
    return "Thủy";
  } else if (item == "huo") {
    return "Hỏa";
  } else if (item == "tu") {
    return "Thổ";
  } else if (item == "bigSingle") {
    return "Tài Lẻ";
  } else if (item == "smallSingle") {
    return "Xỉu Lẻ";
  } else if (item == "bigPair") {
    return "Tài Chẵn";
  } else if (item == "smallPair") {
    return "Xỉu Chẵn";
  } else {
    return item;
  }
}

function getWidth(index) {
  if (index == 0) {
    return 12.5 + "%";
  } else if (index == 1) {
    return 12.5 + "%";
  } else if (index == 2) {
    return 15 + "%";
  } else if (index == 3) {
    return 15 + "%";
  } else if (index == 4) {
    return 15 + "%";
  } else if (index == 5) {
    return 10 + "%";
  } else if (index == 6) {
    return 10 + "%";
  } else if (index == 7) {
    return 10 + "%";
  }
}

function getName(LotteryCode) {
  for (var key in Lotteries.value) {
    for (var obj of Lotteries.value[key]) {
      if (obj.LotteryCode == LotteryCode) {
        return obj.LotteryName;
      }
    }
  }
}
function getLotteryData() {
  var obj = {
    offset: 0,
    limit: 999,
    status: 8,
  };
  var that = this;
  mqant_lottery.request(topic.getBetRecordList, obj, function (data, topicName, msg) {
    console.log("fff", data);

    //清空
    lottertRecordObj.allList = [];
    paginationObj.totalGet = 0;
    //赋值
    lottertRecordObj.allList = data.Data.List;
    //记录总数
    paginationObj.total = recordObj.allList.length;
    // recordObj.allList.forEach(function (item, index) {
    //   paginationObj.totalGet += item.valid_bet_amount;
    // });
    //分页截取
    sliceArr();
  });
}
function changeType() { }
function getSingelFactoryType(index) {
  //let curData = factoryOptions.data[Number(factoryOptions.index)].name;

  return factoryOptions.data[Number(factoryOptions.index)].name;;
}

function sliceFactoryName(name) {
  if (name == 'TOÀN BỘ') {
    return name
  }
  let sliceName = name.slice(name.indexOf("/") + 1);
  return sliceName;
}

function getAllFactoryList() {
  //获取所有厂商类型
  let srcList = lobbyInfo.value.FactoryList;
  srcList = srcList.slice(1, srcList.length - 1)

  let destList = [];
  let tempList = [];
  for (let key in srcList) {
    let data = srcList[key].Factory;

    let info = srcList[key].vi.split('/');
    //apiBg/LiveCasino,取Bg则从第3位取到/
    let factoryName = info[0]//info.slice(3, info.indexOf("/"));

    if (tempList.indexOf(factoryName) === -1) {
      destList.push({
        name: factoryName,
        data: [data],
      });
      tempList.push(factoryName);
    } else {
      for (let j = 0; j < destList.length; j++) {
        if (destList[j].name == factoryName) {
          destList[j].data.push(data);
          break;
        }
      }
    }
  }



  destList = [{
    name: 'TOÀN BỘ',
    data: [],
  }].concat(destList)
  console.log('uuuuu', destList)
  return destList;
}

function getDefaultTime(number) {
  let startTime = new Date(); //默认7天前
  startTime.setTime(startTime.getTime() - 3600 * 1000 * 24 * number);
  let endTime = new Date(); //当前时间
  return [startTime, endTime];
}
function language() {
  return i18n.global.locale;
}
const handleSizeChange = (val) => {
  sliceArr();
};
const handleCurrentChange = (val) => {
  sliceArr();
};

function changeTime(index) {
  btnSelectIndex.value = index;
  if (index == 0) {
    timeObj.model = getDefaultTime(0);
  } else if (index == 1) {
    timeObj.model = getDefaultTime(1);
  } else if (index == 2) {
    timeObj.model = getDefaultTime(7);
  } else if (index == 3) {
    timeObj.model = getDefaultTime(30);
  }
  getGameRecord();
}

function search() {
  getGameRecord();
}
function reset() {
  timeObj.model = getDefaultTime(7);
  btnSelectIndex.value = 2;
  paginationObj.currentPage = 1;
  paginationObj.pageSize = 10;
  sliceArr();
}
function sliceArr() {
  let startIndex = (paginationObj.currentPage - 1) * paginationObj.pageSize;
  let endIndex = startIndex + paginationObj.pageSize;
  recordObj.list = recordObj.allList.slice(startIndex, endIndex);
  lottertRecordObj.list = lottertRecordObj.allList.slice(startIndex, endIndex);
  // log.info('sliceArr', recordObj.list)
}
function getGameRecord() {
  let startDate = timeObj.model[0].Format("yyyy-MM-dd") + " 00:00:00";
  let endDate = timeObj.model[1].Format("yyyy-MM-dd") + " 23:59:59";
  let obj = {
    FactoryName: typeOptions.data,
    startDate: startDate,
    endDate: endDate,
  };
  mqant.request(topic.betRecord, obj, function (data, topicName, msg) {
    //清空
    recordObj.allList = [];
    paginationObj.totalGet = 0;
    //赋值
    recordObj.allList = data.Data.BetRecord;
    //记录总数
    paginationObj.total = recordObj.allList.length;
    recordObj.allList.forEach(function (item, index) {
      paginationObj.totalGet += item.valid_bet_amount;
    });
    //分页截取
    sliceArr();
  });
}
</script>

<style lang="scss" scoped>
.betHistory {
  .cardInfo {
    margin-bottom: 0;
    overflow: hidden;
    width: 100%;
    border-radius: 8px;

    .cardTitle {
      display: flex;
      align-items: baseline !important;
      justify-content: flex-start !important;
      padding: 24px 0 21px 24px;
      height: 73px;
      line-height: 28px;
      background-color: #292e3b;
      font-weight: 500;
      color: #fff;

      .title {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
      }

      .titleRight {
        color: #8491a5 !important;
        font-size: 14px;
        margin-left: 15px;
      }
    }

    .cardBody {
      color: #fff;
      border-radius: 8px;
      background-color: transparent !important;
      margin: 0 4px !important;
      padding: 16px;
      font-size: 12px;

      .noPadding {
        padding: 0;
      }

      .tabList {
        :deep(.el-tabs) {
          .el-tabs__header {
            margin: 0 0 24px;
          }

          .el-tabs__active-bar {
            background-color: #fff;
          }

          .el-tabs__nav-wrap::after {
            background-color: #363d4e;
          }

          .el-tabs__item {
            color: #8491a5;
            font-size: 16px;
            text-align: center;
          }

          .is-active {
            color: #fff !important;
          }
        }
      }

      :deep(.el-form) {
        .el-form-item__label {
          color: #8491a5 !important;
          font-size: 14px;
          line-height: 40px;
        }

        .el-form-item__content {
          flex: none;
        }

        .el-input__wrapper {
          background: #363d4e;
          border: 1px solid transparent;
          border-radius: 6px;
          color: #677684 !important;
          height: 40px;
          line-height: 40px;
          font-size: 14px;
          box-shadow: none;
        }

        .el-select,
        .el-date-editor {
          width: 244px !important;

          span {
            color: #677684 !important;
            font-size: 14px;
            line-height: 40px;
            height: 40px;
          }
        }
      }

      .lable {
        color: #8491a5 !important;
        font-size: 14px;
        line-height: 40px;
        text-align: right;
        padding-right: 16px;
      }

      .btnGroup {
        margin-left: 20px;
      }

      :deep(.el-button-group) {
        >.el-button:first-child {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
        }

        >.el-button {
          background: #363d4e;
          border: 1px solid transparent !important;
          line-height: 40px;
          min-width: 70px;
          height: 40px;
          padding: 0 10px;

          span {
            color: #677684;
          }
        }

        .el-button:hover {
          background: #4a69ff !important;
          border-color: transparent !important;

          span {
            color: #fff;
          }
        }

        .active {
          background: #4a69ff !important;
          border-color: transparent !important;

          span {
            color: #fff;
          }
        }
      }

      .submitButton {
        background: #4a69ff;
        box-shadow: 0 0 6px 0 rgba(74, 105, 255, 0.4);
        border-radius: 6px;
        color: #fff;
        width: 96px;
        height: 40px;
        border: none;
        font-size: 14px;
      }

      .inquire {
        margin-left: 20px;
        margin-right: 10px;
      }
    }
  }

  .list-info {
    margin: 15px 20px 0;

    .header {
      border-radius: 8px 8px 0 0 !important;
      background: #363d4e !important;
      height: 50px;
      display: flex;

      .header-item {
        width: 20%;
        text-align: center;
        line-height: 50px;
        font-size: 14px;
        color: #8491a5;
        font-weight: bold;
      }
    }

    .content {
      position: relative;
      height: 485px;
      overflow-y: auto;
      border-radius: 0 8px 8px 8px;
      background-color: #232733;

      .content-row {
        background: #232733 !important;
        //height: 48px !important;
        padding: 10px 0px;
        border-bottom: 1px solid #363d4e !important;
        display: flex;
        align-items: center;

        .content-item {
          width: 20%;
          line-height: 26px;
          text-align: center;
          font-weight: 600;
          color: #fff;
          white-space: normal;
          word-break: break-all;
          word-wrap: break-word;
          padding: 0 10px;
        }
      }

      .not-data {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        img {
          width: 248px;
          height: 198px;
        }

        span {
          margin-top: 20px;
          color: #909399;
          font-size: 14px;
        }
      }
    }
  }

  .totalData {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    align-items: center;

    .left {
      display: flex;

      .total {
        background: #363d4e !important;
        color: #fff !important;
        margin-left: 20px !important;

        padding: 0 16px;
        height: 56px;
        line-height: 56px;
        border-radius: 8px;
        font-size: 15px;
      }

      .loseOrWin {
        background: #363d4e !important;
        color: #fff !important;
        margin-left: 20px !important;
        padding: 0 16px;
        height: 56px;
        line-height: 56px;
        border-radius: 8px;
        font-size: 15px;
      }
    }

    .right {
      text-align: right;
      padding: 30px 20px 30px 20px;

      :deep(.el-pagination) {
        justify-content: center !important;
      }

      :deep(.el-input) {
        .el-input__wrapper {
          background-color: #363d4e !important;
          border-radius: 6px;
          width: 244px;
          color: #677684;
          height: 40px;
          line-height: 40px;
          box-shadow: none;
        }
      }
    }
  }
}
</style>
