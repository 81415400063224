<template>
    <div>
        <p><span style="color: rgb(84, 141, 212);"><strong>ĐIỀU KHOẢN HỢP TÁC</strong></span></p>
        <p><br></p>
        <p><span style="color: rgb(255, 255, 0);">I. QUYỀN LỢI VÀ NGHĨA VŨ CỦA S6&nbsp; ĐỐI VỚI ĐỐI TÁC HỢP TÁC (HAY CÒN GỌI
                LÀ ĐẠI LÝ)</span></p>
        <p><span style="color: rgb(255, 255, 0);"><br></span></p>
        <p>1. Hỗ trợ khách hàng của S6&nbsp; sẽ xét duyệt tình hình đặt cược của hội viên. Hội viên thuộc Đại Lý bắt buộc
            đồng ý và tuân thủ nguyên tắc, chính sách và quy trình của hội viên của S6 . S6&nbsp; có quyền từ chối cũng như
            đóng khoá tài khoản của hội viên và Đại Lý nếu không tuân theo nguyên tắc của công ty.</p>
        <p><br></p>
        <p>2. Đại Lý có thể đăng nhập vào trang quản lý của đại lý bất kỳ lúc nào để theo dõi số hội viên cũng như tình hình
            đặt cược của hội viên. Đại Lý sẽ căn cứ vào số liệu trên hệ thống quản lý để tính toán số hoa hồng nhận được.
        </p>
        <p><br></p>
        <p>3. S6&nbsp; có quyền thay đổi bất kỳ điều khoản nào, bao gồm: phương án tính phần trăm hoa hồng, phương thức
            thanh toán. Nếu có sự thay đổi nào, S6&nbsp; nhất định sẽ thông báo tới quý khách qua các hình thức như email,
            thông cáo, vv…Nếu quý khách có bất kỳ ý kiến phản đối nào đều có thể thông tin cho chúng tôi qua chăm sóc khách
            hàng 24/7 hoặc lựa chọn việc ngừng hợp tác. Sau khi thay đổi, Đại Lý phải tuân thủ và thực thi điều khoản mới.
        </p>
        <p><span style="color: rgb(255, 255, 0);"><br></span></p>
        <p><span style="color: rgb(255, 255, 0);">II. QUYỀN LỢI VÀ NGHĨA VỤ CỦA ĐỐI TÁC HỢP TÁC VỚI S6&nbsp;</span></p>
        <p><span style="color: rgb(255, 255, 0);"><br></span></p>
        <p>1. Đại Lý có trách nhiệm nỗ lực quảng bá cho trang S6&nbsp; nhằm tăng lợi nhận cho bản thân cũng như trang S6 .
            Đại Lý có thể dùng nhiều cách của mình để quảng cáo, nhưng có trách nhiệm phải giới thiệu cho hội viên của mình
            các trò chơi và các chương trình ưu đãi. Nếu chọn phương thức quảng cáo mất phí, thì mức phí này sẽ do Đại Lý bỏ
            tiền túi ra.</p>
        <p><br></p>
        <p>2. Đại Lý có nghĩa vụ cập nhật thông tin liên lạc thường xuyên, đảm bảo giữ liên lạc với S6 , có thể liên hệ với
            chuyên viên đại lý hoặc chăm sóc khách hàng 24/7.</p>
        <p><br></p>
        <p>3. Bất ký tài liệu nào có liên quan đến S6&nbsp; đều không được tự ý copy, công khai, gửi cho bên thứ ba, bao gồm
            các tài liệu như: logo, bảng biểu, giao diện game, hình ảnh, văn bản. Nếu phát hiện ra, S6&nbsp; có quyền truy
            tố pháp luật. Nếu có nhã ý hợp tác, mời quý khách liên hệ chuyên viên đại lý hoặc chăm sóc khách hàng 24/7. Nếu
            có các ý tưởng quảng bá, xin mời gửi tới chúng tôi, chúng tôi rất vui lòng được góp sức tạo ra sản phẩm dựa vào
            ý tưởng của bạn.</p>
        <p><br></p>
        <p><span style="color: rgb(255, 255, 0);">III. ĐIỀU KHOẢN QUY ĐỊNH</span></p>
        <p><br></p>
        <p>1. Chưa được sự chấp nhận của chúng tôi, Đại Lý không được phép mở hai hoặc nhiều tài khoản. Nghiêm cấm đăng ký
            tài khoản hội viên dưới cấp Đại Lý, nếu bị phát hiện, S6&nbsp; có quyền ngưng hợp tác, cắt hoa hồng và huỷ tất
            cả số tiền thắng được trong tài khoản hội viên.</p>
        <p><br></p>
        <p>2. Để đảm bảo quyền lợi và sự riêng tư của hội viên S6 , chúng tôi sẽ không cung cấp thông tin tài khoản và mật
            khẩu của bất kỳ hội viên nào. Đại Lý không được phép lấy thông tin hội viên hoặc đăng nhập vào tài khoản của hội
            viên dưới bất kỳ hình thức nào. Nếu phát hiện Đại Lý xâm phạm quyền riêng tư của hội viên S6 , chúng tôi được
            phép cắt hoa hồng và dừng hợp tác ngay lập tức.</p>
        <p><br></p>
        <p>3. Hội viên dưới cấp Đại Lý không được phép mở nhiều hơn 1 tài khoản. S6&nbsp; có quyền yêu cầu hội viên cung cấp
            chứng minh thư để xác nhận, kiểm tra liệu có mở nhiều tài khoản hay không. Nếu phát hiện làm trái quy định,
            chúng tôi có quyền ngưng hợp tác, cắt hoa hồng, đóng tài khoản và huỷ bỏ tất cả tiền thắng của hội viên.</p>
        <p><br></p>
        <p>4. Nếu hội viên dưới cấp Đại Lý có bất kỳ hành vi sai trái nào thuộc phạm vi cá nhân hoặc tổ chức như đặt cược
            không hợp lệ hoặc lạm dụng ưu đãi, chúng tôi có quyền cắt số hoa hồng tương ứng. Nếu thông tin tài khoản ngân
            hàng của hội viên cần xét duyệt, chúng tôi có quyền giữ số hoa hồng tương ứng cho tới khi kết thúc xét duyệt.
        </p>
        <p><br></p>
        <p>5. Các điều khoản trong hợp đồng sẽ được tính từ thời gian hai bên bắt đầu hợp tác, hai bên có quyền ngưng hợp
            tác bất kỳ lúc nào. Trong bất kỳ tình huống nào, nếu Đại Lý có ý định ngưng hợp tác, bắt buộc phải thông báo
            bằng văn bản hoặc email cho chuyên viên đại lý hoặc chăm sóc khách hàng 24/7 trước 7 ngày. Nếu vi phạm điều
            khoản, chúng tôi có quyền cắt hợp đồng.</p>
        <p><br></p>
        <p>6. Khi chưa nhận được sự đồng ý, Đại Lý không được phép tiết lộ bất kỳ tài liệu nào liên quan đến S6 , bao gồm:
            số tiền hoa hồng nhận được, bảng biểu hoa hồng, phương thức tính hoa hồng, v.v…Đại Lý có nghĩa vụ bảo mật thông
            tin kể cả khi đã chấm dứt hợp tác.</p>
        <p><br></p>
        <p>7. Nếu không liên lạc được Đại Lý trong vòng 3 tháng, chúng tôi có quyền đóng tài khoản và chấm dứt hợp tác. Khi
            đó, S6&nbsp; được phép huỷ bỏ hoa hồng trong 6 tháng tính từ lúc chấm dứt hợp tác, nếu quá thời hạn vẫn không
            liên lạc được, chúng tôi được phép huỷ bỏ tất cả số hoa hồng liên quan.</p>
        <p><br></p>
        <p>8. Khi hai bên chấm dứt hợp tác, hai bên không có trách nhiệm thi hành các quyền và nghĩa vụ với nhau. Kết thúc
            hợp đồng không có nghĩa sẽ cắt đứt mối quan hệ hợp tác giữa chúng tôi và hội viên đã tham gia trong thời gian
            hợp tác.</p>
        <p><br></p>
        <p>9.&nbsp;Tất cả các đại lý có hoa hồng từ 100 triệu trở lên sẽ bị khấu trừ 10% phí hành chính - dịch vụ - ngân
            hàng bắt đầu áp dụng từ 01/5/2023</p>
        <p><br></p>
    </div>
</template>

<script setup>

</script>

<style lang="scss" scoped></style>