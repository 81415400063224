<template>
  <div class="live">
    <div class="container">
      <div class="banner">
        <img :src="$img('liveCasino/live_banner.png')" alt="">
      </div>
    </div>
    <div class="main">
      <div class="providerList">
        <ul>
          <li class="third-game-live fiveFelx" v-for="(item, index) in listData"
            @click="openFactory(item.Factory.FactoryName)">
            <div class="gameItem">
              <div class="ani-container">
                <SvgaPlayer :id="'btn_ani_zr_black_' + index" :svgaUrl="btn_ani_zr_black_svga" :svgaWidth="688"
                  :svgaHeight="200">
                </SvgaPlayer>
              </div>
              <div class="imgs">
                <img class="croupier" :src="$img(`liveCasino/${item.Factory.FactoryName}_icon_1.png`)" alt="">
                <img class="foreground" :src="$img(`liveCasino/${item.Factory.FactoryName}_icon_3.png`)" alt="">
              </div>
              <div class="playInfo">
                <img class="platLogo" :src="$img(`liveCasino/${item.Factory.FactoryName}_icon_2.png`)" alt="">
                <p class="playCode">{{ item.Factory.FactoryShowName }}</p>
                <div class="playRebateWp">
                  <span class="playRebate">Phí hoàn tối đa 1.3%</span>
                </div>
                <div class="playButton">Vào trò chơi</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script setup>
import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import btn_ani_zr_black_svga from "@/assets/pc/svga/btn_ani_zr_black.svga";
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { isLogged } = useGetters("tcp", ["isLogged"]);
const $act = getCurrentInstance().appContext.config.globalProperties.$act
let listData = getFactorDataBytype('LiveCasino');
function getFactorDataBytype(type) {
  let data = lobbyInfo.value.StaticPicture[type];
  let rusult = [];
  if (!data) return;
  for (let key in data) {
    let info = data[key];
    if (info.Factory.Status == 1) {
      rusult.push(info);
    }
  }
  rusult.sort((a, b) => {
    return b.Factory.Sort - a.Factory.Sort
  })
  return rusult;
}
const openFactory = (factoryName) => {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    return;
  }
  if (factoryName) {
    let url = getApiLoginUrl(factoryName, '');
    $act.openPage(url);
  }
}
</script>

<style lang="scss" scoped>
.live {
  position: relative;
  margin: 0 auto;
  background: #292e3b;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;

  .container {
    width: 1400px;
    height: auto;
    margin: 0 auto;
    position: relative;

    .banner {
      width: 1400px;
      margin: 35px 0 10px;
      padding-bottom: 20px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .main {
    padding-top: 30px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;

    .providerList {
      padding: 0;
      width: 1400px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      position: relative;
      z-index: 9;

      >ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 100%;
        padding: 0 0 30px;

        .third-game-live {
          cursor: pointer;
          position: relative;

          .gameItem {
            background-repeat: no-repeat;
            background-image: url('@/assets/pc/recommend/bg_live_item.png');
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, .2);
            border-radius: 24px;
            overflow: hidden;
            width: 100%;
            height: 100%;
            background-size: contain;

            .imgs {
              width: 240px;
              left: 65px;
              height: 100%;
              position: relative;
              z-index: 1;
              pointer-events: none;

              img {
                position: absolute;
              }

              .croupier {
                width: 168px;
                top: auto;
                bottom: 0;
                transition: all .5s;
              }

              .foreground {
                height: 78px;
                left: auto;
                right: 0;
                bottom: 0;
                transform: translateX(-5px);
                width: auto;
              }
            }

            .playInfo {
              position: absolute;
              right: 40px;
              bottom: 35px;
              width: 250px;
              align-items: flex-end;
              justify-content: flex-start;
              padding: 0;
              height: 162px;
              top: 14px;
              transition: transform .3s, -webkit-transform .3s;

              display: flex;
              flex-direction: column;
              z-index: 1;
              box-sizing: border-box;
              margin: 0;
              pointer-events: none;

              .platLogo {
                height: 40px;
                position: relative;
                z-index: 2;
              }

              .playCode {
                font-size: 28px;
                line-height: 40px;
                color: #fff;
                font-weight: 600;
                position: relative;
                z-index: 2;
              }

              .playRebateWp {
                line-height: 25px;

                .playRebate {
                  font-size: 18px;
                  line-height: 25px;
                  color: #fff;
                  opacity: .5;
                  position: relative;
                  z-index: 99;
                }
              }

              .playButton {
                width: auto;
                min-width: 130px;
                height: 36px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 24px;
                background: #fff;
                color: #24262b;
                transition: -webkit-transform .3s;
                transition: transform .3s;
                transition: transform .3s, -webkit-transform .3s;

                position: relative;
                z-index: 2;
                border-radius: 24px;
                padding: 0 4px;
                font-size: 14px;
              }
            }

            .ani-container {
              position: absolute;
              width: 100%;
              height: 100%;
              bottom: 0;
              display: none;
            }
          }
        }

        .third-game-live:hover .croupier {
          transform: scale(1.16);
        }

        .third-game-live:hover .playInfo .playButton {
          transform: scale(1.1);
        }

        .third-game-live:hover .ani-container {
          display: block;
        }

        .fiveFelx {
          width: 688px;
          height: 200px;
          margin: 0 0 24px;
        }
      }
    }
  }
}
</style>