<template>
  <div class="addBankCard">
    <div class="nav">
      <van-nav-bar title="Thêm vào">
        <template #left>
          <i class="van-icon van-icon-arrow-left van-nav-bar__arrow" @click="comeBack()"></i>
        </template>
      </van-nav-bar>
    </div>
    <div class="scrollList">
      <div class="wrapper-tontent">
        <p class="remindText">1、Để đảm bảo tiền chuyển nhanh chóng, vui lòng điền họ tên trùng với họ tên tài khoản ngân
          hàng；</p>
        <p class="remindText">2、Vui lòng kiểm tra thông tin tài khoản, tài khoản không chính xác sẽ không thể chuyển
          khoản.</p>
        <van-field v-model="obj.realName" :disabled="obj.isDisabled" placeholder="xin điền họ tên" :border="false" />
        <van-field v-model="obj.cardNum" type="number" placeholder="vui lòng nhập số tài khoản ngân hàng"
          :border="false" />
        <p class="remindText">Ngân hàng</p>
        <div class="select-bank" @click="showPicker = true">
          <span class="span-2">{{ obj.curBankName }}</span>
          <img :src="$img('userCenter/icon_jiantou.png')" alt="">
        </div>
        <van-field v-model="obj.password" type="number" placeholder="nhập mật khẩu rút tiền" :border="false" />
        <div class="pay-btn" @click="onSubmit">
          <span> Xác nhận </span>
        </div>
      </div>
    </div>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker :columns="obj.bankNameList" @cancel="showPicker = false" @confirm="onConfirm"
        confirm-button-text="confirm" cancel-button-text="cancel" />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch } from "vue";

import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { Toast } from "vant";

const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const { basicInfo } = useGetters("tcp", ["basicInfo"]);
let showPicker = ref(false);
let obj = reactive({
  realName: '',
  isDisabled: false,
  cardNum: '',
  password: '',
  bankNameList: [],
  curBankName: ''
})

let DoudouPwd = ref('')

// bus.emit(bus.event.showFooter, false);
function comeBack() {
  router.go(-1);
  // bus.emit(bus.event.showFooter, false);
}
onBeforeUnmount(() => {
  // bus.emit(bus.event.showFooter, true);
})
if (isLogged.value) {
  getUserInfo()
  getBankList()
}
bus.on('onConnected', () => {
  getUserInfo()
  getBankList()
})
function getBankList() {
  mqant.request(topic.bankList, {}, function (data, topicName, msg) {
    for (let key in data.Data.douDouBtList) {
      let info = data.Data.douDouBtList[key];
      obj.bankNameList.push(info.BtName);
    }
    obj.curBankName = obj.bankNameList[0];
  });
}
function onConfirm(value) {
  obj.curBankName = value;
  showPicker.value = false;
}

function getUserInfo() {

  mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
    DoudouPwd.value = data.Data.DoudouPwd;
    obj.realName = data.Data.realName;
    obj.isDisabled = !data.Data.realName ? false : true
  });
}

function onSubmit() {
  if (!obj.realName) {
    Toast.fail('Vui lòng nhập tên');
    return;
  }
  if (!obj.cardNum) {
    Toast.fail('Nhập tài khoản ngân hàng');
    return;
  }
  if (!obj.password) {
    Toast.fail('Nhập mật khẩu giao dịch');
    return;
  }
  if (obj.password !== DoudouPwd.value) {
    Toast.fail('Mật khẩu sai');
    return;
  }
  let objSubmit = {
    btName: obj.curBankName,
    cardNum: obj.cardNum,
    accountName: obj.realName,
  }
  log.info('objSubmit', objSubmit)
  mqant.request(topic.bindBank, objSubmit, function (data, topicName, msg) {
    if (data.Code == 0) {
      Toast.success(data.ErrMsg);
      comeBack();
    } else {
      Toast.fail(data.ErrMsg);
    }
  });
}
</script>

<style lang="scss" scoped>
.addBankCard {
  width: 100%;
  height: calc(100% - 50px);
  position: relative;
  background-color: #293356;

  .nav {
    :deep(.van-nav-bar) {
      box-shadow: 0 0.5px 0 0 hsla(0, 0%, 100%, .15);
      line-height: 5.866667vw;
      text-align: center;
      background-color: #293356;
      user-select: none;

      .van-nav-bar__content {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        height: 13.333333vw;
      }

      .van-nav-bar__left {
        font-size: 5.333333vw;
      }

      .van-nav-bar__arrow {
        margin-right: 1.066667vw;
        font-size: 5.333333vw;
      }

      .van-nav-bar__title {
        max-width: 60%;
        margin: 0 auto;
        color: #fff;
        font-weight: 500;
        font-size: 4.266667vw;
      }

      .van-icon {
        color: #fff;
      }

      .van-nav-bar__left,
      .van-nav-bar__right {
        position: absolute;
        top: 0;
        bottom: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 0 4.266667vw;
        font-size: 3.733333vw;
        cursor: pointer;

        .rightText {
          font-size: 5.333333vw;
          color: #fff;
        }
      }
    }
  }

  .scrollList {
    overflow: hidden;
    position: absolute;
    top: 16vw;
    bottom: 0;
    left: 0;
    right: 0;

    .wrapper-tontent {
      padding: 0 4.266667vw;
      margin-bottom: 5.333333vw;
      font-size: 3.733333vw;
      color: #fff;

      .select-bank {
        margin-top: 20px;
        width: 100%;
        height: 49px;
        padding: 0 16px;
        background-color: #1e2646;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .span-2 {
          font-size: 12px;
          color: #ffffff;
        }

        img {
          height: 11px;
        }
      }

      .remindText {
        margin-top: 2.666667vw;
        line-height: 1.2;
        font-size: 3.733333vw;
        color: #fff;
        font-weight: 400;
      }

      .pay-btn {
        position: relative;
        height: 49px;
        background-image: linear-gradient(90deg,
            #6e4aff 17%,
            #5064ff 100%),
          linear-gradient(#0e1525,
            #0e1525);
        background-blend-mode: normal,
          normal;
        border-radius: 25px;

        span {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          font-size: 14px;
        }
      }
    }
  }
}

:deep(.van-cell) {
  margin: 15px auto;
  background-color: #1e2646;
  border-radius: 8px;
  font-size: 12px;
  --van-field-label-color: #424f81;
  --van-field-input-text-color: #fff;
}
</style>
