<template>
  <div class="personalInfo">
    <div class="header">
      <div class="van-nav-bar van-nav-bar--fixed" style="z-index: 1; box-shadow: none; background-color: transparent">
        <div class="van-nav-bar__content">
          <div class="van-nav-bar__left" @click="back()">
            <i class="van-icon van-icon-arrow-left van-nav-bar__arrow"><!----></i>
          </div>
          <div class="van-nav-bar__title van-ellipsis">Thêm vào</div>
        </div>
      </div>
    </div>
    <div class="wrapper-tontent">
      <div class="header">
        <span>Số tài khoản</span>
        <div>Số lương tài khoản{{ infoData.myDouDouBT.length }}/3</div>
      </div>
      <div class="body">
        <div v-for="(item, index) in infoData.myDouDouBT" class="card card">
          <div class="bank">
            <div class="bankName">
              {{ item.BtName }}
            </div>
          </div>
          <div class="bankNum">
            {{ item.CardNum }}
          </div>
          <div class="bankInfo">
            <div role="radiogroup" class="van-radio-group">
              <div v-if="false" role="radio" tabindex="0" aria-checked="true" class="van-radio">
                <div class="van-radio__icon van-radio__icon--round van-radio__icon--checked">
                  <span class="radio-style iconfont icon-icon_radio_button_sel1"></span>
                </div>
                <span class="van-radio__label"><span>Mặc định</span></span>
              </div>
            </div>
          </div>
        </div>
        <p class="modifyText">
          Mỗi hội viên được thêm tối đa 3 tài khoản ngân hàng
        </p>
        <div class="send" @click="toAdd()">
          <div class="sendBtn">
            Thêm vào
          </div>
        </div>
      </div>
      <div class="botoom"></div>
    </div>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import { Dialog } from "vant";
import "vant/es/dialog/style";
import moment from "moment";
import { topic } from "@/components/protocol/api";
export default {
  data() {
    return {
      infoData: {
        myDouDouBT: [],
      },
      checked: '0'
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged", "wallet", "basicInfo"]),
    ...mapGetters("user", ["userInfo"]),
  },
  components: {},
  created() {
    var that = this;
    if (this.isLogged) {
      this.getUserInfo();
    }
    var that = this;
    bus.on("onConnected", () => {
      that.getUserInfo();
    });
  },
  methods: {
    back() {
      window.history.back();
    },
    toAdd() {
      this.$router.push({ name: 'addBankCard' })
    },
    getUserInfo() {
      var that = this;
      this.$tools.loading();

      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        that.$tools.dismissLoading();
        that.infoData = data.Data;
        console.log("111", data);
        that.RealName = data.Data.RealName;
        that.oldJYPw = data.Data.DoudouPwd;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper-tontent {
  font-size: 3.733333vw;
  color: #fff;
  margin-bottom: 5.333333vw;
  font-weight: 400;
  padding: 0 4.266667vw;

  .body {
    text-align: center;

    .send {
      padding: 0 4.266667vw;
      margin-top: 6.666667vw;

      .sendBtn {
        height: 13.333333vw;
        background-image: linear-gradient(90deg, #7146ff, #4a69ff);
        border-radius: 6.666667vw;
        line-height: 13.333333vw;
        vertical-align: middle;
        font-size: 3.733333vw;
        color: #fff;
        text-align: center;
        font-weight: 400;
      }
    }

    .modifyText {
      font-size: 3.2vw;
      color: #e9cfa4;
      text-align: center;
      font-weight: 400;
      margin: 5.333333vw 0;
    }

    .card {
      padding: 5.333333vw;
      margin-top: 5.333333vw;
      background-image: url('@/assets/mobile/userCenter/bg_bank_card.png');
      // background: #35416d;
      border-radius: 2.133333vw;
      background-size: contain;
      color: #fff;

      .bankInfo {
        display: flex;
        height: 10.666667vw;
        justify-content: space-between;
        align-items: center;

        :deep(.van-radio__label) {
          color: #fff;
        }

        // .van-radio {
        //   display: flex;
        //   align-items: center;
        //   overflow: hidden;
        //   cursor: pointer;
        //   justify-content: center;

        //   .van-radio__label {
        //     color: #fff;
        //     text-align: center;
        //     margin-left: 0;
        //     line-height: 5.333333vw;
        //   }

        //   .van-radio__icon {
        //     flex: none;
        //     height: 1em;
        //     font-size: 5.333333vw;
        //     line-height: 1em;
        //     cursor: pointer;
        //     margin-right: 1.333333vw;
        //   }

        //   .van-radio__icon--checked {
        //     color: #e9cfa4;
        //   }
        // }
      }

      .bankNum {
        padding: 13.333333vw 0 8vw;
        display: flex;
      }

      .bank {
        display: flex;
        justify-content: space-between;
        align-content: center;

        .bankName {
          font-size: 4.8vw;
          color: #e9cfa4;
          text-align: right;
          font-weight: 500;
        }
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 5.333333vw 0;
  }
}

.header {
  height: 13.333333vw;
}

.van-icon {
  color: white !important;
}

.van-nav-bar__title {
  color: white !important;
}
</style>
