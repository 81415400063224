<template>
    <div class="fish">
        <div class="flexContainer">
            <div class="container">
                <div class="main">
                    <div class="providerList providerListFish">
                        <ul>
                            <li v-for="(item, index) in listData" :class="item.Factory.Status !== 1 ? 'disabled' : ''"
                                @click="itemClick(item)">
                                <img :src="$img(`fish/${item.Factory.FactoryName}_icon_1.png`)" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CommonItemPop :title="popObj.title" :data="popObj.data" v-if="showPop" />
</template>


<script setup>
import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import btn_ani_chess_white_svga from "@/assets/pc/svga/btn_ani_chess_white.svga";
import { ref } from "vue";
import CommonItemPop from "@/views/pc/components/CommonItemPop.vue"
import { reactive } from "vue";
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { isLogged } = useGetters("tcp", ["isLogged"]);
const $act = getCurrentInstance().appContext.config.globalProperties.$act
let showPop = ref(false);
let popObj = reactive({
    show: true,
    data: []
})
let listData = getFactorDataBytype('Fish');
function getFactorDataBytype(type) {
    let data = lobbyInfo.value.StaticPicture[type];
    let rusult = [];
    if (!data) return;
    for (let key in data) {
        let info = data[key];
        if (info.Factory.Status == 1) {
            rusult.push(info);
        }
    }
    rusult.sort((a, b) => {
        return b.Factory.Sort - a.Factory.Sort
    })
    return rusult;
}
const itemClick = (item) => {
    if (item.Factory.Status !== 1) {
        return;
    }
    popObj.title = item.Factory.FactoryShowName;
    popObj.data = item.GameList;
    showPop.value = true;
}
bus.on(bus.event.showPop, (bool) => {
    showPop.value = false;
})
</script>

<style lang="scss" scoped>
.fish {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: auto;
    background: #292e3b;
    border-top: 1px solid hsla(0, 0%, 100%, .05);

    .flexContainer {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .container {
            background-image: url('@/assets/pc/fish/img_fish_bg.png');
            min-height: 816px;
            background-size: 1920px;
            width: 1920px;
            margin: 0 auto;
            position: relative;
            overflow: hidden;

            .main {
                width: 1400px;
                padding: 32px 0;
                margin: 0 auto;
                position: relative;

                .providerListFish {
                    height: auto;
                    justify-content: flex-end;
                    overflow-x: visible;
                    background: 0 0;
                    margin-bottom: 30px;
                    padding-left: 0;

                    ul {
                        width: 680px;
                        margin-top: 150px;
                        flex-wrap: wrap;
                        display: flex;
                        justify-content: center;

                        li {
                            width: 200px;
                            height: 260px;
                            margin-left: 10px;
                            margin-right: 10px;
                            background: 0 0;
                            transition: all .3s;
                            cursor: pointer;

                            img {
                                width: 100%;
                            }
                        }

                        li:hover {
                            transform: scale(1.1);
                        }
                    }
                }

                .disabled {
                    cursor: not-allowed;
                    -webkit-filter: grayscale(100%);
                    filter: grayscale(100%);
                }

                .providerList {
                    width: 1400px;
                    border-radius: 16px;
                    display: flex;
                    align-items: center;
                    position: relative;
                    z-index: 9;
                }
            }
        }
    }

}
</style>