<template>
	<div class="round_box" style="width: 100%;position: relative;">
		
		<img  :src="$img('turntable/'+'arrow.png')" alt="" style="width:38px;position: absolute;top: -5px;left: calc(50% - 19px);z-index:1;">
		
			<img class="img_rotate" ref="rotImg" :src="$img('turntable/'+'wup.png')" alt="" style="width: 100%;">
			<!-- <div class="center">
				<div class="pointer"></div>
			</div> -->
<!-- 			<button @click="toDraw">点击抽奖</button>
 -->

	</div>
</template>

<script>
	import {
		ref
	} from "vue";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import {
		theme
	} from '@/views/theme'

	import {
		bus,
		busName
	} from "@/components/core/bus";
	export default {
		components: {

		},
		data() {
			return {
				rotate: 0,
				resetRotate: 0,
				hitId: 1, // 1-8
				drawStatus: false
			};
		},
		setup() {

		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),
		},
		async mounted() {
			await this.$nextTick();
			let evenTransition = this.whichTransitionEvent();
			let img = this.$refs.rotImg;
			let that = this;
			const hitAre = ['30M流量包', '20金币', '20M流量包', '10M流量包', '5金币',
				'谢谢参与', '10金币', '50M流量包'
			];
			// 监听 动画结束 
			img.addEventListener(evenTransition, tranHand, false);

			function tranHand() {
				// 复位
				bus.emit('canTurn')
				that.resetRotate = that.rotate > 360 ? that.rotate % 360 : 0;
				img.style.transition = "none 0s ease 0s";
				img.style.transform = `rotate(${that.resetRotate}deg)`;
				//alert(`抽奖结果【 ${hitAre[that.hitId - 1]} 】`);
				that.drawStatus = false
			}
		},
		methods: {
			// handleClose() {
			// 	this.$emit('handleClose')

			// },
			start() {
				this.$refs.rotImg.style.transition = "all 10s ease 0s";
				this.$refs.rotImg.style.transform = `rotate(${this.rotate}deg)`;
			},
			toDraw(index) {
				
				if (this.drawStatus) {
					console.log('正在抽奖中');
					return
				}
				// 标记状态
				this.drawStatus = true
				/**
				 * 圆盘共 8 份 每份 45度, 停位置(id)度数 (id - 1)*45
				 * 基数 3圈 360*4
				 * this.rotate 当前角度
				 * **/
				let reset = 360 * 6;
				let idx = index  //获取1-16随机数 this.getRandomInt(1, 16);
				// 设置命中
				this.hitId = idx;
				// 需要多转角度
				let addRotate = this.resetRotate > 0 ? 360 - this.resetRotate : 0;
				// 总共角度
				let allRotate = this.rotate - (idx - 1) * 45  + reset + addRotate;
				// 角度限制
				this.rotate = this.setRotate(allRotate);

				this.start()
			},
			// 递归计算角度 不超过 360*6
			setRotate(deg) {
				let rest = deg - 360;
				return rest > 360 * 6 ? this.setRotate(rest) : deg;
			},
			getRandomInt(min, max) {
				// 向上收
				min = Math.ceil(min);
				// 向下收
				max = Math.floor(max);
				return Math.floor(Math.random() * (max - min)) + min; //不含最大值，含最小值
			},
			// 动画兼容
			whichTransitionEvent() {
				let el = document.createElement('span'),
					transitions = {
						'transition': 'transitionend',
						'OTransition': 'oTransitionEnd',
						'MozTransition': 'transitionend',
						'WebkitTransition': 'webkitTransitionEnd'
					};
				for (let t in transitions) {
					if (el.style[t] !== undefined) {
						return transitions[t];
					}
				}
				el = null;
			}
		
	},

	};
</script>

<style lang="scss" scoped>
	.img_rotate {
		transform: rotate(0deg);
	}

	.round_box {
		// width: 100%;
		// max-width: 375px;
		position: relative;
		overflow: hidden;

		img {
			width: 100%;
		}

		.center {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.pointer {
				width: 5px;
				height: 90px;
				background-color: #f40;
				position: absolute;
				top: -90px;
			}

			.pointer::before {
				content: '';
				width: 0;
				height: 0;
				border-top: 15px solid transparent;
				border-right: 15px solid transparent;
				border-bottom: 15px solid #f40;
				border-left: 15px solid transparent;
				position: absolute;
				top: -20px;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
</style>
