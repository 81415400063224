<template>
	<div @touchmove.prevent @mousewheel.prevent @click="cancel()" class="wrap" style="width: 100%;height: 100%;font-weight: bold;position: fixed;z-index: 99999;background-color: rgba(0, 0, 0, .4);top: 0;left: 0;">
		<div style="position: relative;width: 928px;margin: 100px auto;" @click.stop="">
			<div style="position: absolute;right: 110px;top:30px;color:#222f42;z-index:9999;font-size: 20px;">
				Trang : {{Math.ceil(offSet/7)+1}}
			</div>
			
			<img :src="$img('turntable/'+'recordBg.png')" alt="" style="width: 100%;">
			<div @click="cancel()" class="canClick"  style="width: 44px;height: 44px;border-radius: 31.5px;text-align: center;position: absolute;right: -10px;top:-20px ;z-index: 999;">
				<img :src="$img('turntable/'+'closeWhite.png')" alt="" style="width: 44px;margin-top: 9.5px;">
				
			</div>
			<img :src="$img('turntable/'+'shangyiye.png')" @click="prev()" :class="offSet>0?'canClick':'diseableImg'"  alt="" style="width: 35px;position: absolute;left: 5px;top: 240px;z-index: 999;">
			<img :src="$img('turntable/'+'xiayiye.png')" @click="next()" alt=""  :class="offSet+7<allNum?'canClick':'diseableImg'" style="width: 35px;position: absolute;right: 5px;top: 240px;z-index: 999;">
			
			<div style="position: absolute;left: 0;top:0;width: 100%;">
				<div style="width: 495px;color:white;font-size: 25.5px;line-height: 70px;text-align: center;position: relative;margin: 11px auto;">
					
					<div style="position: relative;z-index: 8;">LỊCH SỬ QUAY</div>
<!-- 					<img :src="$img('turntable/'+'recordTop.png')" alt="" style="width: 100%;position: absolute;left: 0;top: 0;">
 -->					
				</div>
				<div style="width: 90%;margin-left: 5%;border-radius: 10px;overflow: hidden;height: 332px;">
					<div class="flexs_r_c" style="height: 52px;width: 100%;">
						<div style="width: 30%;font-size: 22.5px;color:white;text-align: center;line-height: 52px;">PHIÊN</div>
						<div style="width: 30%;font-size: 22.5px;color:white;text-align: center;line-height: 52px;">THỜI GIAN</div>
						<div style="width: 40%;font-size: 22.5px;color:white;text-align: center;line-height: 52px;">TRÚNG THƯỞNG</div>
					</div>
					<div class="flexs_r_c" v-for="(item,index) in recordData" style="height: 40px;width: 100%;">
						<div style="width: 30%;font-size: 22.5px;color:white;text-align: center;line-height: 40px;">{{item.No}}</div>
						<div style="width: 30%;font-size: 22.5px;color:white;text-align: center;line-height: 40px;">{{$tools.formatDate(item.CreateAt, "yyyy-MM-dd")}}</div>
					
						<div  style="width: 40%;font-size: 22.5px;color:white;text-align: center;line-height: 40px;" v-if="item.GetVnd>0" class="canClick">{{item.WordType}}-{{item.GetVnd/1000}}K</div>
						<div  style="width: 40%;font-size: 22.5px;color:white;text-align: center;line-height: 40px;" v-else class="canClick">{{item.WordType}}-{{item.GetPoint}}điểm</div>
						
					</div>
				</div>
				<div class="flexs_sb" style="width: 94%;margin-left: 3%;border-radius: 10px;overflow: hidden;height: 54px;margin-top: -5px;">
					<div class="flexs_r_c">
							<img :src="$img('turntable/'+'num_L_H.png')"  alt="" style="width: 30px;margin-left: 24px;">
						
							<img :src="$img('turntable/'+'num_U_H.png')"  alt="" style="width: 30px;margin-left: 14px;">
						
							<img :src="$img('turntable/'+'num_C_H.png')"  alt="" style="width: 30px;margin-left: 16px;">
						
							<img :src="$img('turntable/'+'num_K_H.png')"  alt="" style="width: 30px;margin-left: 17px;">
						
							<img :src="$img('turntable/'+'num_Y_H.png')"  alt="" style="width: 30px;margin-left: 7px;">
						
					</div>
					<div style="font-size: 22.5px;margin-right: 24px;color: white;">GIÁ TRỊ ĐỔI LUCKY: IPHONE 14 PRO MAX 256GB</div>
					
					
				</div>
				
			</div>
			
		</div>
	</div>
	
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import {mqant} from "@/components/protocol/mqantlib";
	import { bus, busName } from "@/components/core/bus";

	
		import  tools from "@/utils/tools";
	
	import {
		req,
		qs
	} from "@/utils/request";
	
	import {
		topic
	} from "@/components/protocol/api";
			import { http } from "@/components/protocol/api";
	
	export default {
		data(){
			return {
				offSet:0,
				recordData:[],
				allNum:0
			}
		},
		props:{
			
			
		},
		created() {
			this.getData()
		},
		methods:{
			prev(){
				if(this.offSet>0){
					this.offSet-=7
					this.getData()
				}
			},
			next(){
				if(this.offSet+7<this.allNum){
					this.offSet += 7
					this.getData()
					
				}
			},
			cancel(){
				this.$emit('cancel')
			},
			getData(){
				let params = {
					Offset:this.offSet,
					PageSize:7
				
				};
				let that = this;
				mqant.request(topic.turnTableRecord, params, function(res) {
					console.log('ssss',res)
					if (res.Code == 0) {
						that.recordData = res.Data.Record
						that.allNum = res.Data.TotalNum
					} else {
						that.$message({
							message: that.$t(res.ErrMsg),
							type: 'error'
						});
					}
				});
			},
			
			
			
		}
	}
</script>

<style scoped lang="scss">
	.title{
		
		
		color:#999999;
		position: relative;
		z-index: 88;
		text-align: center;
		//margin-top: 30px;
		padding-top: 20px;
		// top: 30px;
	}
	.cancel{
		width: 144px;
		height: 40px;
		color: white;
		margin: 0 auto;
		line-height: 40px;
		text-align: center;
		border-radius: 20px;
		background-color: #d3b35c;
	}
	.sure{
		width: 144px;
		height: 40px;
		color: white;
		line-height: 40px;
		text-align: center;
		border-radius: 20px;
		background-color: #3a8c38;
	}
</style>