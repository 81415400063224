<template>
  <div
    class=""
    style="
	
      padding: 10px;
      background: #1e242c;
      margin: 0 13px;
      border-radius: 12px;
      border: 1px solid rgba(214, 217, 224, 0.29);
      
    "
  >
    <div class="flexs_r_c row2">
      <div class="titleBtn">Chọn ngẫu nhiên</div>

      <div class="flexs_r_c" style="margin-left: 20px">
        <div
          v-for="(item, index) in numArr2"
          v-if="isR"
          :class="selectItem == item ? 'select' : 'nolmal'"
          @click="itemClick(item)"
          class="btn canClick"
          :style="index > 0 ? 'margin-left:6px' : ''"
        >
          {{ item }}
        </div>
        <div
          v-for="(item, index) in numArr1"
          v-else
          :class="selectItem == item ? 'select' : 'nolmal'"
          @click="itemClick(item)"
          class="btn canClick"
          :style="index > 0 ? 'margin-left:6px' : ''"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div v-if="!isZH">
      <div class="flexs_r_c row2">
        <div class="titleBtn">Chọn số</div>

        <div class="flexs_r_c" style="margin-left: 20px">
          <div
            v-for="(item, index) in dxdsArr"
            :class="selectItem == item ? 'select' : 'nolmal'"
            @click="itemClick(item)"
            class="btn canClick"
            :style="index > 0 ? 'margin-left:6px' : ''"
          >
            {{ item }}
          </div>
        </div>
      </div>

      <div class="flexs_r_c row2">
        <div class="titleBtn">Trường long</div>

        <div class="flexs_r_c" style="margin-left: 20px">
          <div
            v-for="(item, index) in topArr"
            :class="selectItem == item ? 'select' : 'nolmal'"
            @click="itemClick(item)"
            class="btn canClick"
            :style="index > 0 ? 'margin-left:6px' : ''"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="inputBox" >
      <textarea rows="3" v-model="text"   v-on:focus="showTip=false" v-on:blur="showTip=true"  class="inputTextarea"></textarea>
      <div  class="playExplain" v-if="showTip">
        Ví dụ: 10<span>,</span>20<span>,</span>30 hoặc 10 20 30 hoặc
        10<span>;</span>20<span>;</span>30 hoặc
        10<span>|</span>20<span>|</span>30
      </div>
      <button
        type="button" @click="doBet()"
        class="el-button btn el-button--primary el-button--mini inputSubBtn"
      >
        <!----><!----><span>Đặt cược</span></button
      ><button 
        type="button" @click="reset()"
        class="el-button btn el-button--default el-button--mini inputReBtn"
      >
        <!----><!----><span>Chọn lại</span>
      </button>
    </div>

  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";

export default {
  data() {
    return {
      numArr1: ["1số", "2số", "3số", "5số", "10số"],
      numArr2: ["10số", "20số", "30số", "40số", "50số"],
      dxdsArr: ["Kép bằng", "Chẵn", "Lẻ", "Tài", "Xỉu"],
      topArr: ["Top 1", "Top 2", "Top 5", "Top 10", "Top 20"],
      text: "",
      rightIndex1: -1,
      rightIndex2: -1,
      rightIndex3: -1,
      selectItem: null,
      numLength: "",
      isZH: false,
      isR: false,
      showTip:true
    };
  },
  props: {
    type: {
      //1.1键全选 2.全部
      type: Number,
      default: 1,
    },

    recordData: {},
    selectSub: {},
  },
  mounted() {
    var that = this;

    bus.on("allClean", (val) => {
      that.text = "";
      that.selectItem = null;
    });
    if (
      this.selectSub.SubPlayCode.indexOf("ZH") >= 0 ||
      this.selectSub.SubPlayCode.indexOf("BCZH") >= 0
    ) {
      this.numArr1 = [this.numArr1[0]];
      this.isZH = true;
    }
    if (
      this.selectSub.SubPlayCode.indexOf("2DTW") >= 0 ||
      this.selectSub.SubPlayCode.indexOf("3DTW") >= 0 ||
      this.selectSub.SubPlayCode.indexOf("4D") >= 0
    ) {
      this.numArr1 = this.numArr2;
      this.isR = true;
    }
    //this.getTopData()
    if (
      this.selectSub.SubPlayCode == "BZ_C3" ||
      this.selectSub.SubPlayCode == "BZ_C4" ||
      this.selectSub.SubPlayCode.indexOf("3DTW") >= 0 ||
      this.selectSub.SubPlayCode.indexOf("4D") >= 0
    ) {
      this.dxdsArr = [this.dxdsArr[0]];
    }
    if (
      this.selectSub.SubPlayCode == "BZ_C3" ||
      this.selectSub.SubPlayCode.indexOf("3DTW") >= 0
    ) {
      this.numLength = 3;
    } else if (
      this.selectSub.SubPlayCode == "BZ_C4" ||
      this.selectSub.SubPlayCode.indexOf("4D") >= 0
    ) {
      this.numLength = 4;
    } else {
      this.numLength = 2;
    }
  },
  methods: {
    doBet() {
      if(!this.text.length){
        this.$message({
            message: "Điền số",
            type: "warning",
          });
        
        
        return
      }
      this.text += "";
      var obj = {
        numArr: this.text.split(","),

        betType: "random",
        SubPlayCode: this.selectSub.SubPlayCode,
      };

      bus.emit("BET", obj);
      this.text = "";
      this.selectItem = null;
    },
    reset() {
      this.text = "";
      this.selectItem = null;
    },
    itemClick(str) {

      if (this.selectItem == str) {
        this.selectItem = null;
        this.text = "";
        this.showTip = true
        return;
      }
      this.showTip = false
      this.selectItem = str;
      if (str == "1số") {
        if (this.isZH) {
          var forNum = this.selectSub.UnitBetCodeCount;
          var arr = [];
          for (var i = 0; i < 100; i++) {
            var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
            if (this.numLength == 3 && num >= 10 && num < 100) {
              num = "0" + num;
            }
            if (this.numLength == 4) {
              if (num >= 10 && num < 100) {
                num = "00" + num;
              } else if (num >= 100 && num < 1000) {
                num = "0" + num;
              }
            }
            num = num < 10 ? "0" + num : num;

            if (arr.indexOf(num) < 0) {
              arr.push(num);
              if (arr.length == forNum) {
                break;
              }
            }
          }
          this.text = arr.join(",");
        } else {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          this.text = num < 10 ? "0" + num : num;
        }
      } else if (str == "2số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 2) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "3số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 3) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "5số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 5) {
              break;
            }
          }
        }

        this.text = arr.join(",");
      } else if (str == "10số") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 10) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "20số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 20) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "30số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 30) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "40số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 40) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "50số") {
        var arr = [];
        for (var i = 0; i < 1000; i++) {
          var num = this.done(this.numLength, 9); //Math.round(Math.random()*100)
          num = num < 10 ? "0" + num : num;
          if (this.numLength == 3 && num >= 10 && num < 100) {
            num = "0" + num;
          }
          if (this.numLength == 4) {
            if (num >= 10 && num < 100) {
              num = "00" + num;
            } else if (num >= 100 && num < 1000) {
              num = "0" + num;
            }
          }
          if (arr.indexOf(num) < 0) {
            arr.push(num);
            if (arr.length == 50) {
              break;
            }
          }
        }
        this.text = arr.join(",");
      } else if (str == "Kép bằng") {
        if (this.numLength == 2) {
          this.text = "00,11,22,33,44,55,66,77,88,99";
        } else if (this.numLength == 3) {
          this.text = "000,111,222,333,444,555,666,777,888,999";
        } else {
          this.text = "0000,1111,2222,3333,4444,5555,6666,7777,8888,9999";
        }
      } else if (str == "Chẵn") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = i;
          if (num % 2 == 0) {
            num = num < 10 ? "0" + num : num;
            arr.push(num);
          }
        }
        this.text = arr.join(",");
      } else if (str == "Lẻ") {
        var arr = [];
        for (var i = 0; i < 100; i++) {
          var num = i;
          if (num % 2 == 1) {
            num = num < 10 ? "0" + num : num;
            arr.push(num);
          }
        }
        this.text = arr.join(",");
      } else if (str == "Tài") {
        var arr = [];
        for (var i = 50; i < 100; i++) {
          var num = i;

          num = num < 10 ? "0" + num : num;
          arr.push(num);
        }
        this.text = arr.join(",");
      } else if (str == "Xỉu") {
        var arr = [];
        for (var i = 0; i < 50; i++) {
          var num = i;

          num = num < 10 ? "0" + num : num;
          arr.push(num);
        }
        this.text = arr.join(",");
      } else if (str == "Top 1") {
        this.text = this.getTopData(1);
      } else if (str == "Top 2") {
        this.text = this.getTopData(2);
      } else if (str == "Top 5") {
        this.text = this.getTopData(3);
      } else if (str == "Top 10") {
        this.text = this.getTopData(4);
      } else if (str == "Top 20") {
        this.text = this.getTopData(5);
      }
    },
    done(len, max) {
      var arr = [];
      var result = "";
      var count = 0;
      while (count < len) {
        var n = Math.floor(Math.random() * (max + 1));
        if (arr.join().indexOf(n) == -1) {
          arr.push(n);
          count++;
        }
      }
      for (let index = 0; index < arr.length; index++) {
        result = result + arr[index];
      }
      return parseInt(result);
    },
    getTopData(type) {
      var data = {};
      var recordData = this.recordData.slice(0,50)

      for (var recordObj of recordData) {
        var obj = recordObj.OpenCode;
        var forNum = 10;
        if (this.selectSub.SubPlayCode.indexOf("FUNNY") >= 0 || this.isR) {
          forNum = 1;
        }

        for (var i = 0; i < forNum; i++) {
          if (obj["L" + i]) {
            var arr = obj["L" + i];
            if (
              this.selectSub.SubPlayCode == "2DTW_TOU" ||
              this.selectSub.SubPlayCode == "2DTW_WEI" ||
              this.selectSub.SubPlayCode == "3DTW_TJ" ||
              this.selectSub.SubPlayCode.indexOf("4D") >= 0
            ) {
              arr = obj.L0;
            }

            if (
              this.selectSub.SubPlayCode == "2DTW_TOU_L1" ||
              this.selectSub.SubPlayCode == "2DTW_WEI_L1" ||
              this.selectSub.SubPlayCode == "3DTW_TJ_L1"
            ) {
              arr = obj.L1;
            }
            if (this.selectSub.SubPlayCode == "2DTW_L7") {
              arr = obj.L7;
            }

            for (var num of arr) {
              var str;
              if (
                this.selectSub.SubPlayCode == "BZ_C2_HEAD" ||
                this.selectSub.SubPlayCode.indexOf("2DTW_TOU") >= 0 ||
                this.selectSub.SubPlayCode == "2DTW_L7"
              ) {
                str = num.substr(0, 2);
              } else if (
                this.selectSub.SubPlayCode == "BZ_C2" ||
                this.selectSub.SubPlayCode == "BZ_C2_1K" ||
                this.selectSub.SubPlayCode.indexOf("FUNNY") >= 0 ||
                this.selectSub.SubPlayCode.indexOf("2DTW_WEI") >= 0
              ) {
                str = num.substr(num.length - 2, 2);
              } else if (
                this.selectSub.SubPlayCode == "BZ_C3" ||
                this.selectSub.SubPlayCode.indexOf("3DTW") >= 0
              ) {
                if (num.length >= 3) {
                  str = num.substr(num.length - 3, 3);
                }
              } else if (
                this.selectSub.SubPlayCode == "BZ_C4" ||
                this.selectSub.SubPlayCode.indexOf("4D") >= 0
              ) {
                if (num.length >= 4) {
                  str = num.substr(num.length - 4, 4);
                }
              }

              if (data[str]) {
                data[str]++;
              } else {
                data[str] = 1;
              }
            }
          }
        }
      }
      let newArr = Object.entries(data).sort((a, b) => b[1] - a[1]); //Object.fromEntries(Object.entries(data).sort((a, b) => a[1]  - b[1]));

      if (type == 1) {
        return newArr[0][0];
      } else if (type == 2) {
        var tmpArr = [];
        for (var i = 0; i < 2; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);
        return tmpArr.join(",");
      } else if (type == 3) {
        var tmpArr = [];
        for (var i = 0; i < 5; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr.join(",");
      } else if (type == 4) {
        var tmpArr = [];
        for (var i = 0; i < 10; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr.join(",");
      } else if (type == 5) {
        var tmpArr = [];
        for (var i = 0; i < 20; i++) {
          tmpArr.push(newArr[i][0]);
        }
        tmpArr = tmpArr.sort((a, b) => a - b);

        return tmpArr.join(",");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  margin-right: 8px;
  height: 32px;
  line-height: 32px;
  width: 68px;
  text-align: center;
  color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  user-select: none;
  font-size: 14px;
  font-weight: 500;
}
.select {
  background-color: #4a69ff;
  color: white;
}
.nolmal {
  background-color: #292e3b;
  color: white;
}
.titleBtn {
  height: 52px;
  line-height: 52px;
  background-image: none;
  color: #8491a5;
  width: 112px;
  position: relative;
  font-size: 12px;
  padding: 0 10px;
  text-align: center;
}
.titleBtn:after {
  content: "";
  position: absolute;
  right: 0;
  top: 16px;
  display: inline-block;
  width: 1px;
  height: 20px;
  background-color: #677684;
}
textarea {
  border: solid 0px;
  resize:none;
  outline: none;
}
.el-button--mini {
    padding: 7px 15px;
    font-size: 12px;
    border-radius: 3px;
}
.row2 {
  height: 52px;
  background-color: #363d4e;
  display: flex;
  margin-bottom: 6px;
  border-radius: 12px;
}
.inputBox{
	margin:0;
    padding: 0 10px 10px;
	flex:1;
	position:relative;
	border-radius:12px;
  border: 1px solid rgba(214,217,224,.29);
	width:100%;
	background-color: #363d4e;
	 .btn{
		position:absolute;
		
		width: 96px;
		height: 32px;
		right: 22px;
		border-radius: 6px;
		border: none!important;
		font-size: 14px;
		color:#fff;
	}
	.inputReBtn {
		bottom: 26px;
		background-color:#292e3b;
		box-shadow: 0 1px 2px 0 rgba(0,0,0,.2);
		color: #fff;
	}
	.inputSubBtn{
		bottom: 10px!important;
		left: auto;
		top: 16px;
		background: #4a69ff!important;
	}
	.inputTextarea{
		background-color: transparent;
		//border: 1px solid rgba(214,217,224,.29);
		border-radius: 12px;
		height: 90px!important;
		width:600px;
		padding: 17px 12px 0px 10px!important;
		color: #fff;
	}
	.playExplain{
		color: #535d6f;
		user-select: none;
		pointer-events: none;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		padding: 18px;
		font-size: 14px;
		line-height: 16px;
		padding: 18px 120px 18px 18px;
	}
}
</style>