<template>
    <div class="luckyWin">
        <div class="container">
            <div class="banner">
                <img :src="$img('luckyWin/feature_banner.png')" alt="">
            </div>
        </div>
        <div class="main">
            <div class="providerList">
                <ul>
                    <li v-for="(item, index) in listData" @click="itemClick(item)">
                        <div class="featureItem">
                            <div class="main">
                                <img :src="$img('luckyWin/icon/' + item.GameCode + '_1.png', 'pc')" alt="">
                            </div>
                            <div class="ani-container">
                                <SvgaPlayer :id="'btn_ani_feature_white_' + index" :svgaUrl="btn_ani_feature_white_svga"
                                    :svgaWidth="216" :svgaHeight="278">
                                </SvgaPlayer>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
  
  
<script setup>
import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import btn_ani_feature_white_svga from "@/assets/pc/svga/btn_ani_feature_white.svga";
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
const { isLogged } = useGetters("tcp", ["isLogged"]);
const $act = getCurrentInstance().appContext.config.globalProperties.$act
let listData = getLuckyWinData();
function getLuckyWinData() {
    let slotData = lobbyInfo.value.StaticPicture.LuckyWin;
    if (!slotData.apiLuckyWin) return [];
    let list = slotData.apiLuckyWin.GameList;
    return list;
}
function itemClick(item) {
    if (!isLogged.value) {
        bus.emit(bus.event.loginState, true);
        return;
    }
    let url = getApiLoginUrl(item.FactoryName, item.GameCode);
    $act.openPage(url);
}
</script>
  
<style lang="scss" scoped>
.luckyWin {
    position: relative;
    margin: 0 auto;
    background: #292e3b;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;

    .container {
        width: 1400px;
        height: auto;
        margin: 0 auto;
        position: relative;

        .banner {
            width: 1400px;
            margin: 35px 0 10px;
            padding-bottom: 20px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .main {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0;

        .providerList {
            padding: 0;
            width: 1400px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            position: relative;
            z-index: 9;

            ul {
                width: 100%;
                max-width: 100%;
                display: flex;
                justify-content: center !important;
                flex-wrap: wrap;
                padding: 0 0 30px;

                li {
                    width: 276px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    position: relative;
                    padding: 0 30px;
                    box-sizing: border-box;
                    transition: all .3s;

                    .featureItem {
                        .main {
                            img {
                                width: 100%;
                            }
                        }

                        .ani-container {
                            position: absolute;
                            width: 216px;
                            height: 278px;
                            top: 0;
                            // -webkit-transform: scale(.9);
                            transform: scale(.9);
                            display: none;
                        }
                    }

                    .featureItem:hover .ani-container {
                        display: block;
                    }
                }
            }
        }
    }
}
</style>