<template>
  <div class="">
    <topHeader title="Thay đổi mật khẩu đăng nhập" :showRight="false" :showBack="true"></topHeader>
    <div class="flexs_r_c" style="justify-content: center">
      <div class="topItem" :class="selectIndex == 0 ? 'van-tab--active' : ''" @click="topClick(0)">
        Mật khẩu
      </div>
      <div class="topItem" :class="selectIndex == 1 ? 'van-tab--active' : ''" @click="topClick(1)">
        Mật khẩu rút tiền
      </div>
    </div>

    <div v-if="selectIndex == 0" style="
        padding: 0 4.266667vw;
        margin-bottom: 5.333333vw;
        font-size: 3.733333vw;
        color: #fff;
      ">
      <p class="remindText">Mật khẩu gồm 6-15 ký tự chữ và số</p>
      <div class="van-cell van-field van-field--error">
        <div class="van-cell__value van-cell__value--alone van-field__value">
          <div class="van-field__body">
            <input :type="pwSee ? 'text' : 'password'" autocomplete="off" name="oldLoginPwd" v-model="pw"
              placeholder="Mật khẩu cũ" class="van-field__control" />
            <div class="van-field__right-icon" @click="pwSee = !pwSee">
              <span class="iconfont icon-icon_show icon-icon_hide" v-if="!pwSee"></span>

              <span v-else class="iconfont icon-icon_show icon-icon_show"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="van-cell van-field van-field--error">
        <div class="van-cell__value van-cell__value--alone van-field__value">
          <div class="van-field__body">
            <input :type="pw2See ? 'text' : 'password'" autocomplete="off" name="oldLoginPwd" v-model="pw2"
              placeholder="Mật khẩu mới" class="van-field__control" />
            <div class="van-field__right-icon" @click="pw2See = !pw2See">
              <span class="iconfont icon-icon_show icon-icon_hide" v-if="!pw2See"></span>

              <span v-else class="iconfont icon-icon_show icon-icon_show"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="van-cell van-field van-field--error">
        <div class="van-cell__value van-cell__value--alone van-field__value">
          <div class="van-field__body">
            <input :type="confirmSee ? 'text' : 'password'" autocomplete="off" v-model="confirmPw" name="oldLoginPwd"
              placeholder="Xác nhận mật khẩu mới" class="van-field__control" />
            <div class="van-field__right-icon" @click="confirmSee = !confirmSee">
              <span class="iconfont icon-icon_show icon-icon_hide" v-if="!confirmSee"></span>

              <span v-else class="iconfont icon-icon_show icon-icon_show"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else style="
        padding: 0 4.266667vw;
        margin-bottom: 5.333333vw;
        font-size: 3.733333vw;
        color: #fff;
      ">
      <p class="remindText">Mật khẩu giao dịch gồm 6 số</p>
      <div class="van-cell van-field van-field--error">
        <div class="van-cell__value van-cell__value--alone van-field__value">
          <div class="van-field__body">
            <input :type="jySee ? 'text' : 'password'" autocomplete="off" @input="jyPw = checkNum(jyPw)"
              name="oldLoginPwd" v-model="jyPw" placeholder="Mật khẩu cũ" class="van-field__control" />
            <div class="van-field__right-icon" @click="jySee = !jySee">
              <span class="iconfont icon-icon_show icon-icon_hide" v-if="!jySee"></span>

              <span v-else class="iconfont icon-icon_show icon-icon_show"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="van-cell van-field van-field--error">
        <div class="van-cell__value van-cell__value--alone van-field__value">
          <div class="van-field__body">
            <input :type="jy2See ? 'text' : 'password'" autocomplete="off" @input="jyPw2 = checkNum(jyPw2)"
              name="oldLoginPwd" v-model="jyPw2" placeholder="Mật khẩu mới" class="van-field__control" />
            <div class="van-field__right-icon" @click="jy2See = !jy2See">
              <span class="iconfont icon-icon_show icon-icon_hide" v-if="!jy2See"></span>

              <span v-else class="iconfont icon-icon_show icon-icon_show"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="van-cell van-field van-field--error">
        <div class="van-cell__value van-cell__value--alone van-field__value">
          <div class="van-field__body">
            <input :type="jyConfirmSee ? 'text' : 'password'" autocomplete="off" v-model="jyConfirmPw"
              @input="jyConfirmPw = checkNum(jyConfirmPw)" name="oldLoginPwd" placeholder="Xác nhận mật khẩu mới"
              class="van-field__control" />
            <div class="van-field__right-icon" @click="jyConfirmSee = !jyConfirmSee">
              <span class="iconfont icon-icon_show icon-icon_hide" v-if="!jyConfirmSee"></span>

              <span v-else class="iconfont icon-icon_show icon-icon_show"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="send" @click="send()">
      <div class="sendBtn">Xác nhận</div>
    </div>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import { Dialog } from "vant";
import "vant/es/dialog/style";
import moment from "moment";
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";

import { topic } from "@/components/protocol/api";
export default {
  data() {
    return {
      selectIndex: 0,
      pw: "",
      pwSee: false,
      pw2: "",
      pw2See: false,
      confirmPw: "",
      confirmSee: false,

      jyPw: "",
      jySee: false,
      jyPw2: "",
      jy2See: false,
      jyConfirmPw: "",
      jyConfirmSee: false,
      oldJYPw: null,
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged", "wallet", "basicInfo"]),
    ...mapGetters("user", ["userInfo"]),
  },
  components: { topHeader },
  created() {
    var that = this;
    if (this.isLogged) {
      this.getUserInfo();
    }
    var that = this;
    bus.on("onConnected", () => {
      that.getUserInfo();
    });
  },
  methods: {
    send() {
      if (this.selectIndex == 0) {
        this.changePwSubmit();
      } else {
        this.changeJySubmit();
      }
    },
    getUserInfo() {
      var that = this;
      this.$tools.loading();

      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        // console.log('dd',data)
        that.$tools.dismissLoading();

        that.oldJYPw = data.Data.DoudouPwd;
      });
    },
    topClick(index) {
      if (this.selectIndex != index) {
        if (index == 1) {
          if (!this.oldJYPw.length) {
            this.$dialog
              .confirm({
                title: "Xin lưu ý",
                message:
                  "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
                confirmButtonText: 'Xác nhận',
                cancelButtonText: 'Hủy bỏ'
              })
              .then(() => {
                this.$router.push({ path: "/transactionPwd" });
              })
              .catch(() => { });
          } else {
            this.selectIndex = index;
            this.pw = ''
            this.pw2 = ''
            this.confirmPw = ''
            this.jyPw = ''
            this.jyPw2 = ''
            this.jyConfirmPw = ''
          }
        } else {
          this.selectIndex = index;
          this.pw = ''
          this.pw2 = ''
          this.confirmPw = ''
          this.jyPw = ''
          this.jyPw2 = ''
          this.jyConfirmPw = ''
        }
      }
    },
    checkNum(data) {
      if (data) {
        if ((data + "").length >= 6) {
          data = data.substr(0, 6);
        }
        return (data = data.replace(/[^0-9]/g, ""));
      }
    },
    changeJySubmit() {
      if (
        !this.jyPw.length ||
        this.jyPw.length != 6 ||
        !this.jyPw2.length ||
        this.jyPw2.length != 6 ||
        !this.jyConfirmPw.length ||
        this.jyConfirmPw.length != 6
      ) {
        this.$tools.toast("Mật khẩu giao dịch gồm 6 số");

        return;
      }
      if (this.jyPw != this.oldJYPw) {
        this.$tools.toast(this.$t("原密码错误"));

        return;
      }

      if (this.jyPw2 != this.jyConfirmPw) {
        this.$tools.toast(this.$t("两次输入的密码不一致！"));

        return;
      }
      var obj = {
        DoudouPwd: this.jyPw2,
      };
      var that = this;

      mqant.request(
        topic.updateDoudouPwd,
        obj,
        function (data, topicName, msg) {
          if (data.Code == 0) {
            //	that.handleClose()
            that.$tools.success(that.$t("设置成功"));
            that.getUserInfo()
            that.jyPw = ''
            that.jyPw2 = ''
            that.jyConfirmPw = ''
          } else {
            that.$tools.toast(data.ErrMsg);
          }
        }
      );
    },
    changePwSubmit() {
      if (!this.pw.length) {
        this.$tools.toast("Mật khẩu cũ không được để trống");

        return;
      }
      if (!this.pw2.length) {
        this.$tools.toast("Mật khẩu mới không được để trống");

        return;
      }

      if (!this.confirmPw.length) {
        this.$tools.toast("Xác nhận mật khẩu mới không được để trống");

        return;
      }

      if (
        this.pw.length < 6 ||
        this.pw2.length < 6 ||
        this.confirmPw.length < 6 ||
        this.pw.length > 15 ||
        this.pw2.length > 15 ||
        this.confirmPw.length > 15
      ) {
        this.$tools.toast("Mật khẩu dài 6-15 ký tự chữ và số");
        return;
      }

      if (this.pw2 != this.confirmPw) {
        this.$tools.toast(this.$t("两次输入的密码不一致！"));

        return;
      }
      let params = {
        OldPwd: this.pw,
        NewPwd: this.pw2,
        ConfirmPwd: this.confirmPw,
      };
      this.$tools.loading();
      var that = this;
      mqant.request(topic.changePW, params, function (data, topicName, msg) {
        that.$tools.dismissLoading();

        if (data.Code == 0) {
          //window.history.back();
          that.$tools.success(that.$t("修改密码成功！"));
          that.pw = ''
          that.pw2 = ''
          that.confirmPw = ''

        } else {
          that.$tools.toast(data.ErrMsg);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.flexs_r_c {
  padding-top: 65px;
}

.topItem {
  border-radius: 1.6vw;
  height: 8vw !important;
  line-height: 8vw !important;
  color: #fff;
  font-size: 3.733333vw;
  width: 150px;
  text-align: center;
  background-color: rgb(47, 57, 95);
}

.van-tab--active {
  background-image: linear-gradient(135deg, #7146ff, #4a69ff);
}

.remindText {
  margin-top: 5.333333vw;
  line-height: 1.2;
  font-size: 3.733333vw;
  color: #fff;
  font-weight: 400;
}

.van-cell {
  padding: 0;
  background-color: transparent !important;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  overflow: hidden;
  color: #323233;
  font-size: 3.733333vw;
  line-height: 6.4vw;
}

.van-field__body {
  height: 13.333333vw;
  line-height: 13.333333vw;
  width: 100%;
  padding: 0;
  margin-top: 4.266667vw;
  border-radius: 2.133333vw;
  background-color: #1e2646;
  align-items: center;

  .van-field__control {
    border-radius: 2.133333vw;
    padding: 0 4.266667vw;
    color: #fff;
  }
}

.van-field__right-icon {
  margin-right: 0;
  color: #90a2dc;
  padding: 0 2.133333vw;
  line-height: inherit;
}

.van-cell:after {
  border-bottom: none !important;
}

.send {
  margin: 5.333333vw 4.266667vw;

  .sendBtn {
    height: 13.333333vw;
    background-image: linear-gradient(90deg, #7146ff, #4a69ff);
    border-radius: 6.666667vw;
    line-height: 13.333333vw;
    vertical-align: middle;
    font-size: 3.733333vw;
    color: #fff;
    text-align: center;
    font-weight: 400;
  }
}
</style>

<style>
.van-toast {
  word-break: keep-all !important;
}
</style>
