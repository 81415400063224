<template>
  <div class="withdraw">
    <div class="nav">
      <van-nav-bar title="Rút Tiền">
        <!-- <template #left>
          <i class="van-icon van-icon-arrow-left van-nav-bar__arrow" @click="comeBack()"></i>
        </template> -->
        <template #right>
          <span @click="toBill()" class="rightText iconfont icon-icon_history"></span>
        </template>
      </van-nav-bar>
    </div>
    <div class="scrollList">
      <van-loading v-if="loading" class="loading" type="spinner" />
      <div v-if="!loading" class="main">
        <div class="wrapper-tontent">
          <div class="cardList">
            <div class="moneyLeft">
              <div class="money">{{ $tools.formatNum2k(wallet.VndBalance) }}</div>
              <div class="moneyText">
                <span>Số dư</span>
              </div>
            </div>
          </div>
          <div class="card">
            <p class="channelTitle">Chọn tài khoản ngân hàng</p>
          </div>
          <div class="select-bank" @click="showPicker = true">
            <span class="span-2">{{ obj.curBank.BtName }}</span>
            <img :src="$img('userCenter/icon_jiantou.png')" alt="">
          </div>
          <div class="remindText">
            <div>
              <i class="iconfont icon-icon_remind"></i>
            </div>
            <span>Phạm vi rút tiền trên mỗi lệnh : <br> {{ $tools.formatNum2k(payInfo.doudouMin) }} ~
              {{ $tools.formatNum2k(payInfo.doudouMax) }} VND(K)
            </span>
          </div>
          <van-field v-model="obj.money" type="number" placeholder="Nhập số tiền rút" :border="false" />
          <van-field v-model="obj.password" placeholder="Mật khẩu rút tiền" :border="false" />
          <div class="hint">
            <p> Để tránh xảy ra sai sót, vui lòng nhập chính xác mật khẩu giao dịch</p>
          </div>
          <div class="pay-btn" @click="onSubmit">
            <span>Rút Tiền</span>
          </div>
        </div>
      </div>
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker :columns="obj.bankNameList" @cancel="showPicker = false" @confirm="onConfirm"
          confirm-button-text="confirm" cancel-button-text="cancel" />
      </van-popup>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch } from "vue";

import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
import i18n from "@/language/i18n";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { Toast, Dialog } from "vant";

const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const { payInfo } = useGetters("pay", ["payInfo"])
const { basicInfo } = useGetters("tcp", ["basicInfo"]);
let loading = ref(true);
let showPicker = ref(false);
let obj = reactive({
  userData: {},
  bankList: [],
  bankNameList: [],
  curBank: {},
  password: '',
  money: '',
})
// bus.emit(bus.event.showFooter, false);
if (isLogged.value) {
  getUserInfo();
}
bus.on('onConnected', () => {
  getUserInfo()
})
function toBill() {
  router.push({ path: '/billRecord' });


}
function getUserInfo() {
  mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
    if (data.Code == 0) {
      log.info('userInfo', data.Data);
      obj.userData = data.Data;
      obj.bankList = [];
      obj.bankNameList = [];
      for (let i = 0; i < obj.userData.myDouDouBT.length; i++) {
        let info = obj.userData.myDouDouBT[i];
        obj.bankList.push(info);
        // let str = "****" + info.CardNum + ' ' + info.BtName;
        let str = info.CardNum + ' ' + info.BtName;
        obj.bankNameList.push(str);
      }
      obj.curBank = obj.bankList[0];
      loading.value = false;
    } else {
      loading.value = true;
    }
  });
}

function comeBack() {
  router.go(-1);
  // bus.emit(bus.event.showFooter, true);
}
onBeforeUnmount(() => {
  // bus.emit(bus.event.showFooter, true);
})
function onConfirm(value) {
  let index = 0;
  for (let i = 0; i < obj.bankNameList.length; i++) {
    let info = obj.bankNameList[i];
    if (value == info) {
      index = i;
      break;
    }
  }
  for (let i = 0; i < obj.bankList.length; i++) {
    if (i == index) {
      obj.curBank = obj.bankList[i];
      break;
    }
  }
  showPicker.value = false;
}

function onSubmit() {
  if (!obj.money) {
    Toast.fail('Vui lòng nhập số tiền');
    return;
  }
  if (!obj.password) {
    Toast.fail('Nhập mật khẩu giao dịch');
    return;
  }
  if (obj.password !== basicInfo.value.DoudouPwd) {
    Toast.fail('Mật khẩu sai');
    return;
  }
  let objSubmit = {
    btOid: obj.curBank.Oid,
    amount: obj.money * 1000,
    btName: "",
    cardNum: "",
    accountName: "",
  };
  tools.loading();
  mqant.request(topic.payDoudou, objSubmit, function (res) {
    tools.dismissLoading();
    if (res.Code == 0) {
      obj.bankList = [];
      obj.bankNameList = [];
      obj.password = '';
      obj.money = '';
      Toast.success(res.ErrMsg);
    } else {
      Toast.fail(res.ErrMsg);
    }
  });
}
</script>

<style lang="scss" scoped>
.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.withdraw {
  width: 100%;
  height: calc(100% - 50px);
  position: relative;
  background-color: #293356;

  .nav {
    :deep(.van-nav-bar) {
      box-shadow: 0 0.5px 0 0 hsla(0, 0%, 100%, .15);
      line-height: 5.866667vw;
      text-align: center;
      background-color: #293356;
      user-select: none;

      .van-nav-bar__content {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        height: 13.333333vw;
      }

      .van-nav-bar__left {
        font-size: 5.333333vw;
      }

      .van-nav-bar__arrow {
        margin-right: 1.066667vw;
        font-size: 5.333333vw;
      }

      .van-nav-bar__title {
        max-width: 60%;
        margin: 0 auto;
        color: #fff;
        font-weight: 500;
        font-size: 4.266667vw;
      }

      .van-icon {
        color: #fff;
      }

      .van-nav-bar__left,
      .van-nav-bar__right {
        position: absolute;
        top: 0;
        bottom: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 0 4.266667vw;
        font-size: 3.733333vw;
        cursor: pointer;

        .rightText {
          font-size: 5.333333vw;
          color: #fff;
        }
      }
    }
  }

  .scrollList {
    overflow-y: auto;
    position: absolute;
    top: 13.333333vw;
    bottom: 0;
    left: 0;
    right: 0;



    .main {
      .wrapper-tontent {
        font-size: 3.733333vw;
        color: #fff;
        padding-bottom: 5.333333vw;
        padding-left: 4.266667vw;
        padding-right: 4.266667vw;

        .cardList {
          height: 29.333333vw;
          margin: 5.333333vw 0;

          .moneyLeft {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 164px;
            height: 100%;
            padding: 0 5.333333vw;
            position: relative;
            background: #35416d;
            box-shadow: 0 0.533333vw 1.066667vw 0 rgba(0, 0, 0, .2);
            border-radius: 3.2vw;

            .money {
              flex: 2;
              display: flex;
              flex-direction: column;
              justify-content: center;
              font-size: 3.733333vw;
              color: #21e06b;
              line-height: 1.5;
              font-weight: 500;
              text-align: left;
            }

            .moneyText {
              flex: 1;
              font-size: 3.733333vw;
              color: #e9cfa4;
              font-weight: 500;
              position: relative;
              text-align: center;
              display: flex;

              span {}
            }
          }
        }

        .select-bank {
          margin-top: 20px;
          width: 100%;
          height: 49px;
          padding: 0 16px;
          background-color: #1e2646;
          border-radius: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .span-2 {
            font-size: 12px;
            color: #ffffff;
          }

          img {
            height: 11px;
          }
        }

        .remindText {
          margin-top: 2.666667vw;
          line-height: 1.2;
          font-size: 3.2vw;
          color: #90a2dc;
          font-weight: 400;
          display: flex;

          i {
            font-size: 3.733333vw;
            color: #e9cfa4;
            margin-right: 1.333333vw;
          }
        }

        .hint {
          margin-bottom: 20px;

          p {
            color: #90a2dc;
            font-weight: 400;
            line-height: 1.2;
          }
        }

        .pay-btn {
          position: relative;
          height: 49px;
          background-image: linear-gradient(90deg,
              #6e4aff 17%,
              #5064ff 100%),
            linear-gradient(#0e1525,
              #0e1525);
          background-blend-mode: normal,
            normal;
          border-radius: 25px;

          span {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            color: #ffffff;
            font-size: 14px;
          }
        }
      }
    }
  }
}

:deep(.van-cell) {
  margin: 15px auto;
  background-color: #1e2646;
  border-radius: 8px;
  font-size: 12px;
  --van-field-label-color: #424f81;
  --van-field-input-text-color: #fff;
}
</style>
