<template>
    <div class="message">
        <div class="cardInfo">
            <div class="cardTitle">
                <span v-if="!mailObj.show" class="title">Hòm thư</span>
                <div v-else class="slotTitle" @click="mailObj.show = false">
                    <i class="el-icon-arrow-left"></i>
                    <span class="titleText">Chi tiết thông báo</span>
                </div>
            </div>
            <div class="cardBody">
                <div v-if="!mailObj.show" class="list">
                    <div v-for="(item, index) in mailObj.data" class="list-item" @click="itemClick(index)">
                        <div class="msgTitle">
                            <div class="left-title">
                                <div class="new-notice"> Thông báo mới nhất </div>
                                <el-button type="danger" :icon="Delete" circle @click.stop="openDelTip()" />
                            </div>
                            <div class="right-title">
                                {{ $tools.formatDate(item.SendTime, "yyyy-MM-dd") }}
                                {{ $tools.formatDate(item.SendTime, "hh:mm") }}
                            </div>
                        </div>
                        <div class="msgContent">{{ item.Content }}</div>
                    </div>
                </div>
                <div v-else class="detailPage">
                    <div>
                        <div class="top">
                            <div class="timeBox">
                                <span data-v-44799a54="">
                                    {{ $tools.formatDate(mailObj.data[mailObj.index].SendTime, "yyyy-MM-dd") }}
                                    {{ $tools.formatDate(mailObj.data[mailObj.index].SendTime, "hh:mm") }}
                                </span>
                            </div>
                        </div>
                        <div class="main">
                            <div>
                                {{ mailObj.data[mailObj.index].Content }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ref,
    onMounted,
    onBeforeUnmount,
    reactive,
    nextTick,
    watch,
} from "vue";
import {
    Delete,
} from '@element-plus/icons-vue'
import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { http } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
    "onLogged",
    "doLogin",
]);
let mailObj = reactive({
    data: [],
    index: 0,
    show: false,
})

if (isLogged.value) {
    getMessageList()
} else {
    bus.on('onConnected', () => {
        getMessageList()
    })
}

function itemClick(index) {
    mailObj.index = index;
    mailObj.show = true;
}

function delItem() {
    let oid = mailObj.data[mailObj.index].Oid;
    mqant.request(topic.deleteMail, { Oid: oid }, function (data, topicName, msg) {
        if (data.Code == 0) {
            ElMessage({
                type: 'success',
                message: 'Xóa thành công',
            })
            getMessageList();
        }
    });
}
const openDelTip = () => {
    ElMessageBox.confirm(
        'Bạn có chắc chắn muốn xóa?',
        'Warning',
        {
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ',
            type: 'warning',
        })
        .then(() => {
            delItem();
        })
        .catch(() => {
        })
}
function getMessageList() {
    mqant.request(topic.mailList, {}, function (data, topicName, msg) {
        if (data.Code == 0) {
            //重置
            mailObj.data = [];
            mailObj.index = 0;
            mailObj.show = false;
            mailObj.data = data.Data.mail;
            // log.info('getMessageList', data);
        }
    });
}
</script>

<style lang="scss" scoped>
.message {
    .cardInfo {

        .cardTitle {
            display: flex;
            align-items: baseline !important;
            justify-content: flex-start !important;
            padding: 24px 0 21px 24px;
            height: 73px;
            line-height: 28px;
            background-color: #292e3b;
            color: #fff;
            font-size: 20px;

            .slotTitle {
                cursor: pointer;
                color: #fff;

                span {
                    padding-left: 10px;
                    color: #fff;
                }
            }
        }

        .cardBody {
            padding: 24px 24px;
            width: 100%;

            .list {
                height: 600px;
                overflow-y: auto;
                display: flex;
                flex-direction: column;

                .list-item {
                    padding: 20px 10px;
                    margin-bottom: 5px;
                    border-bottom: 1px solid rgba(115, 122, 146, .14);
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    background: #363d4e;
                    border-radius: 8px;

                    .msgTitle {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-bottom: 12px;

                        .left-title {
                            display: flex;
                            align-items: center;

                            .new-notice {
                                color: #fff;
                                margin-right: 20px;
                                background-color: #4a69ff !important;
                                border-radius: 2px;
                                width: 160px;
                                padding: 0 4px;
                                height: 26px;
                                line-height: 26px;
                                text-align: center;
                            }
                        }
                    }

                    .msgContent {
                        max-width: 200px;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        font-size: 14px;
                    }
                }
            }

            .detailPage {
                color: #8491a5;

                .top {
                    font-size: 14px;

                    p {
                        color: #8491a5 !important;
                        padding-top: 20px;
                        margin-bottom: 10px;
                        padding-left: 24px;
                    }

                    .timeBox {
                        display: flex;
                        justify-content: space-between;
                        margin: 0 20px 10px;
                        padding-bottom: 10px;
                        border-bottom: 1px solid #d8d8d8 !important;
                    }
                }

                .main {
                    margin: 20px;
                    font-size: 14px;
                    text-indent: 30px;
                    line-height: 1.6;
                }
            }
        }
    }
}
</style>