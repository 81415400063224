<template>
    <div :id="props.id" :style="`width:${svgaWidth}px;height:${svgaHeight}px;`"></div>
</template>
   
<script setup>
import { defineProps, onMounted, nextTick } from "vue";
import SVGA from 'svgaplayerweb';
import log from "@/utils/logger";
const props = defineProps({
    id: {
        type: String,
        default: '',
    },
    svgaUrl: {
        type: null,
        default: "",
    },
    loop: {
        type: Number,
        default: 0,
    },
    svgaWidth: {
        type: Number,
        default: 0, // px
    },
    svgaHeight: {
        type: Number,
        default: 0, // px
    },
});
const render = async () => {
    const player = new SVGA.Player('#' + props.id);
    var parser = new SVGA.Parser('#' + props.id);
    parser.load(props.svgaUrl, function (videoItem) {
        player.loops = props.loop;  // 设置循环播放次数
        player.setVideoItem(videoItem);
        player.startAnimation();
    })
};

onMounted(() => {
    render();
});
</script>
   
<style lang="scss" scoped></style>
  