<template>
	<div >
		<iframe :src="url" frameborder="0" style="width:100vw;height:100vh;"></iframe>
	</div>
</template>

<script>
	import {encryptBy,decryptBy} from '@/utils/crypto';

export default {
  components: {
   
  },
  data() {
    return {
      url:''
    };
  },
  computed: {},
  created(){

  },
  mounted() {
    this.url = decryptBy(this.$route.query.url) 
},
  methods: {
   
  },
};
</script>

<style lang="scss" scoped>

	
</style>

