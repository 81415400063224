<template>
    <div class="billRecord">
        <div class="cardInfo">
            <div class="cardTitle">
                <span class="title">Lịch sử thắng thua</span>
            </div>
            <div class="cardBody noBgColor">
                <el-tabs v-model="activeIndex" class="demo-tabs" @tab-click="handleClick">
                    <el-tab-pane label="Xổ số" name="0"></el-tab-pane>
                    <el-tab-pane label="Bên thứ 3" name="1"></el-tab-pane>
                </el-tabs>
                <BillRecordOfLottery v-if="activeIndex == '0'" />
                <BillRecordOfAll v-if="activeIndex == '1'" />
            </div>
        </div>
    </div>
</template>

<script setup>
import {
    ref,
    onMounted,
    onBeforeUnmount,
    reactive,
    nextTick,
    watch,
} from "vue";

import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { nameRule, passRule, phoneRule, realNameRule } from '@/utils/vaildate.js'
import { topic } from "@/components/protocol/api";
import { http } from "@/components/protocol/api";
import { mqant } from "@/components/protocol/mqantlib";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import BillRecordOfLottery from "@/views/pc/components/userCenter/billRecord/BillRecordOfLottery.vue"
import BillRecordOfAll from "@/views/pc/components/userCenter/billRecord/BillRecordOfAll.vue"

const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
    "onLogged",
    "doLogin",
]);

const activeIndex = ref('0')
const handleClick = (tab, event) => {
    // console.log(tab, event)
}
</script>

<style lang="scss" scoped>
.billRecord {
    .cardInfo {
        margin-bottom: 0;
        overflow: hidden;
        width: 100%;
        border-radius: 8px;

        .cardTitle {
            display: flex;
            align-items: baseline !important;
            justify-content: flex-start !important;
            padding: 24px 0 21px 24px;
            height: 73px;
            line-height: 28px;
            background-color: #292e3b;
            font-weight: 500;
            color: #fff;

            .title {
                color: #fff;
                font-size: 20px;
                font-weight: 500;
            }

            .titleRight {
                color: #8491a5 !important;
                font-size: 14px;
                margin-left: 15px;
            }
        }

        .cardBody {
            color: #fff;
            border-radius: 8px;
            background-color: transparent !important;
            margin: 0 4px !important;
            padding: 16px;
            font-size: 12px;

            .noPadding {
                padding: 0;
            }

            :deep(.el-tabs) {
                .el-tabs__header {
                    margin: 0 0 24px;
                }

                .el-tabs__active-bar {
                    background-color: #fff;
                }

                .el-tabs__nav-wrap::after {
                    background-color: #363d4e;
                }

                .el-tabs__item {
                    color: #8491a5;
                    font-size: 16px;
                    text-align: center;
                }

                .is-active {
                    color: #fff !important;
                }
            }

            :deep(.el-form) {
                .el-form-item__label {
                    color: #8491a5 !important;
                    font-size: 14px;
                    line-height: 40px;
                }

                .el-form-item__content {
                    flex: none;
                }

                .el-input__wrapper {
                    background: #363d4e;
                    border: 1px solid transparent;
                    border-radius: 6px;
                    color: #677684 !important;
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    box-shadow: none
                }

                .el-select,
                .el-date-editor {
                    width: 244px !important;

                    span {
                        color: #677684 !important;
                        font-size: 14px;
                        line-height: 40px;
                        height: 40px;
                    }
                }
            }

            .lable {
                color: #8491a5 !important;
                font-size: 14px;
                line-height: 40px;
                text-align: right;
                padding-right: 16px;
            }

            .btnGroup {
                margin-left: 20px;
            }

            :deep(.el-button-group) {
                >.el-button:first-child {
                    border-top-left-radius: 6px;
                    border-bottom-left-radius: 6px;
                }

                >.el-button {
                    background: #363d4e;
                    border: 1px solid transparent !important;
                    line-height: 40px;
                    min-width: 70px;
                    height: 40px;
                    padding: 0 10px;

                    span {
                        color: #677684;
                    }
                }

                .el-button:hover {
                    background: #4a69ff !important;
                    border-color: transparent !important;

                    span {
                        color: #fff;
                    }
                }

                .active {
                    background: #4a69ff !important;
                    border-color: transparent !important;

                    span {
                        color: #fff;
                    }
                }
            }

            .submitButton {
                background: #4a69ff;
                box-shadow: 0 0 6px 0 rgba(74, 105, 255, .4);
                border-radius: 6px;
                color: #fff;
                width: 96px;
                height: 40px;
                border: none;
                font-size: 14px;
            }

            .inquire {
                margin-left: 20px;
                margin-right: 10px;
            }
        }
    }

    .list-info {
        margin: 15px 20px 0;

        .header {
            border-radius: 8px 8px 0 0 !important;
            background: #363d4e !important;
            height: 50px;
            display: flex;

            .header-item {
                width: 20%;
                text-align: center;
                line-height: 50px;
                font-size: 14px;
                color: #8491a5;
                font-weight: bold;
            }
        }

        .content {
            position: relative;
            height: 485px;
            overflow-y: auto;
            border-radius: 0 8px 8px 8px;
            background-color: #232733;

            .content-row {
                background: #232733 !important;
                height: 48px !important;
                border-bottom: 1px solid #363d4e !important;
                display: flex;

                .content-item {
                    width: 20%;
                    line-height: 50px;
                    text-align: center;
                    color: #fff;
                }
            }

            .not-data {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;

                img {
                    width: 248px;
                    height: 198px;
                }

                span {
                    margin-top: 20px;
                    color: #909399;
                    font-size: 14px;
                }
            }
        }
    }

    .pagination {
        text-align: right;
        padding: 30px 20px 30px 20px;

        :deep(.el-pagination) {
            justify-content: center !important;
        }
    }
}
</style>