<template>
  <div style="position: relative">
    <div class="flexs_r" style="justify-content: center; padding: 0px 0" v-if="Lotteries">
      <div v-for="(item, index) in titleArr" :key="item" style="padding-left: 10px; position: relative;"
        :style="'width:' + getWidth(index) + 'px'" :class="index != 3 ? 'borderRight' : ''">
        <div class="flexs_sb" style="position:relative;">
          <div class="title">{{ item.title }}</div>
          <el-dropdown trigger="click" placement="bottom-start" v-if="index == 1 || index == 2" @command="handleCommand">

            <span class="el-dropdown-link canClick" @click="clickIndex = index">
              {{ getCurrentDay(index) }}

              <i class="el-icon-caret-bottom"></i>
            </span>
            <template #dropdown>
              <el-dropdown-menu @mouseenter.stop="enterDrop()" style="z-index:0;">
                <el-dropdown-item :command="index1" :class="{ selected: getSelected() == index1 }"
                  v-for="(item1, index1) in southTypeArr" :key="index1">{{ item1 }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>

        <div v-if="index == 0 || index == 3">
          <div v-for="(item1, index1) in Lotteries[item.key]" :key="item1" class="listName"
            @click="itemClick(item1, index, index1)">
            <div class="name" :class="isSelect(index + '-' + index1) ? 'select' : 'nol'">
              {{ item1.LotteryName }}
            </div>
          </div>
        </div>
        <div v-else class="flexs_r_c" style="flex-wrap: wrap">
          <div v-for="(item1, index1) in getSouthArr(index)" :key="item1" class="listName" style="width: 50%"
            @click="itemClick(item1, index, index1)">
            <div class="name" :class="isSelect(index + '-' + index1) ? 'select' : 'nol'">
              {{ item1.LotteryName }}
            </div>
          </div>
        </div>



      </div>
    </div>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      titleArr: [
        {
          title: "Miền Bắc",
          key: "North",
          show: true,
        },
        {
          title: "Miền Nam",
          key: "South",

          show: false,
        },
        {
          title: "Miền Trung",
          key: "Central",

          show: false,
        },
        {
          title: "Keno",
          key: "Vip",

          show: false,
        },
      ],
      selectLottery: null,
      selectIndex: "0-0",
      southTypeIndex: null,
      centerTypeIndex: null,
      clickIndex: 0,
      southTypeArr: [
        "Toàn bộ",
        "Chủ nhật",
        "Thứ2",
        "Thứ3",
        "Thứ4",
        "Thứ5",
        "Thứ6",
        "Thứ7",
      ],
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp_lottery", ["Lotteries", "Plays", "Timestamp"]),
  },
  created() {
    var that = this;

    bus.on("lotteryChange", (val) => {
      var index1;
      var index2;
      for (var i = 0; i < that.titleArr.length; i++) {
        if (this.titleArr[i].key == val.AreaCode) {
          index1 = i;
          var arr = that.Lotteries[this.titleArr[i].key];
          for (var j = 0; j < arr.length; j++) {
            if (arr[j].LotteryCode == val.LotteryCode) {
              index2 = j;
              break;
            }
          }
        }
      }
      that.selectIndex = index1 + "-" + index2;
    });

    var time = this.Timestamp ? new Date(this.Timestamp) : new Date();
    var day = time.getDay();
    if (!this.southTypeIndex) {
      if (day == 0) {
        this.southTypeIndex = 1;
      } else {
        this.southTypeIndex = day + 1;
      }
    }
    if (!this.centerTypeIndex) {
      if (day == 0) {
        this.centerTypeIndex = 1;
      } else {
        this.centerTypeIndex = day + 1;
      }
    }
  },
  methods: {
    enterDrop() {
      console.log('fffff')
      bus.emit('showHeader')
    },
    itemClick(val, index, index1) {
      this.selectIndex = index + "-" + index1;
      if (this.$route.path == "/lottery") {
        bus.emit("changeLottery", val.LotteryCode);
      } else {
        this.$router.push({
          name: "lottery",
          query: {
            lottertCode: val.LotteryCode,
          },
        });
      }
    },
    handleCommand(e) {
      if (this.clickIndex == 1) {
        this.southTypeIndex = e;
      } else if (this.clickIndex == 2) {
        this.centerTypeIndex = e;
      }

      var arr = this.getSouthArr(this.clickIndex);
    },

    isSelect(val) {
      if (val == this.selectIndex) {
        return true;
      }
      return false;
    },
    getWidth(index) {
      if (index == 1) {
        return 418;
      } else {
        return 232;
      }
    },

    getSelected() {
      if (this.clickIndex == 1) {
        return this.southTypeIndex;
      } else if (this.clickIndex == 2) {
        return this.centerTypeIndex;
      }
    },
    getSouthArr(index) {
      var arr = [];

      for (var i = 0; i < this.Lotteries[this.titleArr[index].key].length; i++) {
        var obj = this.Lotteries[this.titleArr[index].key][i];

        if (obj.Intervals > 0) {
          arr.push(obj);
        } else {
          if (index == 1) {
            if (this.southTypeIndex == 0) {
              arr.push(obj);
            } else if (this.southTypeIndex == 1) {
              if (obj.WeekNumber == 0) {
                arr.push(obj);
              }
            } else {
              if (obj.WeekNumber == this.southTypeIndex - 1) {
                arr.push(obj);
              }
            }
          } else {
            if (this.centerTypeIndex == 0) {
              arr.push(obj);
            } else if (this.centerTypeIndex == 1) {
              if (obj.WeekNumber == 0) {
                arr.push(obj);
              }
            } else {
              if (obj.WeekNumber == this.centerTypeIndex - 1) {
                arr.push(obj);
              }
            }
          }
        }
      }

      var last;
      var tmpArr = [];
      for (var i = 0; i < arr.length; i++) {
        var obj = arr[i];

        var hasContail = false;
        tmpArr.forEach(function (item, index) {
          if (item.LotteryCode == obj.LotteryCode) {
            hasContail = true;

            if ((item.WeekNumber + "").length < 3) {
              tmpArr[index].WeekNumber += "," + obj.WeekNumber;
            }
          }
        });

        if (!hasContail) {
          tmpArr.push(obj);
        }
      }
      arr = tmpArr;

      return arr;
    },
    getCurrentDay(index) {
      if (index == 1 || index == 2) {
        if (this.southTypeIndex >= 0 || this.centerTypeIndex >= 0) {
          if (index == 1) {
            return this.southTypeArr[this.southTypeIndex];
          } else {
            return this.southTypeArr[this.centerTypeIndex];
          }
        } else {
          var time = this.Timestamp ? new Date(this.Timestamp) : new Date();
          var day = time.getDay();
          if (day != 0) {
            return "Thứ" + (day + 1);
          } else {
            return "Chủ nhật";
          }
        }
      } else {
        if (this.centerTypeIndex >= 0) {
          return this.southTypeArr[this.centerTypeIndex];
        } else {
          var time = this.Timestamp ? new Date(this.Timestamp) : new Date();
          var day = time.getDay();
          if (day != 0) {
            return "Thứ" + (day + 1);
          } else {
            return "Chủ nhật";
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.borderRight {
  border-right: 1px solid hsla(0, 0%, 100%, 0.5);
}

.title {
  background: 0 0;
  color: #fff;
  padding: 0 0 0 4px;
  font-weight: 600;
  font-size: 14px;
  border-radius: 2px 0 0 2px;
  display: inline-block;
  height: 30px;
  white-space: nowrap;
}

.listName {
  height: 40px;
  width: 203px;
  display: flex;
  line-height: 1.2;
  align-items: center;
  text-overflow: ellipsis;
  cursor: pointer;

  .name {
    transition: all 0.2s;
    padding: 5px;
    font-size: 13px;
    border-radius: 2px;
    cursor: pointer;
    font-weight: bold;
  }

  .select {
    background: #fff;
    font-size: 12px;

    color: #1b233d;
  }

  .nol {
    color: #fff;
  }
}

.name:hover {
  padding: 8px;
  background: #fff;
  font-size: 15px;
  font-weight: 700;
  color: rgb(233, 207, 164) !important;
}

.filters {
  height: 28px;
  margin-right: 6px;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #e9cfa4;
  overflow: hidden;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
}

.el-dropdown-link {
  height: 28px;
  margin-right: 6px;
  line-height: 28px;
  width: 68px;
  padding: 0 5px;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
  border-radius: 4px;
  border: 1px solid #e9cfa4;
  overflow: hidden;
  transform: scale(0.8);
  color: #e9cfa4;
}
</style>

<style>
.el-dropdown-menu {
  background-color: #2e3342 !important;
}
</style>
