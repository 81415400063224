<template>
  <div class="giftInfo">
    <div class="nav">
      <van-nav-bar :title="props.data.ActivityName">
        <template #left>
          <i class="van-icon van-icon-arrow-left van-nav-bar__arrow" @click="comeBack()"></i>
        </template>
      </van-nav-bar>
    </div>
    <div class="main">
      <div class="content" v-html="props.data.ContentPc"></div>
      <div class="get  mainBtnBG"
        v-if="((showGet == 0 && !isLogged) || (showGet == 0 && isLogged)) && data.ActivityId != 'RegisterGet'"
        @click='joinIn()'>
        {{ $t('参与') }}</div>
      <div class="get  mainBtnBG" v-if="showGet == -1 && isLogged && props.data.ActivityId != 'RegisterGet'"
        style="background: gray;">
        {{ $t('参与') }}</div>

      <div class="get " v-if="showGet == 1 && isLogged && props.data.ActivityId != 'RegisterGet'"
        style="background: gray;">
        {{ $t('已参与') }}</div>
      <div style="width: 100%;height: 10px;"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch } from "vue";

import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { useRouter, useRoute } from "vue-router";
import i18n from "@/language/i18n";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
import { Toast } from "vant";

const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const curRouter = useRoute();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const { basicInfo } = useGetters("tcp", ["basicInfo"]);
let showGet = ref(false);
const props = defineProps({
  data: {
    type: Object,
    default: {},
  }
})
// let list = lobbyInfo.value.ActivityConf.Rebate.concat(
//   lobbyInfo.value.ActivityConf.Bonus
// );
// if (curRouter.path == '/giftInfo') {
//   if (curRouter.query.oid) {
//     list.forEach(element => {
//       if (element.Oid == curRouter.query.oid) {
//         data = element;
//       }
//     });
//   } else {
//     router.push('/');
//   }
// } else {
//   router.push('/');
// }

if (isLogged.value) {
  getData()
} else {
  bus.off('onConnected')
  bus.on('onConnected', (val) => {
    getData()
  })
}
function joinIn() {
  if (!isLogged.value) {
    router.push({ name: "LoginRegist" });
    return;
  }
  let params = {
    ActivityId: props.data.ActivityId,
    language: i18n.global.locale

  };

  mqant.request(topic.activityJoin, params, function (res) {
    if (res.Code == 0) {
      showGet.value = 1
    } else {
      Toast.fail(res.ErrMsg);
    }
  });
}
function getData() {
  let params = {
    ActivityId: props.data.ActivityId,
    language: i18n.global.locale

  };
  mqant.request(topic.activityGetJoin, params, function (res) {
    if (res.Code == 0) {
      showGet.value = res.Data
    } else {
      Toast.fail(res.ErrMsg);
    }
  });
}
function comeBack() {
  bus.emit(bus.event.showGiftInfo, false);
}
</script>

<style lang="scss" scoped>
.giftInfo {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #293356;

  .nav {
    :deep(.van-nav-bar) {
      box-shadow: 0 0.5px 0 0 hsla(0, 0%, 100%, .15);
      line-height: 5.866667vw;
      text-align: center;
      background-color: #293356;
      user-select: none;

      .van-nav-bar__content {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        height: 50px;
      }

      .van-nav-bar__left {
        font-size: 5.333333vw;
      }

      .van-nav-bar__arrow {
        margin-right: 1.066667vw;
        font-size: 5.333333vw;
      }

      .van-nav-bar__title {
        max-width: 60%;
        margin: 0 auto;
        color: #fff;
        font-weight: 500;
        font-size: 4.266667vw;
      }

      .van-icon {
        color: #fff;
      }

      .van-nav-bar__left,
      .van-nav-bar__right {
        position: absolute;
        top: 0;
        bottom: 0;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
        padding: 0 4.266667vw;
        font-size: 3.733333vw;
        cursor: pointer;

        .rightText {
          font-size: 5.333333vw;
          color: #fff;
        }
      }
    }
  }

  .main {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #fff;

    .content {
      position: relative;

      .get {
        //background: linear-gradient(to right,rgb(1,174,246),rgb(0,109,221));
        background: linear-gradient(to right, #dbab7b, #f3e4ce, #dcb07f);
        color: #4d4b49;
        width: 210px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 16px;
        margin: 0 auto;
        margin-top: 19px;
        margin-bottom: 50px;
        border-radius: 4px;
      }

      :deep(h1) {
        display: block;
        font-size: 2em !important;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-weight: bold;
      }

      :deep(p) {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      :deep(img) {
        width: 100%;
      }

      :deep(table) {
        width: 100%;
        border-top: 1px solid black;
        border-left: 1px solid black;

        td,
        th {
          border-bottom: 1px solid black;
          border-right: 1px solid black;
          padding: 3px 5px;
        }

        th {
          border-bottom: 1px solid black;
          text-align: center;
        }
      }
    }
  }
}

:deep(.van-cell) {
  margin: 15px auto;
  background-color: #1e2646;
  border-radius: 8px;
  font-size: 12px;
  --van-field-label-color: #424f81;
  --van-field-input-text-color: #fff;
}
</style>
