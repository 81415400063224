<template>
	<div @click="cancel()" class="wrap"
		style="color:black;width: 100%;height: 100%;font-weight: bold;position: fixed;z-index: 999;background-color: rgba(0, 0, 0, .4);top: 0;left: 0;">
		<div @click.stop=""
			style="border-radius: 10px;position: relative;width: 384px;margin: 272px auto;background-color: white;height: 373px;">
			<div class="flexs_sb" style="padding-top: 27px;">
				<div style="font-size: 16.5px;margin-left: 32px;">Cài đặt tiền cược mặc định</div>

				<img src="@/assets/pc/lottery/close-1.png" alt="" @click="cancel()" class="canClick"
					style="width: 18px;margin-right: 21px;">

			</div>
			<div style="height: 1px;background-color: #dddddd;margin-top: 30px;"></div>
			<div class="flexs_sb" style="margin-top: 20px;padding: 0 20px;">
				<div style="font-size: 14.5px;">Tiền cược mặc định</div>
				<input
					style="width: 170px;height: 43px;border-radius: 3px;border: 1px solid black;padding-left: 10px;margin-left:10px;"
					v-model="defaultNum" @input="defaultNum=checkNum(defaultNum)" type="text">
			</div>
			<div style="padding: 0 27px;margin-top:20px;color: #f52828;font-size: 14.5px;line-height: 26px;">Xin lưu
				ý:<br>
				1. Số tiền đặt cược bắt buộc phải là bội số của 100,
				ví du 1,000, 1,500<br>
				2. Tiền cược mặc định được dùng cho tất cả các
				loại hình xổ số
			</div>
			<div class="flexs_sb" style="margin-top:18px ;padding: 0 27px;">
				<div class="btn left canClick" @click="cancel()">Hủy</div>
				<div class="btn right canClick" @click="sure()">Lưu</div>
			</div>
		</div>
	</div>

</template>

<script>
	import { bus, busName } from "@/components/core/bus";

	export default {
		data() {
			return {
				defaultNum: ''
			}
		},
		props: {


		},
		created() {

		},
		methods: {
			cancel() {
				this.$emit('close')
			},
			checkNum(data) {

				if (data) {

					return data = this.formatNum(Number(data.replace(/[^\d.%\\]/g, '')))

				}

			},
			sure(){
				var subStr = (this.defaultNum+'' ).substr(this.defaultNum.length-2,2)
				//console.log('xx',subStr)
				for(var i=0;i<subStr.length;i++){
					if(parseInt(subStr[i])!=0){
						this.$message({
							message: 'Số tiền đặt cược bắt buộc phải là bôi số của 100, ví dụ 1,000, 1,500',
							type: 'info'
						});
						return
					}
				}
				bus.emit('defaultNum',this.defaultNum)
					localStorage.setItem('defaultNum',this.defaultNum)
					this.cancel()
			},
			formatNum(num) {

				var numeral = require('numeral');
				return numeral(num).format('0,000');
			},

		}
	}
</script>

<style scoped lang="scss">
	.btn{
		width: 137px;
		height: 43px;
		text-align: center;
		line-height: 43px;
		font-size: 14.5px;
		border-radius: 5px;
	}
	.left{
		border: 1px solid black;
		color: black;
	}
	.right{
		background-image: linear-gradient(90deg,#7146ff,#4a69ff);
		color: white;
	}
</style>
