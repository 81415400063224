<template>
  <div>
    <van-popup v-model:show="showPop" position="right" @close="close()">
      <div style="
          width: 55.5vw;
          height: 100%;
          background-image: linear-gradient(-45deg, #1b233d, #3a4970);
          height: 100vh;
        ">
        <div class="userInfoBox">
          <div>
            <div class="avatar-container">
              <img class="avatar-img" :src="require('@/assets/common/avatar/' + userInfo.Avatar + '.png')"
                @click="openAvatar()">
            </div>
          </div>
          <div class="money">
            {{ $tools.formatNum2k(wallet.VndBalance) }} <span>VND</span>
          </div>
          <div class="username">{{ userInfo.Account }}</div>
          <div class="btnBox">
            <button tag="button" @click="toWithDraw()">Rút tiền</button>
            <button class="" @click="toRecharge()">Nạp tiền</button>

          </div>
        </div>
        <div class="menuListBox">
          <ul>
            <li class="listItem" v-for="(item, index) in columList" @click="columClick(item)">
              <div class="titles">
                <i :class="item.class" class="iconfont menuIcon"></i>
                <div class="titles">{{ item.title }}</div>
              </div>
              <i class="iconfont icon-icon_more_blue_16"></i>
            </li>
          </ul>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Dialog } from "vant";
import { mqant } from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import "vant/es/dialog/style";
export default {
  data() {
    return {
      columList: [
        // { title: "Đơn chưa thanh toán", class: "icon-icon_wal", to: "" },
        { title: "Lịch sử", class: "icon-icon_stake", to: "betRecord" },
        {
          title: "Chi tiết",
          class: "icon-icon_draw_notice",
          to: "rewardHistory",
        },
        { title: "Hòm thư", class: "icon-icon_message_centre", to: "messageCenter" },
        { title: "CSKH", class: "icon-icon_customer_service", to: "liveChat" },
        {
          title: "Trở về trang chủ",
          class: "icon-icon_nav_game_nor",
          to: "home",
        },
        {
          title: "Kết quả thắng thua",
          class: "icon-icon_stake",
          to: "gameRecord",
        },
        { title: "Thoát an toàn", class: "icon-icon_close_46", to: "login" },
      ],
    };
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  props: {
    showPop: {
      type: Boolean,
      default: false,
    }
  },
  watch: {},
  computed: {
    ...mapGetters("tcp_lottery", [
      "Lotteries",
      "Plays",
      "Timestamp",
    ]),
    ...mapGetters("tcp", ["isLogged", "wallet"]),

    ...mapGetters("user", ["userInfo"]),
    ...mapGetters("global", ["lobbyInfo"]),
  },
  created() {

  },
  methods: {
    ...mapActions("user", ["doLogout"]),
    close() {
      bus.emit(bus.event.closeCommonRightPop, false);
    },
    toRecharge() {
      var that = this;
      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        if (!data.Data.DoudouPwd.length) {
          Dialog.confirm({
            title: "Xin lưu ý",
            message:
              "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ',
          })
            .then(() => {
              that.$router.push({ path: "/transactionPwd" });
            })
            .catch(() => { });
        } else
          if (!data.Data.myDouDouBT.length) {
            Dialog.confirm({
              title: "Xin lưu ý",
              message:
                'Cần điền thông tin ngân hàng',
              confirmButtonText: 'Xác nhận',
              cancelButtonText: 'Hủy bỏ',
            })
              .then(() => {
                that.$router.push({ path: '/addBankCard' });
              })
              .catch(() => { });

          } else {
            that.$router.push({ name: "recharge" });

          }
      });
    },
    toWithDraw() {
      var that = this;
      mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
        if (!data.Data.DoudouPwd.length) {
          Dialog.confirm({
            title: "Xin lưu ý",
            message:
              "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ',
          })
            .then(() => {
              that.$router.push({ path: "/transactionPwd" });
            })
            .catch(() => { });
        } else if (!data.Data.myDouDouBT.length) {
          Dialog.confirm({
            title: "Xin lưu ý",
            message:
              'Cần điền thông tin ngân hàng',
            confirmButtonText: 'Xác nhận',
            cancelButtonText: 'Hủy bỏ',
          })
            .then(() => {
              that.$router.push({ path: '/addBankCard' });
            })
            .catch(() => { });

        } else {
          that.$router.push({ name: "withdraw" });

        }
      });
    },
    columClick(item) {
      var that = this;
      if (item.to == "login") {
        Dialog.confirm({
          title: "Lời khuyên",
          message: "Chắc chắn muốn đăng xuất chứ? ",
          confirmButtonText: 'Xác nhận',
          cancelButtonText: 'Hủy bỏ',
        })
          .then(() => {
            that.doLogout()
            that.$router.push({
              name: 'LoginRegist',
            });
          })
          .catch(() => {
            // on cancel
          });
      } else if (item.to == "liveChat") {
        this.$act.openPage(this.lobbyInfo.CustomerInfo.customerLiveChat);
      } else {
        if (item.to.length) {
          var params = {};
          if (item.to == "betRecord") {
            params = { isRoot: false };
          }
          this.$router.push({
            name: item.to,
            params: params,
          });
        }
      }
    },
    openAvatar() {
      bus.emit(bus.event.avatarState, true);
    }
  },
};
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  top: 0;
  z-index: 10;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #293356;
  box-shadow: 0 0.5px 0 0 hsla(0, 0%, 100%, 0.15);
  height: 50px;

  .bar-content {
    position: relative;

    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100%;

    .van-nav-bar__left,
    .van-nav-bar__right {
      position: absolute;
      top: 0;
      bottom: 0;
      color: rgb(215, 169, 114);

      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      padding: 0 4.266667vw;
      font-size: 3.733333vw;
      cursor: pointer;
    }

    .van-icon {
      color: #fff;
      padding: 1.333333vw;
    }

    .van-nav-bar__left {
      left: 0;
    }
  }
}

.van-nav-bar__arrow {
  margin-right: 1.066667vw;
  font-size: 4.266667vw;
}

.van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #fff;
  font-weight: 500;
  font-size: 4.266667vw;
}

.van-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.rightIcon {
  font-size: 6.4vw;
  color: #fff;
}

.van-nav-bar__right {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 4.266667vw;
  font-size: 3.733333vw;
  cursor: pointer;
}

.userInfoBox {
  padding: 8.533333vw 0 6.4vw 5.333333vw;

  .username {
    font-size: 3.733333vw;
    color: #90a2dc;
    margin-top: 2.133333vw;
    font-weight: 500;
  }

  .money {
    margin-top: 3.733333vw;
    font-size: 3.2vw;
    color: #fff;
    font-weight: 500;
    max-width: 48vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .avatar-container {
    width: 16vw;
    height: 16vw;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid hsla(0, 0%, 100%, 0.22);
    border-radius: 8vw;
    overflow: hidden;

    .avatar-img {
      max-width: 16vw;
      max-height: 16vw;
      object-fit: cover;
      box-sizing: border-box;
    }
  }

  .btnBox {
    padding-right: 9.333333vw;
    margin-top: 3.733333vw;
    display: flex;
    justify-content: space-between;

    button {
      width: 19.466667vw;
      height: 6.933333vw;
      line-height: 6.4vw;
      outline: 0;
      border: 0;
      text-align: center;
      background: #e9cfa4;
      border-radius: 4.266667vw;
      font-size: 3.2vw;
      color: #273569;
      font-weight: 400;
    }
  }
}

.menuListBox {
  position: relative;
}

.menuListBox:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(0deg, transparent 40%, #90a2dc);
}

.listItem {
  height: 13.333333vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5.333333vw;
  font-size: 3.2vw;
  color: #90a2dc;
  border-bottom: 1px solid rgba(187, 218, 241, 0.14);
  margin: 1px 0;

  .icon-icon_more_blue_16 {
    font-size: 3.2vw;
  }

  .menuIcon {
    margin-right: 2.666667vw;
  }

  .titles {
    display: flex;
    align-items: center;
  }
}

.allGameSelect {
  font-size: 4.266667vw;
  font-weight: 600;
}
</style>
