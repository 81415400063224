<template>
  <div class="lottery">
    <topHeader :selectLo="selectLo"></topHeader>
    <div class="clEntrance">
      <img :src="$img('lottery/img_changlong.png')" alt="" />
    </div>
    <div v-for="(item, index) in titleArr" :key="item">
      <div class="funcBox">
        <a class="name"><span>{{ item.title }}</span></a>
        <div class="filter" v-if="index == 1 || index == 2">
          <van-popover v-if="index == 1" v-model:show="showPopoverS" style="width: 84px" placement="bottom-end">
            <div v-for="(item1, index1) in southTypeArr" class="popoverItem" @click="handleCommand(index1, index)">
              {{ item1 }}
            </div>
            <template #reference>
              <span class="recharge">
                {{ getCurrentDay(index) }}
                <i class="van-icon van-icon-arrow-down arrowDown"></i></span>
            </template>
          </van-popover>

          <van-popover v-if="index == 2" v-model:show="showPopoverC" style="width: 84px" placement="bottom-end">
            <div v-for="(item1, index1) in southTypeArr" class="popoverItem" @click="handleCommand(index1, index)">
              {{ item1 }}
            </div>
            <template #reference>
              <span class="recharge">
                {{ getCurrentDay(index) }}
                <i class="van-icon van-icon-arrow-down arrowDown"></i></span>
            </template>
          </van-popover>
        </div>
      </div>

      <ul class="playsBox">
        <li v-for="(item1, index1) in getCurrentArr(index)" :class="selectLo == item1 ? 'active' : ''">
          <div class="lottery-name">{{ item1.LotteryName }}</div>
          <div class="van-count-down">{{ getTime(item1.Countdown) }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import topHeader from "@/views/mobile/components/lottery/topHeader.vue";

import { bus, busName } from "@/components/core/bus";
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      titleArr: [
        {
          title: "Miền Bắc",
          key: "North",
          show: true,
        },
        {
          title: "Miền Nam",
          key: "South",

          show: false,
        },
        {
          title: "Miền Trung",
          key: "Central",

          show: false,
        },
        {
          title: "Keno",
          key: "Vip",

          show: false,
        },
      ],
      southTypeArr: [
        "Toàn bộ",
        "Chủ nhật",
        "Thứ2",
        "Thứ3",
        "Thứ4",
        "Thứ5",
        "Thứ6",
        "Thứ7",
      ],
      selectLo: {
        LotteryName: "",
      },
      southTypeIndex: null,
      centerTypeIndex: null,
      showPopoverS: false,
      showPopoverC: false,
    };
  },
  components: { topHeader },

  watch: {},
  computed: {
    ...mapGetters("tcp_lottery", ["Lotteries", "Plays", "Timestamp"]),
  },
  created() {
    for (var key in this.Lotteries) {
      var arr = this.Lotteries[key];
      for (var obj of arr) {
        if (obj.LotteryCode == this.$route.query.curlottery) {
          this.selectLo = obj;
          break;
        }
      }
    }

    var time = this.Timestamp ? new Date(this.Timestamp) : new Date();
    var day = time.getDay();
    if (day == 0) {
      this.southTypeIndex = 1;
      this.centerTypeIndex = 1;
    } else {
      this.southTypeIndex = day + 1;
      this.centerTypeIndex = day + 1;
    }
  },
  methods: {
    getCurrentArr(index) {
      if (index == 1 || index == 2) {
        return this.getSouthArr(index);
      } else {
        return this.Lotteries[this.titleArr[index].key];
      }
    },
    handleCommand(e, type) {
      if (type == 1) {
        this.southTypeIndex = e;
        this.showPopoverS = false;
      } else {
        this.centerTypeIndex = e;
        this.showPopoverC = false;
      }
    },
    getTime(Countdown) {
      var second = Countdown;
      var hour = parseInt(second / 3600);
      var minute = parseInt((second - hour * 3600) / 60);
      var second2 = second - hour * 3600 - minute * 60;
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second2 < 10) {
        second2 = "0" + second2;
      }
      if (hour < 24) {
        return hour + ":" + minute + ":" + second2;
      }
    },

    getCurrentDay(index) {
      if (index == 1 || index == 2) {
        if (this.southTypeIndex >= 0 || this.centerTypeIndex >= 0) {
          if (index == 1) {
            return this.southTypeArr[this.southTypeIndex];
          } else {
            return this.southTypeArr[this.centerTypeIndex];
          }
        } else {
          var time = this.Timestamp ? new Date(this.Timestamp) : new Date();
          var day = time.getDay();
          if (day != 0) {
            return "Thứ" + (day + 1);
          } else {
            return "Chủ nhật";
          }
        }
      } else {
        if (this.centerTypeIndex >= 0) {
          return this.southTypeArr[this.centerTypeIndex];
        } else {
          var time = this.Timestamp ? new Date(this.Timestamp) : new Date();
          var day = time.getDay();
          if (day != 0) {
            return "Thứ" + (day + 1);
          } else {
            return "Chủ nhật";
          }
        }
      }
    },
    getSouthArr(index) {
      var arr = [];
      for (var i = 0; i < this.Lotteries[this.titleArr[index].key].length; i++) {
        var obj = this.Lotteries[this.titleArr[index].key][i];

        if (obj.Intervals > 0) {
          arr.push(obj);
        } else {
          if (index == 1) {
            if (this.southTypeIndex == 0) {
              arr.push(obj);
            } else if (this.southTypeIndex == 1) {
              if (obj.WeekNumber == 0) {
                arr.push(obj);
              }
            } else {
              if (obj.WeekNumber == this.southTypeIndex - 1) {
                arr.push(obj);
              }
            }
          } else {
            if (this.centerTypeIndex == 0) {
              arr.push(obj);
            } else if (this.centerTypeIndex == 1) {
              if (obj.WeekNumber == 0) {
                arr.push(obj);
              }
            } else {
              if (obj.WeekNumber == this.centerTypeIndex - 1) {
                arr.push(obj);
              }
            }
          }
        }
      }

      var last;
      var tmpArr = [];
      for (var i = 0; i < arr.length; i++) {
        var obj = arr[i];

        var hasContail = false;
        tmpArr.forEach(function (item, index) {
          if (item.LotteryCode == obj.LotteryCode) {
            hasContail = true;

            if ((item.WeekNumber + "").length < 3) {
              tmpArr[index].WeekNumber += "," + obj.WeekNumber;
            }
          }
        });

        if (!hasContail) {
          tmpArr.push(obj);
        }
      }
      arr = tmpArr;

      return arr;
    },
  },
};
</script>

<style lang="scss" scoped>
.lottery {
  position: absolute;
  top: 50px;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  padding: 5.333333vw 0;

  .clEntrance {
    display: flex;
    padding: 0 4.266667vw 5.333333vw;

    width: 100%;

    img {
      flex: 1;
      height: 17.066667vw;
    }
  }

  .funcBox {
    display: flex;
    justify-content: space-between;
    padding: 0 4.266667vw;
    color: #fff;
    font-size: 3.2vw;

    .filter {
      font-size: 3.2vw;
      color: #90a2dc;
    }

    .name {
      position: relative;
      display: inline-block;
      height: 5.333333vw;
      line-height: 5.333333vw;
      padding-bottom: 1.6vw;
    }
  }

  .gameListUl {
    margin: 0 0 4.266667vw;
    padding: 0 8.533333vw 7.2vw;
    display: flex;
    flex-wrap: wrap;
    background: #35416d;

    .scratch {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      margin-top: 3.2vw;
      width: 82.933333vw;
      height: 10.666667vw;
      background: url("@/assets/mobile/home/scratch.png") no-repeat;
      background-size: contain;
      color: #fff;
      text-align: left;
      text-indent: 4.266667vw;

      .title {
        line-height: 4.8vw;
        font-size: 3.466667vw;
        font-weight: 600;
      }

      .tips {
        line-height: 3.733333vw;
        font-size: 2.666667vw;
        opacity: 0.9;
      }
    }

    .lotteryitem {
      position: relative;
      width: 33.33333%;
      margin-top: 2.133333vw;

      height: 27.2vw;

      box-sizing: border-box;
      border-radius: 3.2vw;
      padding-top: 4vw;
      display: inline-block;
      //text-align: center;
      box-shadow: 0 0.533333vw 1.6vw 0 rgba(0, 0, 0, 0.09);

      img {
        width: 21.866667vw;
        height: 21.866667vw;
      }

      .betNow {
        position: absolute;
        bottom: -0.533333vw;
        left: calc(50% - 10.93vw);
        height: 6.933333vw;
        width: 21.86vw;
      }
    }
  }
}

.filter .dropDownIcon {
  margin-left: 0.8vw;
  position: relative;
  top: 0.533333vw;
}

.popoverItem {
  padding: 2.133333vw 4.266667vw;
  font-size: 3.2vw;
  text-align: center;
}

.playsBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  padding: 0 0 2.133333vw;
  padding-left: 16px;

  li {
    margin: 0 3.2vw 3.2vw 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 28.266667vw;
    height: 14.933333vw;
    color: #90a2dc;
    background: #384369;
    border-radius: 1.6vw;
    box-sizing: border-box;
    overflow: hidden;
    text-align: center;

    div:first-child {
      margin-top: 1.6vw;
      width: 92%;
      margin-left: 4%;
      text-align: center;
      line-height: 3.733333vw;
    }

    div {
      font-size: 3.2vw;
    }
  }

  li.active {
    color: #fff;
    background: linear-gradient(135deg, #7146ff, #4a69ff);

    .van-count-down {
      color: hsla(0, 0%, 100%, 0.6);
    }
  }
}
</style>
