<template>
  <div class="notice">
    <div class="noticeBar">
      <div class="icon">
        <img src="@/assets/pc/home/paomadeng_icon.png" alt="">
      </div>
      <marquee behavior="scroll" direction="left" truespeed scrollamount="3" onmouseout="this.start()"
        onmouseover="this.stop()">
        <span v-for="item in lobbyInfo.LobbyNotice">
          <span @click="itemClick(index)">{{ item.Content }}&nbsp;&nbsp;&nbsp;</span>
        </span>
      </marquee>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  reactive,
  nextTick,
  watch,
} from "vue";

import {
  useGetters,
  useActions,
  useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
import { mqant } from "@/components/protocol/mqantlib";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
  "onLogged",
  "doLogin",
]);
// let contentObj = reactive({
//   content: []
// })
// if (isLogged.value) {
//   getNotice()
// } else {
//   contentObj.content.push('Chào mừng đến với');//默认
// }
// bus.on('onConnected', () => {
//   getNotice()
// })

// function getNotice() {
//   mqant.request(topic.notice, {}, function (data, topicName, msg) {
//     contentObj.content = [];
//     for (let key in data.Data) {
//       contentObj.content.push(data.Data[key].Content);
//     }
//   });
// }
function itemClick(index) {
  if (!isLogged.value) {
    bus.emit(bus.event.loginState, true);
    return;
  }
  bus.emit(bus.event.noticeState, true)
}
</script>

<style lang="scss" scoped>
.notice {
  // margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  // border-radius: 50px;
  width: 100%;
  height: 50px;
  background-color: #1e222c;
  color: #fff;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: relative;

  .noticeBar {
    overflow: hidden;
    display: flex;
    align-items: center;
    width: 100%;

    .icon {
      display: flex;
      align-items: center;
      padding: 0 30px 0 25px;

      img {
        width: 30px;
      }
    }

    marquee:hover {
      text-decoration: underline
    }
  }
}
</style>