<template>
  <van-popup v-model:show="showPop" position="bottom">
    <div class="showSelectNumPop">
      <div class="title">Chọn dãy số</div>
      <ul>
        <li v-for="(item, index) in datas" @click="itemClick(index)">
          <span> {{ item }}</span
          ><span> {{ countNumData(index) }}</span
          ><span
            ><i
              v-if="selectIndex == index"
              class="icon-icon_succeed iconfont"
            ></i
          ></span>
        </li>
      </ul>
      <div  class="btnGroup" @click="cancel()">
        <button  class="cancel">Huỷ</button>
      </div>
    </div>
   
  </van-popup>

</template>

<script>
import { bus, busName } from "@/components/core/bus";

export default {
  data() {
    return {
      datas: [
        "000-099",
        "100-199",
        "200-299",
        "300-399",
        "400-499",
        "500-599",
        "600-699",
        "700-799",
        "800-899",
        "900-999",
      ],
      selectIndex: 0,
      showPop: true,
    };
  },
  props: {
    numData: {
      //1.1键全选 2.全部
    },
    title: {},
    defauleIndex: {},
  },
  created() {
    this.selectIndex = this.defauleIndex;
  },
  methods: {
    itemClick(index) {
      this.selectIndex = index;
      this.cancel();
      this.showPop = false;
      this.$emit("sure", this.selectIndex);
    },
    countNumData(index) {
      var count = 0;
      for (var num of this.numData) {
        if (
          num >= this.datas[index].split("-")[0] &&
          num <= this.datas[index].split("-")[1]
        ) {
          count++;
        }
      }

      return count;
    },
    confirm() {
      this.$emit("sure", this.selectIndex);
    },
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.showSelectNumPop {
  background-color: rgba(27, 35, 61, 0.9);
}
.title {
  height: 10.666667vw;
  line-height: 10.666667vw;
  margin-bottom: 8.533333vw;
  font-size: 4.266667vw;
  color: #fff;
  text-align: center;
  font-weight: 400;
  position: relative;
  border-bottom: 1px solid rgba(144,162,220,.5);
}
ul {
  max-height: 145.066667vw;
  overflow-y: scroll;
  padding-bottom: 8.533333vw;
  li:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(180deg,transparent 40%,#90a2dc);
}
  li {
    text-align: center;
    height: 10.666667vw;
    display: flex;
    align-items: center;
    padding: 0 6.4vw;
    position: relative;
    .icon-icon_succeed {
      color: #5cae50;
      font-weight: 700;
      margin-left: 17.6vw;
    }
    span:first-of-type {
      color: #fff;
      font-family: Helvetica Neue;
      font-size: 3.733333vw;
      display: inline-block;
      width: 20vw;
      height: 6.4vw;
      line-height: 6.4vw;
      border-radius: 1.066667vw;
      border: 1px solid #fff;
      margin-left: 13.333333vw;
    }
    span:nth-of-type(2) {
      width: 6.4vw;
      height: 6.4vw;
      line-height: 6.4vw;
      text-align: center;
      display: inline-block;
      background-color: #fff;
      border-radius: 1.066667vw;
      color: #1a223b;
      font-size: 3.2vw;
      margin-left: 14.933333vw;
    }
  }
}
.btnGroup {
  height: 10.666667vw;
  display: flex;
  
  //box-shadow: inset 0 0.5px 0 0 rgba(144,162,220,.5);
  button.cancel {
    font-size: 3.733333vw;
    color: #90a2dc;
    text-align: center;
    font-weight: 400;
    width:100%;
    background: rgba(27, 35, 61, 0.9);
    border-top:1px solid rgba(144,162,220,.5);
}
}

.van-popup {
  background: transparent !important;
}
</style>
