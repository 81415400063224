<template>
    <div class="slot">
        <div class="container">
            <div class="banner">
                <img :src="$img('slot/slot_hot_banner.png')" alt="">
            </div>
            <div class="main">
                <aside>
                    <ul>
                        <li :class="curSelectIndex == index ? 'active' : ''" v-for="(item, index) in tabObj.data"
                            @click="tabItemClick(index)">
                            <!-- <div class="ani-container">
                                <SvgaPlayer :id="'sf_games_btn_02_' + index" :svgaUrl="sf_games_btn_02_svga"
                                    :svgaWidth="360" :svgaHeight="110">
                                </SvgaPlayer>
                            </div> -->
                            <div class="img_games_1">
                                <img class="subImg" :src="$img(`slot/${item.name}_icon_1.png`)" alt="">
                                <div class="name">
                                    <p class="nametext">{{ $tools.getFactoryName(item.name) }}</p>
                                    <p class="namesub">Những điều bất ngờ thú vị</p>
                                </div>
                                <div class="circleLogo">
                                    <img :src="$img(`slot/${item.name}_icon_2.png`)" alt="">
                                </div>
                            </div>
                        </li>
                    </ul>
                </aside>
                <div class="games-wrapper">
                    <div class="search-bar">
                        <input v-model="searchObj.searchKey" class="input" type="seach" placeholder="Tìm kiếm"
                            v-on:input="searchFun">
                        <span class="iconfont icon-icon_search"></span>
                    </div>
                    <div class="games">
                        <ul v-show="curSelectIndex == 0" class="hot-games">
                            <li v-if="searchObj.isSearch" class="game-logo" v-for="(item, index) in searchObj.data"
                                @click="itemClick(item)"
                                :style="{ backgroundImage: 'url(' + require(`@/assets/pc/slot/hot_bg_${getHotBgColor(item)}.png`) + ')', backgroundSize: '100% 100%' }">
                                <div class="el-image">
                                    <img :src="getImg(item)" alt="">
                                </div>
                                <div class="btns-wrapper">
                                    <div class="name">
                                        <img class="whiteLogo" :src="$img(`slot/${item.FactoryName}_icon_3.png`)" alt="">
                                        <p>{{ item }}</p>
                                    </div>
                                    <button class="el-button startBtn">
                                        <span> Bắt đầu chơi </span>
                                    </button>
                                </div>
                            </li>
                            <li v-else class="game-logo" v-for="(item, index) in tabObj.data[curSelectIndex].list"
                                @click="itemClick(item)"
                                :style="{ backgroundImage: 'url(' + require(`@/assets/pc/slot/hot_bg_${getHotBgColor(item)}.png`) + ')', backgroundSize: '100% 100%' }">
                                <div class="el-image">
                                    <img :src="getImg(item)" alt="">
                                </div>
                                <div class="btns-wrapper">
                                    <div class="name">
                                        <img class="whiteLogo" :src="$img(`slot/${item.FactoryName}_icon_3.png`)" alt="">
                                        <p>{{ item.GameName.slice(3).toUpperCase() }}</p>
                                    </div>
                                    <button class="el-button startBtn">
                                        <span> Bắt đầu chơi </span>
                                    </button>
                                </div>
                            </li>
                        </ul>
                        <ul v-show="curSelectIndex !== 0" class="all-games">
                            <li v-if="searchObj.isSearch" v-for="(item, index) in searchObj.data" @click="itemClick(item)">
                                <div class="el-image">
                                    <img :src="getImg(item)" alt="">
                                </div>
                                <div class="btns-wrapper">
                                    <div class="name">
                                        <img class="whiteLogo" src="" alt="">
                                        <p>{{ item.GameName }}</p>
                                    </div>
                                    <button class="el-button startBtn el-button--primary">
                                        <span> Bắt đầu chơi </span>
                                    </button>
                                </div>
                            </li>
                            <li v-else v-for="(item, index) in tabObj.data[curSelectIndex].list" @click="itemClick(item)">
                                <div class="el-image">
                                    <img :src="getImg(item)" alt="">
                                </div>
                                <div class="btns-wrapper">
                                    <div class="name">
                                        <img class="whiteLogo" src="" alt="">
                                        <p>{{ item.GameName }}</p>
                                    </div>
                                    <button class="el-button startBtn el-button--primary">
                                        <span> Bắt đầu chơi </span>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
import {
    ref,
    onMounted,
    onBeforeUnmount,
    reactive,
    nextTick,
    watch,
} from "vue";

import {
    useGetters,
    useActions,
    useMutations,
} from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import SvgaPlayer from "@/views/pc/components/SvgaPlayer.vue";
import sf_games_btn_02_svga from "@/assets/pc/svga/sf_games_btn_02.svga";
import { useRouter } from 'vue-router';
import i18n from "@/language/i18n";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from 'vue'
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { Lotteries } = useGetters("tcp_lottery", ["Lotteries"]);
const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", [
    "onLogged",
    "doLogin",
]);

let curSelectIndex = ref(0);
let searchObj = reactive({
    searchKey: '',
    isSearch: false,
    data: []
});
let tabObj = reactive({
    data: []
});
tabObj.data.push(reactive({ name: 'apiHot', list: getSlotRecommendData() }));//自定义一个热推荐对象
for (let key in lobbyInfo.value.StaticPicture.Slot) {
    let info = lobbyInfo.value.StaticPicture.Slot[key];
    if (key == 'apiLuckyWin') {
        continue
    }
    if (info.GameList.length > 0) {
        tabObj.data.push(
            {
                name: key,
                list: info.GameList
            }
        );
    }
}
function searchFun(key) {
    if (searchObj.searchKey.length == 0) {
        searchObj.isSearch = false;
        return;
    }
    searchObj.data = [];
    tabObj.data[curSelectIndex.value].list.forEach(item => {
        if (item.GameName) {
            if (item.GameName.toLowerCase().indexOf(searchObj.searchKey.toLowerCase()) >= 0) {
                searchObj.data.push(item);
            }
        }
    });
    searchObj.isSearch = true;
}
function itemClick(item) {
    if (!isLogged.value) {
        bus.emit(bus.event.loginState, true);
        return;
    }
    let url = getApiLoginUrl(item.FactoryName, item.GameCode);
    $act.openPage(url);
}
function language() {
    return i18n.global.locale;
}
function getImg(item) {
    let str = lobbyInfo.value.HotDomain + '/gameIcon2' + '/' + item.FactoryName + '/' + language() + '/' + item.GameCode + '.png';
    return str;
}

function tabItemClick(index) {
    curSelectIndex.value = index;
}
function getSlotRecommendData() {
    let result = [];
    let data = lobbyInfo.value.StaticPicture.Slot;
    for (let key in data) {
        let gameData = data[key].GameList;
        for (let key1 in gameData) {
            let gameInfo = gameData[key1];
            if (gameInfo.Hot > 0) {
                result.push(gameInfo);
            }
        }
    }
    // log.info('getSlotRecommendData---', result);
    return result;
}

function getHotBgColor(item) {
    //自定义一下枚举
    //apiCq apiDs apiJdb apiJili apiPg apiPp apiRich88 apiSpribe
    let index = 0;
    switch (item.FactoryName) {
        case 'apiCq':
            index = 0;
            break;
        case 'apiJdb':
            index = 1;
            break;
        case 'apiJili':
            index = 2;
            break;
        case 'apiPp':
            index = 3;
            break;
    }
    return index
}
</script>
  
<style lang="scss" scoped>
.slot {
    position: relative;
    margin: 0 auto;
    background: #292e3b;
    width: 100%;
    height: auto;
    border-top: 1px solid hsla(0, 0%, 100%, .05);
    display: flex;
    flex-wrap: wrap;

    .container {
        width: 1920px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;

        .banner {
            width: 1400px;
            height: 400px;
            height: auto;
            margin: 24px auto 0;

            img {
                width: 100%;
                height: 100%;
                border-radius: 12px;
            }
        }

        .main {
            display: flex;
            width: 1400px;
            padding: 50px 0;
            margin: 0 auto;
            position: relative;

            aside {
                flex: 0 0 360px;

                ul {
                    background: #23252a;
                    box-shadow: none;
                    border-radius: 12px;
                    padding: 15px 0;

                    li {
                        background: #293466;
                        border-right: none !important;
                        margin: 5px auto;
                        position: relative;
                        overflow: hidden;
                        background-image: url('@/assets/pc/slot/img_bg_type_pc_pre1.png');
                        background-size: 100% 100%;

                        div {
                            background: no-repeat 20px 0;
                            background-size: 25%;
                            color: #fff;
                            cursor: pointer;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;
                            width: 358px;
                            height: 104px;
                            overflow: hidden;
                            -webkit-user-select: none;
                            user-select: none;
                            z-index: 2;
                        }

                        .ani-container {
                            display: none;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            bottom: -104px;
                            left: 360px;
                            // -webkit-transform: scale(2.5);
                            // transform: scale(2.5);
                            // -webkit-transform-origin: 10% 50%;
                            // transform-origin: 10% 50%;
                        }

                        .img_games_1 {
                            .subImg {
                                height: 120px;
                                position: absolute;
                                left: 26px;
                                top: 0;
                                transition: all .5s;
                                transform-origin: 50% 0;
                            }

                            .name {
                                z-index: 0;
                                width: 200px;
                                display: flex;
                                flex-direction: column;
                                padding-left: 40px;

                                .nametext {
                                    color: #fff;
                                    text-align: left;
                                    padding-top: 0;
                                    font-size: 24px;
                                    text-shadow: 0 2px 4px rgba(0, 0, 0, .3);
                                }

                                .namesub {
                                    color: #fff;
                                    opacity: .65;
                                    padding-top: 14px;
                                    width: 100%;
                                    font-size: 12px;
                                    text-align: left;
                                    transform: scale(.9);
                                    transform-origin: 0 100%;
                                    text-shadow: 0 0 2px rgba(0, 0, 0, .492);
                                }
                            }

                            .circleLogo {
                                background: no-repeat 26.5px #23252a;
                                justify-content: flex-start;
                                box-shadow: inset 1px 0 0 0 hsla(0, 0%, 100%, .4);
                                border-radius: 28px 0 0 28px;
                                border-right: 1px solid transparent;
                                position: absolute;
                                right: 0;
                                height: 52px;
                                width: 87px;

                                img {
                                    margin-left: 28px;
                                    width: 40px;
                                }
                            }
                        }
                    }

                    li:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        right: 0;
                        bottom: 0;
                        background-image: linear-gradient(270deg, #3d4d99, #7b9de1 100%, #c2d4ff 0);
                        background-size: 100%;
                        opacity: 0;
                    }

                    .active {
                        background-image: url('@/assets/pc/slot/img_bg_type_pc_pre2.png');
                    }

                    li:hover {
                        background-image: url('@/assets/pc/slot/img_bg_type_pc_pre2.png');
                    }

                    li:hover .ani-container {
                        display: block;
                    }

                    li:hover .img_games_1 .subImg {
                        transform: scale(1.3);
                    }
                }
            }
        }

        .games-wrapper {
            height: 1229px;
            flex: 1 0;

            .search-bar {
                position: static;
                margin-left: 40px;
                position: relative;

                .input {
                    background: #23252a;
                    color: #fff;
                    box-shadow: none;
                    border-radius: 20px;
                    border: none;
                    min-width: 200px;
                    height: 40px;
                    font-size: 16px;
                    padding: 0 30px;
                    box-sizing: border-box;
                }

                .iconfont {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    margin-left: -30px;
                    font-size: 25px;
                }
            }

            .games::-webkit-scrollbar {
                display: none;
                width: 10px;
            }

            .games {
                height: 1149px;
                margin-top: 40px;
                margin-bottom: 50px;
                overflow-y: auto;

                ul {
                    padding: 0;
                    margin-left: 30px;
                    display: flex;
                    flex-wrap: wrap;
                }

                .hot-games {
                    li {
                        width: 336px;
                        height: 180px;
                        margin: 0;
                        background: no-repeat transparent;
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-evenly;
                        align-items: center;

                        .bg {
                            position: relative;
                            width: 100%;
                            height: 100%;
                        }

                        .el-image {
                            width: 110px;
                            height: 110px;
                            border: 3px solid transparent;
                            border-radius: 60px;
                            background-image: linear-gradient(#fff, #fff), linear-gradient(180deg, #ffe6b3, #fc6);
                            background-origin: border-box;
                            background-clip: content-box, border-box;
                            transition: all .5s;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            right: 10px;
                            overflow: hidden;

                            img {
                                width: 120px;
                                height: 120px;
                                box-shadow: 0 0 4px 2px #fff;
                                border-radius: 60px;
                                vertical-align: top;
                            }
                        }

                        .btns-wrapper {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            width: 160px;
                            position: relative;

                            .name {
                                color: #fff;
                                font-size: 13px;
                                position: relative;
                                width: 100%;
                                top: -10px;
                                text-align: center;

                                .whiteLogo {
                                    width: auto;
                                    height: auto;
                                    max-width: 90%;
                                    max-height: 90%;
                                    position: relative;
                                    margin-bottom: 4px;
                                }

                                p {
                                    width: 100%;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            }

                            .el-button {
                                background: #fff;
                                color: #212947;
                                border-radius: 18px;
                                padding: 0;
                                width: 110px;
                                height: 33px;
                                transform: scale(.9);
                                box-shadow: inset 0 1px 4px 0 hsla(0, 0%, 100%, .4), inset 0 -2px 4px 0 rgba(0, 0, 0, .3);
                            }
                        }
                    }

                    li:hover .el-image {
                        -webkit-transform: scale(1.1);
                        transform: scale(1.1);
                    }
                }

                .all-games {
                    margin-left: 14px;

                    li {
                        width: 340px;
                        height: 180px;
                        background: url('@/assets/pc/slot/game_bg.png') no-repeat 50%/100%;
                        display: flex;
                        flex-direction: row;
                        box-shadow: none;
                        margin: 0;
                        border: 1px solid transparent;
                        border-radius: 12px;
                        justify-content: space-evenly;
                        align-items: center;
                        transition: all .5s;
                        cursor: pointer;

                        .el-image {
                            width: 120px;
                            height: 120px;
                            margin-left: 30px;
                            box-shadow: none;
                            border: 3px solid transparent;
                            border-radius: 60px;
                            background-image: linear-gradient(#fff, #fff), linear-gradient(180deg, #b38f6a, #ffe6cc, #b38f6a);
                            background-origin: border-box;
                            background-clip: content-box, border-box;
                            position: relative;
                            display: inline-block;
                            overflow: hidden;

                            img {
                                vertical-align: top;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .btns-wrapper {
                            width: 140px;
                            flex: 1;
                            display: flex;
                            flex-direction: column;
                            margin: 0 30px 0 20px;
                            position: relative;

                            .name {
                                color: #fff;
                                font-size: 20px;
                                font-weight: 700;
                                width: 100%;
                                text-align: center;

                                p {
                                    width: 90%;
                                    margin: 0 auto;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                    overflow: hidden;
                                }
                            }

                            .el-button {
                                opacity: 1;
                                position: static;
                                -webkit-transform: none;
                                transform: none;
                                background: 0 0 !important;
                                margin-top: 20px;
                                color: #fff !important;
                                border: 1px solid #fff !important;
                                box-shadow: 0 3px 6px 0 hsla(0, 0%, 100%, .6);
                                border-radius: 18px;
                                font-size: 14px;
                                transition: all .5s;
                                padding: 10px 12px;
                            }
                        }
                    }

                    li:hover .btns-wrapper .el-button {
                        background: #fff !important;
                        color: #212947 !important;
                    }
                }
            }
        }
    }
}
</style>