<template>
  <div
    style="border: 1px solid rgba(144, 162, 220, 0.4); border-bottom: none"
    class="flexs_r"
  >
    <div style="width: 496px">
      <div class="flexs_r_c">
        <div
          v-for="(item, index) in titleArr"
          class="canClick"
          :key="index"
          @click="selectIndex = index"
          style="
            width: 25%;
            height: 48px;
            line-height: 48px;
            text-align: center;
            font-size: 14px;
            position: relative;
          "
          :class="selectIndex == index ? 'select' : 'nolmal'"
        >
          {{ item }}
          <div v-if="selectIndex == index" class="line"></div>
        </div>
      </div>
      <div v-if="selectIndex == 0" style="margin-top: 14px; position: relative">
        <div class="flexs_r_c" style="justify-content: center">
          <div class="redTag tag" style="">Tài</div>
          <progress-bar
            v-if="bigSmallData.length"
            style="margin-left: 17px"
            :target="getPersent(0)"
            :colorTop="'#f94168'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />

          <div class="blueTag tag" style="margin-left: 40px">Xỉu</div>
          <progress-bar
            v-if="bigSmallData.length"
            style="margin-left: 17px"
            :target="getPersent(1)"
            :colorTop="'#26a1ff'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />
        </div>
        <div style="margin-top: 20px; justify-content: center" class="flexs_r">
          <div v-for="(item, index) in 18" :key="item">
            <div v-for="item1 in bigSmallData[index]" :key="item1">
              <div
                class="corner redTag"
                v-if="item1 == 'big'"
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                T
              </div>
              <div
                class="corner blueTag"
                v-else
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                X
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectIndex == 1" style="margin-top: 14px; position: relative">
        <div class="flexs_r_c" style="justify-content: center">
          <div class="redTag tag">Lẻ</div>
          <progress-bar
            v-if="twoOneData.length"
            style="margin-left: 17px"
            :target="getPersent(2)"
            :colorTop="'#f94168'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />

          <div class="blueTag tag" style="margin-left: 40px">Chẵn</div>
          <progress-bar
            v-if="twoOneData.length"
            style="margin-left: 17px"
            :target="getPersent(3)"
            :colorTop="'#26a1ff'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />
        </div>
        <div style="margin-top: 20px; justify-content: center" class="flexs_r">
          <div v-for="(item, index) in 18" :key="item">
            <div v-for="item1 in twoOneData[index]" :key="item1">
              <div
                class="corner redTag"
                v-if="item1 == 'single'"
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                L
              </div>
              <div
                class="corner blueTag"
                v-else
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                C
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectIndex == 2" style="margin-top: 14px; position: relative">
        <div class="flexs_r_c" style="justify-content: center">
          <div class="redTag tag">Trên</div>
          <progress-bar
            v-if="upDownData.length"
            style="margin-left: 17px"
            :target="getPersent(4)"
            :colorTop="'#f94168'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />

          <div class="grayTag tag" style="margin-left: 30px">Hoà</div>
          <progress-bar
            v-if="upDownData.length"
            style="margin-left: 17px"
            :target="getPersent(5)"
            :colorTop="'#909090'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />

          <div class="blueTag tag" style="margin-left: 30px">Dưới</div>
          <progress-bar
            v-if="upDownData.length"
            style="margin-left: 17px"
            :target="getPersent(6)"
            :colorTop="'#26a1ff'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />
        </div>
        <div
          style="margin-top: 20px; justify-content: center"
          class="flexs_r"
          v-if="upDownData.length"
        >
          <div v-for="(item, index) in 18" :key="item">
            <div v-for="item1 in upDownData[index]" :key="item1">
              <div
                class="corner redTag"
                v-if="item1 == 'top'"
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                T
              </div>
              <div
                class="corner grayTag"
                v-else-if="item1 == 'center'"
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                H
              </div>
              <div
                class="corner blueTag"
                v-else
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                D
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectIndex == 3" style="margin-top: 8px">
        <div class="flexs_r_c">
          <div class="jinTag tag" style="margin-left: 38px">Kim</div>
          <progress-bar
            v-if="fiveData.length"
            style="margin-left: 17px"
            :target="getPersent(7)"
            :colorTop="'#ffbb00'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />

          <div class="muTag tag" style="margin-left: 40px">Mộc</div>
          <progress-bar
            v-if="fiveData.length"
            style="margin-left: 17px"
            :target="getPersent(8)"
            :colorTop="'#6dd400'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />

          <div class="blueTag tag" style="margin-left: 40px">Thủy</div>
          <progress-bar
            v-if="fiveData.length"
            style="margin-left: 17px"
            :target="getPersent(9)"
            :colorTop="'#26a1ff'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />
        </div>
        <div class="flexs_r_c" style="margin-top: 8px">
          <div class="redTag tag" style="margin-left: 101px">Hỏa</div>
          <progress-bar
            v-if="fiveData.length"
            style="margin-left: 17px"
            :target="getPersent(10)"
            :colorTop="'#f94168'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />

          <div class="tuTag tag" style="margin-left: 40px">Thổ</div>
          <progress-bar
            v-if="fiveData.length"
            style="margin-left: 17px"
            :target="getPersent(11)"
            :colorTop="'#ca8c10'"
            :percentageColor="'white'"
            :colorBg="'transparent'"
            :percentageSize="12"
            :textSize="12"
            :sizeOut="25"
            :sizeIn="25"
            :total="100"
            :encumbrances="0"
          />
        </div>
        <div
          style="margin-top: 20px; justify-content: center"
          class="flexs_r"
          v-if="fiveData.length"
        >
          <div v-for="(item, index) in 18" :key="item">
            <div v-for="item1 in fiveData[index]" :key="item1">
              <div
                class="corner jinTag"
                v-if="item1 == 'jin'"
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                K
              </div>
              <div
                class="corner muTag"
                v-else-if="item1 == 'mu'"
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                M
              </div>
              <div
                class="corner blueTag"
                v-else-if="item1 == 'shui'"
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                T
              </div>
              <div
                class="corner redTag"
                v-else-if="item1 == 'huo'"
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                H
              </div>
              <div
                class="corner tuTag"
                v-else
                style="
                  margin-top: 5px;
                  margin-left: 5px;
                  width: 18px;
                  height: 18px;
                  border-radius: 9px;
                  line-height: 18px;
                "
              >
                T
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="width: 347px; position: relative">
      <div class="rightLine"></div>

      <div style="height: 48px; padding-top: 10px">
        <el-popover
          placement="bottom"
          style="padding: 0"
          :width="150"
          trigger="click"
          :visible="showPop"
        >
          <template #reference>
            <!-- <el-button class="m-2">Click to activate</el-button> -->
            <div
              class="selectTop canClick"
              @click="showPop = !showPop"
              v-if="recordData.length"
            >
              {{ recordData[reIndex].Number }}

              <i class="el-select__caret el-input__icon el-icon-arrow-up"></i>
            </div>
          </template>
          <div style="height: 274px; overflow-y: scroll; background-color: #2e3342">
            <div
              style="height: 34px; line-height: 34px; padding-left: 20px"
              @click="reClick(index)"
              class="reList canClick"
              :class="reIndex == index ? 'reSelect' : 'reNol'"
              v-for="(item, index) in recordData"
            >
              {{ item.Number }}
            </div>
          </div>
        </el-popover>
      </div>

      <div
        id="reList"
        style="
          height: 252px;
          white-space: nowrap;
          overflow-y: auto;
          border-left: 1px solid rgba(144, 162, 220, 0.4);
        "
        v-if="recordData.length"
      >
        <div
          v-for="(item, index) in recordData.length > 50 ? 50 : recordData"
          @click="reIndex = index"
          :key="index"
          :style="reIndex == index ? 'background-color:#3a4768' : ''"
          style="height: 40px; border-top: 1px solid rgba(144, 162, 220, 0.4)"
          class="flexs_r_c canClick hov_item"
        >
          <div
            style="
              width: 102px;
              text-align: center;
              font-size: 12px;
              color: #8491a5;
              border-right: 1px solid rgba(144, 162, 220, 0.4);
              margin-left: 10px;
              height: 40px;
              line-height: 40px;
            "
          >
            {{ recordData[index].Number }}
          </div>

          <div class="openCodeList">
            <div style="">
              {{ getCurrentType(4, recordData[index]) }}
            </div>

            <div
              v-if="getCurrentType(0, recordData[index]) == 'big'"
              style="color: #f94168"
            >
              Tài
            </div>
            <div v-else style="color: #26a1ff">Xỉu</div>

            <div
              v-if="getCurrentType(1, recordData[index]) == 'single'"
              style="color: #f94168"
            >
              Lẻ
            </div>
            <div v-else style="color: #26a1ff">Chẵn</div>

            <div
              v-if="getCurrentType(2, recordData[index]) == 'top'"
              style="color: #f94168"
            >
              Trên
            </div>
            <div v-else-if="getCurrentType(2) == 'center'" style="color: #26a1ff">
              Hoà
            </div>
            <div v-else style="color: #909090">Dưới</div>

            <div
              v-if="getCurrentType(3, recordData[index]) == 'jin'"
              style="color: #ffbb00"
            >
              Kim
            </div>
            <div
              v-else-if="getCurrentType(3, recordData[index]) == 'mu'"
              style="color: #6dd400"
            >
              Mộc
            </div>
            <div
              v-else-if="getCurrentType(3, recordData[index]) == 'shui'"
              style="color: #26a1ff"
            >
              Thủy
            </div>
            <div
              v-else-if="getCurrentType(3, recordData[index]) == 'huo'"
              style="color: #f94168"
            >
              Hỏa
            </div>
            <div v-else style="color: #ca8c10">Thổ</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <vipInfo v-if="showVip" :info="selectInfo" @close="showVip = false"></vipInfo>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import ProgressBar from "./ProgressBar.vue";
import vipInfo from "./vipInfo.vue";

export default {
  components: {
    ProgressBar,
    vipInfo,
  },
  data() {
    return {
      titleArr: ["Tài Xỉu", "Chẵn lẻ", "Trên hòa dưới", "Ngũ hành"],
      selectIndex: 0,
      bigSmallData: [],
      twoOneData: [],
      upDownData: [],
      fiveData: [],
      recordData: [],
      showVip: false,
      selectTS: [],
      reIndex: 0,
      showPop: false,
    };
  },

  props: {
    data: {},
    playData: {},
    selectLottery: {},
  },
  setup() {},
  computed: {},
  mounted() {
    var that = this;
    //  bus.off('freshVData')
    //  bus.on('freshVData', (val) => {

    that.recordData = this.data;
    that.countBigSmall(this.data);
    that.countDoubleSingle(this.data);
    that.countUpDown(this.data);
    that.countFiveData(this.data);
    // });
    bus.on("freshVData", (val) => {
      console.log("rrrr", val.LotteryCode, that.selectLottery.LotteryCode);
      if (val.LotteryCode == that.computedselectLottery.LotteryCode)
        that.recordData = val.data;
      that.countBigSmall(val.data);
      that.countDoubleSingle(val.data);
      that.countUpDown(val.data);
      that.countFiveData(val.data);
    });
    bus.on("allClean", (val) => {
      that.selectTS = [];
    });
    //	this.countBigSmall(null)
  },
  methods: {
    reClick(index) {
      this.reIndex = index;
      this.showPop = false;
      document.getElementById("reList").scrollTop = 40 * index;
    },
    showInfo(text) {
      this.selectInfo = text;
      this.showVip = true;
    },
    containTS(str) {
      //var contain = false
      for (var item of this.selectTS) {
        if (str == item) {
          return true;
        }
      }
      return false;
    },
    tsClick(str, code, odd, code1) {
      var contain = false;
      var newStr = code;
      for (var i = 0; i < this.selectTS.length; i++) {
        if (this.selectTS[i] == newStr) {
          contain = true;
          this.selectTS.splice(i, 1);

          break;
        }
      }
      if (!contain) {
        this.selectTS.push(newStr);
      }

      var obj = {
        num: code,
        Odds: odd * 1000,
        betType: "VIP",
        SubPlayCode: code1,
        Name: str,

        UnitBetAmount: 1000,
      };
      bus.emit("BET", obj);
    },

    getSZX(type, data) {
      if (type == "S" || type == "X") {
        for (var obj of data) {
          if (obj.SubPlayCode == "UPDOWN_UP_DOWN") {
            return obj.Odds;
          }
        }
      } else {
        for (var obj of data) {
          if (obj.SubPlayCode == "UPDOWN_DRAW") {
            return obj.Odds;
          }
        }
      }
    },
    getJMSHT(type, data) {
      if (type == "J" || type == "T") {
        for (var obj of data) {
          if (obj.SubPlayCode == "ELE5_JT") {
            return obj.Odds;
          }
        }
      } else if (type == "M" || type == "H") {
        for (var obj of data) {
          if (obj.SubPlayCode == "ELE5_MH") {
            return obj.Odds;
          }
        }
      } else {
        for (var obj of data) {
          if (obj.SubPlayCode == "ELE5_S") {
            return obj.Odds;
          }
        }
      }
    },

    getPersent(type) {
      //0大 1小 2单 3双
      var arr;
      if (type == 0 || type == 1) {
        arr = this.bigSmallData;
      } else if (type == 2 || type == 3) {
        arr = this.twoOneData;
      } else if (type == 4 || type == 5 || type == 6) {
        arr = this.upDownData;
      } else {
        arr = this.fiveData;
      }

      var count = 0;
      var total = 0;

      for (var i = 0; i < (arr.length >= 16 ? 16 : arr.length); i++) {
        var tmpArr = arr[i];

        for (var j = 0; j < tmpArr.length; j++) {
          var str = tmpArr[j];
          total++;

          if (type == 0) {
            if (str == "big") {
              count++;
            }
          } else if (type == 1) {
            if (str == "small") {
              count++;
            }
          } else if (type == 2) {
            if (str == "single") {
              count++;
            }
          } else if (type == 3) {
            if (str == "double") {
              count++;
            }
          } else if (type == 4) {
            if (str == "top") {
              count++;
            }
          } else if (type == 5) {
            if (str == "center") {
              count++;
            }
          } else if (type == 6) {
            if (str == "bottom") {
              count++;
            }
          } else if (type == 7) {
            if (str == "jin") {
              count++;
            }
          } else if (type == 8) {
            if (str == "mu") {
              count++;
            }
          } else if (type == 9) {
            if (str == "shui") {
              count++;
            }
          } else if (type == 10) {
            if (str == "huo") {
              count++;
            }
          } else if (type == 11) {
            if (str == "tu") {
              count++;
            }
          }
        }
      }

      return (count / total) * 100;
    },

    getCurrentType(type, data) {
      if (!data) {
        return "";
      }
      var total = 0;
      var arr = data.OpenCode.L0;
      var topCount = 0;
      var bottomCount = 0;

      for (var num of arr) {
        total += parseInt(num);
        if (num > 0 && num < 41) {
          topCount++;
        } else if (num >= 41) {
          bottomCount++;
        }
      }
      if (type == 0) {
        if (total >= 811) {
          return "big";
        } else {
          return "small";
        }
      } else if (type == 1) {
        if (total % 2 == 0) {
          return "double";
        } else {
          return "single";
        }
      } else if (type == 2) {
        if (topCount > 10) {
          return "top";
        } else if (bottomCount > 10) {
          return "bottom";
        } else {
          return "center";
        }
      } else if (type == 3) {
        if (total >= 210 && total <= 695) {
          return "jin";
        } else if (total >= 696 && total <= 763) {
          return "mu";
        } else if (total >= 764 && total <= 855) {
          return "shui";
        } else if (total >= 856 && total <= 923) {
          return "huo";
        } else if (total >= 924 && total <= 1410) {
          return "tu";
        }
      } else {
        return total;
      }
    },

    countDoubleSingle(val) {
      var data = this.data;
      if (val) {
        data = val;
      }
      var index = 0;
      var indexArr = [];
      var tmpArr = [];

      var lastStr;
      for (var i = 0; i < data.length; i++) {
        var total = 0;
        var arr = data[i].OpenCode.L0;
        for (var num of arr) {
          total += parseInt(num);
        }

        var str;
        if (total % 2 == 0) {
          str = "double";
        } else {
          str = "single";
        }
        if (lastStr == str) {
          if (indexArr.length < 6) {
            indexArr.push(str);
            tmpArr[index] = indexArr;
          } else {
            indexArr = [];
            index++;
            indexArr.push(str);
          }
        } else {
          indexArr = [];
          if (lastStr) {
            index++;
          }
          indexArr.push(str);
        }
        tmpArr[index] = indexArr;

        lastStr = str;
      }
      if (tmpArr.length > 18) {
        tmpArr = tmpArr.slice(0, 18);
      }
      var arrs = [];
      for (var i = tmpArr.length - 1; i >= 0; i--) {
        arrs.push(tmpArr[i]);
      }

      this.twoOneData = arrs;
    },
    countFiveData(val) {
      var data = this.data;
      if (val) {
        data = val;
      }
      var index = 0;
      var indexArr = [];
      var tmpArr = [];

      var lastStr;
      for (var i = 0; i < data.length; i++) {
        var total = 0;
        var arr = data[i].OpenCode.L0;
        for (var num of arr) {
          total += parseInt(num);
        }

        var str;
        if (total >= 210 && total <= 695) {
          str = "jin";
        } else if (total >= 696 && total <= 763) {
          str = "mu";
        } else if (total >= 764 && total <= 855) {
          str = "shui";
        } else if (total >= 856 && total <= 923) {
          str = "huo";
        } else if (total >= 924 && total <= 1410) {
          str = "tu";
        }
        if (lastStr == str) {
          if (indexArr.length < 6) {
            indexArr.push(str);
            tmpArr[index] = indexArr;
          } else {
            indexArr = [];
            index++;
            indexArr.push(str);
          }
        } else {
          indexArr = [];
          if (lastStr) {
            index++;
          }
          indexArr.push(str);
        }
        tmpArr[index] = indexArr;

        lastStr = str;
      }

      // if(tmpArr.length>16){
      // 	tmpArr = tmpArr.slice(0,16)
      // }
      // var arrs = []
      // for(var i=tmpArr.length-1;i>=0;i--){
      // 	arrs.push(tmpArr[i])
      // }
      //  //console.log('8888888',arrs)

      // this.fiveData = arrs
      this.fiveData = tmpArr;
    },

    countUpDown(val) {
      var data = this.data;
      if (val) {
        data = val;
      }
      var index = 0;
      var indexArr = [];
      var lastStr;
      var tmpArr = [];
      for (var i = 0; i < data.length; i++) {
        var total = 0;
        var arr = data[i].OpenCode.L0;

        var topCount = 0;
        var bottomCount = 0;

        for (var num of arr) {
          if (num > 0 && num < 41) {
            topCount++;
          } else if (num >= 41) {
            bottomCount++;
          }
        }

        var str;
        if (topCount > 10) {
          str = "top";
        } else if (bottomCount > 10) {
          str = "bottom";
        } else str = "center";
        if (lastStr == str) {
          if (indexArr.length < 6) {
            indexArr.push(str);
            tmpArr[index] = indexArr;
          } else {
            indexArr = [];
            index++;
            indexArr.push(str);
          }
        } else {
          indexArr = [];
          if (lastStr) {
            index++;
          }
          indexArr.push(str);
        }
        //this.bigSmallData[index] = indexArr
        tmpArr[index] = indexArr;
        lastStr = str;
      }
      //this.upDownData = tmpArr

      if (tmpArr.length > 18) {
        tmpArr = tmpArr.slice(0, 18);
      }
      var arrs = [];
      for (var i = tmpArr.length - 1; i >= 0; i--) {
        arrs.push(tmpArr[i]);
      }

      this.upDownData = arrs;
    },
    countBigSmall(val) {
      //>881大  1-40超过10个上 41-80>10个 下  和 1-40等于10
      var data = this.data;
      if (val) {
        data = val;
      }
      var index = 0;
      var indexArr = [];
      var lastStr;
      var tmpArr = [];
      for (var i = 0; i < data.length; i++) {
        var total = 0;
        var arr = data[i].OpenCode.L0;

        for (var num of arr) {
          total += parseInt(num);
        }

        var str;
        if (total >= 811) {
          str = "big";
        } else {
          str = "small";
        }
        if (lastStr == str) {
          if (indexArr.length < 6) {
            indexArr.push(str);
            tmpArr[index] = indexArr;
          } else {
            indexArr = [];
            index++;
            indexArr.push(str);
            tmpArr[index] = indexArr;
          }
        } else {
          indexArr = [];
          if (lastStr) {
            index++;
          }
          indexArr.push(str);
          tmpArr[index] = indexArr;
        }
        //this.bigSmallData[index] = indexArr
        lastStr = str;
      }
      //this.bigSmallData = tmpArr

      if (tmpArr.length > 18) {
        tmpArr = tmpArr.slice(0, 18);
      }
      var arrs = [];
      for (var i = tmpArr.length - 1; i >= 0; i--) {
        arrs.push(tmpArr[i]);
      }

      this.bigSmallData = arrs;
    },
  },
};
</script>

<style scoped>
.flexs_r {
  display: flex;
  flex-direction: row;
}
.select {
  color: #fff;
}
.line {
  position: absolute;
  right: 8%;
  top: auto;
  bottom: 5px;
  width: 80%;
  height: 1px;
  background-color: white;
}
.nolmal {
  color: #8491a5;
}
.tag {
  padding: 0 5px;
  border-radius: 2px;
  height: 29px;
  color: white;
  font-size: 12px;
  text-align: center;
  line-height: 29px;
}
.redTag {
  background-color: #f94168;
}
.blueTag {
  background-color: #26a1ff;
}
.grayTag {
  background-color: #909090;
}
.jinTag {
  background-color: #ffbb00;
}
.muTag {
  background-color: #6dd400;
}
.tuTag {
  background-color: #ca8c10;
}
.corner {
  width: 25px;
  height: 25px;
  border-radius: 12.5px;
  color: white;
  font-size: 15.5px;
  text-align: center;
  line-height: 25px;
}

.playSelect {
  background-color: #173e83;
}
.playNolmal {
  background-color: #4a5fa9;
}
.fourbtn {
  width: 200px;
  height: 71px;
  text-align: center;
  border-radius: 3px;
  color: white;
  font-size: 14.5px;
}
.threeBtn {
  width: 270px;
  height: 71px;
  text-align: center;
  color: white;
  font-size: 14.5px;
  border-radius: 3px;
}
.fiveBtn {
  width: 156px;
  height: 71px;
  text-align: center;
  color: white;
  font-size: 14.5px;
  border-radius: 3px;
}
.selectTop {
  margin: 0px auto;
  background-color: transparent;
  border: 1px solid #4a69ff;
  border-radius: 12px;
  font-size: 13px;
  color: #4a69ff;
  font-weight: 400;
  text-align: center;
  width: 180px;
  height: 28px;
  line-height: 28px;
}
:deep(.el-select__caret) {
  width: 25px;
  line-height: 28px;
  font-size: 14px;
  transform: rotate(180deg);
  color: #4a69ff !important;
}
.rightLine {
  content: "";
  width: 1px;
  height: 20px;
  position: absolute;
  left: -1px;
  top: 18px;
  background-color: rgba(144, 162, 220, 0.5);
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.reSelect {
  background-color: #4a69ff;
  color: white;
  font-size: 14px;
}
.reNol {
  background-color: #2e3342;
  color: #90a2dc;
  font-size: 14px;
}
.reList:hover {
  background-color: #4a69ff;
  color: white;
}

.openCodeList {
  background: #363d4e;
  flex: none;
  padding: 0 9px;
  border-radius: 13px;
  width: 192px;
  margin-left: 12px;
  height: 26px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}
.hov_item:hover {
  background-color: #3a4768;
}
</style>

<style>
.el-popper .is-light {
  padding: 0;
}
</style>
