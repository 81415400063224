<template>
    <div class="agency">
        <div class="container">
            <ul class="menubar">
                <li class="menuitem" :class="selecteIndex == 0 ? 'selected' : ''" @click="selecteIndex = 0">
                    <div>Thỏa thuận nhượng quyền thương mại</div>
                </li>
                <li class="menuitem" :class="selecteIndex == 1 ? 'selected' : ''" @click="selecteIndex = 1">
                    <div>Chi tiết đại lý</div>
                </li>
            </ul>
            <div class="right">
                <div v-if="selecteIndex == 0">
                    <AgencyCooperate />
                </div>
                <div v-if="selecteIndex == 1">
                    <AgencyContact />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AgencyCooperate from "@/views/pc/components/agency/AgencyCooperate.vue"
import AgencyContact from "@/views/pc/components/agency/AgencyContact.vue"
import { ref } from "vue"
let [showAbout, showAgent] = [ref(false), ref(false)];
let selecteIndex = ref(0);
</script>

<style lang="scss" scoped>
.agency {
    position: relative;
    margin: 0 auto;
    width: 1400px;
    padding-top: 24px;

    .container {
        display: flex;
        border-radius: 8px;
        overflow: hidden;

        .menubar {
            background: #292e3b;
            min-width: 280px;
            border-right: 0;
            margin-right: 14px;
            border-radius: 8px;
            overflow: hidden;
            height: fit-content;
            list-style: none;
            position: relative;

            .menuitem {
                padding-left: 20px;
                font-size: 14px;
                color: #8491a5;
                height: 58px;
                line-height: 58px;
                position: relative;
                box-shadow: inset 0 -0.5px 0 0 rgba(144, 162, 220, .29);
                padding: 0 20px;
                list-style: none;
                cursor: pointer;
                transition: border-color .3s, background-color .3s, color .3s;
                box-sizing: border-box;
                white-space: nowrap;
            }

            .menuitem:hover {
                background: #4a69ff;
                font-size: 14px;
                color: #fff;
                box-shadow: inset 0 -0.5px 0 0 rgba(144, 162, 220, .29);
            }

            .selected {
                background: #4a69ff;
                font-size: 14px;
                color: #fff !important;
            }
        }



        .right {
            background: #292e3b;
            color: #f9f9f9;
            padding: 20px 20px 44px;
            width: 1156px;
            border-radius: 8px;
            overflow: hidden;
        }
    }
}
</style>