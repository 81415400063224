import Fingerprint from "fingerprintjs2";
import log from "@/utils/logger";
import { req, qs } from "@/utils/request";
import { http, topic } from "@/components/protocol/api";
import {mqant_lottery} from "@/components/protocol/mqantlib";
import { bus, busName } from "@/components/core/bus";
import tools from "@/utils/tools.js"
import router from '@/router/index'
import { Toast } from 'vant';
import i18n from '@/language/i18n'
import { mapMutations ,mapActions} from "vuex";
import { ElMessage } from "element-plus";
import { getApiLoginUrl } from "@/components/protocol/api";
import axios from "axios"; // 引入axios
const reconnectTime = 3000
let reconnect = true
let connecting = false
let timer = null

export const tcp_lottery = {
  namespaced: true,
  state: {
    isLogged: false,
    isConnect: false,
    Lotteries:null,
    Plays:{},
    Timestamp:null,
    isGetConfig:false
   
  },
  mutations: {
   
    setIsConnect (state, connect) {
      state.isConnect = connect
      if(!connect){
        state.isGetConfig = false;
      }
    },
    setLogged (state, logged) {
      state.isLogged = logged
    },
    setLotteries(state, Lotteries){
      state.Lotteries = Lotteries
      state.isGetConfig = true;
    
      if(timer){
        clearInterval(timer);
      }
     
      var that = this

      
     
      timer = setInterval(() => {
        for (var key in Lotteries) {
        

          var arr = Lotteries[key]
          for(var i=0;i<arr.length;i++){
            var obj = arr[i]
            if(obj.Countdown>0){
              obj.Countdown --
            }else {
              obj.Countdown = obj.Intervals

            }

            arr[i] = obj

          }

          Lotteries[key] = arr

          this.commit('tcp_lottery/setLotteries',Lotteries)

         
          
        }
        
       
        
        
        if(state.Timestamp){
          state.Timestamp+=1000
        }
      
      }, 1000);
      
    },
    setPlays(state, Plays){
      state.Plays = Plays
    },
    setTimestamp(state, Timestamp){

      state.Timestamp = Timestamp


    },
  },
  actions: {
    connect_lottery ({ commit, dispatch, rootGetters, getters,setters}) {
      if (this.connecting) {
        log.warn("cur connect state is connecting...")
        return
      }
      reconnect = true
      connecting = true
      return new Promise((resolve, reject) => {
        
        let host = rootGetters["global/lobbyInfo"].TcpInfo.lottery.TcpHost
        let port = rootGetters["global/lobbyInfo"].TcpInfo.lottery.WssPort
        let useSSL = "https:" == document.location.protocol ? true : false;
        let client_id = rootGetters["global/uuid"]+'lottery'
        log.info("client_id:" + client_id,)
        if (host.indexOf("wss") == 0) {
          useSSL = true;
        }
        mqant_lottery.init({
          host: host,
          port: port,
          client_id: client_id,
          useSSL: useSSL,
          onSuccess: function (data) {
            connecting = false
            reconnect = true
            // ElMessage.success('彩票连接上了---------')
            log.info('彩票连接上了---------');
           
            bus.emit('lotteryConnect')
            commit("setIsConnect", true)
           // Toast.clear()
            mqant_lottery.on("game/push", function (data2, topic, msg2) {
              // if (data2.Code === 302) {
              //   reconnect = false
              //   dispatch("user/doLogout", {}, { root: true })
              // }
               log.info("pushbbb:" + topic)
               log.info(data2);
              if(data2.Action=="noticeOpen"){
                bus.emit('noticeOpen',data2.Data)
              }
              if(data2.Action=="betResult"){
                bus.emit('betResult',data2.OpenData[0])
              }
              
              if(data2.Action=="timestamp"){

                commit('setTimestamp', data2.Data.Timestamp)

                var lotterys = getters["Lotteries"]

                var CountDownTime = data2.Data.CountDownTime
                  

                for(var key in lotterys){
                  var arr = lotterys[key]
                  for(var i=0;i<arr.length;i++){
                    var obj = arr[i]  

                    

                    if(CountDownTime[obj.LotteryCode]){
                      obj.Countdown = CountDownTime[obj.LotteryCode]
                    }

                    arr[i] = obj
                    
                  }

                  lotterys[key] = arr
                }

                
                  commit('setLotteries', lotterys)
            
                

              }
              
              // console.log(msg2.payloadString)
              dispathcPush(commit, dispatch, rootGetters, getters, data2, topic, msg2)
            });
            resolve(true);
            // dispatch("tcp/login", {}, { root: true }).then(succ => {
            //   resolve();
            //   bus.emit('onConnected')
            //   commit("setIsConnect", true)
            // }, failed => { })
          },
          onConnectionLost: function (responseObject) {
            connecting = false;
            commit("setIsConnect", false);
            bus.emit(bus.event.showLoading,true);
            log.error("彩票断开了长连接----------");
            // ElMessage.error('彩票断开了长连接---------')
            if (reconnect) {
              log.error("彩票长连接中重连----------");
              setTimeout(() => {
                // dispatch("connect_lottery").then(succ => {
                //   resolve();
                // })
                dispatch("connect_lottery").then(()=>{
                  dispatch("user/autoLogin", {}, { root: true }).then(()=>{
                    bus.emit(bus.event.showLoading,false);
                  })
                })
            }, reconnectTime);

            }
          },
          onFailure: function () {
            connecting = false;
            commit("setIsConnect", false);
            bus.emit(bus.event.showLoading,true);
            log.error("彩票长连接出错了----------");
            // ElMessage.error('彩票长连接出错了---------')
            if (reconnect) {
              log.error("彩票长连接出错重连----------");
              setTimeout(() => {
                dispatch("connect_lottery").then(succ => {
                  resolve();
                })
            }, reconnectTime);
            }
          },
        });
      })
    },
    disConnect ({ commit, dispatch, rootGetters, getters }) {
      commit("setLogged", false)
      reconnect = false
      connecting = false
      if (mqant_lottery .isConnect()) mqant_lottery .disConnect();
    },
    login({ commit, dispatch, rootGetters, getters }) {
      let name = "apiLottery";
      let url = getApiLoginUrl(name, "");
      return new Promise((resolve, reject)=>{
        axios
        .get(url, {
          params: {},
        }).then(function (res) {
          var newUrl = res.request.responseURL;
          let p = newUrl.split("?")[1];

          let keyValue = p.split("&");
          let obj = {};
          for (let i = 0; i < keyValue.length; i++) {
            let item = keyValue[i].split("=");
            let key = item[0];
            let value = item[1];
            obj[key] = value;
          }

          let token = obj.token;
          mqant_lottery.request(
            topic.tcpLogin,
            {
              token: token,
            },
            function (res, topic, msg) {
              // ElMessage.success('彩票登录成功了---------')
              log.info('彩票登录成功了---------');
              if(res.Code == 0){
                commit("setLogged", true);
                resolve(true);
              }else{
                commit("setLogged", false);
                reject();
              }
            }
          );
        });
        })
      }

      // return new Promise((resolve, reject) => {
      //   mqant.request(
      //     topic.tcpLogin,
      //     {
      //       uid: clientId,
      //       token: token.AccessToken,
      //     },
      //     function (res, topic, msg) {
      //       //TODO
      //       if (res.Code == 0) {
      //         dispatch('tcp_lottery/login',{},{root:true}).then(()=>{
      //           log.info('login-success',res)
      //           commit("setLoggedData", res.Data)
      //           commit("setLogged", true)
      //           dispatch("requestNotice" )
      //           dispatch("pay/requestPayInfo",{},{root:true}  )
      //           dispatch("requestBasicInfo" )
      //           commit("user/setUserInfo", res.Data.user, { root: true })
      //           bus.emit('onConnected')
      //           bus.emit(bus.event.showLoading,false);
      //           resolve()
      //         },()=>{
      //           reject()
      //         });
      //       } else if (res.Code == 401) {
      //         reconnect = false;
      //         reject();
      //         dispatch("user/doLogout", {}, { root: true })
      //       }
      //     }
      //   );
      // })
      
      // axios
      //   .get(url, {
      //     params: {},
      //   }).then(function (res) {
      //     var newUrl = res.request.responseURL;
      //     let p = newUrl.split("?")[1];

      //     let keyValue = p.split("&");
      //     let obj = {};
      //     for (let i = 0; i < keyValue.length; i++) {
      //       let item = keyValue[i].split("=");
      //       let key = item[0];
      //       let value = item[1];
      //       obj[key] = value;
      //     }

      //     let token = obj.token;
      //     mqant_lottery.request(
      //       topic.tcpLogin,
      //       {
      //         token: token,
      //       },
      //       function (res, topic, msg) {
      //         // ElMessage.success('彩票登录成功了---------')
      //         log.info('彩票登录成功了---------');
      //         commit("setLogged", true)
      //       }
      //     );
      //   });
      //   },
  },
  getters: {
    logging (state) {
      return state.logging
    },
    isConnect (state) {
      return state.isConnect
    },
    isLogged (state) {
      return state.isLogged
    },
    Lotteries(state){
      return state.Lotteries
    },
    Plays(state){
      return state.Plays

    },
    isGetConfig(state){
      return state.isGetConfig
    },
    Timestamp(state){
      return state.Timestamp

    }
  }
}
function dispathcPush (commit, dispatch, rootGetters, getters, data, topic, msg) {
  switch (data.Action) {
    case "wallet":
      commit("tcp/setWallet", data.Wallet, { root: true })

    // case "all":
    //   dispatchAllAction(commit, dispatch, rootGetters, getters, data, topic, msg)
    //   break;
    // case "chat":
    //   log.info(msg.payloadString);
    //   if (data.Action === "newMessage") {
    //     that.msgList.unshift(data.msg);
    //   }
    //   break;
    // case "pay":
    //   // console.log(msg.payloadString);
    //   if (data.Action === "HD_giftCode") {
    //     bus.emit('giftCodeResult', {
    //       data: data
    //     })
    //   }
    //   break;
    // case "lobby":
    //   switch (data.Action) {
    //     case "HD_NotifyJackpot":
    //       bus.emit('showGIF', {
    //         data: data.Data
    //       })
    //     case "HD_login":
    //       if (data.Code == 302) {
    //         bus.emit('loginOut')
    //         // dispatch("user/doLogout", {}, { root: true })
    //       }
    //       break;
    //   }
    //   break;
    // case "activity":

    //   if (data.Action == 'HD_ReceiveVipGiftAll') {
    //     bus.emit('receiveGift', {
    //       data: data
    //     })
    //   } else {
    //     bus.emit('receiveGift2', {
    //       data: data
    //     })
    //   }
    //   if (data.Action == 'HD_ReceiveMemberAll') {
    //     bus.emit('receiveMemberAll', {
    //       data: data
    //     })
    //   }
    //   if (data.Action == 'HD_StartTurnTable') {
    //     bus.emit('HD_StartTurnTable', {
    //       data: data
    //     })
    //   }
    //   break
    default:
    // log.warn(data);
    // console.log(msg2.payloadString)
  }
}

function dispatchAllAction (commit, dispatch, rootGetters, getters, data, topic, msg) {
  switch (data.Action) {
    case "wallet":
      break;
  }
}