<template>
  <div
    @click="cancel()"
    class="wrap"
    style="
      width: 100%;
      height: 100%;
      font-weight: bold;
      position: fixed;
      z-index: 999;
      background-color: rgba(0, 0, 0, 0.6);
      top: 0;
      left: 0;
      overflow-y:scroll;
      
    "
  >
    <div @click.stop="" class="overlay-wrapper">
      <div
        style="
          height: 40px;
          position: relative;
          border-top-left-radius: 2.133333vw;
          border-top-right-radius: 2.133333vw;
          line-height: 40px;
          font-size: 12px;
          font-weight: bold;
          position: relative;
          color: #151a2e;
          border-bottom: 1px solid #afb8e5;
          text-align: center;
        "
      >
        <span
          class="overlay-xbtn iconfont icon-icon_close_white"
          @click="cancel()"
        ></span>
        Cách chơi
        <!-- <img
          src="@/assets/mobile/lottery/close.png"
          alt=""
          @click="cancel()"
          class="canClick"
          style="width: 20px; position: absolute; right: 10px; top: 5px"
        /> -->
      </div>
      <div
        class="info"
        style="
          padding-bottom: 4.8vw;
          height: inherit;
          word-break: break-word;
          overflow-y: scroll;
          line-height: 4.533333vw;
          font-size: 3.466667vw;
          white-space: pre-line;
          padding: 2.666667vw 4.266667vw 0;
          color: #464646;
        "
      >
        {{ info }}
      </div>
      <div class="btn-wp" @click="cancel()"><div class="btn">Xác nhận</div></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    info: {},
  },
  created() {},
  methods: {
    cancel() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
.info::-webkit-scrollbar {
  display: none;
}
.info {
  /* 隐藏滚动条 */
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
}
.overlay-wrapper {
 // position: absolute;
  //top: 50%;
  //left: 50%;
  //-webkit-transform: translate(-50%, -50%);
  //transform: translate(-50%, -50%);
  margin :100px auto;
  width: 72.266667vw;
  border-radius: 2.133333vw;
  background-color: white;
  
}
.overlay-xbtn {
  position: absolute;
  right: 3.733333vw;
  font-size: 3.04vw;
}
.btn-wp {
  padding: 4.266667vw 6.133333vw;
  .btn {
    height: 10.4vw;
    line-height: 10.4vw;
    background: #5927ff;
    color: #fff;
    border-radius: 2.133333vw;
    text-align: center;
    font-size: 3.733333vw;
  }
}
</style>
