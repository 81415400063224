<template>
  <div class="header">
    <div class="left">
      <div class="logoBox"></div>
    </div>
    <div class="rightInfo">
      <p class="loginBox" v-if="!isLogged">
        <span class="register" @click="toLoginRegist('regist')">Đăng ký</span><span class="login"
          @click="toLoginRegist('login')">Đăng nhập</span>
      </p>
      <div class="moneyBox" v-else>
        <div class="money">
          <p>{{ userInfo.Account }}</p>
          <p class="total_money">{{ $tools.formatNum2k(wallet.VndBalance) }}</p>
        </div>
        <i class="iconfont icon-icon_refresh_gold_12"></i>
      </div>
      <van-popover v-model:show="showPopover" v-if="isLogged" placement="bottom-end">
        <ul class="funds">
          <!-- <li class="arrow"></li> -->
          <li class="fundItem" @click="openUserRouter('/recharge')">
            <i class="iconfont icon-icon_fund_topup"> </i> Nạp tiền
          </li>
          <li class="fundItem" @click="openUserRouter('/withdraw')">
            <i class="iconfont icon-icon_fund_withdraw"></i>
            Rút Tiền
          </li>
          <!-- <li class="fundItem">
            <i class="iconfont icon-icon_transfer_amount"></i>
            Chuyển đổi tiền
          </li> -->
          <li class="fundItem" @click="quitLogin">
            <i class="iconfont icon-icon_fault"></i> Đăng xuất
          </li>
        </ul>
        <template #reference>
          <span class="recharge">
            Số tiền <i class="van-icon van-icon-arrow-down arrowDown"></i></span>
        </template>
      </van-popover>
    </div>
  </div>
</template>

<script>
import { bus, busName } from "@/components/core/bus";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { mqant } from "@/components/protocol/mqantlib";
import { Dialog, Toast } from "vant";
import "vant/es/dialog/style";
import { topic, getApiLoginUrl } from "@/components/protocol/api";
import action from '@/components/core/action'
export default {
  data() {
    return {
      showPopover: false,
      showWithDraw: true,
    };
  },
  props: {},
  computed: {
    ...mapGetters("tcp", ["isLogged", "wallet", "basicInfo"]),
    ...mapGetters("user", ["userInfo",]),
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  created() {
    var that = this;
  },
  methods: {
    ...mapActions("tcp", ["disConnect"]),
    // ...mapMutations("user", ["loginOut"]),
    ...mapActions("global", ["loginOutAll"]),
    toLoginRegist(type) {
      this.$router.push({ name: "LoginRegist", query: { type: type } });
    },
    openUserRouter(name) {
      if (name == "/withdraw") {
        var that = this;
        mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
          if (!data.Data.DoudouPwd.length) {
            Dialog.confirm({
              title: "Xin lưu ý",
              message:
                "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
              confirmButtonText: 'Xác nhận',
              cancelButtonText: 'Hủy bỏ'
            })
              .then(() => {
                that.$router.push({ path: "/transactionPwd" });
              })
              .catch(() => { });
          } else if (!data.Data.myDouDouBT.length) {
            Dialog.confirm({
              title: "Xin lưu ý",
              confirmButtonText: 'Xác nhận',
              cancelButtonText: 'Hủy bỏ',
              message:
                'Cần điền thông tin ngân hàng',
            })
              .then(() => {
                that.$router.push({ path: '/addBankCard' });
              })
              .catch(() => { });

          } else {
            that.$router.push({ path: name });
          }
        });
      } else if (name == "/recharge") {
        var that = this;
        mqant.request(topic.userInfo, {}, function (data, topicName, msg) {
          if (!data.Data.DoudouPwd.length) {
            Dialog.confirm({
              title: "Xin lưu ý",
              message:
                "Quý khách chưa cài đặt mật khẩu giao dịch, vui lòng cài đặt!",
              confirmButtonText: 'Xác nhận',
              cancelButtonText: 'Hủy bỏ'
            })
              .then(() => {
                that.$router.push({ path: "/transactionPwd" });
              })
              .catch(() => { });
          } else
            if (!data.Data.myDouDouBT.length) {
              Dialog.confirm({
                title: "Xin lưu ý",
                confirmButtonText: 'Xác nhận',
                cancelButtonText: 'Hủy bỏ',
                message:
                  'Cần điền thông tin ngân hàng',
              })
                .then(() => {
                  that.$router.push({ path: '/addBankCard' });
                })
                .catch(() => { });

            } else {
              that.$router.push({ path: name });
            }
        });
      } else {
        this.$router.push({ path: name });
      }
    },
    quitLogin() {
      this.$router.push({ path: "/" });
      this.loginOutAll();
      Toast.success('Thoát thành công')
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  background-color: #293356;
  box-sizing: border-box;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.08);

  .left {
    width: 152px;
    display: flex;
    align-items: center;
    padding-left: 16px;

    .logoBox {
      width: 136px;
      height: 34px;
      background-size: 100% 100%;
      background-image: url("@/assets/common/logo.png");
    }
  }

  .rightInfo {
    display: flex;
    align-items: center;
    padding-right: 16px;

    .loginBox {
      .register {
        border-right: 1px solid #e9cfa4;
      }

      span {
        height: 3.733333vw;
        padding: 0 2.666667vw;
        line-height: 3.733333vw;
        font-size: 3.2vw;
        color: #e9cfa4;
        display: inline-block;
        text-align: center;
      }
    }
  }
}

.moneyBox {
  display: flex;
  align-items: center;
  margin-right: 2.133333vw;
  color: #e9cfa4;

  .iconfont {
    display: block;
    font-size: 5.333333vw;
  }

  .money {
    font-size: 3.2vw;
    padding-top: 1.066667vw;
    text-align: right;
    margin-right: 1.6vw;

    p {
      padding: 0;
      margin: 0;
      line-height: 3.733333vw;
    }

    p:first-child {
      color: #90a2dc;
    }

    .total_money {
      overflow: hidden;
      width: 26.666667vw;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.van-popover__wrapper {
  display: inline-block;

  .recharge {
    width: 17.066667vw;
    background: #e9cfa4;
    color: #293356;
    border: none;
    padding: 0;
    display: inline-block;
    height: 7.466667vw;
    border-radius: 1.6vw;
    font-size: 3.2vw;
    line-height: 7.466667vw;
    text-align: center;
    box-sizing: border-box;
  }
}

.funds {
  padding: 0.8vw 0;

  .fundItem {
    width: 34.666667vw;
    height: 8vw;
    box-shadow: inset 0 -1px 0 0 rgba(83, 88, 91, 0.05);
    font-size: 3.466667vw;
    color: rgba(83, 88, 91, 0.9);
    line-height: 8vw;
    padding-left: 1.333333vw;
    align-items: center;
    display: flex;

    i {
      font-size: 4vw;
      background-image: linear-gradient(137deg, #a98fff 1%, #4a69ff 99%);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      margin-right: 1.333333vw;
    }
  }
}

ol,
ul {
  list-style: none;
}
</style>
